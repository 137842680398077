import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {v4 as uuid} from "uuid"

import { DashboardNavBar } from "../../../../components/DashboardNavBar/DashboardNavBar";
import { SideMenuLayout } from "../../../../layout/sideMenuLayout/SideMenuLayout";
import { getInitialOperatorJobsThunk } from "../../thunks/getInitialOperatorJobs";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { getLoadMoreOperatorJobsThunk } from "../../thunks/getLoadMoreOperatorJobs";
import { JobMarketplaceCard } from "../../../../components/JobMarketplaceCard/JobMarketplaceCard";
import { MarketplaceHeader } from "../../../../components/MarketplacHeader/MarketplaceHeader";
import { FilterPopUp } from "../../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { MARKETPLACE_TYPES } from "../../../../constants/e9Constants";

import {
  resetFilters,
  setFilterByCity,
  setFilterByCountry,
  setFilterByEquipmentType,
  setFilterByState,
  setFilterModalOpen,
  setFilteredDataRequired,
} from "../../slice/operatorJobsNearMeSlice";
import { getInitialFilteredOperatorJobsThunk } from "../../thunks/getInitialFilteredOperatorJobs";
import { getLoadMoreFilteredOperatorJobsThunk } from "../../thunks/getLoadMoreFilteredDataThunk";
import { Helmet } from "react-helmet";

export const OperatorJobsNearMe = () => {
  const dispatch = useDispatch();
  const {
    initialLoading,
    data,
    endOfData,
    filterModalOpen,
    filterByEquipmentType,
    filterByCountry,
    filterByState,
    filterByCity,
    filterDataRequired,
  } = useSelector((state) => state.operatorJobsNearMe);
  const navigate = useNavigate();

  const filterFunction = (values) => {
    dispatch(setFilterByEquipmentType(values.equipmentType));
    dispatch(setFilterByCountry(values.country));
    dispatch(setFilterByState(values.state));
    dispatch(setFilterByCity(values.city));
    
    if (values.equipmentType || values.country || values.state || values.city) {
      dispatch(setFilteredDataRequired(true));
      dispatch(getInitialFilteredOperatorJobsThunk());
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(setFilterModalOpen(false));
  };



  const renderFunction = (array) =>
    array && array.length > 0 ? (
      array.map((operatorJobs) => (
        <JobMarketplaceCard
        key={uuid()}
          verificationStatus={
            operatorJobs.UserJobResponseTypeValue === "Rejected"
              ? "fail"
              : "success"
          }
          verificationText={operatorJobs.UserJobResponseTypeValue}
          jobId={operatorJobs.JobId}
          name={operatorJobs.JobTitle}
          location={`${operatorJobs.CityName},${operatorJobs.StateName}, ${operatorJobs.CountryName}`}
          money={operatorJobs.Salary ? `₹${operatorJobs.Salary}` : "N.A."}
          cycle={
            operatorJobs.SalaryTypeValue
              ? `${operatorJobs.SalaryTypeValue}`
              : "N.A."
          }
          onClick={() =>
            navigate(`/jobs-near-me/AllJobsNearMeOP/Card/${operatorJobs.JobId}`,{
             
              state: { OperatorJobResponse: operatorJobs.UserJobResponseTypeValue} 
            
          })
          }
          postedOn={operatorJobs.JobPostedDateTime ? operatorJobs.JobPostedDateTime.split(",")[1] : "NA"}
        />
      ))
    ) : (
      <p>No Data Available</p>
    );



  useEffect(() => {
    filterDataRequired
      ? dispatch(getInitialFilteredOperatorJobsThunk())
      : dispatch(getInitialOperatorJobsThunk());
  }, [filterDataRequired]);




  return (
    <SideMenuLayout>
      <Helmet>
      <link
        rel="OPERATOR JOBS Page "
        href="https://www.equip9.com/jobs-near-me/AllJobsNearMeOP"
      />
      
      <meta name="keywords" content="operator machine"/>
      <title>Discover Top Operator Jobs - Operator Machine</title>
      <meta name="description" content="Explore lucrative job opportunities for operators our Operator Machine dashboard. 
      Find top operators for your business needs today"/>
     
      <h1>Operator Machine Dashboard: 
        Your Hub for Top Operator Jobs & Maintenance Partners</h1>
      <h2>Find Lucrative Operator Jobs and Reliable Maintenance Partners</h2>
      <h3>Explore Job Listings for Top Operators and Skilled Maintenance Partners</h3>
      </Helmet>
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.NEAR_ME_JOBS_OPEARTOR}
        isModalOpen={filterModalOpen}
        onSubmitButtonClick={filterFunction}
        onResetFilterClick={() => dispatch(resetFilters())}
        initialEquipmentValue={filterByEquipmentType}
        initialCountryValue={filterByCountry}
        initialStateValue={filterByState}
        initialCityValue={filterByCity}
        onCloseButtonClick={() => {dispatch(setFilterModalOpen(false))}}
      />
      <DashboardNavBar />
      <MarketplaceHeader
        heading="Jobs Available For You"
        filterButtonSelected={filterDataRequired}
        filterButtonClickAction={() => {
          dispatch(setFilterModalOpen(true));
        }}
      />
      <MarketplaceHoc
        marketplaceType="jobs"
        initialLoading={initialLoading}
        dataArray={data ? data : []}
        cardsRenderFunction={renderFunction}
        loadMoreAction={ () => 
          filterDataRequired
            ? dispatch(getLoadMoreFilteredOperatorJobsThunk())
            : dispatch(getLoadMoreOperatorJobsThunk())
        }
        endOfData={endOfData}
      />
    </SideMenuLayout>
  );
};

