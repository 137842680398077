import React from "react";
import { useSelector } from "react-redux";
import { useQuery} from "react-query";
import { ReactSVG } from "react-svg";
import './Cancelled.css';
import { MPCancelledJobList, OPCancelledJobList } from "../../../../services/jobsPosted/jobsPosted";

export const Cancelled = () => {
  const userData = useSelector((state) => state.user);
  const businessTypeValue = userData?.BusinessTypeValue;

  /*************UseQuery************************* */
  const { data: mpfulltimecancelledjobs } = useQuery("MPfulltimecancelledjobs", () => MPCancelledJobList());
  const { data: opcancelledjobs } = useQuery("OPfulltimecancelledjobs", () =>OPCancelledJobList());

  /*************Getting List ************************* */
  const MpfulltimeCancelledJobs =mpfulltimecancelledjobs?.data?.Data?.FullTimeMaintenanceCancelledJobList;
  const OPCancelledJobs = opcancelledjobs?.data?.Data?.OperatorCancelledJobList;

  /************Rendering list for OP || MP ********************* */
  return (
    <div>
      {businessTypeValue == "Operator Partner" ? (
        <div>
          {OPCancelledJobs?.length > 0 ? (
            <>
              <div className="cards-container">
                {OPCancelledJobs?.map((item) => (
                  <React.Fragment key={item.UserVerificationDocumentId}>
                    <div className="onGoingCard-layout">
                      <div>
                        <div className="verfication-badge">
                          <div className="badge-layout-cancelled">
                            <p className="userResposeTypeValue-cancelled">
                              {item.UserJobResponseTypeValue}
                            </p>
                          </div>
                        </div>
                        <div className="jobNumber">
                          <span>Job Id:</span>
                          <span>{item.JobId}</span>
                        </div>

                        <div className="jobTitle-font">
                          <div>{item.JobTitle}</div>
                        </div>
                        <div>
                          <div className="Equipment-category">
                            {" "}
                            <span>{item.EquipmentType}</span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span>{item.EquipmentMake}</span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span>{item.EquipmentModel}</span>
                          </div>
                        </div>

                        <div className="worklocation">
                          <p>{item.WorkLocation}</p>
                        </div>
                        <div className="payment-layout">
                          <p className="paymentfont">
                            &#8377;&nbsp;{item.Salary}/
                            <span className="paymentfont">
                              {item.SalaryType}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </>
          ) : (
            <div className="no-data-found">
              <div style={{marginTop:"15%"}}>
              <ReactSVG
              style={{marginLeft:"66px"}}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoCancelledJobs.svg`}
               />
              <p className="no-material-added-yet-upcoming ">OOPS! NO Cancelled Jobs!</p>
              </div>
            </div>
          )}
        </div>
      ) : (
          <div>
            {MpfulltimeCancelledJobs?.length > 0 ? (
              <>
                <div className="cards-container">
                  {MpfulltimeCancelledJobs?.map((item) => (
                    <React.Fragment key={item.UserVerificationDocumentId}>
                      <div className="onGoingCard-layout">
                        <div>
                          <div className="verfication-badge">
                            <div className="badge-layout">
                              <p className="userResposeTypeValue">
                                {item.UserJobResponseTypeValue}
                              </p>
                            </div>
                          </div>
                          <div className="jobNumber">
                            <span>Job Id:</span>
                            <span>{item.JobId}</span>
                          </div>

                          <div className="jobTitle-font">
                            <div>{item.JobTitle}</div>
                          </div>
                          <div>
                            <div className="Equipment-category">
                              {" "}
                              <span>{item.EquipmentType}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentMake}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentModel}</span>
                            </div>
                          </div>

                          <div className="worklocation">
                            <p>{item.WorkLocation}</p>
                          </div>
                          <div className="payment-layout">
                            <p className="paymentfont">
                              &#8377;&nbsp;{item.Salary}/
                              <span className="paymentfont">
                                {item.SalaryType}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            ) : (
              <div className="no-data-found">
               <div style={{marginTop:"15%"}}>
              <ReactSVG
              style={{marginLeft:"66px"}}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoCancelledJobs.svg`}
               />
              <p className="no-material-added-yet-upcoming ">OOPS! NO Cancelled Jobs!</p>
              </div>

              </div>
            )}
          </div>
      )}
    </div>
  );
};
