import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userDetailsThunk } from "../../../global/user/thunks/userDetailThunk";
import { QrPopUp } from "../../../../components/ScanQrCodeModal/QrPopUp";
import { getDashboardDetailsThunk } from "../../thunks/getDashboardDetailsThunk";
import { openQrModal } from "../../slice/dashboardSlice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { JobCard } from "../../../../components/JobCard/JobCard";
import { DashboardFallbackCard } from "../../../../components/DashboardFallbackCard/DashboardFallbackCard";
import { Sticky } from "../../../../components/Sticky/Sticky";
import { DASHBOARD_BUTTONS } from "../../../../../constants/renderData";
import { DashboardButton } from "../../../../components/DashboardButton/DashboardButton";
import { subString } from "../../../../helpers/dataConversion/substring";
import { resetFilters as rentalResetFilters } from "../../../rentalEquipmentMarketplace/slice/rentalEquipmentMartketplace";
import { resetFilters as usedRentalFilters } from "../../../usedEquipmentMarketplace/slice/usedEquipmentMarketplaceSlice";
import { resetFilters as operatorJobsResetFilters } from "../../../operatorJobs/slice/operatorJobsNearMeSlice";
import { resetFilters as maintenanceJobsResetFilters } from "../../../maintenanceJobs/slice/maintenancePartnerJobsSlice";
import { resetFilters as operatorResetFilters } from "../../../operatorNearMe/slice/operatorNearMeSlice";
import { resetFilters as maintenanceResetFilters } from "../../../maintenancePartnerNearMe/slice/maintenancePartnerSlice";
import { NativeCarousel } from "../../../../components/Carousel/NativeCarousel";
import { getCurrencyForUserThunk } from "../../../global/user/thunks/getCurrencyForUserThunk";
import {
  resetCart,
  resetCheckoutDetails,
} from "../../../subscription/slice/subscriptionSlice";
import { Box, Flex, Text, Tooltip, useToast } from "@chakra-ui/react";
import { DashboardLoader } from "../../../../components/loaders/dashboardLoader/DashboardLoader";
import { resetEquipmentListingSlice } from "../../../equipmentListing/slice/equipmentListingSlice";
// import { getPostLoginAdvertisments } from "../../../../services/advertisement/advertisement";
import { getPostLoginAdvertisementThunk } from "../../../advertisement/thunks/getPostLoginAdvertisementThunk";
import { fetchHandler } from "../../../../../index";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { primaryKeyEncryption } from "../../../../helpers/dataConversion/primaryKeyEncryption";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";
import { V5DashboardAssetCard } from "../../../../components/V5DashboardAssetCard/V5DashboardAssetCard";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { V5DashboardPartnerCard } from "../../../../components/V5DashboardPartnerCard/V5DashboardPartnerCard";
import { Helmet } from "react-helmet";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";

export const Dashboard = () => {
  const dashboard = useSelector((state) => state.dashboard);
  const userData = useSelector((state) => state.user);
  const advertisements = useSelector((state) => state.advertisement);
  const [appLanguage, setAppLanguage] = useState();
  const { defaultLanguage } = useSelector((state) => state.masterData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const assetId = new URLSearchParams(window.location.search).get("id");
  const [urlParam, setUrlParam] = useSearchParams();

  useEffect(() => {
    const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
    const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID);
    if (id && !urlAssetId) {
      setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`);
    }
  }, []);

  const decryptAssetId = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        `/decryptAssetId`,
        {},
        { "content-type": "application/json" },
        { token: assetId.split(" ").join("+") },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      return data.Data;
    } catch (error) {}
  };

  const renderBusinessOwnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS.BUSINESS_OWNER.map((button) => (
      <DashboardButton
        onClick={() => {
          !button.route
            ? dispatch(openQrModal())
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText.toLowerCase() === "My Leads".toLowerCase()) &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.TaxNumber
            ? toast({
                status: "error",
                description: "Please update GST Number to access the feature.",
                position: "top-right",
                isClosable: true,
              })
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText === "My Leads") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber
            ? toast({
                status: "error",
                description:
                  "Company verification under review for the user verified from admin.",
                position: "top-right",
                isClosable: true,
              })
            : navigate(`${button.route}`);
        }}
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderEquipmentOwnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS.EQUIPMENT_OWNER.map((button) => (
      <DashboardButton
        onClick={() => {
          !button.route
            ? dispatch(openQrModal())
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText === "My Leads") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.TaxNumber
            ? toast({
                status: "error",
                description: "Please update GST Number to access the feature.",
                position: "top-right",
                isClosable: true,
              })
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business" ||
                button.buttonText === "My Leads") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber
            ? toast({
                status: "error",
                description: "Company is under review. Please try again later",
                position: "top-right",
                isClosable: true,
              })
            : navigate(`${button.route}`);
        }}
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderMaintenancePartnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS?.MAINTENANCE_PARTNER?.map((button) => (
      <DashboardButton
        onClick={() => {
          !button.route
            ? dispatch(openQrModal())
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.TaxNumber
            ? toast({
                status: "error",
                description: "Please update GST Number to access the feature.",
                position: "top-right",
                isClosable: true,
              })
            : (button.buttonText === "E9 Services" ||
                button.buttonText === "My Business") &&
              userData.UserTypeValue === "Company" &&
              !userData.accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber
            ? toast({
                status: "error",
                description: "Company is under review. Please try again later",
                position: "top-right",
                isClosable: true,
              })
            : navigate(`${button.route}`);
        }}
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderOperatorPartnerButtons = () => {
    const buttons = DASHBOARD_BUTTONS?.OPERATOR_PARTNER?.map((button) => (
      <DashboardButton
        onClick={() =>
          !button.route ? dispatch(openQrModal()) : navigate(`${button.route}`)
        }
        buttonText={button.buttonText}
        imageUrl={button.image}
      />
    ));
    return buttons;
  };

  const renderDashboardButtons = (businessTypeValue) => {
    switch (businessTypeValue.toLowerCase()) {
      case "business owner":
        return renderBusinessOwnerButtons();
      case "equipment owner":
        return renderEquipmentOwnerButtons();
      case "maintenance partner":
        return renderMaintenancePartnerButtons();
      case "operator partner":
        return renderOperatorPartnerButtons();
      default:
        <></>;
    }
  };

  const verifyUserType = (userType,marketplaceType) => {

   if (
    (marketplaceType === "partner" || marketplaceType === "equipment" || 
     marketplaceType === "dealership" || marketplaceType === "material") &&
    (userData?.BusinessTypeValue.toLowerCase() === "business owner" || 
     userData?.BusinessTypeValue.toLowerCase() === "equipment owner")
  ) {
    return true
  } else if (
    marketplaceType === "job" &&
    (userData?.BusinessTypeValue.toLowerCase() === "maintenance partner" || userData?.BusinessTypeValue.toLowerCase() === "operator partner")
  ) {
    return true
  } else {
    return false ;
  }
  


  }
  const redirectMarketplace = (data) => {

    const subCategory = data?.SubCategory.toString().toLowerCase() ;
   const userType =  verifyUserType( data?.SubCategory , data?.MarketplaceType);

   if(userType === true){
    switch (subCategory) {
      case "used":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(
          `/marketplace/MarketPlaceUEM/Cards/${primaryKeyEncryption(
            data.AssetId
          )}`
        );
        break;
      case "rental":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(
          `/marketplace/MarketPlaceREM/Cards/${primaryKeyEncryption(
            data.AssetId
          )}`
        );
        break;
      case "operator":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/near-me/MarketPlaceOP/Card/${data?.AssetId}`);
        break;
      case "mechanic":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/near-me/MarketPlaceMP/Card/${data?.AssetId}`);

      case "material":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/marketplace/MaterialMarketplace/material-details/${convertToBase64(data?.AssetId)}/${convertToBase64(data?.MarketplaceType)}`);
        break ;
      case "opjobs":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/jobs-near-me/AllJobsNearMeOP/Card/${data?.AssetId}`);
        break ;
      case "mpjobs":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/jobs-near-me/AllJobsNearMeMP/Card/${data?.AssetId}`);
        break ;
      case "dealership":
        const likeStatus = true ;
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/marketplace/DealershipMarketplaceHome/dealership-detail/${convertToBase64(data?.AssetId)}/${convertToBase64(data?.SecondaryId)}/${likeStatus}/${convertToBase64(userData.accountDetails?.CompanyDetails?.TaxNumber)}`);
        break ;
      case "nearmeoperator":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/near-me/MarketPlaceMP/Card/${data?.AssetId}`);
        break ;
      case "nearmemaintanence":
        sessionStorage.removeItem("DEFERRED_MARKETPLACE");
        navigate(`/near-me/MarketPlaceMP/Card/${data?.AssetId}`);
        break ;
      default:
        break;
    }
   }else{
    sessionStorage.removeItem("DEFERRED_MARKETPLACE");
    navigate('/not-authorized')
   }

  };
  useEffect(() => {
    (async () => {
      if (assetId) {
        const data = await decryptAssetId(assetId);
        if (data?.AssetId) {
          redirectMarketplace(data);
        }
      }
    })();
  }, [assetId]);

  useEffect(() => {
    setAppLanguage(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    // user
    dispatch(userDetailsThunk());
    dispatch(getCurrencyForUserThunk());
    //advertisement
    dispatch(getPostLoginAdvertisementThunk());
    dispatch(getDashboardDetailsThunk());

    // Reset all filters on dashboard
    dispatch(rentalResetFilters());
    dispatch(usedRentalFilters());
    dispatch(operatorJobsResetFilters());
    dispatch(maintenanceJobsResetFilters());
    dispatch(operatorResetFilters());
    dispatch(maintenanceJobsResetFilters());
    dispatch(maintenanceResetFilters());
    // CART and checkout reset
    dispatch(resetEquipmentListingSlice());
    dispatch(resetCart());
    dispatch(resetCheckoutDetails());
  }, []);

  useEffect(() => {
    if (!userData.accountDetails) {
      dispatch(userDetailsThunk());
      dispatch(getCurrencyForUserThunk());
    }
  }, [userData]);

  return (
    <>
     <Helmet>
     <link
        rel="MAINTENANCE PARTNER -Dashboard page"
        href="https://www.equip9.com/dashboard"
      />
      <meta name="keywords" content="Equip9: Construction Equipment Suppliers Hub"/>
      
      <meta name="description" content="Discover Equip9, where business owners, equipment owners, 
      mechanics, and operators connect for heavy equipment needs."/>
      <h1>Welcome to Equip9: Your Construction Equipment Supplier Hub</h1>
      <h2>Explore a Wide Range of Construction Equipment Options</h2>
      <h3>Connect with Top Suppliers on Equip9's Dashboard</h3>
      </Helmet>

      <QrPopUp />
      <V5SidebarLayout defaultLanguage={appLanguage}>
        <div>
          <NativeCarousel
            images={advertisements ? advertisements.data?.requiredImgUrl : []}
            redirectionUrl={
              advertisements ? advertisements.data?.requiredRedirectUrl : []
            }
            speed="3000"
            height="350px"
            width="100%"
          />
        </div>
        <Box padding={"1rem 1.2rem"}>
          <Flex justifyContent="space-between" overflowX="scroll" gap="1rem">
            {renderDashboardButtons(userData?.BusinessTypeValue)}
          </Flex>

          {dashboard.isLoading ? (
            <DashboardLoader />
          ) : dashboard.dashboardData && !dashboard.isLoading ? (
            dashboard?.dashboardData?.map((section) => {
              // //("Section" , section);
              return (
                <div
                  style={{
                    width: "100%",
                    margin: "1em 0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      // padding: "1em",
                      display: "flex",
                      justifyContent:
                        section.DashboardCategoryValue === "Sticky"
                          ? ""
                          : "space-between",
                    }}
                  >
                    {section.DashboardCategoryValue !== "Sticky" && (
                      <p
                        style={{
                          textTransform: "uppercase",
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "31.39px",
                          letterSpacing: "0.01em",
                        }}
                      >
                        {section.DispalyLabelName}
                      </p>
                    )}
                    {section.DashboardCategoryValue === "Sticky" ||
                    !section.Data ? (
                      <></>
                    ) : (section.Data &&
                        section.DashboardCategoryValue === "MarketPlace" &&
                        (section.ServiceTypeValue === "Used Equipment" ||
                          section.ServiceTypeValue === "Rental Equipment" ||
                          section.ServiceTypeValue === "Dealership")) ||
                      section?.ServiceTypeValue?.toLowerCase() ===
                        "Material Contact".toLowerCase() ? (
                      <Link
                        to={`/marketplace/${section.RedirectPath}`}
                        style={{
                          color: "#002961",
                          fontWeight: 600,
                          fontWeight: 600,
                        }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    ) : section.Data &&
                      section.DashboardCategoryValue === "NearMe" &&
                      (section.ServiceTypeValue === "Maintenance Search" ||
                        section.ServiceTypeValue === "Operator Search") ? (
                      <Link
                        to={`/near-me/${section.RedirectPath}`}
                        style={{ color: "#002961", fontWeight: 600 }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    ) : section.Data &&
                      section.DashboardCategoryValue === "Jobs" &&
                      section.DashboardServiceTypeValue === "Posted Jobs" ? (
                      <Link
                        to={`/jobs-near-me/${section.RedirectPath}`}
                        style={{ color: "#002961", fontWeight: 600 }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    ) : (
                      <Link
                        onClick={() => dispatch(openQrModal())}
                        style={{ color: "#002961", fontWeight: 600 }}
                      >
                        View All <ArrowRightIcon />
                      </Link>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2em",
                      padding:
                        section.DashboardCategoryValue === "Sticky"
                          ? ""
                          : "1.5rem 0.25rem",
                      overflowY:
                        section.DashboardCategoryValue === "Sticky"
                          ? ""
                          : "scroll",
                      // maxWidth: "calc(100vw - 240px)",
                      width: "100%",
                      alignItems:
                        section.DashboardCategoryValue === "Sticky"
                          ? "center"
                          : "",
                      justifyContent:
                        section.DashboardCategoryValue === "Sticky"
                          ? "center"
                          : "",
                    }}
                  >
                    {section.Data &&
                    section.Data.length > 0 &&
                    section.DashboardServiceTypeValue === "Equipment Search" ? (
                      section?.Data?.map((item) => (
                        // <EquipmentCard
                        //   onMarketplace={true}
                        //   imageLink={`
                        //     ${process.env.REACT_APP_CDN_PVT_URL}${item.ImageFileReference}`}
                        //   heading={item.HeaderTitle}
                        //   subHeading={item.SubHeader3}
                        //   description={item.SubHeader2}
                        //   price={item.SubHeader1}
                        //   onClick={() =>
                        //     (section.Data &&
                        //       section.DashboardCategoryValue ===
                        //         "MarketPlace" &&
                        //       (section.ServiceTypeValue ===
                        //         "Used Equipment" ||
                        //         section.ServiceTypeValue ===
                        //           "Rental Equipment" ||
                        //         section.ServiceTypeValue === "Dealership")) ||
                        //     section?.ServiceTypeValue?.toLowerCase() ===
                        //       "Material Contact".toLowerCase()
                        //       ? navigate(
                        //           `/marketplace/${section.RedirectPath}`
                        //         )
                        //       : section.Data &&
                        //         section.DashboardCategoryValue === "NearMe" &&
                        //         (section.ServiceTypeValue ===
                        //           "Maintenance Search" ||
                        //           section.ServiceTypeValue ===
                        //             "Operator Search")
                        //       ? navigate(`/near-me/${section.RedirectPath}`)
                        //       : section.Data &&
                        //         section.DashboardCategoryValue === "Jobs" &&
                        //         section.DashboardServiceTypeValue ===
                        //           "Posted Jobs"
                        //       ? navigate(
                        //           `/jobs-near-me/${section.RedirectPath}`
                        //         )
                        //       : (() => {
                        //           return null;
                        //         })()
                        //   }
                        // />
                        <V5DashboardAssetCard
                          key={item?.AssetUniqueId}
                          minW={[
                            "400px",
                            "400px",
                            "344px",
                            "295px",
                            "262px",
                            "272px",
                          ]}
                          maxW="275px"
                          width="100%"
                          height="272px"
                          display="flex"
                          flexDirection="column"
                          boxShadow="0px 6px 15px -3px #00000029"
                          cursor="pointer"
                          onClick={() =>
                            (section.Data &&
                              section.DashboardCategoryValue ===
                                "MarketPlace" &&
                              (section.ServiceTypeValue === "Used Equipment" ||
                                section.ServiceTypeValue ===
                                  "Rental Equipment" ||
                                section.ServiceTypeValue === "Dealership")) ||
                            section?.ServiceTypeValue?.toLowerCase() ===
                              "Material Contact".toLowerCase()
                              ? navigate(`/marketplace/${section.RedirectPath}`)
                              : section.Data &&
                                section.DashboardCategoryValue === "NearMe" &&
                                (section.ServiceTypeValue ===
                                  "Maintenance Search" ||
                                  section.ServiceTypeValue ===
                                    "Operator Search")
                              ? navigate(`/near-me/${section.RedirectPath}`)
                              : section.Data &&
                                section.DashboardCategoryValue === "Jobs" &&
                                section.DashboardServiceTypeValue ===
                                  "Posted Jobs"
                              ? navigate(
                                  `/jobs-near-me/${section.RedirectPath}`
                                )
                              : (() => {
                                  return null;
                                })()
                          }
                        >
                          <V5DashboardAssetCard.Header
                            width="100%"
                            height="150px"
                          >
                            <V5DashboardAssetCard.Image
                              src={`${process.env.REACT_APP_CDN_PVT_URL}${item?.ImageFileReference}`}
                            />
                          </V5DashboardAssetCard.Header>
                          <V5DashboardAssetCard.Body
                            display="flex"
                            height="122px"
                            flexDirection="column"
                            padding="1em 1.5em"
                          >
                            <V5DashboardAssetCard.Heading
                              tooltip={item?.HeaderTitle}
                            >
                              {subString(22, item?.HeaderTitle)}
                            </V5DashboardAssetCard.Heading>

                            <V5DashboardAssetCard.HighlightedText>
                              {section.ServiceTypeValue &&
                              section.ServiceTypeValue.toString().includes(
                                "Equipment"
                              )
                                ? item?.SubHeader1
                                : item?.SubHeader3}
                            </V5DashboardAssetCard.HighlightedText>
                            <Tooltip label={item?.SubHeader2}>
                              <V5DashboardAssetCard.Location>
                                {section?.ServiceTypeValue.toString().toLowerCase() ===
                                "Dealership".toLowerCase()
                                  ? subString(
                                      20,
                                      item?.GoogleLocation?.City,
                                      item?.GoogleLocation?.State,
                                      item?.GoogleLocation?.Country
                                    )
                                  : subString(20, item?.SubHeader2)}
                              </V5DashboardAssetCard.Location>
                            </Tooltip>
                            <V5DashboardAssetCard.Text>
                              {section.ServiceTypeValue.toString().toLowerCase() ===
                                "Dealership".toLowerCase() ||
                              section.ServiceTypeValue.toString().toLowerCase() ===
                                "Material Contact".toLowerCase()
                                ? item?.SubHeader1
                                : item?.SubHeader3}
                            </V5DashboardAssetCard.Text>
                          </V5DashboardAssetCard.Body>
                        </V5DashboardAssetCard>
                      ))
                    ) : section.Data &&
                      section.DashboardCategoryValue === "Sticky" ? (
                      <Sticky
                        imageUrl={`${process.env.REACT_APP_CDN_PVT_URL}${section.ImageBaseUrl}${section.Data.ImageFileReference}`}
                        heading={section.Data.HeadingTitle}
                        highlightedText={section.Data.SubHeading1}
                        subHeading={section.Data.SubHeading2}
                        onClick={() => {
                          dispatch(openQrModal());
                        }}
                      />
                    ) : section.Data &&
                      section.Data.length > 0 &&
                      section.DashboardCategoryValue === "Jobs" ? (
                      section?.Data?.map((item) => (
                        <JobCard
                          heading={item?.JobTitle}
                          salary={`₹ ${item?.Salary}`}
                          location={`${item?.CityName}, ${item?.StateName}, ${item?.CountryName}`}
                          lastDate={item?.JobExpiryDate.split(",")[1]
                            .split("-")
                            .join(" ")}
                          statusMessage={item?.UserJobResponseTypeValue}
                          onClick={() =>
                            navigate(`/jobs-near-me/${section.RedirectPath}`)
                          }
                        />
                      ))
                    ) : section.Data &&
                      section.Data.length > 0 &&
                      section.DashboardCategoryValue === "NearMe" ? (
                      section?.Data?.map((item) => (
                        // <PartnerCard
                        //   imageUrl={
                        //     item.ProfilePictureFileReference
                        //       ? `${process.env.REACT_APP_CDN_PVT_URL}${section.ImageBaseUrl}${item.ProfilePictureFileReference}`
                        //       : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                        //   }
                        //   name={subString(10, item.PartnerName)}
                        //   equipmentType={subString(10, item.EquipmentTypeValue)}
                        //   experience={`${item.ExperienceYear}.${item.ExperienceMonth} Years Experienced`}
                        //   onShareButtonClick={() => {
                        //     // dispatch(openQrModal());
                        //     navigate(`/near-me/${section.RedirectPath}`);
                        //   }}
                        //   onClick={() => {
                        //     // dispatch(openQrModal());
                        //     navigate(`/near-me/${section.RedirectPath}`);
                        //   }}
                        // />
                        <V5DashboardPartnerCard
                          onButtonClick={() => {
                            navigate(`/near-me/${section.RedirectPath}`);
                          }}
                        >
                          <V5DashboardPartnerCard.Image
                            src={
                              item?.ProfilePictureFileReference
                                ? `${process.env.REACT_APP_CDN_PVT_URL}${section.ImageBaseUrl}${item?.ProfilePictureFileReference}`
                                : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                            }
                          />
                          <Flex
                            flexDirection="column"
                            gap="0.75em"
                            marginTop="1em"
                            alignItems="center"
                          >
                            <V5DashboardPartnerCard.Heading>
                              {subString(15, item?.PartnerName)}
                            </V5DashboardPartnerCard.Heading>

                            <V5DashboardPartnerCard.Text color="#002961">
                              {subString(10, item?.EquipmentTypeValue)}
                            </V5DashboardPartnerCard.Text>
                            <V5DashboardPartnerCard.Text>{`${item?.ExperienceYear}.${item?.ExperienceMonth} Years Experienced`}</V5DashboardPartnerCard.Text>
                          </Flex>
                        </V5DashboardPartnerCard>
                      ))
                    ) : (
                      <DashboardFallbackCard />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            ""
          )}
        </Box>
      </V5SidebarLayout>
    </>
  );
};

// export const Dashboard = () => {
//   return <V5SidebarLayout>

//   </V5SidebarLayout>
// }
