import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { ImageViewWithTitle } from "../ImageViewWithTitle/ImageViewWithTitle";
import { useEffect, useState } from "react";

export const MultipleImageUpload = ({
  title = "Title",
  isRequired= false,
  maxWidth = "200px",
  onAddButtonClick = () => {},
  mediaArray = [],
  errorMessage = "No content Available",
  deleteAction = () => {},
  isDisabled = false,
  type = "image",
}) => {
  const [deleteElementName, setDeleteElementName] = useState(null);
  useEffect(() => {
    if (deleteElementName) {
      deleteAction(deleteElementName);
    }
  }, [deleteElementName]);
  return (
    <Box maxWidth={maxWidth}>
      <Flex justifyContent="space-between">
        <Text
          textTransform="uppercase"
          color="#4A5A82"
          fontWeight="700"
          fontSize="24px"
        >
          {title}
          <Text color={"#E53E3E"} display={"inline-block"}>{isRequired && "*"}</Text>
        </Text>
        <Button
          border="1px solid #4A5A82"
          color="#4A5A82"
          background="#FFF"
          fontWeight="700"
          fontSize="18px"
          textTransform="uppercase"
          onClick={onAddButtonClick}
          isDisabled={isDisabled}
        >
          + Add
        </Button>
      </Flex>
      <Flex
        gap="1em"
        width="100%"
        overflowX="scroll"
        marginTop="1em"
        justifyContent={mediaArray && mediaArray.length > 0 ? "" : "center"}
        alignItems={mediaArray && mediaArray.length > 0 ? "" : "center"}
      >
        {mediaArray && mediaArray.length > 0 ? (
          mediaArray.map((element) => (
            <ImageViewWithTitle
              key={element.id}
              title={element.value}
              mediaUrl={element.url}
              onDeleteImageClick={() => setDeleteElementName(element.id)}
              type={type}
            />
          ))
        ) : (
          <Text fontStyle="italic">{errorMessage}</Text>
        )}
      </Flex>
    </Box>
  );
};
