import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import "./UpcomingJobStaus.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { formatNumberIndianStyle } from "../../../staticData/CommonMethod";
import { ViewRatings } from "../../components/ViewRatings/ViewRatings";
import { useToast } from "@chakra-ui/react";
import { createPostRating, getRatingOwner } from "../../services/upcoming/upcoming";
import { getOwnerJobDetailsbyID, jobDescriptionUpcomingMP } from "../../services/jobsPosted/jobsPosted";

export const Mpupcoming = () => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const pwr = useParams();
  const [ownerMpRatingData, setOwnerMpRatingData] = useState(null);
  const { mpupcomingJobId } = useParams();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const onCloseLogoutModal = () => setIsLogoutModalOpen(false);
  const cancelReff = React.useRef();

  /************************ */
  const [isLogoutModalOpenViewRating, setIsLogoutModalOpenViewRating] =
    useState(false);
  const onCloseLogoutModalViewRating = () =>
    setIsLogoutModalOpenViewRating(false);
  const cancelRefViewRating = React.useRef();
  const [Error, setError] = useState(null);
  const [rating, setRating] = useState(0);
  const [paymentonTimerating, setpaymentonTimerating] = useState(0);
  const [behaviorRating, setbehaviorRating] = useState(0);
  const [ratinglength, setratinglength] = useState(0);


  const toast = useToast();

  const ratingDescriptions = {
    1: "Terrible",
    2: "Bad",
    3: "Okay",
    4: "Good",
    5: "Great",
  };

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handlePaymentStarrClick = (paymentonTimerating) => {
    setpaymentonTimerating(paymentonTimerating);
  };

  const handlebehaviorStarClick = (behaviorRating) => {
    setbehaviorRating(behaviorRating);
  };

  const {
    data: UpcomingJobDescriptionmp,
    isLoading,
    isError,
  } = useQuery("upcomingjobDescriptionmp", () =>
    jobDescriptionUpcomingMP(mpupcomingJobId)
  );

  const { data: UpcomingJobOwnerDetailsMP } = useQuery(
    "upcomingjobownerDetailsmp",
    () => getOwnerJobDetailsbyID(mpupcomingJobId)
  );

  const resultJobDetails =
    UpcomingJobDescriptionmp?.data?.Data?.PartnerJobDetails[0];
  const ownerDetails = UpcomingJobOwnerDetailsMP?.data?.Data?.OwnerDetails[0];
  const userCredentialId = ownerDetails?.UserCredentialId;

  // console.log("ownerDetails",ownerDetails)

  const handleSubmitRating = async () => {
    const payload = {
      BehaviourRating: behaviorRating,
      PaymentOnTime: paymentonTimerating,
      SafetyConditions: rating,
      OwnerUserCredentialId: userCredentialId,
      JobId: parseInt(mpupcomingJobId),
    };

    const data = await createPostRating(payload);
    try {
      if (data?.Status === 201) {
        toast({
          title: "",
          description: `${data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        queryClient.invalidateQueries("upcomingjobownerDetailsmp");
        onCloseLogoutModal();
      }
    } catch (err) {
      toast({
        title: "",
        description: `${data?.Message?.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getRatingOwner(parseInt(mpupcomingJobId), userCredentialId)
      .then((data) => {
        const ratings = data?.Data?.OwnerJobRating[0];
        if (ratings) {  
          setOwnerMpRatingData(ratings); // Storing the ratings data in state
          setratinglength(data?.Data?.OwnerJobRating);
        }
      })
      .catch((err) => {
        setError(err);
        console.error("Error fetching ratings:", err);
      });
  }, [userData.token, mpupcomingJobId, userCredentialId]);

  return (
    <SideMenuLayout>
      <DashboardNavBar />
      <MidSection>
        <div>
          <div className="upcoming-job-title-layout">
            <p className="Job-title-upcomming">{resultJobDetails?.JobTitle}</p>
          </div>
          <div className="owner-contact-details">
            <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
              <ReactSVG
                style={{ marginTop: "8px" }}
                src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerContact.svg`}
              />
              <p className="OwnerDetailsText">OWNER's CONTACT DETAILS</p>
            </div>

            <div className="upcoming-seprator"></div>
          </div>
          <br />
          <div className="Rating-layout">
            <div className="imageContainer">
              <img
                className="userImageProfile"
                src={`${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/${ownerDetails?.ProfilePic}`}
              />
            </div>
            <div className="rating-star">
              <p className="rating-details">
                {ownerDetails?.OverAllRating || "0"}
              </p>
            </div>
            {/* <br/> */}
            <div style={{ marginTop: "20px" }}>
              <p className="OwnerName">{ownerDetails?.OwnerName}</p>
              <p className="companyName">
                <span className="companyName">Company: </span>
                {ownerDetails?.CompanyName}
              </p>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <ReactSVG
                  style={{ marginTop: "9px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/ownercall.svg`}
                />
                <p className="phoneNumber">{ownerDetails?.MobileNumber}</p>
              </div>
            </div>
            <div className="rating-seprator"></div>
            <div className="giveRatings">
              <ReactSVG
                src={
                  "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/star.svg"
                }
                style={{ marginLeft: "1px" }}
              />

              {ratinglength?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{ marginTop: "-8px" }}
                    onClick={() => setIsLogoutModalOpenViewRating(true)}
                  >
                    {" "}
                    View Ratings
                  </p>
                  <ReactSVG
                    style={{ marginTop: "1px" }}
                    src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerDetailrightarrow.svg`}
                  />
                </div>
              ) : (
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                >
                  <p
                    style={{ marginTop: "-8px" }}
                    onClick={() => setIsLogoutModalOpen(true)}
                  >
                    {" "}
                    Give Ratings
                  </p>
                  <ReactSVG
                    style={{ marginTop: "1px" }}
                    src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerDetailrightarrow.svg`}
                  />
                </div>
              )}
            </div>
          </div>
          {/* *************************************************** */}

          <div className="owner-contact-details">
            <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
              <ReactSVG
                style={{ marginTop: "8px" }}
                src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/Equipmentdetaailicon.svg`}
              />
              <p className="OwnerDetailsText">EQUIPMENT DETAILS</p>
            </div>

            <div className="upcoming-seprator"></div>
          </div>

          <div className="Equipment-details-card-layout">
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.EquipmentTypeName}
              </p>
              <p className="EquipmentTypeText">Equipment Type</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.EquipmentMakeName}
              </p>
              <p className="EquipmentTypeText">Equipment Make</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.EquipmentModelName}
              </p>
              <p className="EquipmentTypeText">Equipment Model</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">{resultJobDetails?.EquipmentAge}</p>
              <p className="EquipmentTypeText">Equipment Age</p>
            </div>
          </div>
          {/* <br /> */}
          {/************* job detials******** */}

          <div className="owner-contact-details">
            <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
              <ReactSVG
                style={{ marginTop: "8px" }}
                src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/Ownerlocation.svg`}
              />
              <p className="OwnerDetailsText">JOB DETAILS</p>
            </div>

            <div className="upcoming-seprator"></div>
          </div>

          <div className="job-layout">
            <p className="resultDetails">{resultJobDetails?.WorkLocation}</p>
            <p className="EquipmentTypeText">Job Location</p>
          </div>

          {/*************************************** */}

          <div className="owner-contact-details">
            <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
              <ReactSVG
                style={{ marginTop: "8px" }}
                src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/Ownersalary.svg`}
              />
              <p className="OwnerDetailsText">IN HAND SALARY DETAILS</p>
            </div>

            <div className="upcoming-seprator"></div>
          </div>

          <div className="Equipment-details-card-layout">
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                &#8377;{" "}
                {`${formatNumberIndianStyle(resultJobDetails?.Salary)} ${
                  resultJobDetails?.SalaryTypeValue
                }`}
              </p>

              <p className="EquipmentTypeText">in hand salary</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">{resultJobDetails?.PFValue}</p>
              <p className="EquipmentTypeText">PF Provided</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">{resultJobDetails?.PayCycleValue}</p>
              <p className="EquipmentTypeText">Pay Cycle</p>
            </div>
          </div>

          {/****************Food Accommodation********************* */}
          <div className="owner-contact-details">
            <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
              <ReactSVG
                style={{ marginTop: "8px" }}
                src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/bedAccomdation.svg`}
              />
              <p className="OwnerDetailsText">FOOD & ACCOMMODATION</p>
            </div>

            <div className="upcoming-seprator"></div>
          </div>

          <div className="Equipment-details-card-layout">
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.FoodBenefitValue}
              </p>
              <p className="EquipmentTypeText">Food</p>
            </div>
            <div className="Equipment-details-type-card">
              <p className="resultDetails">
                {resultJobDetails?.AccomodationValue}
              </p>
              <p className="EquipmentTypeText">Accommodation</p>
            </div>
          </div>
        </div>

        {/**********************Working Shift****************** */}
        <div className="owner-contact-details">
          <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
            <ReactSVG
              style={{ marginTop: "8px" }}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/workingShoft.svg`}
            />
            <p className="OwnerDetailsText">WORKING SHIFT</p>
          </div>

          <div className="upcoming-seprator"></div>
        </div>

        <div className="Equipment-details-card-layout">
          <div className="Equipment-details-type-card">
            <p className="resultDetails">{resultJobDetails?.WorkShiftValue}</p>
            <p className="EquipmentTypeText">Shift Type</p>
          </div>
          <div className="Equipment-details-type-card">
            <p className="resultDetails">
              {`${resultJobDetails?.WorkingHourValue} + ${resultJobDetails?.LunchBreakValue}`}
            </p>
            <p className="EquipmentTypeText">Working Hours</p>
          </div>
          <div className="Equipment-details-type-card">
            <p className="resultDetails">
              {resultJobDetails?.JobDurationValue}
            </p>
            <p className="EquipmentTypeText">Job Duration</p>
          </div>
        </div>

        {/******************************** */}

        <div className="owner-contact-details">
          <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
            <ReactSVG
              style={{ marginTop: "8px" }}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerContact.svg`}
            />
            <p className="OwnerDetailsText">NATURE OF WORK</p>
          </div>

          <div className="upcoming-seprator"></div>
        </div>

        <div className="Equipment-details-card-layout">
          <div className="Equipment-details-type-card">
            <p className="resultDetails">
              {resultJobDetails?.NatureofWorkType}
            </p>
            <p className="EquipmentTypeText">Shift Type</p>
          </div>
          <div className="Equipment-details-type-card">
            <p className="resultDetails">{resultJobDetails?.InsuranceTypeId}</p>
            <p className="EquipmentTypeText">Insurance</p>
          </div>
          <div className="Equipment-details-type-card">
            <p className="resultDetails">{resultJobDetails?.TravelAllowance}</p>
            <p className="EquipmentTypeText">Travel Allowance</p>
          </div>
        </div>

        <AlertDialog
          isOpen={isLogoutModalOpen}
          leastDestructiveRef={cancelReff}
          onClose={onCloseLogoutModal}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <ReactSVG
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/RatingStar.svg`}
                  />
                  <p className="rateOwnerText">Rate Owner</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="Rating-layout-owner">
                    <div className="Condition-heading">Saftey Condition</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "-12px",
                      }}
                    >
                      <p className="ratingText">{ratingDescriptions[rating]}</p>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((starNumber) => (
                          <span
                            key={starNumber}
                            className={`star ${
                              starNumber <= rating ? "active" : ""
                            }`}
                            onClick={() => handleStarClick(starNumber)}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="rating-section">
                      {rating}
                      <span className="outofrating">/5</span>
                    </div>
                  </div>
                  <div className="Rating-layout-owner">
                    <div className="Condition-heading">Payment on time</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "-8px",
                      }}
                    >
                      <p className="ratingText">
                        {ratingDescriptions[paymentonTimerating] || " "}
                      </p>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((paymentstarNumber) => (
                          <span
                            key={paymentstarNumber}
                            className={`star ${
                              paymentstarNumber <= paymentonTimerating
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handlePaymentStarrClick(paymentstarNumber)
                            }
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="rating-section">
                      {paymentonTimerating}
                      <span className="outofrating">/5</span>
                    </div>
                  </div>

                  <div className="Rating-layout-owner">
                    <div className="Condition-heading">Behavior</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "-12px",
                      }}
                    >
                      <p className="ratingText">
                        {ratingDescriptions[behaviorRating] || " "}
                      </p>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((Rating) => (
                          <span
                            key={Rating}
                            className={`star ${
                              Rating <= behaviorRating ? "active" : ""
                            }`}
                            onClick={() => handlebehaviorStarClick(Rating)}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="rating-section">
                      {behaviorRating}
                      <span className="outofrating">/5</span>
                    </div>
                  </div>
                </div>
              </AlertDialogHeader>
              <div className="button-layout">
                <button onClick={onCloseLogoutModal} className="submit-button">
                  <p className="button-text">Cancel</p>
                </button>
                <button className="submit-button" onClick={handleSubmitRating}>
                  <p className="button-text">Submit</p>
                </button>
              </div>

              <AlertDialogFooter></AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <ViewRatings
          jobId={mpupcomingJobId}
          userCredentialId={userCredentialId}
          isLogoutModalOpenrating={isLogoutModalOpenViewRating}
          setIsLogoutModalOpenrating={setIsLogoutModalOpenViewRating}
        />
      </MidSection>
    </SideMenuLayout>
  );
};
