
export const convertToBase64 = (str)=>{
    return btoa(encodeURIComponent(str));
}

export const convertFromBase64 = (str) => {
    try {
      return decodeURIComponent(atob(str));
    } catch (error) {
      console.error('Error decoding base64 string:', error);
      return null;
    }
  };

export const convertFileToBase64 = (file) =>{
  return new Promise((resolve,reject)=>{
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = ()=>{
      resolve(fileReader.result)
    };

    fileReader.onerror = (error)=>{
      reject(error)
    }
  })
}

export const convertBase64ToFile = (base64String, fileName, fileType) => {
  const byteCharacters = atob(base64String.replace(/^data:image\/(png|jpg|jpeg|mp4);base64,/, ''));
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });
  
  return new File([blob], fileName, { type: fileType });
};

export const convertBase64ToPdf = (base64String, fileName) => {
  const byteCharacters = atob(base64String?.replace(/^data:application\/pdf;base64,/, ''));
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  return new File([blob], fileName, { type: 'application/pdf' });
};


  