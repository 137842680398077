export const  MARKETPLACE_TYPES =  {
    NEAR_ME_PARTNER : "NEAR_ME_PARTNER",
    NEAR_ME_PARTNER_OPERATOR: "NEAR_ME_PARTNER_OPERATOR",
    NEAR_ME_PARTNER_MAINTENANCE: "NEAR_ME_PARTNER_MAINTENANCE",
    NEAR_ME_JOBS: "NEAR_ME_JOBS",
    NEAR_ME_JOBS_OPEARTOR: "NEAR_ME_JOBS_OPEARTOR",
    NEAR_ME_JOBS_MAINTENANCE: "NEAR_ME_JOBS_MAINTENANCE", 
    EQUIPMENT_MARKETPLACE : "EQUIPMENT_MARKETPLACE",
    EQUIPMENT_MARKETPLACE_USED : "EQUIPMENT_MARKETPLACE_USED",
    EQUIPMENT_MARKETPLACE_RENTAL : "EQUIPMENT_MARKETPLACE_RENTAL",
    DEARLERSHIPS_MARKETPLACE : "DEALERSHIPS_MARKETPLACE",
    MATERIALS_MARKETPLACE : "MATERIALS_MARKETPLACE"
}