import {fetchHandler} from "../../../index"

export const getPurchasedLeadsById = async(id) => {
    try{
        if(!id){
            throw new Error("Atleast 1 parameter is required.")
        }
        const {data} = await fetchHandler("/GetUserLeadsCheck", {ServiceTypeId: id});
        if(data.Status === 200 && Object.keys(data.Data > 0)){
            return data.Data.LeadsData
        }
        else{
            const error = new Error()
            error.message = data.Message.FailMessage
            throw error
        }
    }
    catch(error){
        throw error
    }
} 