import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
  Flex,
  Select,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

export const ImageUpload = ({
  name = "",
  isRequired = false,
  formikProps = { formikProps },
  onDelectionIconClick = () => {},
  label = "Label",
  showLabel = true,
  helperText = "Helper Text",
  placeholderRender,
  formatsAllowed = ["jpeg", "png", "jpg", "pdf"],
  fileSize = "200MB",
  isDisabled,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileType, setFileType] = useState("");
  const inputRef = useRef();
  const { setFieldValue, errors, touched } = formikProps;

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    // console.log(file);
    if (file) {
      const type = file.type.split("/")[0];
      setFileName(file.name);
      setFileType(type);
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setFieldValue(name, file);
    }
  };

  const deleteButtonClickHandler = () => {
    setFileType(null);
    setFileName(null);
    setImageFile(null);
    setImagePreview(null);
    setFieldValue(name, null);
  };

  const fomatText = (array) => {
    const format = array.reduce((currentString, format, index) => {
      if(!index){
        return currentString += format.toUpperCase()
      } 
      return currentString += `, ${format.toUpperCase()}`
    }, "")
    return format
  }

  return (
    <>
      <FormControl
        name={name}
        isDisabled={isDisabled}
        isRequired={isRequired}
        maxW="374px"
        width=""
        isInvalid={errors[name] && touched[name]}
      >
        <Flex
          flexDirection="column"
          gap="0.5em"
          justifyContent={"center"}
          alignItems={"center"}
          cursor={isDisabled ? "not-allowed" : "pointer"}
        >
          {/* <Flex mb="0.5em" flexDirection="column">
            <FormLabel visibility={showLabel ? "" : "hidden"} mb="0">
              {label}
            </FormLabel>
            <Text
              fontWeight="600"
              color="#2c2c2c95"
              opacity={isDisabled ? "0.7" : ""}
            >
              {helperText}
            </Text>
          </Flex> */}
          <Input
            name={name}
            type="file"
            onChange={handleInputChange}
            ref={inputRef}
            hidden
          />
          <Box
            // position="relative"
            display={"flex"}
            height="144px"
            width={"250px"}
            maxW={"250px"}
            maxH={"144px"}
            margin={"auto 0"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            // paddingTop={"10px"}
            opacity={isDisabled ? "0.7" : ""}
            // border={
            //   errors[name] !== "" && touched[name] !== ""
            //     ? "2px dashed #2c2c2c60"
            //     : "2px dashed #D60000"
            // }
            border={"2px solid #EEEEEE"}
            bgColor="#2c2c2c10"
            onClick={() => inputRef.current.click()}
          >
            
            {!imagePreview ? (
              <Flex direction={"column"} justifyContent={"space-around"} alignItems={"center"}>
              <Box height={"24px"} w={"24px"}>
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/upload.svg`}
              />
            </Box>
              <Text
                style={{
                  textAlign: "center",
                  // marginTop: "90px",
                  fontSize: "22px",
                  fontWeight: "50",
                }}
              >
                {/* Upload Document */}
                <p style={{ fontSize: "14px" }}>{`(${fileSize} file size)`}</p>
                <p style={{ fontSize: "14px" }}>
                  {formatsAllowed && formatsAllowed.length > 0
                    ? formatsAllowed.join(", ")
                    : ""}
                </p>
                {placeholderRender}
              </Text>
              </Flex>
            ) : fileType === "image" ? (
              <Image height="100%" width="100%" src={imagePreview} objectFit={"contain"}/>
            ) : fileType === "application" ? (
              <embed
                src={imagePreview}
                type="application/pdf"
                width="100%"
                height="200px"
                onClick={() => inputRef.current.click()}
              />
            ) : fileType === "video" ? (
              <video
                style={{ height: "100%", width: "100%" }}
                src={imagePreview}
                controls={true}
              />
            ) : (
              <></>
            )}
          </Box>
          <Flex
          width="100%"
          padding="0.5em 0.75em"
          background="#2c2c2c10"
          borderRadius="0.5em"
          alignItems="center"
          w={"250px"}
          maxW={"250px"}
          justifyContent="space-between"
        >
          <Text fontSize="12px" fontWeight="600">
            {fileName ? fileName : "No file selected yet."}
          </Text>
          <Button
            onClick={deleteButtonClickHandler}
            background="transparent"
            outline="none"
            dropShadow="none"
            boxShadow="none"
            _hover={{background: "transparent"}}
          >
            <DeleteIcon />
          </Button>
        </Flex>
        </Flex>
       
        <FormErrorMessage>{errors[name]}</FormErrorMessage>
      </FormControl>
    </>
  );
};
