import React, { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../features/global/user/slice/userSlice";
import { resetDocumentFlow } from "../../features/documentFlow/slice/docFlowSlice";
import { Link } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import {
  sidebarData,
  businessOwnerIndividualData,
  businessOwnerCompanyData,
  equipmentOwnerIndividualData,
  equipmentOwnerCompanyData,
  maintenancePartnerCompanyData,
  maintenancePartnerIndividualData,
  OperatorPatner,
  maintenancePatnerIndividual,
} from "../../../staticData/staticData";
import { ReactSVG } from "react-svg";
import "./SideMenu.css";
import { useSelector } from "react-redux";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import { openQrModal } from "../../../webapp/features/dashboard/slice/dashboardSlice";
import { getDefaultAppLanguage } from "../../features/global/mtData/helpers/localStorage";
import { setLanguage } from "../../features/global/appConfig/slice/appConfigSlice";
import { getAllLanguageJsonThunk } from "../../features/global/mtData/thunk/getAllLanguagesThunk";
import { CustomGeneralModal } from "../CustomGeneralModal/CustomGeneralModal";

export const SideMenu = ({ defaultLanguage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [expandedItems, setExpandedItems] = useState([]);
  const userData = useSelector((state) => state.user);

  const gstTaxNumber = userData?.accountDetails?.CompanyDetails?.TaxNumber;
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const onCloseLogoutModal = () => setIsLogoutModalOpen(false);
  const cancelRef = React.useRef();
  
  useEffect(()=>{
    dataToRender()
  },[defaultLanguage])

  const handleItemClick = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const confirmLogout = () => {
    setIsLogoutModalOpen(false);
    dispatch(logoutUser());
    dispatch(resetDocumentFlow());
    navigate("/");
  };
  const handleOptionClick = (profileName, route) => {
    let stringWithoutSpaces = profileName.replace(/\s/g, '');
    
    if (route) {

      if (profileName.toLowerCase() === "My Leads".toLocaleLowerCase() && userData.UserTypeValue === "Company" &&
        !userData.accountDetails?.CompanyDetails?.IsE9VerifiedTaxNumber) {
          toast({
            status: "error",
            description:
              "Company verification under review for the user verified from admin.",
            position: "top-right",
            isClosable: true,
          })
          return;
      }
      return navigate(route);
    }
    if (profileName === "Logout") {
      handleLogoutClick();
    } else if (
      stringWithoutSpaces.toLowerCase() === "pay-per-use" ||
      stringWithoutSpaces.toLowerCase() === "referafriend" ||
      stringWithoutSpaces.toLowerCase() === "refundpayment" ||
      stringWithoutSpaces.toLowerCase() === "myleads"

    ) {
      dispatch(openQrModal());
    }
  };

  const BusinessTypeValue = userData.BusinessTypeValue?.toLowerCase();
  const UserTypeValue = userData.UserTypeValue?.toLowerCase();

  const handleNoGstNumber = (link) => {
    if (!userData.accountDetails.CompanyDetails.TaxCodeTypeValue) {
      toast({
        status: "success",
        position: "top-right",
        description: "No GST Number recorded",
        title: "Please update GST Number",
      });
    } else {
      navigate(link);
    }
  };
  // useEffect(()=>{
  //   dataToRender();
  // },[defaultLanguage])
  // condition for types of business
  const dataToRender = () => {
    const dataMappings = {
      [`${API_CONSTANTS.BUSINESS_OWNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        businessOwnerIndividualData,
      [`${API_CONSTANTS.BUSINESS_OWNER}-${API_CONSTANTS.COMPANY}`]:
        businessOwnerCompanyData,
      [`${API_CONSTANTS.EQUIPMENT_OWNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        equipmentOwnerIndividualData,
      [`${API_CONSTANTS.EQUIPMENT_OWNER}-${API_CONSTANTS.COMPANY}`]:
        equipmentOwnerCompanyData,
      [`${API_CONSTANTS.MAINTENCE_PATNER}-${API_CONSTANTS.COMPANY}`]:
        maintenancePartnerCompanyData,
      [`${API_CONSTANTS.MAINTENCE_PATNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        maintenancePartnerIndividualData,
      [`${API_CONSTANTS.OPERATOR_PATNER}-${API_CONSTANTS.INDIVIDUAL}`]:
        OperatorPatner,
      };
      
      const key = `${BusinessTypeValue}-${UserTypeValue}`;


    return dataMappings[key] || sidebarData;
  };
  return (
    <div>
      <Flex
        flexDirection="column"
        marginTop="1em"
        color="#2c2c2c95"
        fontWeight="600"
        padding="1em 0.75em"
        height="447px"
        overflow="scroll"
      >
        <div className="sidebar-container">
          { dataToRender().length > 0 ? (
            dataToRender().map((item, index) => (
              <div
                className={`parent ${expandedItems.includes(index) ? "expanded" : ""
                  }`}
                key={index}
              >
                <div
                  className="listparent"
                  onClick={() => handleItemClick(index)}
                >
                  <ReactSVG src={item.icon} alt="img" className="list-icon" />
                  <ReactSVG
                    src={item.iconhover}
                    alt="img"
                    className="list-icon-hover"
                  />
                  <div
                    className="icon-name"
                    onClick={() => {
                      // //console.log("Hello")
                      // //console.log
                      // //console.log(item.profileName)
                      handleOptionClick(item.profileName, item.route)
                    }
                    }
                  >
                    <p>{item.profileName}</p>
                  </div>
                  {item.additionalOptions && (
                    <div className="expand-icon">
                      {expandedItems.includes(index) ? (
                        <ReactSVG
                          className="expand-icon-property"
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/forward.svg`}
                        />
                      ) : (
                        <ReactSVG
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/arrowDown.svg`}
                          alt="img"
                          className="expand-icon-property"
                        />
                      )}
                    </div>
                  )}
                </div>
                {expandedItems.includes(index) && item.additionalOptions && (
                  <div className="additional-options">
                    {item.additionalOptions.map((option, optionIndex) => (
                      <div
                        className="options"
                        key={optionIndex}
                        onClick={() => {
                          // if (
                          //   option.addName === "Buy New Subscription" &&
                          //   userData.UserTypeValue === "Company"
                          // ) {
                          //   if (
                          //     gstTaxNumber &&
                          //     userData.accountDetails.CompanyDetails
                          //       ?.IsE9VerifiedTaxNumber
                          //   ) {
                          //     return navigate(option.route);
                          //   } else if (
                          //     gstTaxNumber &&
                          //     !userData.accountDetails.CompanyDetails
                          //       ?.IsE9VerifiedTaxNumber
                          //   ) {
                          //     toast({
                          //       title: "GST Number Required",
                          //       description: "Please get tax number verified",
                          //       status: "error",
                          //       duration: 5000,
                          //       isClosable: true,
                          //       position: "top-right",
                          //     });
                          //     navigate("/");
                          //   } else {
                          //     toast({
                          //       title: "GST Number Required",
                          //       description:
                          //         "Please update your GST Number to access this feature.",
                          //       status: "error",
                          //       duration: 5000,
                          //       isClosable: true,
                          //       position: "top-right",
                          //     });
                          //     navigate("/");
                          //   }
                          // } else {
                          // Existing functionality for other options
                          // //console.log("REDIRECTING HERE!!!")
                          handleItemClick(optionIndex);
                          handleOptionClick(option.addName, option.route);
                          // }
                        }}
                      >
                        {<Link to={`${option.route}`}> {option.addName} </Link>}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : null}
        </div>
      </Flex>
     
      <CustomGeneralModal
       isOpen={isLogoutModalOpen}
       iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Logout.svg`}
       heading={"Are you sure you want to log out?"}
       hasCloseButton={true}
       onCloseButtonClick={onCloseLogoutModal}
       primaryActionText={"Yes"}
       primaryAction={confirmLogout}
       secondaryActionText={"No"}
       isArrow={false}
       secondaryAction={onCloseLogoutModal}
       margin={"0 50px"}
      ></CustomGeneralModal>
    </div>
  );


};
