export const API_CONSTANTS = {
  /** HTTP VERBS START HERE */
  HTTP_DELETE: "DELETE",
  HTTP_GET: "GET",
  HTTP_PATCH: "PATCH",
  HTT_POST: "POST",
  HTTP_PUT: "PUT",
  /** HTTP VERBS END HERE */

  /** HTTP HEADER KEYS START HERE */
  HEADER_TOKEN: "token",
  /** HTTP HEADER KEYS END HERE */

  /** API ENPOINTS START HERE */
  GET_COUNTRY: "/Country",
  GET_STATE: "/State",
  GET_CITY: "/City",
  POST_LANGUAGE: "/GetAllLanguages",
  LANGUAGE_TRANSALTION_JSON: "LanguageJSONData",
  GET_ALL_BUSINESS_TYPES: "/GetAllBusinessType",
  GET_ALL_USER_TYPES: "/GetUserType",
  SUPPORT_TICKET_UNREGISTERED: "/SupportTicketUnregistred/Create",
  SEND_OTP: "/SendOTP",
  REGISTER_USER: "/Register",
  SAVE_OPERATOR_DETAILS: "/SaveOperatorDetails",
  SAVE_MAINTENANCE_PARTNER_DETAILS: "/SaveMaintenancePartnerDetails",
  SAVE_EQUIPMENT_OWNER_INDIVIDUAL_DETAILS: "/SaveEquipmentOwnerDetails",
  SAVE_BUSINESS_OWNER_DETAILS: "/SaveBusinessOwnerDetails",
  SAVE_COMPANY_DETAILS: "/SaveCompanyDetails",
  GET_LOGIN_DETAILS: "/GetLoginDetails",
  GENERATE_JWT_TOKEN: "/GenerateJWTToken",
  GENERATE_OTP_FOR_REGISTER: "/SendOTP",
  GENERATE_OTP_FORGET_PASSWORD: "/SendForgotPasswordOTP",
  SEND_FORGOT_PASSWORD_OTP: "/SendForgotPasswordOTP",
  UPDATE_PASSWORD_AFTER_FORGOT_PASSWORD: "/UpdatePasswordAfterForgotPassword",
  GET_AUTH_USER_DETAILS: "/GetUserDetails",
  GET_ALL_COMPANY_DESIGNATIONS: "/GetCompanyDesignationType",
  UPDATE_AUTH_USER_DESIGNATION: "/UpdateUserCompanyDesignationDetails",
  UPDATE_AUTH_PROFILE_PICTURE: "/UploadUserProfilePicture",
  GET_ALL_E9_ROLES: "/GetAllUserRolesList",
  POST_SELECTED_E9_ROLE: "/CreateE9UserRole",
  GET_ALL_EQUIPMENTS_TYPE: "/GetAllEquipmentType",
  GET_ALL_INDUSTRY: "/GetAllIndustry?EquipmentTypeId=",
  GET_MAKE: "/Make/",
  SAVE_EQUIPMENT_LICENSE: "/CreateEquipmentLicence",
  HAS_NO_LICENSE: "/CreateNoLicenseRecordForPartner",
  GET_LICENSE_TYPE_LIST: "/GetLicenseTypeList",
  UPLOAD_USER_IDENTITY_DOCUMENT: "/UploadUserIdentityDocument",
  GET_VERIFICATION_DOCUMENTS: "/GetVerificationDocumentType",
  UPLOAD_PROFILE_PICTURE: "/UploadUserProfilePicture",
  UPLOAD_EQUIPMENT_DETAILS: "/CreateUserEquipmentDetails",
  UPLOAD_VERIFICATION_DOCUMENT: "/UploadUserIdentityDocument",
  GET_TAX_DOCUMENT_BY_COUNTRY_ID: "/GetTaxDocTypeByCountryId",
  UPDATE_BUSINESS_IDENTIFICAION_DOCUMENT : "/UpdateCompanyUserTaxDetails",
  UPDATE_USER_LANGUAGE : "/SaveUserLanguageDetails",

  GET_DASHBOARD_DETAILS : "/GetDashboardDetails",
  GET_ALL_TOP_OPERATOR_PARTNER_LIST: "/GetAllTopOperatorPartnersList",
  GET_ALL_TOP_MAINTENANCE_PARTNER_LIST : "/GetAllTopMaintenancePartnersList",
  GET_ALL_OPERATOR_JOBS_WAITING_FOR_YOU : "/GetAllOperatorJobsWaitingForYou",
  GET_MARKETPLACE_FILTER : "/GetMarketPlaceFilter",
  GET_ALL_E9DEALERSHIPMARKETPLACELIST : "/GetE9DealershipMarketPlaceList",
  GET_EQUIPMENT_MAKE_ID : "/Make",
  GET_FILTER_MATERIAL_MARKETPLACELIST:"/GetFilterMaterialMarketplaceList",
  GET_DEALERSHIP_DETAILS_BY_ID :"/GetDealershipDetailsById",
  GET_USER_DS_PRODUCT_LIST :"/GetUserDsProductList",
  GET_MATERIAL_DETAILS_LIST : "/GetMaterialDetailsList",
  GET_MATERIAL_TYPE : "/GetMaterialType",
  GET_ALL_MARKETPLACE_SERVICES : "/GetAllMarketPlaceServices",

  POST_CREATE_E9_DS_MP_CONTACT_DETAILS : "/CreateE9DsMpContactDetails",
  GET_E9_DEALERSHIP_MP_CONNECT_STATUS : "/GetE9DealershipMpConnectStatus",
  POST_MATERIAL_CONTACT_DETAILS : "/PostMaterialContactDetails",
  GET_EQUIPMENT_MAKE_LIST : "/GetEquipmentMakeList",

  // My Business 
  GET_USER_DEALERSHIP_LIST : "GetUserDealershipList",
  DELETE_DEALERSHIP_DETAILS : "/DeleteDealershipDetails",
  GET_EQUIPMENT_STATUS_TYPE : "/GetEquipmentStatusType",
  GET_INDUSTRY_TYPE_LIST : "/GetIndustryTypeList",
  CREATE_DEALERSHIP_DETAILS : "/CreateDealershipDetails",
  CREATE_DEALERSHIP_IMMAGE_DETAILS : "/CreateDealershipImageDetails",
  CREATE_DEALERSHIP_CERTIFICATE_DETAILS : "/CreateDealershipCertificateDetails",
  CREATE_DEALERSHIP_VIDEO_DETAILS : "/CreateDealershipVideoDetails",
  GET_PHOTO_TYPE : "/GetPhotoType",
  GET_VIDEO_TYPE : "/GetVideoType",
  GET_RESPONSE_OPTION_TYPE : "/GetResponseOptionType",
  UPDATE_DEALERSHIP_DETAILS : "/UpdateDealershipDetails",
  GET_MT_MYBUSINESS_TYPE_LIST : "/GetMtMyBusinessTypeList",
  DELETE_DEALERSHIP_IMAGE : "/DeleteDealershipImage",
  DELETE_DEALERSHIP_VIDEO : "/DeleteDealershipVideo",
  GET_EQUIPMENT_TYPE_FILTER_LIST:"/GetEquipmentTypeFilterList",
  DELETE_DEALERSHIP_CERTIFICATE : "/DeleteDealershipCertificate",
  REMOVE_DEALERSHIP_FROM_MARKETPLACE : "/DeleteE9DsMarketplaceDetails",

  // My Leads
  GET_MY_ORDERS_REQUEST_SENT_LIST : "/GetMyOrdersRequestSentList/12/0",
  GET_MY_ORDERS_REQUEST_RECEIVED_LIST : "/GetMyOrdersRequestReceivedList/12/0",
  GET_RESPONSE_TYPE_DETAILS : "/GetResponseTypeDetails",
  GET_TRANSACTION_MODE_DETAILS : "/GetTransactionModeDetails",
  UPDATE_E9_MARKETPLACE_CONTACT_REDEEMED_DETAILS : "/UpdateE9MarketplaceContactRedeemedDetails",
  REVOKE_CONTACT : "/SendUnSendContactRequestForMarketPlaceAsset",
  GET_CONTACT_REQUEST_RECEIVED_LIST : "/GetContactRequestReceivedListForE9MarketPlaceAsset/12/0",
  GET_RENTAL_DURATION : "/GetRentalDurationByE9MarketPlaceId",
  GET_EQUIPMENT_AVAILABILITY : "/GetEquipmentAvailability",
  UPDATE_ACCEPT_REJECT_STATUS : "/UpdateE9MarketPlaceContactResponseStatus",
  UPDATE_ACCEPT_REJECT_REEDEM_STATUS : "/UpdateE9MarketplaceContactRedeemedDetails",
  GET_MYLEADS_DS_CONNECT_REQUEST_SENT_LIST : "/GetMyLeadDsConnectRequestSentList/10/0",
  GET_MYLEADS_DS_CONNECT_REQUEST_RECEIVED_LIST : "/GetMyLeadDsConnectRequestReceivedList/10/0",
  GET_MYLEADS_DS_CONNECT_RECEIVED_LIST : "/GetMyLeadDsConnectReceivedList/10/0?DealershipMpGuid=",
  CREATE_E9_DS_MARKETPLACE_DETAILS : "/CreateE9DsMarketplaceDetails",
  

  // MArketplace Dealership
  GET_E9_DEALERSHIP_MP_LIKE_LIST : "/GetE9DealershipMpLikeList",
  CREATE_E9_DS_MP_LIKE_DETAILS : "/CreateE9DsMpLikeDetails",

  /** Leads Api  */
  CREATE_E9_FREE_REDEEMED_TRANSACTION : "/CreateE9FreeRedeemedTransaction",
  CREATE_E9_SUBSCRIPTION_REDEEMED_TRANSACTION : "/CreateE9RedeemedTransactionDetails",

  /** NEAR ME JOBS  */

  /** E9 SERVICES */
  GET_E9_SERVICE_CATEGORY_DETAILS : "/GetE9ServiceCategoryDetails",
  GET_EQUIPMENT_NICK_NAME_LIST : "/GetEquipmentNickNameList",
  GET_ALL_EQUIPMENT_TYPE_LIST  : "/GetAllEquipmentType",
  GET_ALL_EQUIPMENT_AGE_LIST : "/GetEquipmentAgeList",
  GET_EQUIPMENT_MODEL: "/Model",
  GET_SALARY_TYPE_LIST: "/GetSalaryTypeList",
  GET_PROVIDENT_FUND_LIST: "/GetProvidentFundList",
  GET_PAY_CYCLE_LIST: "/GetPayCycleList",
  GET_FOOD_BENEFIT_LIST: "/GetFoodBenifitsList",
  GET_ACCOMODATION_TYPE_LIST: "/GetAccomodationTypeList",
  GET_SHIFT_TYPE_LIST: "/GetShiftTypeList",
  GET_WORKING_HOUR_TYPE_LIST : "/GetWorkingHourTypeList" ,
  GET_LUNCH_BREAK_TYPE_LIST : "/GetLunchBreakTypeList" ,
  GET_JOB_DURATION_LIST : "/GetJobDurationList" ,
  GET_NATURE_OF_WORK_LIST: "/GetNatureOfWorkList" ,
  GET_TRAVEL_ALLOWANCE_LIST: "/GetTravelAllowanceList" ,
  GET_INSURANCE_TYPE_LIST: "/GetInsuranceTypeList" ,
  GET_MAINTENANCE_CATEGORY: "/GetMaintenanceCategory",
  GET_REPAIR_TYPE: "/RepairType",
  CREATE_OPERTOR_JOB_DETAILS: "/CreateOperatorJobDetails",
  CREATE_OPERATOR_JOB_SEARCH_FILTER : "/CreateOperatorJobSearchFilter", 
  GET_EQUIPMENT_ENGINE_MAKER_LIST : "/GetEngineMakerList",
  GET_FUEL_TYPE_LIST: "/GetFuelTypeList",
  GET_AUTO_ENGINE_SHUTDOWN_LIST: "/GetEquipmentAutoEngineShutdownStatus" ,
  GET_ASSET_OWNERSHIP_TYPE_LIST: "/GetAssetOwnershipTypeList",
  CREATE_NEW_ASSET_DETAILS: "/CreateNewAssetDetails",
  GET_EQUIPMENT_MAPPING_DETAILS: "/GetEquipmentMappingDetails",
  CREATE_MAINTENANCE_JOB_DETAIL: "/CreateMaintenanceJobDetail",
  ADD_IMAGE_TO_MAINTENANCE_JOB_DETAIL : "/AddImageToMaintenanceJobDetail",
  IN_PROCESS_JOBS_LIST_FOR_OWNER: "/InProcessJobListForOwner",
  CREATE_ASSET_PURCHASING_DETAILS: "/CreateAssetPurchasingDetails",
  CREATE_ASSET_OTHER_PROPERTY_DETAILS: "/CreateAssetOtherPropertiesDetails",

    /** My Store*/
    GET_STORE_DETAILS : "/GetStoreDetails",
    GET_COMPANY_STAFF_DETAILS : "/GetCompanyStaffDetails",
    POST_STORE_DETAILS : "/PostStoreDetails",
    POST_STORE_MANAGER_DETAILS : "/PostStoreManagerDetails",
  
  /** API ENPOINTS END HERE */

  /** API BODY PARAMS START HERE */

  /** API BODY PARAMS END HERE */

 /**  BUSINESS ROLES */
  BUSINESS_OWNER:"business owner",
  EQUIPMENT_OWNER:"equipment owner",
  MAINTENCE_PATNER:"maintenance partner",
  OPERATOR_PATNER:"operator partner",

  /**USER TYPE ROLE */
  INDIVIDUAL:"individual",
  COMPANY:"company",

   /**OPERATOR PATNER */
   OPERATOR_PARTNER:"operator partner",
 
  /**API ENDPOINTS */
  UPDATE_PERSONAL_DETAIL:"/UpdatePersonalDetails",
  GET_REFERRED_TYPE : "/GetReferredType",
  GET_USER_DETAILS : "/GetUserDetails",
  CHANGE_PASSWORD : "/ChangePassword",
  GET_PRIVACY_OPTIONS_FOR_USER:"/GetPrivacyOptionsForUser",
  SET_USER_PRIVACY_SETTINGS:"/SetUserPrivacySettings" 

};
