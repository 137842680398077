import React, { useState,useRef, useEffect} from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { useQuery, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { formatNumberIndianStyle } from "../../../staticData/CommonMethod";
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { ratingDescriptions } from "../../../staticData/CommonMethod";
import { ViewRatings } from "../../components/ViewRatings/ViewRatings";
import { Formik, Form,useField, Field } from "formik";
import * as Yup from "yup";
import './UpcomingJobStaus.css';
import { cancelJobOngoing, createPostRating, getRatingOwner, workSubmitReport } from "../../services/upcoming/upcoming";
import { getOwnerJobDetailsbyID, getupcomingMPjobDescription } from "../../services/jobsPosted/jobsPosted";

export const MpOngoing = () => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const { mpongoingJobId } = useParams();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isLogoutModalOpenOtp, setIsLogoutModalOpenOtp] = useState(false);
  const onCloseLogoutModal = () => setIsLogoutModalOpen(false);
  const onCloseLogoutModalOTP = () => setIsLogoutModalOpenOtp(false);

  const [isLogoutModalOpenRating, setIsLogoutModalOpenRating] = useState(false);
  const onCloseLogoutModalRating = () => setIsLogoutModalOpenRating(false);

  const [rating, setRating] = useState(0);
  const [paymentonTimerating, setpaymentonTimerating] = useState(0);
  const [behaviorRating, setbehaviorRating] = useState(0);
  const [isLogoutModalOpenViewRating, setIsLogoutModalOpenViewRating] =useState(false);
  const [Error, setError] = useState(null);
  const [ratinglength, setratinglength] = useState(0);
  const cancelReff = React.useRef();
  const cancelReffOtp = React.useRef();
  const cancelReffRating = React.useRef();
  const [imagePreview, setImagePreview] = useState(null);
  const toast = useToast();
  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const fileInputRef = useRef();

  const { data: OngoingJobDescriptionmp } = useQuery("OngoingjobDescriptionmp", () => getupcomingMPjobDescription(mpongoingJobId));
  const { data: UpcomingJobOwnerDetailsMP } = useQuery("upcomingjobownerDetailsmp",() => getOwnerJobDetailsbyID(mpongoingJobId));

  const resultJobDetails = OngoingJobDescriptionmp?.data?.Data?.JobsDetails[0];
  const ownerDetails = UpcomingJobOwnerDetailsMP?.data?.Data?.OwnerDetails[0];
  const userCredentialId = ownerDetails?.UserCredentialId;

  const FileUploadSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("File is required")
      .test(
        "fileSize",
        "File too large",
        (value) => value && value.size <= 1048576 // 1 MB
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) =>
          value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
      ),
    description: Yup.string()
      .trim()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters long")
      .max(500, "Description can't be longer than 500 characters"),
  });

  const otpSchema = Yup.object({
    otp1: Yup.string().length(1, 'Must be exactly 1 digit').required('Required').matches(/^[0-9]$/, 'Must be a Number digit'),
    otp2: Yup.string().length(1, 'Must be exactly 1 digit').required('Required').matches(/^[0-9]$/, 'Must be a Number digit'),
    otp3: Yup.string().length(1, 'Must be exactly 1 digit').required('Required').matches(/^[0-9]$/, 'Must be a Number digit'),
    otp4: Yup.string().length(1, 'Must be exactly 1 digit').required('Required').matches(/^[0-9]$/, 'Must be a Number digit'),
    reasonforcancellation:Yup.string().required('Please specify the reason').max(500,'500 characters is limit').min(10,'min 10 character is required')
  });


  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };


  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handlePaymentStarrClick = (paymentonTimerating) => {
    setpaymentonTimerating(paymentonTimerating);
  };

  const handlebehaviorStarClick = (behaviorRating) => {
    setbehaviorRating(behaviorRating);
  };
  

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("file", file);

    if (file) {
      setFileType(file.type);
      if (file.type.startsWith("image/")) {
        setFilePreview(URL.createObjectURL(file));
      } else if (file.type === "application/pdf") {
        // For PDF, use a URL to the file
        setFilePreview(URL.createObjectURL(file));
      } else if ([".xls", ".xlsx"].some((ext) => file.name.endsWith(ext))) {
        // For Excel, set a generic icon or message
        setFilePreview("Excel file selected"); // Replace with an appropriate icon or message
      } else {
        // For other file types
        setFilePreview("File selected");
      }
    }
  };

  const submitForm = async (value,{ resetForm }) => {
    const payload = {
      JobId: mpongoingJobId,
      OperatorComment: value.description,
      OperatorWorkUpdate: value.file,
    };
    const data = await workSubmitReport(payload);

    try {
      if (data?.APIResponseCode === 200) {
        toast({
          title: "",
          description: `${data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        resetForm();
        setFilePreview(null); // Reset Formik form
        onCloseLogoutModal(); // Close the modal
      }
    } catch (error) {
      toast({
        title: "",
        description: `Error while processing`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };



  const handleSubmit = async(values,{ resetForm }) => {
    const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4;
    const reason=values.reasonforcancellation;
    const payload={
        JobId:mpongoingJobId,
        EndOtp:values.otp1 + values.otp2 + values.otp3 + values.otp4,
        CancellationReason:values.reasonforcancellation,
        IsJobUpcoming: "0",
    }
    
    try{
      const data=await cancelJobOngoing(payload);
     if(data?.Status===true){
        toast({
            title: "",
            description: `${data?.Message?.SuccessMessage}`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          resetForm();
          onCloseLogoutModalOTP(); 
     }

    }
    catch(error){
        toast({
            title: "",
            description: `Please Enter the Correct OTP`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
    }
  
  };


  const handleKeyUp = (e, nextInputId) => {
    if (e.target.value.length === 1 && nextInputId) {
      document.getElementById(nextInputId).focus();
    }
  };


  const handleSubmitRating = async () => {
    const payload = {
      BehaviourRating: behaviorRating,
      PaymentOnTime: paymentonTimerating,
      SafetyConditions: rating,
      OwnerUserCredentialId: userCredentialId,
      JobId: parseInt(mpongoingJobId),
    };

    const data = await createPostRating(payload);

    try {
      if (data?.Status === 201) {
        toast({
          title: "",
          description: `${data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        queryClient.invalidateQueries("upcomingjobownerDetails");
        onCloseLogoutModal();
      }
    } catch (err) {
      toast({
        title: "",
        description: `${data?.Message?.FailMessage}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };


  useEffect(()=>{
    getRatingOwner(parseInt(mpongoingJobId), userCredentialId)
    .then((data) => {
      const ratings = data?.Data?.OwnerJobRating[0];
      if (ratings) { 
        setratinglength(data?.Data?.OwnerJobRating);
      }
    })
    .catch((err) => {
      setError(err);
      console.error("Error fetching ratings:", err);
    }); 
  },[userData.token, mpongoingJobId, userCredentialId])






  const OTPInput = ({ ...props }) => {
    const [field, meta] = useField(props);
    const inputRef = useRef();
  
    useEffect(() => {
      if (props.autoFocus) {
        inputRef.current.focus();
      }
    }, [props.autoFocus]);
  
    return (
      <>
        <input  className="otp-input"  ref={inputRef} {...field} {...props} />
        {meta.touched && meta.error ? <div className="error-text-ongoing-cancel">{meta.error}</div> : null}
      </>
    );
  };
  return (
    <SideMenuLayout>
      <DashboardNavBar />
      <MidSection>
        <div>
          <div>
            <div className="upcoming-job-title-layout">
              <p className="Job-title-upcomming">
                {resultJobDetails?.JobTitle}
              </p>
            </div>{" "}
            <div className="owner-contact-details">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "6px" }}
              >
                <ReactSVG
                  style={{ marginTop: "8px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerContact.svg`}
                />
                <p className="OwnerDetailsText">WORK STATUS</p>
              </div>

              <div className="upcoming-seprator"></div>
            </div>
            <div className="WorkStatus-button">
              <button
                className="submit-button-report"
                onClick={() => setIsLogoutModalOpen(true)}
              >
                <p className="submit-daily-report">Submit Daily Work Report</p>
              </button>
            </div>
            {/*************OWner Details************ */}
            <div className="owner-contact-details">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "6px" }}
              >
                <ReactSVG
                  style={{ marginTop: "8px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerContact.svg`}
                />
                <p className="OwnerDetailsText">OWNER's CONTACT DETAILS</p>
              </div>

              <div className="upcoming-seprator"></div>
            </div>
            <br />
            <div className="Rating-layout">
              <div className="imageContainer">
                <img
                  className="userImageProfile"
                  src={`${process.env.REACT_APP_CDN_PVT_URL}UserProfilePicture/UserProfilePicture_23_Nov_2023_07_02_54_AM_144.png`}
                />
              </div>
              <div className="rating-star">
                <p className="rating-details">
                  {ownerDetails?.OverAllRating || "0"}
                </p>
              </div>
              {/* <br/> */}
              <div style={{ marginTop: "20px" }}>
                <p className="OwnerName">{ownerDetails?.OwnerName}</p>
                <p className="companyName">
                  <span className="companyName">Company: </span>
                  {ownerDetails?.CompanyName}
                </p>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                >
                  <ReactSVG
                    style={{ marginTop: "9px" }}
                    src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/ownercall.svg`}
                  />
                  <p className="phoneNumber">{ownerDetails?.MobileNumber}</p>
                </div>
              </div>
              <div className="rating-seprator"></div>
              <div className="giveRatings">
                <ReactSVG
                  src={
                    "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/star.svg"
                  }
                  style={{ marginLeft: "1px" }}
                />

{ratinglength?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{ marginTop: "-8px" }}
                    onClick={() => setIsLogoutModalOpenViewRating(true)}
                  >
                    {" "}
                    View Ratings
                  </p>
                  <ReactSVG
                    style={{ marginTop: "1px" }}
                    src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerDetailrightarrow.svg`}
                  />
                </div>
              ) : (
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                >
                  <p
                    style={{ marginTop: "-8px" }}
                    onClick={() => setIsLogoutModalOpen(true)}
                  >
                    {" "}
                    Give Ratings
                  </p>
                  <ReactSVG
                    style={{ marginTop: "1px" }}
                    src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/OwnerDetailrightarrow.svg`}
                  />
                </div>
              )}
              </div>
            </div>
            {/* *************************************************** */}
            <div className="owner-contact-details">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "6px" }}
              >
                <ReactSVG
                  style={{ marginTop: "8px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/Equipmentdetaailicon.svg`}
                />
                <p className="OwnerDetailsText">EQUIPMENT DETAILS</p>
              </div>

              <div className="upcoming-seprator"></div>
            </div>
            <div className="Equipment-details-card-layout">
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  {resultJobDetails?.EquipmentTypeName}
                </p>
                <p className="EquipmentTypeText">Equipment Type</p>
              </div>
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  {resultJobDetails?.EquipmentMakeName}
                </p>
                <p className="EquipmentTypeText">Equipment Make</p>
              </div>
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  {resultJobDetails?.EquipmentModelName}
                </p>
                <p className="EquipmentTypeText">Equipment Model</p>
              </div>
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  {resultJobDetails?.EquipmentAge}
                </p>
                <p className="EquipmentTypeText">Equipment Age</p>
              </div>
            </div>
            {/* <br /> */}
            {/************* job detials******** */}
            <div className="owner-contact-details">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "6px" }}
              >
                <ReactSVG
                  style={{ marginTop: "8px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/Ownerlocation.svg`}
                />
                <p className="OwnerDetailsText">JOB DETAILS</p>
              </div>

              <div className="upcoming-seprator"></div>
            </div>
            <div className="job-layout">
              <p className="resultDetails">{resultJobDetails?.WorkLocation}</p>
              <p className="EquipmentTypeText">Job Location</p>
            </div>
            {/*************************************** */}
            <div className="owner-contact-details">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "6px" }}
              >
                <ReactSVG
                  style={{ marginTop: "8px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/Ownersalary.svg`}
                />
                <p className="OwnerDetailsText">IN HAND SALARY DETAILS</p>
              </div>

              <div className="upcoming-seprator"></div>
            </div>
            <div className="Equipment-details-card-layout">
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  &#8377;{" "}
                  {`${formatNumberIndianStyle(resultJobDetails?.Salary)} ${
                    resultJobDetails?.SalaryTypeValue
                  }`}
                </p>

                <p className="EquipmentTypeText">in hand salary</p>
              </div>
              <div className="Equipment-details-type-card">
                <p className="resultDetails">{resultJobDetails?.PFValue}</p>
                <p className="EquipmentTypeText">PF Provided</p>
              </div>
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  {resultJobDetails?.PayCycleValue}
                </p>
                <p className="EquipmentTypeText">Pay Cycle</p>
              </div>
            </div>
            {/****************Food Accommodation********************* */}
            <div className="owner-contact-details">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "6px" }}
              >
                <ReactSVG
                  style={{ marginTop: "8px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/bedAccomdation.svg`}
                />
                <p className="OwnerDetailsText">FOOD & ACCOMMODATION</p>
              </div>

              <div className="upcoming-seprator"></div>
            </div>
            <div className="Equipment-details-card-layout">
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  {resultJobDetails?.FoodBenefitValue}
                </p>
                <p className="EquipmentTypeText">Food</p>
              </div>
              <div className="Equipment-details-type-card">
                <p className="resultDetails">
                  {resultJobDetails?.AccomodationValue}
                </p>
                <p className="EquipmentTypeText">Accommodation</p>
              </div>
            </div>
          </div>
          {/**********************Cancel Button*********** */}
           <div className="Cancel-job">
            <button className="cancel-button">
                <p className="cancel-text" 
                onClick={() => setIsLogoutModalOpenOtp(true)}
                >Cancel Job</p>
            </button>
           </div>
        </div>

        <AlertDialog
          isOpen={isLogoutModalOpen}
          leastDestructiveRef={cancelReff}
          onClose={onCloseLogoutModal}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "12px",
                      flexDirection: "column",
                      
                    }}
                  >
                    <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg" />
                    <p> Operator Partner Daily Work Updates</p>
                  </div>
                </div>
               
              </AlertDialogHeader>

              
              <div>
                <Formik
                  initialValues={{ file: "", description: "" }}
                  validationSchema={FileUploadSchema}
                  onSubmit={(values, { resetForm ,setSubmitting }) => {
                    submitForm(values, { resetForm })
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, setFieldValue, touched, errors }) => (
                    < div className="form-submit-button">
                        <div>
                        <Form>
                      <label htmlFor="file" className="work-description-text">Upload Document :<span className="error-text-ongoing-star">*</span></label>
                      <br />
                      <div className="form-submit-button" style={{border:"1px solid var(--Light-50, #E3E5E8)", width:"257px", height: '159px',marginLeft:"18%",marginTop:"12px" ,background:" var(--Light-30, #F5F5F5)"}}   >
                      {fileType.startsWith("image/") && filePreview && (
                        <img
                          src={filePreview}
                          alt="Preview"
                          style={{ width:"257px", height: '159px' ,objectFit:"fill"}}
                        />
                      )}
                      {fileType === "application/pdf" && filePreview && (
                        <embed
                          src={filePreview}
                          type="application/pdf"
                          width="100"
                          height="100"
                          style={{ border: "1px solid black" }}
                        />
                      )}
                      </div>
                      <br />
                     
                      <div style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                      <label htmlFor="file" className="docbutton-ongoing">
                   <p className="upload-text"> Upload</p>
                   
                  
                </label>
                </div>
                      <input
                        id="file"
                        name="file"
                        type="file"
                         style={{ display: 'none' }} // Hide the default input
                        onChange={(event) =>
                          handleFileChange(event, setFieldValue)
                        }
                      />
                      {touched.file && errors.file && <div className="error-text-ongoing">{errors.file}</div>}
                      
                      <div className="work-description-layout">
                      <label htmlFor="description" className="work-description-text">Work Description: <span className="error-text-ongoing-star">*</span></label>
                      <br />
                      <Field
                        as="textarea"
                        id="description"
                        name="description"
                        className="resonforcancellation"
                      />
                      {touched.description && errors.description && (
                        <div className="error-text-ongoing">{errors.description}</div>
                      )}
                      </div>
                      <div className="submit-reject">
                      <button style={{marginTop:'2px'}} type="submit"  className="reject-button-ongoing" onClick={onCloseLogoutModal}>
                       <p className="accept-job-text">No Thanks</p> 
                      </button>
                      <button type="submit" className="accept-button-ongoing" disabled={isSubmitting}>
                      <p className="canceljob-text">Submit</p>
                      </button>
                      </div>
                      
                    </Form>
                        </div>
                   
                    </div>
                  )}
                </Formik>
              </div>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isLogoutModalOpenOtp}
          leastDestructiveRef={cancelReffOtp }
          onClose={onCloseLogoutModalOTP}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
            <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "12px",
                      marginBottom:"13px"
                    }}
                  >
                    <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg" />
                  </div>
                  </div>
                  <p className="raise-complaint-text">Raise a Complaint</p>
                  <Formik
              initialValues={{ otp1: '', otp2: '', otp3: '', otp4: '', reasonforcancellation: '' }}
              validationSchema={otpSchema}
              onSubmit={handleSubmit}
            >
                 {({ touched, errors }) => (
                    <Form>
                    <div className="input-layout">   
                    <p className="otp-text">END OTP: <span className="error-text-ongoing-star">*</span></p>
                      <div className="otpinputLayout-ongoing">
                   
                      <div className="otpinputerror-layout-ongoing">
                      <OTPInput className="OtpInputBox" name="otp1" autoFocus maxLength="1" onKeyUp={(e) => handleKeyUp(e, 'otp2')} />
                      </div>
                      <div>
                      <OTPInput className="OtpInputBox" name="otp2" id="otp2" maxLength="1" onKeyUp={(e) => handleKeyUp(e, 'otp3')} />
                      </div>
                      <div>
                      <OTPInput className="OtpInputBox" name="otp3" id="otp3" maxLength="1" onKeyUp={(e) => handleKeyUp(e, 'otp4')} />
                      </div>
                      <div>
                      <OTPInput className="OtpInputBox" name="otp4" id="otp4" maxLength="1" onKeyUp={(e) => handleKeyUp(e, null)} />
                      </div>
                      
                      </div>
                      </div> 
                      <br/>
                      <div className="input-layout">
                    <p className="otp-text">REASON FOR CANCELLATION : <span className="error-text-ongoing-star">*</span></p>
                  <Field name="reasonforcancellation" as="textarea"  className="resonforcancellation-cancel" placeholder="Please Enter the reason for Cancellation" />
                  {touched.reasonforcancellation && errors.reasonforcancellation && <div className="error-text-ongoing-cancel">{errors.reasonforcancellation}</div>}
                </div>
                <br/>
                      <div  className="submit-reject">
                      <button  onClick={onCloseLogoutModalOTP} className="reject-button-ongoing"> <p className="accept-job-text" >No Thanks</p></button>
                      <button   className="accept-button-ongoing"  type="submit"><p  className="canceljob-text" >Submit</p></button>
                      </div>
                    </Form>
                 )}
            </Formik>
                  <div>
                
                  </div>
            </AlertDialogContent>

          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isLogoutModalOpenRating}
          leastDestructiveRef={cancelReffRating}
          onClose={onCloseLogoutModalRating}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <ReactSVG
                    src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/RatingStar.svg`}
                  />
                  <p className="rateOwnerText">Rate Owner</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="Rating-layout-owner">
                    <div className="Condition-heading">Saftey Condition</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "-12px",
                      }}
                    >
                      <p className="ratingText">{ratingDescriptions[rating]}</p>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((starNumber) => (
                          <span
                            key={starNumber}
                            className={`star ${
                              starNumber <= rating ? "active" : ""
                            }`}
                            onClick={() => handleStarClick(starNumber)}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="rating-section">
                      {rating}
                      <span className="outofrating">/5</span>
                    </div>
                  </div>
                  <div className="Rating-layout-owner">
                    <div className="Condition-heading">Payment on time</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "-8px",
                      }}
                    >
                      <p className="ratingText">
                        {ratingDescriptions[paymentonTimerating] || " "}
                      </p>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((paymentstarNumber) => (
                          <span
                            key={paymentstarNumber}
                            className={`star ${
                              paymentstarNumber <= paymentonTimerating
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handlePaymentStarrClick(paymentstarNumber)
                            }
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="rating-section">
                      {paymentonTimerating}
                      <span className="outofrating">/5</span>
                    </div>
                  </div>

                  <div className="Rating-layout-owner">
                    <div className="Condition-heading">Behavior</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "-12px",
                      }}
                    >
                      <p className="ratingText">
                        {ratingDescriptions[behaviorRating] || " "}
                      </p>
                      <div className="star-rating">
                        {[1, 2, 3, 4, 5].map((Rating) => (
                          <span
                            key={Rating}
                            className={`star ${
                              Rating <= behaviorRating ? "active" : ""
                            }`}
                            onClick={() => handlebehaviorStarClick(Rating)}
                          >
                            &#9733;
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="rating-section">
                      {behaviorRating}
                      <span className="outofrating">/5</span>
                    </div>
                  </div>
                </div>
              </AlertDialogHeader>
              <div className="button-layout">
                <button onClick={onCloseLogoutModalRating} className="submit-button">
                  <p className="button-text">Cancel</p>
                </button>
                <button className="submit-button" onClick={handleSubmitRating}>
                  <p className="button-text">Submit</p>
                </button>
              </div>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <ViewRatings
           jobId={ mpongoingJobId}
           userCredentialId={userCredentialId}
           isLogoutModalOpenrating={isLogoutModalOpenViewRating}
           setIsLogoutModalOpenrating={setIsLogoutModalOpenViewRating} />

      </MidSection>
    </SideMenuLayout>
  );
};
