import React, { useEffect, useState } from "react";
import { MarketPlaceLayout } from "../../../layout/dealershipMarketplaceLayout/MarketPlaceLayout";
import { MidSection } from "../../../../sharedComponent/midSection/MidSection";
import "./MyBusinessDealershipCSS.css";
import MyBusinessDealershipViewCard from "./MyBusinessDealershipViewCard";
import { useDispatch, useSelector } from "react-redux";
import {
  convertFromBase64,
  convertToBase64,
} from "../../../../utils/formatConverter/convertToBase64";
import { Box, Button, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { WelcomeDealership } from "../../../components/MyBuisinessDealershipPopUp/WelcomeDealership";
import {
  clearDealershipData,
  clearStepperIndex,
  closeAddToMarketplace,
  openAddDealershipModal,
  openAddToMarketplace,
  openDealershipModal,
  openDeleteModal,
  openRemoveMarketplaceModal,
  setBusinessGuuid,
  setEditGuuid,
} from "../slice/myBusinessDealershipSlice";
import { AddDealershipPopUp } from "../../../components/MyBuisinessDealershipPopUp/AddDealershipPopUp";
import { v4 as uuid } from "uuid";
import { VirtualDealershipPopUp } from "../../../components/MyBuisinessDealershipPopUp/VirtualDealershipPopUp";
import { DeleteDealershipPopUp } from "../../../components/MyBuisinessDealershipPopUp/DeleteDealershipPopUp";
import { useNavigate, useParams } from "react-router-dom";
import { AddToMarketplacePopUp } from "../../../components/AddToMarketplacePopUp/AddToMarketplacePopUp";
import { getFreeLeadsForCompanyThunk } from "../../freeLeads/thunks/getFreeLeadsForCompanyThunk";
import { getFreeLeadsForIndividualThunk } from "../../freeLeads/thunks/getFreeLeadsForUserThunk";
import { convertToFormData } from "../../../helpers/dataConversion/convertToFormData";
import {
  createE9DsMarketplaceDetails,
  dealerhipFreeReedemTransaction,
  dealerhipSubscriptionReedemTransaction,
  removeDealershipFromMarketplace,
} from "../../../services/postDealership/postDealership";
import { DeleteFromMarketplacePopUp } from "./DeleteFromMarketplacePopUp";
import { getDealershipMarketplaceLeadsCheckThunk } from "../../purchasedLeadsCheck/thunks/getDealershipMarketplaceLeadsCheckThunk";
import {
  GetUserDealershipList,
  GetUserDealershipListCompany,
} from "../../../services/dealershipListing/dealershipListing";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";
import { AddIcon } from "@chakra-ui/icons";
import { V5DashboardDealershipCard } from "../../../components/V5DealershipCards/V5DashboardDealershipCard";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { dealershipListingInitialThunk } from "../thunk/dealershipListingInitialThunk";
import { dealershipListingCompanyInitialThunk } from "../thunk/dealershipListingCompanyInitialThunk ";
import { dealershipListingLoadMoreThunk } from "../thunk/dealershipListingLoadMoreThunk";
import { dealershipListingCompanyLoadMoreThunk } from "../thunk/dealershipListingCompanyLoadMoreThunk";
import { subString } from "../../../helpers/dataConversion/substring";

const MyBusinessDealershipListing = () => {
  // Leads Count Data
  const { freeLeadsData } = useSelector((state) => state.freeLeads);
  const { dealership } = useSelector((state) => state.purchasedLeads);
  const [freeListingCount, setFreeListingCount] = useState(null);
  const userData = useSelector((state) => state.user);
  const [userDealershipList, setUserDealershipList] = useState();
  const gstTaxNumber = convertToBase64(
    userData?.accountDetails?.CompanyDetails?.TaxNumber
  );
  const userTypeValue = userData?.UserTypeValue?.toLowerCase();
  const userValue = "Company".toLowerCase();
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const { addToMarketplaceModal } = useSelector(
    (state) => state.myBusinessDealership
  );
  const [dealershipTeansaction, setDealershipTransaction] = useState();
  const [guuidToDelete, setGuuidToDelete] = useState();
  const dealershipListing = useSelector((state) => state.dealershipAssests);

  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceTypeId } = useParams();

  useEffect(() => {
    dispatch(clearStepperIndex());
    sessionStorage.removeItem("POST_DEALERSHIP_STEPPER_INDEX");
    clearInitialValues();
  }, []);

  useEffect(() => {
    if (userTypeValue === userValue) {
      (async () => {
        dispatch(dealershipListingCompanyInitialThunk());
        // const data = await GetUserDealershipListCompany(gstTaxNumber);
        // if (data?.Status === 200) {
        //   setUserDealershipList(data?.Data?.UserDealershipList);
        // } else {
        //   setUserDealershipList(null);
        // }
      })();
    } else {
      (async () => {
        dispatch(dealershipListingInitialThunk());
        // const data = await GetUserDealershipList();
        // if (data?.Status === 200) {
        //   setUserDealershipList(data?.Data?.UserDealershipList);
        // } else {
        //   setUserDealershipList(null);
        // }
      })();
    }
  }, []);

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem("WELCOME_DEALERSHIP_COUNT");
    if (!hasSeenWelcome) {
      setIsFirstLogin(true);
    }
  }, []);
  useEffect(() => {
    if (isFirstLogin) {
      dispatch(openDealershipModal());
      localStorage.setItem("WELCOME_DEALERSHIP_COUNT", "false");
      setIsFirstLogin(false);
    }
  }, []);
  // Add To Marketplace
  const freeSubscriptionCount = () => {
    const dealershipListingData = freeLeadsData.find(
      (category) =>
        category.SubscriptionTypeValue === "Contact Based" &&
        category.ServiceTypeValue === "Dealership Listing"
    );
    setFreeListingCount({
      leadsRemaining: dealershipListingData.ContactRemaining,
      totalLeads: dealershipListingData.FreeLeadsProvided,
    });
    return {
      leadsRemaining: dealershipListingData.ContactRemaining,
      totalLeads: dealershipListingData.FreeLeadsProvided,
    };
  };

  useEffect(() => {
    if (!freeLeadsData || freeLeadsData.length <= 0) {
      if (userTypeValue.toLowerCase() === "Company".toLowerCase()) {
        dispatch(getFreeLeadsForCompanyThunk());
      } else {
        dispatch(getFreeLeadsForIndividualThunk());
      }
    } else {
      freeSubscriptionCount();
    }
  }, [freeLeadsData, userTypeValue]);

  useEffect(() => {
    getSubscriptionLeadsCheck();
  }, []);
  const clearInitialValues = () => {
    dispatch(clearDealershipData());
    dispatch(clearStepperIndex());
    sessionStorage.clear();
    localStorage.removeItem("POST_DEALERSHIP_REGION");
    localStorage.removeItem("POST_DEALERSHIP_IMAGE_LIST");
    sessionStorage.removeItem("DEALERSHIP_PHOTO_TYPE_LIST");
    localStorage.removeItem("POST_DEALERSHIP_VIDEO_LIST");
    sessionStorage.removeItem("DEALERSHIP_VIDEO_TYPE_LIST");
  };
  const getSubscriptionLeadsCheck = async () => {
    dispatch(
      getDealershipMarketplaceLeadsCheckThunk(convertFromBase64(serviceTypeId))
    );
  };
  const handleDeletePopUp = (guuid) => {
    dispatch(setBusinessGuuid(guuid));
    dispatch(openDeleteModal());
  };

  const handleAddClick = () => {
    dispatch(openAddDealershipModal());
  };

  const handleEditClick = async (guuid) => {
    dispatch(setEditGuuid(guuid));
    navigate(`/my-business/your-dealerships/${convertToBase64(serviceTypeId)}`);
  };

  const handleDealershipMarketplacePost = async () => {
    const data = {
      DealershipGuid: dealershipTeansaction?.DealershipGuid,
      DealershipTypeId: dealershipTeansaction?.EquipmentStatusId,
      DaySpan: 365,
      DealershipUniqueId: dealershipTeansaction?.DealershipId,
      DealershipName: dealershipTeansaction?.DealerName,
    };
    const payload = convertToFormData(data);
    try {
      const data = await createE9DsMarketplaceDetails(payload);
      if (data?.Status === 201) {
        toast({
          title: "Dealership added suceesfully",
          position: "top-right",
          status: "success",
        });
        window.location.reload();
      } else {
        toast({
          title: "Dealership added suceesfully",
          position: "top-right",
          status: "success",
        });
        window.location.reload();
      }
    } catch (error) {}
  };
  const handleFreeTransaction = async () => {
    const findLead = await freeLeadsData?.find(
      (leads) => leads?.ServiceTypeValue === "Dealership Listing"
    );

    const payload = {
      ServiceTypeId: findLead?.ServiceTypeId,
      FreeLeadConsumed: 1,
    };
    const data = convertToFormData(payload);
    try {
      const response = await dealerhipFreeReedemTransaction(data);
      if (response?.Status === 201) {
        handleDealershipMarketplacePost();
      }
    } catch (error) {}
  };
  const handleSubscriptionTransaction = async () => {
    const reedeemPayload = [];
    reedeemPayload.push({
      TransactionId: dealership?.selectedSubscription?.E9TransactionId,
      UserSubsServicePurchasedId:
        dealership?.selectedSubscription?.UserSubsServicePurchasedId,
      ServiceId: dealership?.selectedSubscription?.ServiceTypeId,
      LeadPurchased: dealership?.selectedSubscription?.PurchasedCount,
      LeadRedeemed: 1,
      LeadRemaining: dealership?.selectedSubscription?.LeadRemaining - 1,
    });
    try {
      const data = await dealerhipSubscriptionReedemTransaction(
        JSON.stringify(reedeemPayload)
      );
      if (data?.data?.Status === 201) {
        handleDealershipMarketplacePost();
      }
    } catch (error) {}
  };

  const handleAddToMarketplaceModal = (selectedLeadType) => {
    if (selectedLeadType) {
      const leadType = selectedLeadType.toLowerCase();
      switch (leadType) {
        case "free":
          handleFreeTransaction();
          break;
        case "subscription":
          handleSubscriptionTransaction();
          break;
        default:
          break;
      }
    }
  };

  const handelRemoveDealership = async (id) => {
    setGuuidToDelete(id);
    dispatch(openRemoveMarketplaceModal());
  };

  const dealershipLocationHandler = (locationString = "") => {
if(!locationString) return null
const locationStringArray = locationString.split(",")
 if(locationStringArray.length >= 3){
  return `${locationStringArray[locationStringArray.length - 3]}, ${locationStringArray[locationStringArray.length - 2]}, ${locationStringArray[locationStringArray.length - 1]}`
 }
 if(locationStringArray.length === 2){
  return `${locationStringArray[locationStringArray.length - 2]}, ${locationStringArray[locationStringArray.length - 1]}`
 }
 return `${locationStringArray[locationStringArray.length - 1]}`
  }

  const isDateNew = (dateStr) => {
    const formattedDateStr = dateStr
      .replace(",", "")
      .replace("th-", "-")
      .split(" ")
      .slice(0, 5)
      .join(" ");
    const parsedDate = new Date(formattedDateStr);

    const today = new Date();
    const daysDifference =
      Math.abs(today.getTime() - parsedDate.getTime()) / (1000 * 3600 * 24);

    return daysDifference < 5;
  };
  
  const renderCards = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((data) => {
          

          return <Flex gap="1em">
            <V5DashboardDealershipCard
              boxShadow="0px 6px 15px -3px #00000029"
              maxHeight="450px"
              minHeight="430px"
              maxWidth="385px"
            >
              <Box maxH="200px" minH="200px" maxW="385px">
                <V5DashboardDealershipCard.Verification
                  position="relative"
                  backgroundRepeat="no-repeat"
                  backgroundWidth="100%"
                  backgroundSize="cover"
                  minH="200px"
                  maxH="220px"
                  height="100%"
                  url={
                    data.ImageList &&
                    data.ImageList.length > 0 &&
                    `${process.env.REACT_APP_CDN_PVT_URL}${data.ImageList[0]?.ImageFileRef}`
                  }
                >
                  <Flex
                    direction="column"
                    position="inherit"
                    top="20px"
                    // left="10px"
                    gap="1em"
                  >
                    <Flex justifyContent="space-between" padding="0 0.5em">
                    <Button
                      borderRadius="100px"
                      width="110px"
                      height="30px"
                      backgroundColor={
                        data?.IsVerified ? "#319F0060" : "rgba(214, 0, 0, 0.40)"
                      }
                    >
                      <Text color="#fff" fontSize="12px" fontWeight="600">
                        {data?.IsVerified ? "Verified" : "Not Verified"}
                      </Text>
                    </Button>

                    <Button
                      borderRadius="100px"
                      width="110px"
                      height="30px"
                      backgroundColor={"#2C2C2C80"}
                    >
                      <Text color="#fff" fontSize="12px" fontWeight="600">
                        {data?.IsPromoted ? "Promoted" : "Not Promoted"}
                      </Text>
                    </Button>

                    </Flex>

                    <Button
                      width="41px"
                      height="20px"
                      backgroundColor="#F5F5F5"
                    >
                      <Text color="#141619" fontSize="9px" fontWeight="700">
                        {data?.EquipmentStatusValue}
                      </Text>
                    </Button>

                    { isDateNew(data?.ListedDateTime) ? 
                    <Button
                      width="70px"
                      height="20px"
                      backgroundColor="#F5F5F5"
                    >
                      <Text color="#002961" fontSize="9px" fontWeight="700">
                        Newly Added
                      </Text> 
                    </Button>
                    : null }
                  </Flex>
                </V5DashboardDealershipCard.Verification>

                <Flex direction="column" padding="0.5em 1em" gap="1em">
                  <Box display="flex" flexDirection="column" gap="3px">
                    <V5DashboardDealershipCard.PrimaryText
                      tooltip={data?.DealerName}
                    >
                      {subString(18, data?.DealerName)}
                    </V5DashboardDealershipCard.PrimaryText>

                    <V5DashboardDealershipCard.LocationText
                      tooltip={dealershipLocationHandler(data?.Location) ?  dealershipLocationHandler(data?.Location)  : "N.A."}
                    >
                      
                      {dealershipLocationHandler(data?.Location) ?  subString(25, dealershipLocationHandler(data?.Location))  : "N.A."}
                    </V5DashboardDealershipCard.LocationText>

                    <V5DashboardDealershipCard.CommonText
                      leadingText={"Finance Available"}
                      value={data?.FinanceProvidedValue}
                    />
                    <V5DashboardDealershipCard.CommonText
                      leadingText={"Added on"}
                      value={data?.ListedDateTime ? data?.ListedDateTime?.split(",")[1] : "N.A"}
                    />
                     
                     <Flex gap="5px">
                     <V5DashboardDealershipCard.CommonText
                      leadingText={"ID :"}
                      value={data?.DealershipUniqueId}
                    />
                    <Text>|</Text>
                    <V5DashboardDealershipCard.CommonText
                      leadingText={"OEM :"}
                      value={data?.EquipmentMakeValue}
                    />
                     </Flex>

                  </Box>

                  <Divider />

                  <Flex alignItems="center" justifyContent="space-between">
                    <Box display="flex" gap="1em">
                     
                      <Button
                        onClick={() => handleEditClick(data?.DealershipGuid)}
                        maxH="30px"
                        maxW="50px"
                        backgroundColor="#F5F5F5"
                      >
                        {" "}
                        <img
                          style={{ height: "100%", width: "100%" }}
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`}
                        />{" "}
                      </Button>

                      <Button
                        onClick={() => handleDeletePopUp(data?.DealershipGuid)}
                        maxH="30px"
                        maxW="50px"
                        backgroundColor="#F5F5F5"
                      >
                        {" "}
                        <img
                          style={{ height: "100%", width: "100%" }}
                          src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete.svg`}
                        />
                      </Button>
                      
                    </Box>

                    <Box>
                      <Button
                        backgroundColor="transparent"
                        border={
                          data?.ListedOnMarketplace
                            ? "0.817px solid #002961"
                            : "0.817px solid #F79C00"
                        }
                        color={
                          data?.ListedOnMarketplace ? "#002961" : "#F79C00"
                        }
                        onClick={
                          data?.ListedOnMarketplace
                            ? () =>
                                handelRemoveDealership(data?.DealershipMpGuid)
                            : () =>
                                handleAddToMarketplaceModal(
                                  setDealershipTransaction(data),
                                  dispatch(openAddToMarketplace())
                                )
                        }
                      >
                        {data?.ListedOnMarketplace
                          ? "Remove From Marketplace"
                          : "Add To Marketplace"}
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            </V5DashboardDealershipCard>
          </Flex>
        })
      ) : (
        <p>No Dealership</p>
      );
    return renderJsx;
  };
  return (
    <V5SidebarLayout>
      <WelcomeDealership />
      <AddDealershipPopUp />
      <VirtualDealershipPopUp />
      <DeleteDealershipPopUp />
      <AddToMarketplacePopUp
        isOpen={addToMarketplaceModal}
        onCloseButtonClick={() => dispatch(closeAddToMarketplace())}
        freeListingCount={
          freeListingCount && freeListingCount.totalLeads
            ? freeListingCount.leadsRemaining
            : 0
        }
        subscriptionListingCount={
          dealership?.leadsRemaining ? dealership?.leadsRemaining : 0
        }
        onSubmitButtonClick={handleAddToMarketplaceModal}
      />
      <DeleteFromMarketplacePopUp id={guuidToDelete} />

      {/* <div className="my-business-main-container">
            <div className="my-business-content-container">
              <div className="my-business-text-container">
                <h2 className="my-business-text-heading">Your Dealerships Details</h2>
                <p className="my-business-text-description">Your added Dealerships are here</p>
              </div>
            </div>
            <div className="my-business-button-container">
              <Button className="my-business-button" color={"#002961"} border={"1px solid #002961"} borderRadius={"4px"} display={"flex"} gap={"1em"} onClick={handleAddClick}>
                <img className='additional-details-btn-img' src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/Postdealership/add.svg`} />
                ADD</Button>
            </div>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap", padding: "52px", gap: "1.5em" }}>
            {userDealershipList ? (
              userDealershipList.length > 0 &&
              userDealershipList.map((dealership) => (
                <>
                  <MyBusinessDealershipViewCard
                    key={uuid()}
                    imageUrl={dealership?.ImageList == null ? "" : dealership?.ImageList[0]?.ImageFileRef}
                    dealerName={dealership?.DealerName}
                    location={dealership?.Location}
                    financeAvailable={dealership?.FinanceProvidedValue}
                    listedDateTime={dealership?.ListedDateTime}
                    dealershipUniqueId={dealership?.DealershipUniqueId}
                    equipmentMakeValue={dealership?.EquipmentMakeValue}
                    listedOnMarketplace={dealership?.ListedOnMarketplace}
                    onHandleDeleteButtonClick={() => handleDeletePopUp(dealership?.DealershipGuid)}
                    onEditButtonClick={() => handleEditClick(dealership?.DealershipGuid)}
                    isVerified={dealership?.IsVerified}
                    equipmentStatusValue={dealership?.EquipmentStatusValue}
                    onAddButtonClick={dealership?.ListedOnMarketplace ? () => handelRemoveDealership(dealership?.DealershipMpGuid) : () => handleAddToMarketplaceModal(
                      setDealershipTransaction(dealership),
                      dispatch(openAddToMarketplace())
                    )}
                  />
                </>
              ))
            ) : (
              <p className='dealership-null-data'>No Dealership Added Yet</p>
            )}
          </div> */}

      <Box display="flex" flexDirection="column" gap="1em">
        <Flex
          direction={["column", "column", "column", "row", "row", "row"]}
          alignItems="center"
          justifyContent={[
            "space-evenly",
            "space-evenly",
            "space-between",
            "space-between",
            "space-between",
            "space-between",
          ]}
          padding="0.5em 1em"
          gap={["1em", "1em", "1em", "0em", "0em", "0em"]}
        >
          <Box display="flex" flexDirection="column">
            <Text color="#333" fontSize="24px" fontWeight="700">
              Your Dealership Products
            </Text>
            <Text color="#767676" fontSize="20px" fontWeight="600">
              Add Products to your Dealership
            </Text>
          </Box>

          <Box>
            <Button
              color="#002961"
              border="1px solid #002961"
              borderRadius="4px"
              display="flex"
              gap="1em"
              onClick={handleAddClick}
            >
              <AddIcon
                style={{ stroke: "#0029610", width: "24px", height: "24px" }}
                className="additional-details-btn-img"
              />
              <Text color="#002961" fontSize="18px" fontWeight="700">
                Add
              </Text>
            </Button>
          </Box>
        </Flex>

        <MarketplaceHoc
          marketplaceName="Dealership Marketplace"
          marketplaceType={"Dealership Listing"}
          dataArray={dealershipListing?.data}
          // userImageBaseReference={
          //   dealershipListing?. ? uemData.userImageReference : ""
          // }
          // assetImageBaseReference={
          //   dealershipListing ? dealershipListing?.AssetImageBaseReference : ""
          // }
          endOfData={dealershipListing?.endOfData}
          loadMoreAction={() => {
            userTypeValue === userValue
              ? dispatch(
                  dealershipListingCompanyLoadMoreThunk(
                    dealershipListing?.paginationCount
                  )
                )
              : dispatch(
                  dealershipListingLoadMoreThunk(
                    dealershipListing?.paginationCount
                  )
                );
          }}
          initialLoading={dealershipListing?.initialLoading}
          paginationLoading={dealershipListing?.paginationLoading}
          cardsRenderFunction={renderCards}
        />
      </Box>
    </V5SidebarLayout>
  );
};

export default MyBusinessDealershipListing;
