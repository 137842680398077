import { createSlice } from "@reduxjs/toolkit";
import { getInitialOperatorJobsThunk } from "../thunks/getInitialOperatorJobs";
import { getLoadMoreOperatorJobsThunk } from "../thunks/getLoadMoreOperatorJobs";
import { getInitialFilteredOperatorJobsThunk } from "../thunks/getInitialFilteredOperatorJobs";
import { getLoadMoreFilteredOperatorJobsThunk } from "../thunks/getLoadMoreFilteredDataThunk";

export const operatorJobsNearMeSlice = createSlice({
  name: "operatorJobsSlice",
  initialState: {
    data: null,
    paginitionCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    filterByEquipmentType: null,
    filterByCountry: null,
    filterByState: null,
    filterByCity: null,
    filterDataRequired: null,
    filterModalOpen: false,
    error: null,
    errorMessage: null,
  },
  reducers: {
    setFilterByEquipmentType: (state, { payload }) => {
      state.filterByEquipmentType = payload;
    },
    setFilterByCountry: (state, { payload }) => {
      state.filterByCountry = payload;
    },
    setFilterByState: (state, { payload }) => {
      state.filterByState = payload;
    },
    setFilterByCity: (state, { payload }) => {
      state.filterByCity = payload;
    },
    setFilteredDataRequired: (state, { payload }) => {
      state.filterDataRequired = payload;
    },
    setFilterModalOpen: (state, { payload }) => {
      state.filterModalOpen = payload;
    },
    resetFilters : (state) => {
      state.filterByEquipmentType = null;
      state.filterByCountry = null;
      state.filterByState = null;
      state.filterByCity = null;
      state.filterDataRequired = null;
      state.filterModalOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialOperatorJobsThunk.pending, (state) => {
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.data = null;
      })
      .addCase(getInitialOperatorJobsThunk.fulfilled, (state, action) => {
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.paginitionCount += 1;
        if (action.payload.TotalRecords >= state.cardCount) {
          state.data = action.payload.JobsDetails;
        } else if (action.payload.TotalRecords > 0) {
          state.data = action.payload.JobsDetails;
          state.endOfData = true;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreOperatorJobsThunk.fulfilled, (state, action) => {
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.paginitionCount += 1;
        if (action.payload.TotalRecords >= state.cardCount) {
          state.data = [...state.data, ...action.payload.JobsDetails];
        } else if (action.payload.TotalRecords > 0) {
          state.data = [...state.data, ...action.payload.JobsDetails];
          state.endOfData = true;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getInitialFilteredOperatorJobsThunk.pending, (state) => {
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.data = null;
      })
      .addCase(getInitialFilteredOperatorJobsThunk.fulfilled, (state, action) => {
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.paginitionCount = 1;
        if (action.payload.length >= state.cardCount) {
          state.data = action.payload;
        } else if (action.payload.length > 0) {
          state.data = action.payload;
          state.endOfData = true;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreFilteredOperatorJobsThunk.pending, (state) => {
        state.initialLoading = false;
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = false;
      })
      .addCase(getLoadMoreFilteredOperatorJobsThunk.fulfilled, (state, action) => {
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.paginitionCount += 1;
        if (action.payload.length >= state.cardCount) {
          state.data = [...state.data, ...action.payload];
        } else if (action.payload.length > 0) {
          state.data = [...state.data, ...action.payload];
          state.endOfData = true;
        } else {
          state.endOfData = true;
        }
      })
  },
});

export const {
  setFilterByCountry,
  setFilterByCity,
  setFilterByState,
  setFilterByEquipmentType,
  setFilteredDataRequired,
  setFilterModalOpen,
  resetFilters
} = operatorJobsNearMeSlice.actions;

