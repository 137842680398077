import React, { useState, useEffect, useRef } from "react";
import "./Upcoming.css";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { ReactSVG } from "react-svg";
import { formatNumberIndianStyle } from "../../../../../staticData/CommonMethod";
import { useNavigate } from "react-router-dom";
import { AlertDialog,AlertDialogHeader,AlertDialogContent,AlertDialogOverlay,useToast,
} from "@chakra-ui/react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { endWorkOTPMP } from "../../../../services/ongoing/ongoing";
import { getOngoingMPQuickJobs, getUpcominglistMP, getongoinglistOP } from "../../../../services/jobsPosted/jobsPosted";

const OTPInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  const inputRef = useRef();

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus();
    }
  }, [props.autoFocus]);

  return (
    <>
      <input className="otp-input" ref={inputRef} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error-text">{meta.error}</div>
      ) : null}
    </>
  );
};

const handleKeyUp = (e, nextInputId) => {
  if (e.target.value.length === 1 && nextInputId) {
    document.getElementById(nextInputId).focus();
  }
};

const otpSchema = Yup.object({
  otp1: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
  otp2: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
  otp3: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
  otp4: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
});

export const OnGoing = () => {
  const userData = useSelector((state) => state.user);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const onCloseLogoutModal = () => setIsLogoutModalOpen(false);
  const [ongoingJobId, setongoingJobId] = useState(null);
  const cancelReff = React.useRef();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /*************UseQuery***********************************/
  const { data: getonGoinglistMP } = useQuery("getongingmp", () =>getUpcominglistMP());
  const { data: getonGoinglistOP } = useQuery("getongingop", () =>getongoinglistOP());
  const {data:getongoingMPQuickjobs}=useQuery("getQuickJobsMP",()=>getOngoingMPQuickJobs());


/*********Rendering list***********************************/
  const MPonGoing =getonGoinglistMP?.data?.Data?.FullTimeMaintenanceOngoingJobList ||[];
  const OPonGoing = getonGoinglistOP?.data?.Data?.OperatorOngoingJobList;
  const quickMpJoblist=getongoingMPQuickjobs?.data?.Data?.QuickMaintenanceOngoingJobList ||[];

  /************Merging quick and full time Mp Jobs */
  const combinQucikFullTimejobList=[...MPonGoing,...quickMpJoblist];
    // console.log(combinQucikFullTimejobList)

  const businessTypeValue = userData.BusinessTypeValue;



  const getPopUpOpen = (jobId) => {
    setongoingJobId(jobId);
    setIsLogoutModalOpen(true);
  };

  const handleSubmit = async (values) => {
    const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4;
    const payload = {
      EndOtp: otp,
      JobId: ongoingJobId,
    };
    try {
      const data = await endWorkOTPMP(payload);
      if (data?.Status === true) {
        toast({
          title: "",
          description: `Job has been finished scuccessfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getongingmp");
        queryClient.invalidateQueries("MPfulltimecompletedjobs");
        queryClient.invalidateQueries("MPquickcompletedjobs");
        await queryClient.invalidateQueries("OPcompletedjobskey");
        window.location.reload(true);
        setIsLogoutModalOpen(false);
      }
    } catch (error) {
      toast({
        title: "",
        description: `Please enter correct OTP.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <div>
      {businessTypeValue === "Operator Partner" ? (
        <div>
          {OPonGoing?.length > 0 ? (
            <>
              <div className="cards-container">
                {OPonGoing?.map((item) => (
                  <React.Fragment key={item.UserVerificationDocumentId}>
                    <div
                      className="onGoingCard-layout"
                      onClick={() =>
                        navigate(`/ongoing/mp-job-description/${item.JobId}`)
                      }
                    >
                      <div>
                        <div className="verfication-badge">
                          <div className="badge-layout-ongoing">
                            <p className="userResposeTypeValue-ongoing">
                              {item.UserJobResponseTypeValue}
                            </p>
                          </div>
                        </div>
                        <div className="jobNumber">
                          <span>Job Id:</span>
                          <span>{item.JobId}</span>
                        </div>

                        <div className="jobTitle-font">
                          <div>{item.JobTitle}</div>
                        </div>
                        <div>
                          <div className="Equipment-category">
                            {" "}
                            <span>{item.EquipmentType}</span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span>{item.EquipmentMake}</span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span>{item.EquipmentModel}</span>
                          </div>
                        </div>

                        <div className="worklocation">
                          <p>{item.WorkLocation}</p>
                        </div>
                        <div className="payment-layout">
                          <p className="paymentfont">
                            &#8377;&nbsp;
                            {formatNumberIndianStyle(item.Salary)}/
                            <span className="paymentfont">
                              {item.SalaryType}
                            </span>
                          </p>
                          <div className="otpbox">
                            <p
                              className="start-otp"
                              onClick={(e) => {
                                e.stopPropagation();
                                getPopUpOpen(item.JobId);
                              }}
                            >
                              End Job OTP
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <AlertDialog
                isOpen={isLogoutModalOpen}
                leastDestructiveRef={cancelReff}
                onClose={onCloseLogoutModal}
                isCentered
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      <div className="OtpIcon">
                        <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg" />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          marginTop: "23px",
                        }}
                      >
                        Kindly enter OTP to end your work
                      </div>
                    </AlertDialogHeader>
                    <Formik
                      initialValues={{
                        otp1: "",
                        otp2: "",
                        otp3: "",
                        otp4: "",
                      }}
                      validationSchema={otpSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <div className="otpinputLayout">
                          <div className="otpinputerror-layout">
                            <OTPInput
                              className="OtpInputBox"
                              name="otp1"
                              autoFocus
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, "otp2")}
                            />
                          </div>
                          <div>
                            <OTPInput
                              className="OtpInputBox"
                              name="otp2"
                              id="otp2"
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, "otp3")}
                            />
                          </div>
                          <div>
                            <OTPInput
                              className="OtpInputBox"
                              name="otp3"
                              id="otp3"
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, "otp4")}
                            />
                          </div>
                          <div>
                            <OTPInput
                              className="OtpInputBox"
                              name="otp4"
                              id="otp4"
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, null)}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="button-layout">
                          <button
                            onClick={onCloseLogoutModal}
                            className="submit-button"
                          >
                            {" "}
                            <p className="button-text">Cancel</p>
                          </button>
                          <button className="submit-button" type="submit">
                            <p className="button-text">End Job</p>
                          </button>
                        </div>
                        <br />
                      </Form>
                    </Formik>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </>
          ) : (
            <div className="no-data-found">
              <div style={{marginTop:"15%"}}>
              <ReactSVG
              style={{marginLeft:"31px"}}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoongoingJObs.svg`}
               />
              <p className="no-material-added-yet-upcoming ">OOPS! NO Ongoing Jobs!</p>
              </div>
            
            </div>
          )}
        </div>
      ) : (
        <>
          <div>
            {combinQucikFullTimejobList?.length > 0 ? (
              <>
                <div className="cards-container">
                  {combinQucikFullTimejobList?.map((item) => (
                    <React.Fragment key={item.UserVerificationDocumentId}>
                      <div
                        className="onGoingCard-layout"
                        onClick={() =>
                          navigate(`/ongoing/mp-job-description/${item.JobId}`)
                        }
                      >
                        <div>
                          <div className="verfication-badge">
                            <div className="badge-layout">
                              <p className="userResposeTypeValue">
                                {item.UserJobResponseTypeValue}
                              </p>
                            </div>
                          </div>
                          <div className="jobNumber">
                            <span>Job Id:</span>
                            <span>{item.JobId}</span>
                          </div>

                          <div className="jobTitle-font">
                            <div>{item.JobTitle}</div>
                          </div>
                          <div>
                            <div className="Equipment-category">
                              {" "}
                              <span>{item.EquipmentType}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentMake}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentModel}</span>
                            </div>
                          </div>

                          <div className="worklocation">
                            <p>{item.WorkLocation}</p>
                          </div>
                          <div className="payment-layout">
                            <p className="paymentfont">
                              &#8377;&nbsp;
                              {formatNumberIndianStyle(item.Salary)}/
                              <span className="paymentfont">
                                {item.SalaryType}
                              </span>
                            </p>
                            <div className="otpbox">
                              <p
                                className="start-otp"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  getPopUpOpen(item.JobId);
                                }}
                              >
                                End Job OTP
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <AlertDialog
                  isOpen={isLogoutModalOpen}
                  leastDestructiveRef={cancelReff}
                  onClose={onCloseLogoutModal}
                  isCentered
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        <div className="OtpIcon">
                          <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg" />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            marginTop: "23px",
                          }}
                        >
                          Kindly enter OTP to end your work
                        </div>
                      </AlertDialogHeader>
                      <Formik
                        initialValues={{
                          otp1: "",
                          otp2: "",
                          otp3: "",
                          otp4: "",
                        }}
                        validationSchema={otpSchema}
                        onSubmit={handleSubmit}
                      >
                        <Form>
                          <div className="otpinputLayout">
                            <div className="otpinputerror-layout">
                              <OTPInput
                                className="OtpInputBox"
                                name="otp1"
                                autoFocus
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, "otp2")}
                              />
                            </div>
                            <div>
                              <OTPInput
                                className="OtpInputBox"
                                name="otp2"
                                id="otp2"
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, "otp3")}
                              />
                            </div>
                            <div>
                              <OTPInput
                                className="OtpInputBox"
                                name="otp3"
                                id="otp3"
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, "otp4")}
                              />
                            </div>
                            <div>
                              <OTPInput
                                className="OtpInputBox"
                                name="otp4"
                                id="otp4"
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, null)}
                              />
                            </div>
                          </div>
                          <br />
                          <div className="button-layout">
                            <button
                              onClick={onCloseLogoutModal}
                              className="submit-button"
                            >
                              {" "}
                              <p className="button-text">Cancel</p>
                            </button>
                            <button className="submit-button" type="submit">
                              <p className="button-text">End Job</p>
                            </button>
                          </div>
                          <br />
                        </Form>
                      </Formik>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            ) : (
              <div className="no-data-found">
                <div style={{marginTop:"15%"}}>
              <ReactSVG
              style={{marginLeft:"31px"}}
              src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoongoingJObs.svg`}
               />
              <p className="no-material-added-yet-upcoming ">OOPS! NO Ongoing Jobs!</p>
              </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
