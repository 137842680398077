import { useNavigate, useParams } from "react-router-dom";
import fetchHandler from "../../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { useEffect, useState } from "react";

import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../../assets/logo/equip9_logo_dark.png";
import { dealershipDeferredThunk } from "../../../Product/thunk/dealership/dealershipDeferredThunk";
import { primaryKeyEncryption } from "../../../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import { MarketplaceHoc } from "../../../../../webapp/features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { MarketplaceHeader } from "../../../../../webapp/components/MarketplacHeader/MarketplaceHeader";
import DealershipCard from "../../../../../webapp/components/DealershipMarketplaceCard/DealershipCard";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import { openQrModal } from "../../../../../webapp/features/dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../../../webapp/components/ScanQrCodeModal/QrPopUp";
import { delaershipLoadMoreDeferredThunk } from "../../../Product/thunk/dealership/delaershipLoadMoreDeferredThunk";
import BrandHeader from "../BrandHeader";

export const DealershipDeferredMarketplace = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const dealershipDeferred = useSelector((state) => state.dealershipDeferred);
  const { category, subcategory } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dealershipDeferredThunk());
  }, []);

  const encryptAssetDetails = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        "/encryptAssetId",
        {},
        "",
        {
          MarketplaceType: category,
          SubCategory: subcategory,
          AssetId: assetId,
        },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.Token);
    } catch (error) {
    }
  };

  const renderCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <>
            <DealershipCard
              key={uuid()}
              imageUrl={
                data.ImageList &&
                data.ImageList.length > 0 &&
                `${process.env.REACT_APP_CDN_PVT_URL}${data.ImageList[0]?.ImageFileRef}`
              }
              isVerified={data.IsVerified}
              equipmentStatusValue={data.EquipmentStatusValue}
              like={data?.likeStatus}
              isPromoted={data.IsPromoted}
              location={data.Location}
              dealerName={data.DealerName}
              financeProvidedValue={data.FinanceProvidedValue}
              dealershipUniqueId={data.DealershipUniqueId}
              listedDateTime={data.ListedDateTime}
              deferredHeader={data?.DealerName}
              deferredLocation={
                data?.GoogleLocation?.City +
                " " +
                data?.GoogleLocation?.State +
                " " +
                data?.GoogleLocation?.Country
              }
              isDeferred={true}
              onDeferredClick={() => {
                navigate(
                  `/deferred/marketplace/DealershipMarketplace/dealership-detail/${convertToBase64(
                    data?.DealershipGuid
                  )}/${convertToBase64(data?.DealershipMpGuid)}`
                );
              }}
            />
          </>
        ))
      ) : (
        <p>No Dealership Listed Yet</p>
      );

    return renderJSX;
  };

  return (
    <>
      {/* <QrPopUp /> */}
      {/* <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE",encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE",encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      /> */}
      <QrPopUp />
      <BrandHeader />
      <MarketplaceHeader
        heading={"Dealership Marketplace"}
        filterButtonRequired={false}
      />
      <MarketplaceHoc
        marketplaceName="Deferred Marketplace"
        marketplaceType={"Dealership Marketplace"}
        dataArray={dealershipDeferred?.data}
        // userImageBaseReference={
        //   dealershipDeferred?. ? uemData.userImageReference : ""
        // }
        assetImageBaseReference={
          dealershipDeferred ? dealershipDeferred?.AssetImageBaseReference : ""
        }
        endOfData={dealershipDeferred?.endOfData}
        loadMoreAction={() => {
          dispatch(
            delaershipLoadMoreDeferredThunk(dealershipDeferred?.paginationCount)
          );
        }}
        initialLoading={dealershipDeferred?.initialLoading}
        paginationLoading={dealershipDeferred?.paginationLoading}
        cardsRenderFunction={renderCards}
      />
    </>
  );
};
