import { Card } from "../Card/Card";
import "./JobCard.css";
export const JobCard = ({
  heading,
  salary,
  location,
  lastDate,
  onClick,
  statusMessage,
}) => {
  return (
    <Card type="jobCard" onClick={onClick}>
      <div className="job-card">
        <div className="job-card__status">
          <div className="job-card__status__highlight">{statusMessage}</div>
        </div>
        <div className="job-card__section job-card__section--info">
          <h1 className="job-card--heading">{heading}</h1>
          <p className="job-card--price">
            <span className="job-card--category">Salary :</span> {salary}
          </p>
          <p className="job-card--location">
            <span className="job-card--category">Location :</span> {location}
          </p>
        </div>
        <div className="job-card__footer">
          <p className="job-card__footer__info">
            {" "}
            * Last date to apply is {lastDate}
          </p>
        </div>
      </div>
    </Card>
  );
};
