import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPostedJobsListForCompany } from "../../../services/jobsPosted/jobsPosted";

export const getInitialJobsPostedForCompanyThunk = createAsyncThunk("/jobsPosted/initialJobsForCompany", async(_, {getState}) => {
    const {cardsCount} = getState().jobsPosted;
    const {accountDetails} = getState().user;
    
    try{
      const data = await getPostedJobsListForCompany(0, cardsCount, accountDetails.CompanyDetails.TaxNumber)
      return data;
    }
    catch(error){
      return error.message
    }
})