import { useNavigate, useParams } from "react-router-dom";
import { MarketplaceHeader } from "../../../../webapp/components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../../../webapp/features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import fetchHandler from "../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { useEffect, useState } from "react";
import { LoginPopUp } from "../../../components/PopUps/LoginPopUp";
import { EquipmentMarketplaceCard } from "../../../../webapp/components/EquipmentMarketplaceCard/EquipmentMarketplaceCard";
import { v4 as uuid } from "uuid";
import { getDeferredUemThunk } from "../../Product/thunk/getDeferredUemThunk";
import { useDispatch, useSelector } from "react-redux";
import { primaryKeyEncryption } from "../../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import { QrPopUp } from "../../../../webapp/components/ScanQrCodeModal/QrPopUp";
import { uemLoadMoreDeferred } from "../../Product/thunk/uemLoadMoreDeferred";
import logo from "../../../../assets/logo/equip9_logo_dark.png";
import BrandHeader from "./BrandHeader";

export const UsedDeferredMarketplace = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const uemDeferred = useSelector((state) => state.uemDeferred);
  const  category = "equipment"
  const subcategory = "rental"

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDeferredUemThunk({
        category: "equipment",
        subcategory: "uem",
        limit: 12,
        offset: 0,
      })
    );
  }, []);

  const encryptAssetDetails = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        "/encryptAssetId",
        {},
        "",
        {
          MarketplaceType: category,
          SubCategory: subcategory,
          AssetId: assetId,
        },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.Token);
    } catch (error) {
    }
  };

  const renderCards = (dataArray = []) => {
    const renderJsx =
      dataArray && Array.isArray(dataArray) && dataArray.length > 0 ? (
        dataArray.map((equipment) => {
          return (
            <EquipmentMarketplaceCard
              key={uuid()}
              imageUrl={`${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${equipment.AssetImages.Image_Front_FileRef}`}
              equipmentTypeValue={equipment.EquipmentTypeValue}
              oemName={equipment.OEMName}
              modelName={equipment.ModelName}
              price={`${equipment.ExpectedPrice}`}
              status={equipment.IsCurrentlyAvailable ? "Available" : "Sold"}
              listedTime={equipment.Listed}
              equipmentId={equipment.E9MarketPlaceUniqueId}
              city={equipment.CityName}
              state={equipment.StateName}
              country={equipment.CountryName}
              onContactClick={async () => {
                await encryptAssetDetails(equipment.E9MarketPlaceId);
                setModalOpen(true);
              }}
              //  isCtaButton={
              //    (userCredentialId &&
              //      parseInt(equipment.AssetOwnerUserCredentialId) ===
              //        parseInt(userCredentialId)) ||
              //    equipment.ResponseTypeValue === "Request Revoked" ||
              //    equipment.ResponseTypeValue === "Request Sent" ||
              //    !equipment.ResponseTypeValue
              //  }
              buttonText="Connect"
              buttonColor="#F67C00"
              onClick={() => {
                navigate(
                  `/deferred/marketplace/Cards/${category}/${subcategory}/${primaryKeyEncryption(
                    equipment.E9MarketPlaceId
                  )}`
                );
              }}
              isShareEnabled={false}
              isVerifiedEnabled={false}
              isProfileEnabled={false}
            />
          );
        })
      ) : (
        <></>
      );
    return renderJsx;
  };

  return (
    <>
      <QrPopUp />
      <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />
      <BrandHeader />
      <MarketplaceHeader
        heading={"Used Equipment Marketplace"}
        filterButtonRequired={false}
      />
      <MarketplaceHoc
        marketplaceName="Deferred Marketplace"
        marketplaceType={"Used Equipment Marketplace"}
        dataArray={uemDeferred?.data}
        // userImageBaseReference={
        //   uemDeferred?. ? uemData.userImageReference : ""
        // }
        assetImageBaseReference={
          uemDeferred ? uemDeferred?.AssetImageBaseReference : ""
        }
        endOfData={uemDeferred?.endOfData}
        loadMoreAction={() => {
          dispatch(uemLoadMoreDeferred(uemDeferred?.paginationCount));
        }}
        initialLoading={uemDeferred?.initialLoading}
        paginationLoading={uemDeferred?.paginationLoading}
        cardsRenderFunction={renderCards}
      />
    </>
  );
};
