import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashboardNavBar } from "../../../../components/DashboardNavBar/DashboardNavBar";
import { SideMenuLayout } from "../../../../layout/sideMenuLayout/SideMenuLayout";
import { JobMarketplaceCard } from "../../../../components/JobMarketplaceCard/JobMarketplaceCard";
import { useNavigate } from "react-router-dom";
import { MarketplaceHeader } from "../../../../components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { getInitialFilteredMaintenanceData } from "../../thunks/getInitialFilteredMaintenanceJobs";
import { FilterPopUp } from "../../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { setFilterByCity, setFilterByCountry, setFilterByEquipmentType, setFilterByState, setFilterModalOpen, setFilteredDataRequired, resetFilters } from "../../slice/maintenancePartnerJobsSlice";
import { getLoadMoreFilteredMaintenanceData } from "../../thunks/getLoadMoreFilteredMaintenanceJobs";
import { getLoadMoreMaintenanceDataThunk } from "../../thunks/getLoadMoreMaintenancePartnerJobs";
import { MARKETPLACE_TYPES } from "../../../../constants/e9Constants";
import { getInitialMaintenancePartnerJobs } from "../../thunks/getInitialMaintenancePartnerJobs";
import { Helmet } from "react-helmet";

export const MaintenanceJobsNearMe = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

const {
  initialLoading,
  data,
  endOfData,
  filterModalOpen,
  filterByEquipmentType,
  filterByCountry,
  filterByState,
  filterByCity,
  filterDataRequired,
} = useSelector((state) => state.maintenanceJobsNearMe);



const filterFunction = (values) => {
  dispatch(setFilterByEquipmentType(values.equipmentType));
  dispatch(setFilterByCountry(values.country));
  dispatch(setFilterByState(values.state));
  dispatch(setFilterByCity(values.city));
  
  if (values.equipmentType || values.country || values.state || values.city) {
    dispatch(setFilteredDataRequired(true));
    dispatch(getInitialFilteredMaintenanceData());
  } else {
    dispatch(setFilteredDataRequired(false));
  }
  dispatch(setFilterModalOpen(false));
};


// console.log("jobs",data)


  const renderFunction = (array) =>
    array && array.length > 0 ? (
      array.map((jobs) => {
       return <JobMarketplaceCard
        verificationStatus={
          jobs.UserJobResponseTypeValue === "Rejected"
            ? "fail"
            : "success"
        }
        verificationText={jobs.UserJobResponseTypeValue}
        jobId={jobs.JobId}
        name={jobs.JobTitle}
        money={jobs.Salary ? `₹${jobs.Salary}` : "N.A."}
        postedOn={jobs.JobPostedDateTime ? jobs.JobPostedDateTime.split(",")[1] : "NA"}
        location={`${jobs.CityName}, ${jobs.StateName} ,${jobs.CountryName}`}
        cycle={
          jobs.SalaryTypeValue
            ? `${jobs.SalaryTypeValue}`
            : "N.A."
        }
        onClick={() =>
          navigate(`/jobs-near-me/AllJobsNearMeMP/Card/${encodeURIComponent(`${jobs.JobId}`)}`,{
             
              state: { UserJobResponse: jobs.UserJobResponseTypeValue} 
            
          })
          
        }
        />
})
    ) : (
      <p>No Data Available</p>
    );
 
    useEffect(() => {
      filterDataRequired
        ? dispatch(getInitialFilteredMaintenanceData())
        : dispatch(getInitialMaintenancePartnerJobs());
    }, [filterDataRequired]);

 
  return (
    <SideMenuLayout>
      <Helmet>
      <link
        rel="MAINTENANCE JOBS page"
        href="https://www.equip9.com/jobs-near-me/AllJobsNearMeMP"
      />
      <meta name="keywords" content="machinery operators jobs"/>
      <title>Equip9: Connecting Machinery Operators with Jobs</title>
      <meta name="description" content="Explore job opportunities for machinery operators on Equip9. Connect 
      with business owners and find employment in the heavy equipment industry"/>

      </Helmet>

      <FilterPopUp
      marketplaceType={MARKETPLACE_TYPES.NEAR_ME_JOBS_MAINTENANCE}
      isModalOpen={filterModalOpen}
      onSubmitButtonClick={filterFunction}
      onResetFilterClick={() => dispatch(resetFilters())}
      initialEquipmentValue={filterByEquipmentType}
      initialCountryValue={filterByCountry}
      initialStateValue={filterByState}
      initialCityValue={filterByCity}
      onCloseButtonClick={() => {dispatch(setFilterModalOpen(false))}}
      />
      <DashboardNavBar />
      <MarketplaceHeader
      heading="Jobs Available For You"
      filterButtonClickAction={() => {dispatch(setFilterModalOpen(true))}}
      filterButtonSelected={filterDataRequired}

      />
      <MarketplaceHoc
      marketplaceType="jobs"
      initialLoading={initialLoading}
      dataArray={data ? data : []}
      cardsRenderFunction={renderFunction}
      loadMoreAction={ () => 
        filterDataRequired
          ? dispatch(getLoadMoreFilteredMaintenanceData())
          : dispatch(getLoadMoreMaintenanceDataThunk())
      }
      endOfData={endOfData}
      />
    </SideMenuLayout>
  );
};
