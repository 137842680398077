import "./Ongoing.css";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useQuery,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { formatNumberIndianStyle } from "../../../../../staticData/CommonMethod";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { ReactSVG } from "react-svg";
import { useGeocode } from "../../../../hooks/useGeocode";
import { useCurrentLocation } from "../../../../hooks/useCurrentLocation";
import { degreesToRadians } from "../../../../../staticData/CommonMethod";
import { convertToBase64 } from "../../../../../utils/formatConverter/convertToBase64";
import GeolocationComponent from "../../../../components/Geolocation/Geolocation";
import { startWorkOTP } from "../../../../services/ongoing/ongoing";
import { MPQucikUpComingJobList, MPUpComingJobList, opUpComingJobList } from "../../../../services/jobsPosted/jobsPosted";

const OTPInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  const inputRef = useRef();

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus();
    }
  }, [props.autoFocus]);

  return (
    <>
      <input className="otp-input" ref={inputRef} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error-text">{meta.error}</div>
      ) : null}
    </>
  );
};

const otpSchema = Yup.object({
  otp1: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
  otp2: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
  otp3: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
  otp4: Yup.string().length(1, "Must be exactly 1 digit").required("Required"),
});

export const UpcomingJobs = () => {
  const userData = useSelector((state) => state.user);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [showGeoLocation, setShowGeoLocation] = useState(false);
  const [mpupcomingJobId, setmpupcomingJobId] = useState(null);
  const  [Latitude,setLatitude]=useState(null);
  const  [Longitude,setLongitude]=useState(null);
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: '', lng: '' },
  });

  const cancelReff = React.useRef();
  const toast = useToast();
  const queryClient = useQueryClient();


  const onCloseLogoutModal = () => {setIsLogoutModalOpen(false)
    setShowGeoLocation(false)}


    
  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: setLatitude(location.coords.latitude),
        lng: setLongitude(location.coords.longitude),
      },
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: true,
      error,
    });
  };


  useEffect(() => {
    if (!('geolocation' in navigator)) {
      onError({
        code: 0,
        message: 'Geolocation not supported',
      });
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);
 
  // create distance ref
  const distanceRef = useRef();;

  /*************use Query*******************/
  const { data: OPUpcomingjobs } = useQuery("getOPUpcomingjobs", () =>
    opUpComingJobList()
  );

  const { data: MPUpcomingjobs } = useQuery("getMPUpcomingjobs", () =>
    MPUpComingJobList()
  );

  const { data: MPQuickUpcomingjobs } = useQuery("getQucikMPUpcomingjobs", () =>
    MPQucikUpComingJobList()
  );

  const navigate = useNavigate();
  const OpupcomingData = OPUpcomingjobs?.data?.Data?.OperatorUpcomingJobList;
  const MpupcomingData =
    MPUpcomingjobs?.data?.Data?.FullTimeMaintenanceUpcomingJobList || [];

  // Combinng array
  const MpQuickupComingData =
    MPQuickUpcomingjobs?.data?.Data?.QuickMaintenanceUpcomingJobList || [];
  const combinedUpcomingjoblist = [...MpupcomingData, ...MpQuickupComingData];
  const businessTypeValue = userData.BusinessTypeValue;

  const handleKeyUp = (e, nextInputId) => {
    if (e.target.value.length === 1 && nextInputId) {
      document.getElementById(nextInputId).focus();
    }
  };

  

  // const handleCurrentLocation = () => {
  //   handleGeoLocation();
  // };

  // useEffect(() => {
  //   if (isLogoutModalOpen) {
  //     handleCurrentLocation();
  //   }
  // }, [isLogoutModalOpen, handleCurrentLocation]);


  function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  /**********check Delete  for long and lat */

  function haversineDistance(lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371;
  
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
  
    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);
  
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c * 1000; // Distance in meters
  }

  /***********handle pop Open*******/

  const handlepopupOpen = (
    jobId,
    reporterLocationLatitude,
    reporterLocationLongitude
  ) => {

    setShowGeoLocation(true);
    setmpupcomingJobId(jobId);
    setIsLogoutModalOpen(true);

  
    const distance = haversineDistance(
    reporterLocationLatitude,
    reporterLocationLongitude,
    Latitude,
    Longitude
    );
    distanceRef.current = distance;
  };

  /*********************submit OTP********* */

  const handleSubmit = async (values) => {
    const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4;
    const payload = {
      StartOtp: otp,
      JobId: mpupcomingJobId,
    };
      if(distanceRef.current<=200){
        //debugger
        try {
          const data = await startWorkOTP(payload);
          if (data?.Status === true) {
            toast({
              title: "",
              description: `Job has been started successfully`,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
    
            queryClient.invalidateQueries("getMPUpcomingjobs");
            queryClient.invalidateQueries("getQucikMPUpcomingjobs");
            queryClient.invalidateQueries("getOPUpcomingjobs");
            queryClient.invalidateQueries("getongingmp");
            queryClient.invalidateQueries("getongingop");
            setIsLogoutModalOpen(false);
          }
        } catch (err) {
          toast({
            title: "",
            description: `Please enter correct OTP.`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
      else{
        toast({
          title: "",
          description: `Patner is not at Location`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }

  

    
  };

  /********************return for View************* */
  return (
    <div>
      {businessTypeValue === "Operator Partner" ? (
        <div>
          {OpupcomingData?.length > 0 ? (
            <>
              <div className="cards-container">
                {OpupcomingData?.map((item) => (
                  <React.Fragment key={item.UserVerificationDocumentId}>
                    <div
                      className="onGoingCard-layout-ongoing"
                      onClick={() =>
                        navigate(
                          `/upcoming/job-description/${convertToBase64(
                            item.JobId
                          )}`
                        )
                      }
                    >
                      <div>
                        <div className="verfication-badge">
                          <div className="badge-layout">
                            <p className="userResposeTypeValue-upcoming">
                              {item.UserJobResponseTypeValue}
                            </p>
                          </div>
                        </div>
                        <br/>
                        <div className="jobNumber">
                          <span>Job Id:</span>
                          <span>{item.JobId}</span>
                        </div>

                        <div className="jobTitle-font">
                          <div>{item.JobTitle}</div>
                        </div>
                        <div>
                          <div className="Equipment-category">
                            {" "}
                            <span>{item?.EquipmentType || "NA"}</span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span>{item?.EquipmentMake || "NA"}</span>
                            &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                            <span>{item?.EquipmentModel || "NA"}</span>
                          </div>
                        </div>

                        <div className="worklocation">
                          <p>{item.WorkLocation}</p>
                        </div>
                        <div className="payment-layout">
                          <p className="paymentfont">
                            &#8377;&nbsp;
                            {formatNumberIndianStyle(
                              item?.Salary ? item?.Salary : "NA"
                            )}
                            /
                            <span className="paymentfont">
                              {item?.SalaryType ? item?.SalaryType : " "}
                            </span>
                          </p>
                          <div
                            className="otpbox"
                            onClick={(e) => {
                              e.stopPropagation();
                              handlepopupOpen(
                                item?.JobId,
                                item?.ReportLocationLatitude,
                                item?.ReportLocationLongitude
                              );
                            }}
                          >
                            <p className="start-otp">Start Job OTP</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>


              {showGeoLocation && <GeolocationComponent />}

              <AlertDialog
                isOpen={isLogoutModalOpen}
                leastDestructiveRef={cancelReff}
                onClose={onCloseLogoutModal}
                isCentered
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      <div className="OtpIcon">
                        <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg" />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          marginTop: "23px",
                        }}
                      >
                        Kindly enter OTP to start your work
                      </div>
                    </AlertDialogHeader>
                    <Formik
                      initialValues={{ otp1: "", otp2: "", otp3: "", otp4: "" }}
                      validationSchema={otpSchema}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <div className="otpinputLayout">
                          <div className="otpinputerror-layout">
                            <OTPInput
                              className="OtpInputBox"
                              name="otp1"
                              autoFocus
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, "otp2")}
                            />
                          </div>
                          <div>
                            <OTPInput
                              className="OtpInputBox"
                              name="otp2"
                              id="otp2"
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, "otp3")}
                            />
                          </div>
                          <div>
                            <OTPInput
                              className="OtpInputBox"
                              name="otp3"
                              id="otp3"
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, "otp4")}
                            />
                          </div>
                          <div>
                            <OTPInput
                              className="OtpInputBox"
                              name="otp4"
                              id="otp4"
                              maxLength="1"
                              onKeyUp={(e) => handleKeyUp(e, null)}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="button-layout">
                          <button
                            onClick={onCloseLogoutModal}
                            className="submit-button"
                          >
                            {" "}
                            <p className="button-text">Cancel</p>
                          </button>
                          <button className="submit-button" type="submit">
                            <p className="button-text">Start Job</p>
                          </button>
                        </div>
                        <br />
                      </Form>
                    </Formik>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </>
          ) : (
            <div className="no-data-found">
              <div style={{ marginTop: "15%" }}>
                <ReactSVG
                  style={{ marginLeft: "31px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoongoingJObs.svg`}
                />
                <p className="no-material-added-yet-upcoming ">
                  OOPS! NO Upcoming Jobs!
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div>
            {combinedUpcomingjoblist?.length > 0 ? (
              <>
                <div className="cards-container">
                  {combinedUpcomingjoblist?.map((item) => (
                    <React.Fragment key={item.UserVerificationDocumentId}>
                      <div
                        className="onGoingCard-layout"
                        onClick={() =>
                          navigate(`/upcoming/mp-job-description/${item.JobId}`)
                        }
                      >
                        <div>
                          <div className="verfication-badge">
                            <div className="badge-layout">
                              <p className="userResposeTypeValue">
                                {item.UserJobResponseTypeValue}
                              </p>
                            </div>
                          </div>
                          <div className="jobNumber">
                            <span>Job Id:</span>
                            <span>{item.JobId}</span>
                          </div>

                          <div className="jobTitle-font">
                            <div>{item.JobTitle}</div>
                          </div>
                          <div>
                            <div className="Equipment-category">
                              {" "}
                              <span>{item.EquipmentType}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentMake}</span>
                              &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                              <span>{item.EquipmentModel}</span>
                            </div>
                          </div>

                          <div className="worklocation">
                            <p>{item.WorkLocation}</p>
                          </div>
                          <div className="payment-layout">
                            <p className="paymentfont">
                              &#8377;&nbsp;
                              {formatNumberIndianStyle(item.Salary)}/
                              <span className="paymentfont">
                                {item.SalaryType}
                              </span>
                            </p>
                            <div className="otpbox">
                              <p
                                className="start-otp"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handlepopupOpen(
                                    item?.JobId,
                                    item?.ReportLocationLatitude,
                                    item?.ReportLocationLongitude
                                  );
                                }}
                              >
                                Start Job OTP
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <AlertDialog
                  isOpen={isLogoutModalOpen}
                  leastDestructiveRef={cancelReff}
                  onClose={onCloseLogoutModal}
                  isCentered
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        <div className="OtpIcon">
                          <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/headphone.svg" />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            marginTop: "23px",
                          }}
                        >
                          Kindly enter OTP to start your work
                        </div>
                      </AlertDialogHeader>
                      <Formik
                        initialValues={{
                          otp1: "",
                          otp2: "",
                          otp3: "",
                          otp4: "",
                        }}
                        validationSchema={otpSchema}
                        onSubmit={handleSubmit}
                      >
                        <Form>
                          <div className="otpinputLayout">
                            <div className="otpinputerror-layout">
                              <OTPInput
                                className="OtpInputBox"
                                name="otp1"
                                autoFocus
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, "otp2")}
                              />
                            </div>
                            <div>
                              <OTPInput
                                className="OtpInputBox"
                                name="otp2"
                                id="otp2"
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, "otp3")}
                              />
                            </div>
                            <div>
                              <OTPInput
                                className="OtpInputBox"
                                name="otp3"
                                id="otp3"
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, "otp4")}
                              />
                            </div>
                            <div>
                              <OTPInput
                                className="OtpInputBox"
                                name="otp4"
                                id="otp4"
                                maxLength="1"
                                onKeyUp={(e) => handleKeyUp(e, null)}
                              />
                            </div>
                          </div>
                          <br />
                          <div className="button-layout">
                            <button
                              onClick={onCloseLogoutModal}
                              className="submit-button"
                            >
                              {" "}
                              <p className="button-text">Cancel</p>
                            </button>
                            <button className="submit-button" type="submit">
                              <p className="button-text">Start Job</p>
                            </button>
                          </div>
                          <br />
                        </Form>
                      </Formik>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </>
            ) : (
              <div className="no-data-found">
              <div style={{ marginTop: "15%" }}>
                <ReactSVG
                  style={{ marginLeft: "31px" }}
                  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/NoongoingJObs.svg`}
                />
                <p className="no-material-added-yet-upcoming ">
                  OOPS! NO Upcoming Jobs!
                </p>
              </div>
            </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
