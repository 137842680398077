import React, { useState, useEffect } from "react";
import { ratingDescriptions } from "../../../staticData/CommonMethod";
import { useSelector } from "react-redux";
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import './ViewRatings.css';
import { useNavigate } from "react-router-dom";
import { getRatingOwner } from "../../services/upcoming/upcoming";

export const ViewRatings = ({
  jobId,
  userCredentialId,
  isLogoutModalOpenrating,
  setIsLogoutModalOpenrating,
}) => {
  const userData = useSelector((state) => state.user);
  const [ownerMpRatingData, setOwnerMpRatingData] = useState(null);
  const onCloseLogoutModal = () => setIsLogoutModalOpenrating(false);
  const cancelReff = React.useRef();
  const [getrating, setgetRating] = useState(0);
  const [getpaymentonTimerating, setgetpaymentonTimerating] = useState(0);
  const [getbehaviorRating, setgetbehaviorRating] = useState(0);
  const [Error, setError] = useState(null);
  const navigate=useNavigate();


  useEffect(() => {
    getRatingOwner(parseInt(jobId), userCredentialId)
      .then((data) => {
        const ratings = data?.Data?.OwnerJobRating[0];
        if (ratings) {
          setgetRating(ratings?.SafetyConditions);
          setgetpaymentonTimerating(ratings?.PaymentOnTime);
          setgetbehaviorRating(ratings.Behaviour);
          setOwnerMpRatingData(ratings); // Storing the ratings data in state
        }
      })
      .catch((err) => {
        setError(err);
        console.error("Error fetching ratings:", err);
      });
  }, [userData.token, jobId, userCredentialId]);


  const reviewRatingConditions=()=>{
    window.open('/rating-system', '_blank');
  }

  return (
    <div>
      <AlertDialog
        isOpen={isLogoutModalOpenrating}
        leastDestructiveRef={cancelReff}
        onClose={onCloseLogoutModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <div 
            onClick={onCloseLogoutModal}
            style={{
                height:"23px",
                width:"23px",
                borderRadius:"50%",
                background:'#fff',
                marginLeft:"92%",
                marginTop:"-10px",
                boxShadow:"0px 1px 5.3px 0px rgba(0, 0, 0, 0.25)",
                display:'flex',
                justifyContent:"center",
                alignContent:"center",
                cursor:"pointer"}} >
               <ReactSVG  src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/Cross.svg`}/>
            </div>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/RatingStar.svg`}
                />
                <p className="rateOwnerText">Rate Owner</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="Rating-layout-owner">
                  <div className="Condition-heading">Saftey Condition</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-12px",
                    }}
                  >
                    <p className="ratingText">
                      {ratingDescriptions[getrating]}
                    </p>
                    <div className="star-rating">
                      {[1, 2, 3, 4, 5].map((starNumber) => (
                        <span
                          key={starNumber}
                          className={`star ${
                            starNumber <= getrating ? "active" : ""
                          }`}
                        >
                          &#9733;
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="rating-section">
                    {getrating}
                    <span className="outofrating">/5</span>
                  </div>
                </div>
                <div className="Rating-layout-owner">
                  <div className="Condition-heading">Payment on time</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-8px",
                    }}
                  >
                    <p className="ratingText">
                      {ratingDescriptions[getpaymentonTimerating] || " "}
                    </p>
                    <div className="star-rating">
                      {[1, 2, 3, 4, 5].map((paymentstarNumber) => (
                        <span
                          key={paymentstarNumber}
                          className={`star ${
                            paymentstarNumber <= getpaymentonTimerating
                              ? "active"
                              : ""
                          }`}
                        >
                          &#9733;
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="rating-section">
                    {getpaymentonTimerating}
                    <span className="outofrating">/5</span>
                  </div>
                </div>

                <div className="Rating-layout-owner">
                  <div className="Condition-heading">Behavior</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "-12px",
                    }}
                  >
                    <p className="ratingText">
                      {ratingDescriptions[getbehaviorRating] || " "}
                    </p>
                    <div className="star-rating">
                      {[1, 2, 3, 4, 5].map((Rating) => (
                        <span
                          key={Rating}
                          className={`star ${
                            Rating <= getbehaviorRating ? "active" : ""
                          }`}
                        >
                          &#9733;
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="rating-section">
                    {getbehaviorRating}
                    <span className="outofrating">/5</span>
                  </div>
                </div>
              </div>
            </AlertDialogHeader>
            <div className="know-more-rating-layout"
             onClick={reviewRatingConditions}>
              Know more about rating.
            </div>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};
