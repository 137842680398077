import { useEffect, useState } from "react";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import fetchHandler from "../../../webapp/services/fetchHandler";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BrandLoader } from "../../../webapp/components/loaders/brandLoader/BrandLoader";
import { isError } from "lodash";
import { useSelector } from "react-redux";
import {
  primaryKeyDecryption,
  primaryKeyEncryption,
} from "../../../webapp/helpers/dataConversion/primaryKeyEncryption";

export const ShareLoader = () => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [tokenDetails, setTokenDetails] = useState(null);
  const [error, setError] = useState(false);
  const { token, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const isUserLoggedIn = () => token && isAuthenticated;

  const decryptShareToken = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        `/share/decryptAssetId`,
        {},
        { "content-type": "application/json" },
        { token: assetId.split(" ").join("+") },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      console.log(data.Data);
      setTokenDetails(data.Data);
      return data.Data;
    } catch (error) {
      setError(true);
    }
  };

  const getOperatorDetails = async (id) => {
    try {
      const { data } = await fetchHandler(
        `/share/asset?category=partners&id=${id}`,
        "",
        "",
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: false }
      );
      console.log(data);
      // return data.Data
      if (!data.Data || Object.keys(data.Data) < 1) {
        return setError(true);
      }
      console.log(isUserLoggedIn());
      isUserLoggedIn()
        ? navigate(`/near-me/MarketPlaceOP/Card/${id}`)
        : navigate(`/deferred/marketplace/partners/operator`);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    const id = urlSearchParams.get("id");
    if (id) {
      const assetDetails = decryptShareToken(id);
    }
  }, []);

  useEffect(() => {
    if (tokenDetails) {
      const operatorDetails = getOperatorDetails(tokenDetails.AssetId);
    }
  }, [tokenDetails]);

  return <BrandLoader />;
};
