import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { primaryKeyDecryption } from "../../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import fetchHandler from "../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../constants/apiConstants";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Box, Button, Divider, Flex, Text, Tooltip } from "@chakra-ui/react";
import { MaterialDetailsCard } from "../../../../webapp/components/MaterialDetailsCard/MaterialDetailsCard";
import logo from "../../../../assets/logo/equip9_logo_dark.png";
import { LoginPopUp } from "../../../components/PopUps/LoginPopUp";
import { subString } from "../../../../webapp/helpers/dataConversion/substring";

const MaterialDetailsDeferred = () => {
  const { materialTypeValue , materialId , ownerUserCredentialId} = useParams();
  const id = primaryKeyDecryption(materialId);
  const uid = primaryKeyDecryption(ownerUserCredentialId);

  const [materialData, setMaterialData] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const [imageList, setImageList] = useState();
  const [imageListArray, setImageListArray] = useState();
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const category = "material";
  const subCategory = materialTypeValue;

  useEffect(() => {
    getMaterialDetails();
  }, [!materialData]);

  const getMaterialDetails = async () => {
    const { data } = await fetchHandler(
      "/deferredLogin/marketplace",
      { category, id: id },
      "",
      "",
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    if (data?.Status === 200) {
      setMaterialData(data.Data?.MaterialDetails);
      setAdditionalDetails(data.Data?.MaterialDetails[0].AdditionalDetails);
      setImageList(data?.Data?.MaterialDetails[0]?.ImageList);
    }
  };

  useEffect(() => {
    if (imageList) {
      setImageListArray(JSON.parse(imageList));
    }
  }, [imageList]);

  const encryptAssetDetails = async (id) => {
    try {
      const { data } = await fetchHandler(
        "/encryptAssetId",
        {},
        "",
        { MarketplaceType: uid,SubCategory : category, AssetId: id },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.Token);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />

      <Box
        // display="flex"
        // justifyContent="space-between"
        // flexDirection="column"
        // padding="2em 2em"
        // gap="2em"

        display="grid"
        gridTemplateRows="auto 1fr auto"
        padding="1em 2em"
        gap="2em"
      >
        <Box maxH="60px" maxW="80px">
          <img src={logo} style={{ height: "100%", width: "100%" }} />
        </Box>
        <Flex direction="column" padding="0em 2em" gap="2em">
          <Flex
            borderRadius="1em"
            background="#FFF"
            boxShadow="0px 0px 7.82px 0px rgba(0, 0, 0, 0.25)"
            gap="2em"
          >
            <Box
              maxW="630px"
              minW="600px"
              maxH="270px"
              minH="260px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Swiper
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                style={{ height: "226px", width: "579px", borderRadius: "1em" }}
              >
                <div className="material-header-card-img">
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "2em",
                    }}
                    alt="image"
                    src={`${process.env.REACT_APP_CDN_PVT_URL}${imageListArray}`}
                    className="material-header-card-image"
                  />
                </div>
                {imageListArray && imageListArray.length > 0 ? (
                  imageListArray?.map((image, index) => (
                    <SwiperSlide key={index}>
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        alt="image"
                        src={`${process.env.REACT_APP_CDN_PVT_URL}${image.ImageFileRef}`}
                        className="dealership-header-card-image"
                      />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                      }}
                      src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`}
                      className="dealership-header-card-image"
                    />
                  </SwiperSlide>
                )}
              </Swiper>
            </Box>

            <Flex flexDirection="column" justifyContent="center">
              <Text color="#333" fontSize="28px" fontWeight="700">
                {/* {materialData?.[0]?.ProductName
                  ? materialData?.[0]?.ProductName
                  : "NA"} */}

                <Tooltip label={materialData?.[0]?.ProductName?.length > 35 ? materialData?.[0]?.ProductName : null}>
                  <span>
                    {subString(35, materialData?.[0]?.ProductName)}
                  </span>
                </Tooltip>
              </Text>
              <Text color="#333" fontSize="28px" fontWeight="700">
                &#x20B9; {materialData?.[0]?.MinPriceRange}
                {"/- to "}
                <Text as="span">
                  &#x20B9; {materialData?.[0]?.MaxPriceRange}
                  {"/-"}
                </Text>
              </Text>
              <Text color="#002961" fontSize="24px" fontWeight="700">
                PNo -
                <Text as="span">
                  {materialData?.[0]?.MaterialUniqueId
                    ? materialData?.[0]?.MaterialUniqueId
                    : "NA"}
                </Text>
              </Text>
            </Flex>
          </Flex>

          {materialData === null ? (
            <Text color="#011f6b" fontSize="1.2em" textAlign="center">
              No Parts Available
            </Text>
          ) : (
            <Box className="material-main-container" overflowY={"scroll"}>
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      Part Details
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#011f6b" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box>
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0 1em 0.5em"
                >
                  <MaterialDetailsCard
                    formatType={true}
                    minPrice={materialData[0]?.MinPriceRange}
                    maxPrice={materialData[0]?.MaxPriceRange}
                    description="Price Range"
                  />
                  <MaterialDetailsCard
                    heading={
                      materialData[0]?.Height ? materialData[0]?.Height : "NA"
                    }
                    description="Height"
                  />

                  <MaterialDetailsCard
                    heading={
                      materialData[0]?.SLength ? materialData[0]?.SLength : "NA"
                    }
                    description="Length"
                  />
                  <MaterialDetailsCard
                    heading={
                      materialData[0]?.Radius ? materialData[0]?.Radius : "NA"
                    }
                    description="Radius"
                  />
                  <MaterialDetailsCard
                    heading={
                      materialData[0]?.Weight ? materialData[0]?.Weight : "NA"
                    }
                    description="Weight"
                  />
                </Flex>
              </Box>
            </Box>
          )}
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor="rgb(255, 255, 255)"
          boxShadow="rgba(44, 44, 44, 0.06) 0px -10px 15px"
          height="70px"
        >
          <Button
            variant="gradient"
            fontWeight="semibold"
            width="80%"
            margin="0 auto"
            maxW="347px"
            onClick={() => {
              encryptAssetDetails(id);
              setModalOpen(true);
            }}
          >
            Contact Seller
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default MaterialDetailsDeferred;
