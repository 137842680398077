import { useEffect, useState } from "react";
import { NewSideMenuLayout } from "../../../layout/NewSideMenuLayout/NewSideMenuLayout";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { useDispatch, useSelector } from "react-redux";
import { getInitialUserAssetsThunk } from "../thunks/getInitialUserAssetsThunk";
import { Card } from "../../../components/Card/Card";
import defaultImage from "../../../../assets/webapp/marketplace/defaultAssetImage.png";
import { Button } from "@chakra-ui/button";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { useLocation, useNavigate, useParams } from "react-router";
import { getRentalEquipmentAnalyticsThunk } from "../thunks/getRentalEquipmentAnalyticsThunk";
import { getUsedEquipmentAnalyticsThunk } from "../thunks/getUsedEquipmentAnalyticsThunk";
import { getInitialRentalEquipmentsThunk } from "../thunks/getInitalRentalEquipmentsThunk";
import { getInitialUsedEquipmentsThunk } from "../thunks/getInitialUsedEquipmentSlice";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { AnalyticsCard } from "../../../components/AnalyticsCard/AnalyticsCard";
import { Link } from "react-router-dom";
import { ProgressBarCard } from "../../../components/ProgressBarCard/ProgressBarCard";
import {
  deleteAllEquipmentDetails,
  getAssetMarketplaceListedDetails,
} from "../../../services/userAssets/userAssets";
import { useToast } from "@chakra-ui/toast";
import { resetEquipmentListingSlice } from "../../equipmentListing/slice/equipmentListingSlice";
import { WelcomePopUp } from "../../../components/WelcomePopup/WelcomePopup";
import {
  getFreeLeadsForIndividual,
} from "../../../services/freeLeads/freeLeads";
import { getFreeLeadsForIndividualThunk } from "../../freeLeads/thunks/getFreeLeadsForUserThunk";
import { v4 as uuid } from "uuid";
import { getFreeLeadsForCompanyThunk } from "../../freeLeads/thunks/getFreeLeadsForCompanyThunk";
import likeIconUnliked from "../../../../assets/webapp/marketplace/like-icon-unliked.svg";
import viewIcon from "../../../../assets/webapp/marketplace/view-icon.svg";
import connectIcon from "../../../../assets/webapp/marketplace/connects-icon.svg";
import editPopUpIcon from "../../../../assets/webapp/pop-up/edit-popup.svg";
import { MarketplaceLabel } from "../../../components/MarketplaceLabel/MarketplaceLabel";
import { convertPrice } from "../../../helpers/dataConversion/convertPrice";
import { SinglePurposePopup } from "../../../components/SinglePurposePopup/SinglePurposePopUp";
import { AddToMarketplacePopUp } from "../../../components/AddToMarketplacePopUp/AddToMarketplacePopUp";
import { getAssetListingLeadCheckThunk } from "../../purchasedLeadsCheck/thunks/getAssetListingLeadCheckThunk";
import { primaryKeyEncryption } from "../../../helpers/dataConversion/primaryKeyEncryption";
import { V5SidebarLayout } from "../../../layout/V5SidebarLayout/V5SidebarLayout";

export const EquipmentStore = () => {
  const [freeListingCount, setFreeListingCount] = useState(null);
  const [selectedEquipmentonRent, setSelectedEquipmentRent] = useState(null);
  const [selectedEquipmentRentalTime, setSelectedEquipmentRentalTime] =
    useState(null);
  const [selectedEquipmentUsedTime, setSelectedEquipmentUsedTime] =
    useState(null);
  const [
    selectedEquipmentMarketplaceDetails,
    setSelectedEquipmentMarketplaceDetails,
  ] = useState(null);
  const [selectedEquipmentonSale, setSelectedEquipmentonSale] = useState(null);
  const [addToMarketplacePopupOpen, setAddToMarketplacePopupOpen] =
    useState(false);

  // Pop up states
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(true);
  const [isEditPopUpopen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopUpopen, setIsDeletePopupOpen] = useState(false);

  const { UserTypeValue } = useSelector((state) => state.user);
  const { data, imageBaseReference, isInitialLoading } = useSelector(
    (state) => state.equipmentStore
  );
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null);
  const {
    rentalData,
    usedEquipmentIsInitialLoading,
    rentalEquipmentAnalytics,
    rentalEquipmentsAnalyticsLoading,
  } = useSelector((state) => state.rentalEquipmentStore);
  const {
    usedEquipmentData,
    usedEquipmentIsLoading,
    usedEquipmentAnalytics,
    usedEquipmentsAnalyticsLoading,
  } = useSelector((state) => state.usedEquipmentStore);

  // Leads Count
  const { freeLeadsData, freeLeadsLoading } = useSelector(
    (state) => state.freeLeads
  );
  const { purchasedLeads, leadsRemaining } = useSelector(
    (state) => state.subscriptionLeadsCheck
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const handleCardsOnLocation = () => {
    if (!location.search) {
      dispatch(getInitialUserAssetsThunk());
      dispatch(getInitialRentalEquipmentsThunk());
      dispatch(getInitialUsedEquipmentsThunk());
    } else if (location.search === "?marketplace=rental") {
      dispatch(getInitialRentalEquipmentsThunk());
      dispatch(getRentalEquipmentAnalyticsThunk());
    } else {
      dispatch(getInitialUsedEquipmentsThunk());
      dispatch(getUsedEquipmentAnalyticsThunk());
    }
  };

  const handleDeleteUserEquipment = async (id) => {
    try {
      await deleteAllEquipmentDetails(id);
      toast({
        status: "success",
        description: "Asset deleted successfully!",
        position: "top-right",
        isClosable: true,
      });
      handleCardsOnLocation();
    } catch (error) {
      return error;
    }
  };
  const freeSubscriptionCount = () => {
    const equipmentListingData = freeLeadsData.find(
      (category) =>
        category.SubscriptionTypeValue === "Contact Based" &&
        category.ServiceTypeValue === "Equipment Listing"
    );
    setFreeListingCount({
      leadsRemaining: equipmentListingData.ContactRemaining,
      totalLeads: equipmentListingData.FreeLeadsProvided,
    });
    return {
      leadsRemaining: equipmentListingData.ContactRemaining,
      totalLeads: equipmentListingData.FreeLeadsProvided,
    };
  };
  useEffect(() => {
    handleCardsOnLocation();
    getFreeLeadsForIndividual();
    dispatch(getRentalEquipmentAnalyticsThunk());
    dispatch(getUsedEquipmentAnalyticsThunk());
  }, [location]);

  useEffect(() => {
    dispatch(resetEquipmentListingSlice());
  }, []);

  useEffect(() => {
    if (!freeLeadsData || freeLeadsData.length <= 0) {
      if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
        dispatch(getFreeLeadsForCompanyThunk());
      } else {
        dispatch(getFreeLeadsForIndividualThunk());
      }
    } else {
      freeSubscriptionCount();
    }
  }, [freeLeadsData, UserTypeValue]);

  useEffect(() => {
    dispatch(getAssetListingLeadCheckThunk());
  }, []);

  const allEquipmentsRenderFunction = (array) => {
    const cardJsx =
      Array.isArray(array) && array.length > 0 ? (
        array.map((equipment) => {
          return (
            <Card type="equipmentMarketplaceCard" key={uuid()}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  padding: "25px 15px 15px 20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* IMAGE */}
                <div
                  style={{ width: "100%", height: "48%", minWidth: "200px" }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                    src={
                      !Array.isArray(equipment.AssetImages)
                        ? `${
                            process.env.REACT_APP_CDN_PVT_URL
                          }${imageBaseReference}${
                            equipment.AssetImages[
                              `${Object.keys(equipment.AssetImages)[0]}`
                            ]
                          }`
                        : defaultImage
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = defaultImage;
                    }}
                  />
                </div>
                {/* Asset Info */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #EAEAEA",
                  }}
                >
                  <div
                    style={{
                      marginTop: "0.5em",

                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        `/user-equipment-page/${primaryKeyEncryption(
                          equipment.AssetId
                        )}`
                      )
                    }
                  >
                    <p
                      style={{
                        fontSize: "22px",
                        letterSpacing: "1%",
                        lineHeight: "28px",
                        color: "#141619",
                      }}
                    >
                      Asset Name :{" "}
                      <span style={{ fontWeight: 700 }}>
                        {equipment.AssetNickName
                          ? equipment.AssetNickName
                          : "N.A."}
                      </span>
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#141619",
                      }}
                    >
                      Location :{" "}
                      {equipment.StateName && equipment.CityName
                        ? `${equipment.StateName}, ${equipment.CityName}`
                        : "N.A."}
                    </p>
                    <p
                      style={{
                        fontSize: "18x",
                        lineHeight: "24px",
                        fontWeight: 600,
                        color: "#141619",
                      }}
                    >
                      {equipment.EquipmentTypeValue &&
                      equipment.OEMName &&
                      equipment.ModelName
                        ? `${equipment.EquipmentTypeValue} • ${equipment.OEMName} • ${equipment.ModelName}`
                        : "N.A."}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#767676",
                        fontWeight: 600,
                      }}
                    >
                      ID:{" "}
                      {equipment.AssetUniqueId
                        ? `${equipment.AssetUniqueId}`
                        : "N.A."}{" "}
                      AddedOn:
                      <span style={{ color: "#959596" }}>{`${
                        equipment.ListedDateTime
                          ? `${equipment.ListedDateTime.split(",")[1]}`
                          : "N.A."
                      }`}</span>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.25em",
                    }}
                  >
                    {equipment.MarketPlaceListedOn &&
                    equipment.MarketPlaceListedOn.split(",").includes(
                      "Rental Equipment"
                    ) ? (
                      <MarketplaceLabel type="rental" />
                    ) : (
                      <></>
                    )}
                    {equipment.MarketPlaceListedOn &&
                    equipment.MarketPlaceListedOn.split(",").includes(
                      "Used Equipment"
                    ) ? (
                      <MarketplaceLabel type="used" />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* Footer */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0.5em",
                    }}
                  >
                    <Button
                      background="#FBF5F5"
                      isDisabled={equipment.MarketPlaceListedOn}
                      onClick={async () => {
                        setSelectedEquipmentId(equipment.AssetId);
                        setIsDeletePopupOpen(true);
                      }}
                    >
                      {/* Delete */}
                      <img
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/delete.svg`}
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                    <Button
                      background="#F7FAF5"
                      isDisabled={equipment.MarketPlaceListedOn}
                      onClick={() => {
                        setSelectedEquipmentId(equipment.AssetId);
                        setIsEditPopupOpen(true);
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`}
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                  </div>
                  <Button
                    variant="gradient"
                    isDisabled={
                      equipment.MarketPlaceListedOn &&
                      equipment.MarketPlaceListedOn.split(",").length === 2
                    }
                    onClick={async () => {
                      const listedData = await getAssetMarketplaceListedDetails(
                        equipment.AssetId
                      );
                      const usedEquipmentData = listedData.find(
                        (data) => data.MarketPlaceValue === "Used Equipment"
                      );
                      const rentalEquipmentData = listedData.find(
                        (data) => data.MarketPlaceValue === "Rental Equipment"
                      );

                      if (usedEquipmentData.IsListed) {
                        setSelectedEquipmentUsedTime(
                          usedEquipmentData.ListedDateTime
                        );
                        setSelectedEquipmentonSale(true);
                      }
                      if (rentalEquipmentData.IsListed) {
                        setSelectedEquipmentRentalTime(
                          rentalEquipmentData.ListedDateTime
                        );
                        setSelectedEquipmentRent(true);
                      }
                      setSelectedEquipmentId(equipment.AssetId);
                      setAddToMarketplacePopupOpen(true);
                    }}
                  >
                    Add to Marketplace
                  </Button>
                </div>
              </div>
            </Card>
          );
        })
      ) : (
        <>No data available</>
      );
    return cardJsx;
  };
  const usedEquipmentsRenderFunction = (array) => {
    const cardJsx =
      Array.isArray(array) && array.length > 0 ? (
        array.map((equipment) => {
          // console.log(equipment);
          const images = [
            equipment.Image_Front_FileRef,
            equipment.Image_Back_FileRef,
            equipment.Image_Left_FileRef,
            equipment.Image_Right_FileRef,
          ];

          const validImageLinks = images.reduce(
            (imageArray, currentImage, index) => {
              if (!currentImage) {
                return imageArray;
              }
              imageArray.push(
                `${process.env.REACT_APP_CDN_PVT_URL}AssetsImage/${currentImage}`
              );
              return imageArray;
            },
            []
          );

          return (
            <Card type="equipmentMarketplaceCard" key={uuid()}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  padding: "25px 15px 15px 20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.3em",
                    }}
                  >
                    <img style={{ height: "1em" }} src={likeIconUnliked} />
                    <p>
                      {equipment.LikesCount ? equipment.LikesCount : 0} Likes
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.3em",
                    }}
                  >
                    <img style={{ height: "1em" }} src={viewIcon} />
                    <p>
                      {equipment.ViewsCount ? equipment.ViewsCount : 0} Views
                    </p>
                  </div>
                </div>
                {/* IMAGE */}
                <div
                  style={{ width: "100%", height: "48%", minWidth: "200px" }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                    src={
                      validImageLinks && validImageLinks.length > 0
                        ? validImageLinks[0]
                        : defaultImage
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = defaultImage;
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #EAEAEA",
                  }}
                >
                  {/* Asset Info */}
                  <div
                    style={{
                      marginTop: "0.5em",
                      paddingBottom: "0.5em",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "22px",
                        letterSpacing: "1%",
                        lineHeight: "28px",
                        color: "#141619",
                      }}
                    >
                      Asset Name :{" "}
                      <span style={{ fontWeight: 700 }}>
                        {equipment.AssetNickName
                          ? equipment.AssetNickName
                          : "N.A."}
                      </span>
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          lineHeight: "24px",
                          color: "#141619",
                        }}
                      >
                        Location :{" "}
                        {equipment.StateName && equipment.CityName
                          ? `${equipment.StateName}, ${equipment.CityName}`
                          : "N.A."}
                      </p>
                      <div>{/* Labels of Marketplace */}</div>
                    </div>
                    <p
                      style={{
                        fontSize: "18x",
                        lineHeight: "24px",
                        fontWeight: 600,
                        color: "#141619",
                      }}
                    >
                      {equipment.EquipmentTypeValue &&
                      equipment.OEMName &&
                      equipment.ModelName
                        ? `${equipment.EquipmentTypeValue} • ${equipment.OEMName} • ${equipment.ModelName}`
                        : "N.A."}
                    </p>
                    <p
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: "#767676",
                        fontWeight: 600,
                      }}
                    >
                      ID:{" "}
                      {equipment.AssetUniqueId
                        ? `${equipment.AssetUniqueId}`
                        : "N.A."}{" "}
                      AddedOn:
                      <span style={{ color: "#959596" }}>{`${
                        equipment.UpdatedOnMarketplace
                          ? `${equipment.UpdatedOnMarketplace}`
                          : "N.A."
                      }`}</span>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.25em",
                    }}
                  >
                    {equipment.MarketPlaceValue &&
                    equipment.MarketPlaceValue === "Rental Equipment" ? (
                      <MarketplaceLabel type="rental" />
                    ) : (
                      <></>
                    )}
                    {equipment.MarketPlaceValue &&
                    equipment.MarketPlaceValue === "Used Equipment" ? (
                      <MarketplaceLabel type="used" />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* Footer */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "0.5em",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.25em",
                      alignItems: "center",
                    }}
                  >
                    {/*  */}
                    {/* <Button
                      background="#F7FAF5"
                      onClick={() => {
                        dispatch(openQrModal());
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MyBusiness/edit.svg`}
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button> */}
                    <p style={{ fontSize: "16px", fontWeight: "700" }}>
                      {equipment.MarketPlaceValue === "Rental Equipment"
                        ? `${convertPrice(equipment.ExpectedRentalPrice)} / ${
                            equipment.ExpectedRentalTypeValue
                          }`
                        : convertPrice(equipment.ExpectedPrice)}{" "}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.25em",
                      alignItems: "center",
                    }}
                  >
                    {/*  */}
                    <div
                      style={{
                        background: "#FEF8EC",
                        borderRadius: "8px",
                        padding: "11px",
                      }}
                    >
                      <img
                        src={connectIcon}
                        style={{ height: "20px", width: "20px" }}
                      />
                    </div>
                    <p style={{ fontSize: "16px", fontWeight: "700" }}>{`${
                      equipment.RequestCount ? equipment.RequestCount : 0
                    } Connects`}</p>
                  </div>
                </div>
              </div>
            </Card>
          );
        })
      ) : (
        <Text>No Data Available</Text>
      );
    return cardJsx;
  };
  return (
    <V5SidebarLayout gridTemplateRows="80px 1fr" backgroundColor="#FFFFFF">
      <QrPopUp />
      <AddToMarketplacePopUp
        isEquipment="true"
        onCloseButtonClick={() => setAddToMarketplacePopupOpen(false)}
        freeListingCount={
          freeListingCount && freeListingCount.totalLeads
            ? freeListingCount.leadsRemaining
            : 0
        }
        subscriptionListingCount={leadsRemaining ? leadsRemaining : 0}
        isOpen={addToMarketplacePopupOpen}
        onRentalMarketplace={selectedEquipmentonRent}
        onUsedMarketplace={selectedEquipmentonSale}
        listedDateRental={selectedEquipmentRentalTime}
        listedDateUsed={selectedEquipmentUsedTime}
        usedButtonClick={(value) => {
          navigate(
            `/my-store/additional-details?assetId=${selectedEquipmentId}&marketplaceType=used&leadType=${value.lead}`
          );
        }}
        rentalButtonClick={(value) => {
          navigate(
            `/my-store/additional-details?assetId=${selectedEquipmentId}&marketplaceType=rental&leadType=${value.lead}`
          );
        }}
        // rental={(value) => {navigate(`/my-store/additional-details?assetId=${selectedEquipmentId}&marketplaceType=rental&leadType=${value.lead}`)}}
      />
      <WelcomePopUp
       iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/popup/welcomeEquipmentStore.svg`}
        isOpen={isWelcomePopupOpen}
        descriptionArray={[
          "Add your Assets to your Store",
          "List on Marketplace to Sell or Rent",
          "View lead in “My Leads” Section",
        ]}
        onButtonClick={() => {
          setIsWelcomePopupOpen(false);
        }}
      />
      <SinglePurposePopup
        isOpen={isEditPopUpopen}
        iconUrl={editPopUpIcon}
        bodyText="Are you sure you want to edit the equipment?"
        cancelAction={() => {
          setIsEditPopupOpen(false);
        }}
        continueAction={() =>
          navigate(
            `/e9-services/equipment-listing?mode=edit&assetId=${selectedEquipmentId}`
          )
        }
      />
      <SinglePurposePopup
        isOpen={isDeletePopUpopen}
        iconUrl={editPopUpIcon}
        bodyText="Are you sure you want to delete the equipment?"
        continueAction={async () => {
          await handleDeleteUserEquipment(selectedEquipmentId);
          setSelectedEquipmentId(null);
          setIsDeletePopupOpen(false);
        }}
        cancelAction={() => {
          setSelectedEquipmentId(null);
          setIsDeletePopupOpen(false);
        }}
      />
        <Flex
          boxShadow="0px 4px 4px 0px #2c2c2c20"
          border="1px solid #E3E5E8"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          padding="1em 2.5em"
          marginTop="1em"
        >
          <Link
            style={{
              fontSize: "20px",
              lineHeight: "26px",
              fontWeight: 700,
              color: "#141619",
              borderBottom: !location.search ? "2px solid #141619" : "",
              opacity: !location.search ? "" : "0.5",
            }}
            to="/equipment-store"
          >
            My Fleet
          </Link>
          <Link
            style={{
              fontSize: "20px",
              lineHeight: "26px",
              fontWeight: 700,
              color: "#141619",
              opacity: location.search === "?marketplace=rental" ? "" : "0.5",
              borderBottom:
                location.search === "?marketplace=rental"
                  ? "2px solid #141619"
                  : "",
            }}
            to="/equipment-store?marketplace=rental"
          >
            {" "}
            On Rental Marketplace
          </Link>
          <Link
            style={{
              fontSize: "20px",
              lineHeight: "26px",
              fontWeight: 700,
              color: "#141619",
              opacity: location.search === "?marketplace=used" ? "" : "0.5",
              borderBottom:
                location.search === "?marketplace=used"
                  ? "2px solid #141619"
                  : "",
            }}
            to="/equipment-store?marketplace=used"
          >
            On Used Marketplace
          </Link>
          <Button
            color="#002961"
            fontWeight={700}
            border="1px solid #002961"
            background="#FFFFFF"
            fontSize="24px"
            onClick={() => {
              location.search
                ? dispatch(openQrModal())
                : navigate("/e9-services/equipment-listing");
            }}
          >
            {" "}
            {!location.search
              ? "+ Add"
              : location.search === "?marketplace=rental"
              ? "+ Put For Rent"
              : "+ Put For Sale"}
          </Button>
        </Flex>
      <Box padding="1em 52px" width="100%" overflowX="scroll" >
    
        <Flex justifyContent="space-between" alignItems="center">
          {/* Subsctiption analyticss and add button */}
          <Flex gap="1em">
            <ProgressBarCard
              title="Free Listings Remaining"
              colorVariant="gradient"
              totalCount={
                freeListingCount && freeListingCount.totalLeads
                  ? freeListingCount.totalLeads
                  : 0
              }
              progressCount={
                freeListingCount && freeListingCount.leadsRemaining
                  ? freeListingCount.leadsRemaining
                  : 0
              }
            />
            <ProgressBarCard
              title="Subscription Remaining"
              progressCount={leadsRemaining ? leadsRemaining : 0}
              totalCount={purchasedLeads ? purchasedLeads : 0}
              colorVariant="green"
            />
 {location.search &&
          location.search === "?marketplace=rental" &&
          rentalEquipmentAnalytics &&
          rentalEquipmentAnalytics.length > 0 ? (
            rentalEquipmentAnalytics.map(({ name, value }) => (
              <AnalyticsCard
                isLoading={rentalEquipmentsAnalyticsLoading}
                analytics={value ? value : 0}
                description={name ? name : "N.A."}
              />
            ))
          ) : location.search &&
            location.search === "?marketplace=used" &&
            usedEquipmentAnalytics &&
            usedEquipmentAnalytics.length > 0 ? (
            usedEquipmentAnalytics.map(({ name, value }) => (
              <AnalyticsCard
                isLoading={usedEquipmentsAnalyticsLoading}
                analytics={value ? value : 0}
                description={name ? name : "N.A."}
              />
            ))
          ) : (
            <>
              {" "}
              <AnalyticsCard
                analytics={data && data.length > 0 ? data.length : 0}
                description="All Equipments"
                isLoading={isInitialLoading}
              />
              <AnalyticsCard
                analytics={
                  rentalData && rentalData.length > 0 ? rentalData.length : 0
                }
                description="Rental Marketplace"
                isLoading={isInitialLoading}
              />
              <AnalyticsCard
                analytics={
                  usedEquipmentData && usedEquipmentData.length > 0
                    ? usedEquipmentData.length
                    : 0
                }
                description="Used Marketplace"
                isLoading={isInitialLoading}
              />{" "}
            </>
          )}
            
            
          </Flex>
          
        </Flex>
        <Flex gap="1.5em" marginTop="1em">
         
        </Flex>
       
      </Box>
      <MarketplaceHoc
        isNewNavbar={true}
        maxHeight="80px 1fr"
        cardsRenderFunction={(array) =>
          !location.search
            ? allEquipmentsRenderFunction(array)
            : usedEquipmentsRenderFunction(array)
        }
        dataArray={
          !location.search
            ? data
            : location.search === "?marketplace=rental"
            ? rentalData
            : location.search === "?marketplace=used"
            ? usedEquipmentData
            : []
        }
        initialLoading={
          !location.search
            ? isInitialLoading
            : location.search === "?marketplace=rental"
            ? usedEquipmentIsInitialLoading
            : location.search === "?marketplace=used"
            ? usedEquipmentIsLoading
            : false
        }
        hasPagination={false}
      />
    </V5SidebarLayout>
  );
};
