import React from "react";

import { getDealershipMarketplaceInitialDataThunk } from "../thunks/getDealershipMarketplaceInitialDataThunk";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import { FilterPopUp } from "../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { MarketplaceHeader } from "../../../components/MarketplacHeader/MarketplaceHeader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./DealershipMarketplace.css";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { v4 as uuid } from "uuid";
import { MarketPlaceLayout } from "../../../layout/dealershipMarketplaceLayout/MarketPlaceLayout";
import { getDealershipLoadMoreDataThunk } from "../thunks/getDealershipLoadMoreDataThunk";
import shareIcon from "../../../../assets/webapp/dashboard/share.svg";

import {
  resetFilters,
  setFilterByCountry,
  setFilterByEquipmentMake,
  setFilterByState,
  setFilterModalOpen,
  setFilteredDataRequired,
  setLikeMergedData,
  setLikeStatusData,
} from "../slice/dealershipMarketplaceSlice";

import { dealershipInitialFilteredDataThunk } from "../thunks/dealershipInitialFilteredDataThunk";
import { MARKETPLACE_TYPES } from "../../../constants/e9Constants";
import { loadMoreFilteredData } from "../thunks/loadMoreFilteredDataThunk";
import DealershipCard from "../../../components/DealershipMarketplaceCard/DealershipCard";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import {
  GetE9DealershipMpLikeList,
  sendLikeToDealership,
} from "../../../services/dealershipMarketplace/dealershipMarketplace";
import { V5DashboardDealershipCard } from "../../../components/V5DealershipCards/V5DashboardDealershipCard";
import { Box, Button, Divider, Flex, Image, Text } from "@chakra-ui/react";
import { subString } from "../../../helpers/dataConversion/substring";
import { Like } from "../../../components/Like/Like";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";

const DealershipMarketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, likeStatusData, likeMergedData } = useSelector(
    (state) => state.dealershipMarketplace
  );
  const dealershipData = useSelector((state) => state.dealershipMarketplace);

  const dealershipCount = data?.length;

  useEffect(() => {
    (async () => {
      await getLikesStatus();
    })();
  }, []);

  useEffect(() => {
    // console.log("USEEFFECT",dealershipData);
    if (dealershipData.filteredDataRequired) {
      dispatch(dealershipInitialFilteredDataThunk());
    } else {
      dispatch(getDealershipMarketplaceInitialDataThunk());
    }
  }, []);
  useEffect(() => {
    mergedData();
  }, [likeStatusData, data]);

  const mergedData = async () => {
    if (data && likeStatusData) {
      try {
        const likedData = await Promise.all(
          data.map(async (x) => {
            const matchingLike = likeStatusData.find(
              (y) => y.DealershipMpGuid === x.DealershipMpGuid
            );

            if (matchingLike) {
              return {
                ...x,
                likeStatus: matchingLike.LikeStatus === 1 ? true : false,
              };
            } else {
              return {
                ...x,
                likeStatus: false,
              };
            }
          })
        );

        dispatch(setLikeMergedData(likedData));
      } catch (error) {}
    }
  };

  const getLikesStatus = async () => {
    try {
      const { Data } = await GetE9DealershipMpLikeList();
      if (Data?.E9DealershipMpLikeList.length > 0) {
        dispatch(setLikeStatusData(Data));
      } else {
        dispatch(setLikeStatusData(Data));
      }
    } catch (error) {}
  };

  const filterFunction = (values) => {
    // console.log("Filter Function" , values)
    dispatch(setFilterByEquipmentMake(values.equipmentMake));
    dispatch(setFilterByCountry(values.country));
    dispatch(setFilterByState(values.state));
    if (
      values.equipmentMake ||
      values.country ||
      values.state ||
      values.verificationStatus
    ) {
      dispatch(setFilteredDataRequired(true));
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(dealershipInitialFilteredDataThunk());
    dispatch(setFilterModalOpen(false));
  };

  const handleLike = async (body) => {
    try {
      const response = await sendLikeToDealership(body);
      if (response?.Status === 201) {
        getLikesStatus();
      }
    } catch (error) {}
  };

  const renderDealershipCards = (array) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((data) => (
          <>
            <DealershipCard
              key={uuid()}
              imageUrl={
                data.ImageList &&
                data.ImageList.length > 0 &&
                `${process.env.REACT_APP_CDN_PVT_URL}${data.ImageList[0]?.ImageFileRef}`
              }
              isVerified={data.IsVerified}
              equipmentStatusValue={data.EquipmentStatusValue}
              like={data?.likeStatus}
              isPromoted={data.IsPromoted}
              location={data.Location}
              dealerName={data.DealerName}
              financeProvidedValue={data.FinanceProvidedValue}
              dealershipUniqueId={data.DealershipUniqueId}
              listedDateTime={data.ListedDateTime}
              onNavigateClick={() => {
                const dealershipGuid = convertToBase64(data?.DealershipGuid);
                const dealershipMpGuid = convertToBase64(
                  data?.DealershipMpGuid
                );
                const taxNumber = convertToBase64(data?.TaxNumber);
                navigate(
                  `/marketplace/DealershipMarketplaceHome/dealership-detail/${dealershipGuid}/${dealershipMpGuid}/${data?.likeStatus}/${taxNumber}`
                );
              }}
              onLikeClick={async (value) => {
                await handleLike({
                  MarketplaceGuid: data.DealershipMpGuid,
                  OwnerUserCredentialId: data?.OwnerUserCredentialId,
                  LikeStatus: value ? 1 : 0,
                  DealershipUniqueId: data?.DealershipUniqueId,
                });
              }}
            />
          </>
        ))
      ) : (
        <p>No Dealership Listed Yet</p>
      );

    return renderJSX;
  };

  const dealershipLocationHandler = (locationString = "") => {
    if(!locationString) return null
    const locationStringArray = locationString.split(",")
     if(locationStringArray.length >= 3){
      return `${locationStringArray[locationStringArray.length - 3]}, ${locationStringArray[locationStringArray.length - 2]}, ${locationStringArray[locationStringArray.length - 1]}`
     }
     if(locationStringArray.length === 2){
      return `${locationStringArray[locationStringArray.length - 2]}, ${locationStringArray[locationStringArray.length - 1]}`
     }
     return `${locationStringArray[locationStringArray.length - 1]}`
      }

  const isDateNew = (dateStr) => {
    const formattedDateStr = dateStr
      .replace(",", "")
      .replace("th-", "-")
      .split(" ")
      .slice(0, 5)
      .join(" ");
    const parsedDate = new Date(formattedDateStr);

    const today = new Date();
    const daysDifference =
      Math.abs(today.getTime() - parsedDate.getTime()) / (1000 * 3600 * 24);

    return daysDifference < 5;
  };

  const renderCards = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((data) => (
          <Flex gap="1em">
            <V5DashboardDealershipCard
              boxShadow="0px 6px 15px -3px #00000029"
              maxHeight="400px"
              minHeight="390px"
              maxWidth="385px"
              onClick={() => {
                const dealershipGuid = convertToBase64(data?.DealershipGuid);
                const dealershipMpGuid = convertToBase64(
                  data?.DealershipMpGuid
                );
                const taxNumber = convertToBase64(data?.TaxNumber);
                navigate(
                  `/marketplace/DealershipMarketplaceHome/dealership-detail/${dealershipGuid}/${dealershipMpGuid}/${data?.likeStatus}/${taxNumber}`
                );
              }}
            >
              <Box maxH="200px" minH="200px" maxW="385px">
                <V5DashboardDealershipCard.Verification
                  position="relative"
                  backgroundRepeat="no-repeat"
                  backgroundWidth="100%"
                  backgroundSize="cover"
                  minH="200px"
                  maxH="220px"
                  height="100%"
                  url={
                    data.ImageList &&
                    data.ImageList.length > 0 &&
                    `${process.env.REACT_APP_CDN_PVT_URL}${data.ImageList[0]?.ImageFileRef}`
                  }
                >
                  <Flex
                    direction="column"
                    position="inherit"
                    top="20px"
                    // left="10px"
                    gap="1em"
                  >
                    <Flex justifyContent="space-between" padding="0 0.5em">
                    <Button
                      borderRadius="100px"
                      width="110px"
                      height="30px"
                      backgroundColor={
                        data?.IsVerified ? "#319F0060" : "rgba(214, 0, 0, 0.40)"
                      }
                    >
                      <Text color="#fff" fontSize="12px" fontWeight="600">
                        {data?.IsVerified ? "Verified" : "Not Verified"}
                      </Text>
                    </Button>

                    <Button
                      borderRadius="100px"
                      width="110px"
                      height="30px"
                      backgroundColor={"#2C2C2C80"}
                    >
                      <Text color="#fff" fontSize="12px" fontWeight="600">
                        {data?.IsPromoted ? "Promoted" : "Not Promoted"}
                      </Text>
                    </Button>

                    </Flex>

                    <Button
                      width="41px"
                      height="20px"
                      backgroundColor="#F5F5F5"
                    >
                      <Text color="#141619" fontSize="9px" fontWeight="700">
                        {data?.EquipmentStatusValue}
                      </Text>
                    </Button>

                    { isDateNew(data?.ListedDateTime) ? 
                    <Button
                      width="70px"
                      height="20px"
                      backgroundColor="#F5F5F5"
                    >
                      <Text color="#002961" fontSize="9px" fontWeight="700">
                        Newly Added
                      </Text> 
                    </Button>
                    : null }
                    
                  </Flex>
                </V5DashboardDealershipCard.Verification>

                <Flex direction="column" padding="0.5em 1em" gap="1em">
                  <Box display="flex" flexDirection="column" gap="3px">
                    <V5DashboardDealershipCard.PrimaryText
                      tooltip={data?.DealerName}
                    >
                      {subString(18, data?.DealerName)}
                    </V5DashboardDealershipCard.PrimaryText>

                    <V5DashboardDealershipCard.LocationText
                      tooltip={`${data?.GoogleLocation?.City} ${data?.GoogleLocation?.State} ${data?.GoogleLocation?.Country}`}
                    >
                      {subString(18, data?.GoogleLocation?.City , data?.GoogleLocation?.State , data?.GoogleLocation?.Country)}
                    </V5DashboardDealershipCard.LocationText>

                      <Flex gap="5px">
                      <V5DashboardDealershipCard.CommonText
                      leadingText={"Finance Available :"}
                      value={data?.FinanceProvidedValue}
                    />
                    <Text>|</Text>
                    <V5DashboardDealershipCard.CommonText
                      leadingText={"ID"}
                      value={data?.DealershipUniqueId}
                    />
                      </Flex>


                    <V5DashboardDealershipCard.CommonText
                      leadingText={"Added on :"}
                      value={data?.ListedDateTime ? data?.ListedDateTime?.split(",")[1] : "N.A"}
                    />
                  </Box>

                  <Divider />

                  <Flex alignItems="center" justifyContent="space-between">
                    <Box display="flex" gap="1em">
                      <V5DashboardDealershipCard.CommonText
                        leadingText={"OEM"}
                        value={data?.EquipmentMakeValue}
                      />
                    </Box>

                    <Flex alignItems="center" justifyContent="center" gap="1em">
                      <Like
                        onClick={async (value, event) => {
                          event.stopPropagation();
                          await handleLike({
                            MarketplaceGuid: data.DealershipMpGuid,
                            OwnerUserCredentialId: data?.OwnerUserCredentialId,
                            LikeStatus: value ? 1 : 0,
                            DealershipUniqueId: data?.DealershipUniqueId,
                          });
                        }}
                        isLiked={data?.likeStatus}
                      />

                      <Button
                        backgroundColor="transparent"
                        border="none"
                        boxShadow="none"
                        maxH="30px"
                        maxW="50px"
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch(openQrModal());
                        }}
                        _hover={{
                          backgroundColor : "transparent"

                        }}
                        _active={{
                        backgroundColor :"transparent"

                        }}
                      >
                        <Image src={shareIcon} height="30px" width="50px" />
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </V5DashboardDealershipCard>
          </Flex>
        ))
      ) : (
        <p>No Dealership</p>
      );
    return renderJsx;
  };

  return (
    <>
      <QrPopUp />
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.DEARLERSHIPS_MARKETPLACE}
        isModalOpen={dealershipData.filterModalOpen}
        initialEquipmentMakeValue={dealershipData.filterByEquipmentMake}
        initialCountryValue={dealershipData.filterByCountry}
        initialStateValue={dealershipData.filterByState}
        onResetFilterClick={() => {
          dispatch(resetFilters());
        }}
        onSubmitButtonClick={filterFunction}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />
      <QrPopUp />
      <MarketPlaceLayout>
        <MarketplaceHeader
          heading={`${
            dealershipCount ? dealershipCount : ""
          } Dealership Listed`}
          filterButtonSelected={dealershipData.filteredDataRequired}
          filterButtonClickAction={() => dispatch(setFilterModalOpen(true))}
        />
        {/* dealershipCard */}

        <MarketplaceHoc
          marketplaceType="Dealerships"
          dataArray={
            dealershipData.likeMergedData ? dealershipData.likeMergedData : []
          }
          endOfData={dealershipData.endOfData}
          loadMoreAction={() => {
            dealershipData.filteredDataRequired
              ? dispatch(loadMoreFilteredData())
              : dispatch(
                  getDealershipLoadMoreDataThunk(dealershipData.paginitionCount)
                );
          }}
          initialLoading={dealershipData.initialLoading}
          paginationLoading={dealershipData.paginationLoading}
          cardsRenderFunction={renderCards}
        />
      </MarketPlaceLayout>
    </>
  );
};

export default DealershipMarketplace;
