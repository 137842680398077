import { Formik } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
// import { increaseRouteIndex } from "../../../global/user/slice/userSlice";
import { increasePageIndex, setHasTaxVerificationDocument, setTaxVerificationNumber } from "../../slice/docFlowSlice";
import { getTaxDocTypeByCountryId, updateCompanyUserTaxDetails } from "../../../../services/documentFlow/documentFlow";
import { getDefaultAppLanguage } from "../../../global/mtData/helpers/localStorage";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

export const UploadBusinessIdentification = () => {
  const [hasDocument, setHasDocument] = useState(false);
  const [allDocumentTypes, setAllDocumentTypes] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const userData = useSelector((state) => state.user);
  const appData = useSelector((state) => state.appConfig);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const defaultLanguage = getDefaultAppLanguage();

  const [urlParam, setUrlParam] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
    const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
    if(id && !urlAssetId){
     setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`)
    }
   }, [])

  const getAllTaxDocumentTypes = async () => {
    const data= await getTaxDocTypeByCountryId({
      CountryId:appData.country?.CountryId
    })
    
    setAllDocumentTypes(data.Data.TaxDocTypes);
  };

  const updateBusinessIdentificationDocument = async (body, taxNumber) => {
   
    const data=await updateCompanyUserTaxDetails(JSON.stringify(body))

    if(data.Status === 204){
      if(hasDocument){
        
        dispatch(setHasTaxVerificationDocument(1))
        dispatch(setTaxVerificationNumber(taxNumber))
      }
      else{
        dispatch(setHasTaxVerificationDocument(0))
        dispatch(setTaxVerificationNumber(null))
      }
    }
   
  };

  // const postBusinessVerificationWithDocument = async (body) => {
   
  //   const authHeaders = new Headers();
  //   authHeaders.append("token", userData.token);
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_ENDPOINT}${API_CONSTANTS.UPLOAD_VERIFICATION_DOCUMENT}`,
  //     {
  //       method: `${API_CONSTANTS.HTT_POST}`,
  //       body: convertToFormData(body),
  //       headers: authHeaders,
  //     }
  //   );
  //   const data = await response.json();
 
  // };

  useEffect(() => {
    (async () => {
      await getAllTaxDocumentTypes();
    })();
  }, []);

 
  return (
    <V5MultiAdLayout
      heading={defaultLanguage?.Business_Identification_Document}
      subHeading={defaultLanguage?.Please_give_your_document_details_for_verification}
      stepper={true}
    >
      <Formik
        initialValues={
          hasDocument
            ? {
                documentType: null,
                documentNumber: null,
                documentImage: null,
                hasDocument: hasDocument,
              }
            : {
                documentType: null,
                documentNumber: null,
                hasDocument: hasDocument,
              }
        }
        validationSchema={
           Yup.object({
                documentType: Yup.string().required("Required"),
                documentNumber: Yup.string()
                  .required("Required")
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "Please enter correct format"
                  ),
                haveDocument: Yup.boolean(),
              })
        }
        onSubmit={async (values) => {
          const body = {
            CompanyUserTaxDetails: {
              TaxCodeTypeId: parseInt(values.documentType),
              TaxNumber: values.documentNumber,
            },
          };
          setLoading(true);
          await updateBusinessIdentificationDocument(body, values.documentNumber);
          setLoading(false)
          dispatch(increasePageIndex())
          
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit} id="business-identification-form">
            <Flex
              flexDirection="column"
              gap="2em"
              height="250px"
              overflowY="scroll"
              width={"85%"}
              maxW="800px"
              margin="0 auto"
              fontWeight={"700"}
            >
              <Checkbox
                name="hasDocument"
                defaultChecked
                onChange={(e) => {
                  setHasDocument(!e.target.checked);
                  setFieldValue("hasDocument", !e.target.checked);
                }}
                maxW="fit-content"
              >
                I don't have tax document with me.
              </Checkbox>
              <Flex 
              gap="2em" 
              padding="0 0.25em" 
              wrap={"wrap"}>
                <FormControl isRequired={true} maxW="374px" width="100%">
                  <FormLabel>Document Type</FormLabel>
                  <Select
                    name="documentType"
                    h="52px"
                    border="1px solid #2c2c2c14"
                    focusBorderColor="#2c2c2c"
                    backgroundColor={
                      values.documentType ? "#ffff" : "#2c2c2c05"
                    }
                    value={values.documentType}
                    maxW="374px"
                    onChange={(e) => {
                      setFieldValue("documentType", parseInt(e.target.value));
                      setSelectedDocument(
                        allDocumentTypes.find(
                          (document) =>
                            parseInt(document.TaxCodeTypeId) ===
                            parseInt(e.target.value)
                        )
                      );
                    }}
                  >
                    <option value="" isDisabled>
                      Select
                    </option>
                    {allDocumentTypes && allDocumentTypes.length > 0
                      ? allDocumentTypes.map((documentType) => (
                          <option
                            key={uuid()}
                            value={documentType.TaxCodeTypeId}
                          >
                            {documentType.TaxCodeTypeValue}
                          </option>
                        ))
                      : null}
                  </Select>
                </FormControl>

                <FormikInput
                  name="documentNumber"
                  isRequired={true}
                  label="Business Identification Number"
                  placeholder="E.g. MH 1112340000567"
                />
              </Flex>
              
              {/* {hasDocument && (
                <ImageUpload
                  name="documentImage"
                  label="Document Upload"
                  helperText="Please give your document details for verification."
                  formikProps={{ setFieldValue, errors, touched }}
                />
              )} */}
            </Flex>
          </form>
        )}
      </Formik>
      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            type="submit"
            variant="gradient"
            fontWeight="semibold"
            width="400PX"
            margin="0 auto"
            maxW="400px"
            form="business-identification-form"
            isLoading={loading}
          >
            <p style={{ fontSize: "22px" }}>Next</p>
          </Button>
        </Flex>

      </Box>
    </V5MultiAdLayout>
  );
};
