import { ReactSVG } from "react-svg";

export const OverallRatingIcon = ({ ratings, maxHeight = "30px" }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "0.25em",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1E3264",
        maxHeight: maxHeight,
        borderRadius: "4px",
      }}
    >
      <ReactSVG
        src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/star.svg`}
      />
      <p style={{ color: "#FFF", fontWeight: 500 }}>{ratings ? ratings : 0}</p>
    </div>
  );
};
