import { createSlice } from "@reduxjs/toolkit";
import { getInitialMaintenancePartnerJobs } from "../thunks/getInitialMaintenancePartnerJobs";
import { getLoadMoreMaintenanceDataThunk } from "../thunks/getLoadMoreMaintenancePartnerJobs";
import { getInitialFilteredMaintenanceData } from "../thunks/getInitialFilteredMaintenanceJobs";
import { getLoadMoreFilteredMaintenanceData } from "../thunks/getLoadMoreFilteredMaintenanceJobs";

export const maintenancePartnerJobsSlice = createSlice({
  name: "maintenancePartnerJobs",
  initialState: {
    data: null,
    paginitionCount: 0,
    endOfData: false,
    cardCount: 12,
    initialLoading: false,
    paginationLoading: false,
    filterByEquipmentType: null,
    filterByCountry: null,
    filterByState: null,
    filterByCity: null,
    filterDataRequired: null,
    filterModalOpen: false,
    error: null,
    errorMessage: null,
  },
  reducers: {
    setFilterByEquipmentType: (state, { payload }) => {
      state.filterByEquipmentType = payload;
    },
    setFilterByCountry: (state, { payload }) => {
      state.filterByCountry = payload;
    },
    setFilterByState: (state, { payload }) => {
      state.filterByState = payload;
    },
    setFilterByCity: (state, { payload }) => {
      state.filterByCity = payload;
    },
    setFilteredDataRequired: (state, { payload }) => {
      state.filterDataRequired = payload;
    },
    setFilterModalOpen: (state, { payload }) => {
      state.filterModalOpen = payload;
    },
    resetFilters: (state) => {
      state.filterByEquipmentType = null;
      state.filterByCountry = null;
      state.filterByState = null;
      state.filterByCity = null;
      state.filterDataRequired = null;
      state.filterModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialMaintenancePartnerJobs.pending, (state) => {
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.data = null;
      })
      .addCase(getInitialMaintenancePartnerJobs.fulfilled, (state, action) => {
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.paginitionCount = 1;
        if (action.payload.length >= state.cardCount) {
          state.data = action.payload;
        } else if (action.payload.length > 0) {
          state.data = action.payload;
          state.endOfData = true;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreMaintenanceDataThunk.pending, (state) => {
        state.initialLoading = false;
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = false;
      })
      .addCase(getLoadMoreMaintenanceDataThunk.fulfilled, (state, action) => {
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.paginitionCount += 1;
        if (action.payload.length >= state.cardCount) {
          state.data = [...state.data, ...action.payload];
        } else if (action.payload > 0) {
          state.data = [...state.data, ...action.payload];
          state.endOfData = true;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getInitialFilteredMaintenanceData.pending, (state) => {
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.data = null;
      })
      .addCase(getInitialFilteredMaintenanceData.fulfilled, (state, action) => {
        state.initialLoading = false;
        state.paginationLoading = false;
        state.error = null;
        state.errorMessage = false;
        state.paginitionCount = 1;
        if (action.payload.length >= state.cardCount) {
          state.data = action.payload;
        } else if (action.payload.length > 0) {
          state.data = action.payload;
          state.endOfData = true;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getLoadMoreFilteredMaintenanceData.pending, (state) => {
        state.initialLoading = false;
        state.paginationLoading = true;
        state.error = null;
        state.errorMessage = false;
      })
      .addCase(
        getLoadMoreFilteredMaintenanceData.fulfilled,
        (state, action) => {
          state.initialLoading = false;
          state.paginationLoading = false;
          state.error = null;
          state.errorMessage = false;
          state.paginitionCount += 1;
          if (action.payload.length >= state.cardCount) {
            state.data = [...state.data, ...action.payload];
          } else if (action.payload.length > 0) {
            state.data = [...state.data, ...action.payload];
            state.endOfData = true;
          } else {
            state.endOfData = true;
          }
        }
      );
  },
});

export const {
  setFilterByCity,
  setFilterByCountry,
  setFilterByEquipmentType,
  setFilterByState,
  setFilterModalOpen,
  setFilteredDataRequired,
  resetFilters,
} = maintenancePartnerJobsSlice.actions;
