import { useEffect } from "react";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";

import { useDispatch, useSelector } from "react-redux";
import { getInitialPostedJobsThunk } from "../../thunks/getInitialPostedJobsThunk";
import { Card } from "../../../../components/Card/Card";
import { openQrModal } from "../../../dashboard/slice/dashboardSlice";
import { Flex, Text } from "@chakra-ui/layout";
import { getInitialJobsPostedForCompanyThunk } from "../../thunks/getInitialJobsPostedForCompanyThunk";
import { subString } from "../../../../helpers/dataConversion/substring";
import {v4 as uuid} from "uuid"


export const MyJobs = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.jobsPosted);
  const { UserTypeValue } = useSelector((state) => state.user);
  useEffect(() => {
    if (UserTypeValue === "Company") {
      dispatch(getInitialJobsPostedForCompanyThunk());
    } else {
      dispatch(getInitialPostedJobsThunk());
    }
  }, []);
  return (
    <NewSideMenuLayout>
      <div
        style={{
          margin: "0px 52px",
          backgroundColor: "#92929204",
          paddingTop: "1em",
        }}
      >
        <div>
          <h1 style={{ fontSize: "32px", fontWeight: 700 }}>My Jobs</h1>
        </div>
        <div
          style={{
            maxHeight: "calc(100vh - 144px)",
            overflowX: "scroll",
            height: "100%",
          }}
        >
          <div
            style={{
              paddingRight: "52px",
              paddingLeft: "52px",
              //   minHeight: "100vh",
              marginTop: "80px",
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              rowGap: "2em",
              columnGap: "1em",
            }}
          >
            {data && data.length > 0 ? (
              data.map((card) => {
                const {
                  TotalApplicantsCount,
                  JobStatusValue,
                  JobId,
                  JobPostedDateTime,
                  JobTitle,
                  EquipmentTypeName,
                  EquipmentMakeName,
                  EquipmentModelName,
                  WorkLocation,
                } = card;
                return (
                  <Card
                  key={uuid()}
                    type="jobPostedCard"
                    onClick={() => {
                      dispatch(openQrModal());
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        paddingLeft: "1em",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#FED401",
                            borderRadius: "1em",
                            padding: "0.1em 0.5em",
                            justifyContent: "space-between",
                            marginTop: "1.25em",
                          }}
                        >
                          <p style={{ color: "#1E2364", fontSize: "14px" }}>
                            {TotalApplicantsCount} New Partners
                          </p>
                        </div>
                        <div
                          style={{
                            borderTopRightRadius: "8px",
                            borderBottomLeftRadius: "16px",
                            color: "#FFFFFF",
                            backgroundColor: "#7A7C7F",
                            padding: "0.45em 1.5em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: "100px"
                          }}
                        >
                          <p style={{fontSize: "14px"}}>{JobStatusValue}</p>
                        </div>
                      </div>
                      <p style={{ color: "#1E2364", fontSize: "18px" }}>
                        Order Id:{" "}
                        <span style={{ fontWeight: 600 }}>
                          {JobId ? JobId : "NA"}
                        </span>
                      </p>
                      <p style={{ color: "#2c2c2c90", fontSize: "18px" }}>
                        Posted on:{" "}
                        <span style={{ fontWeight: 600 }}>
                          {JobPostedDateTime
                            ? JobPostedDateTime.split(",")[1]
                            : "NA"}
                        </span>
                      </p>
                      <p
                        style={{
                          color: "#2c2c2c",
                          fontSize: "22px",
                          fontWeight: 600,
                          marginBottom: "0.25em",
                        }}
                      >
                        {subString(27, JobTitle)}
                      </p>
                      <p
                        style={{
                          color: "#2c2c2c",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        {subString(
                          27,
                          `${EquipmentTypeName} ${EquipmentMakeName} ${EquipmentModelName}`
                        )}
                      </p>
                      <p style={{ color: "#2c2c2c90", fontSize: "16px" }}>
                        {subString(70, WorkLocation)}
                      </p>
                    </div>
                  </Card>
                );
              })
            ) : (
              <Flex justifyContent="center">
                <Text fontWeight="600" fontSize="18px">
                  No Jobs Found.
                </Text>
              </Flex>
            )}
          </div>
        </div>
      </div>
    </NewSideMenuLayout>
  );
};
