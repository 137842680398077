import { v4 as uuid } from "uuid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NewSideMenuLayout } from "../../../../layout/NewSideMenuLayout/NewSideMenuLayout";

import { MaterialStoreCard } from "../../../../components/MaterialStoreCard/MaterialStoreCard";
import {
  convertFromBase64,
  convertToBase64,
} from "../../../../../utils/formatConverter/convertToBase64";
import {
  deleteMaterialDetails,
  getMaterialStore,
  materialFreeReedemTransaction,
  materialSubscriptionReedemTransaction,
  postMaterialMarketplace,
  removeMaterialFromMarketplace,
} from "../../../../services/materials/materials";
import "./MyMaterialTypeStore.css";
import { myMaterialsInitialDataThunk } from "../../../myMaterials/thunk/myMaterialsInitialDataThunk";
import { Box, Flex } from "@chakra-ui/react";
import { ProgressBarCard } from "../../../../components/ProgressBarCard/ProgressBarCard";
import { AnalyticsCard } from "../../../../components/AnalyticsCard/AnalyticsCard";
import { getFreeLeadsForCompanyThunk } from "../../../freeLeads/thunks/getFreeLeadsForCompanyThunk";
import { getFreeLeadsForIndividualThunk } from "../../../freeLeads/thunks/getFreeLeadsForUserThunk";
import { getMaterialRequestReceivedCountByIdThunk } from "../../../myMaterials/thunk/getMaterialRequestReceivedCountByIdThunk";
import { getMaterialConnectsCountByIdThunk } from "../../../myMaterials/thunk/getMaterialConnectsCountByIdThunk";
import { getMaterialConnectsPendingCountByIdThunk } from "../../../myMaterials/thunk/getMaterialConnectsPendingCountByIdThunk";
import { SinglePurposePopup } from "../../../../components/SinglePurposePopup/SinglePurposePopUp";
import deletePopUpIcon from "../../../../../assets/webapp/pop-up/delete-popup.svg";
import editPopUpIcon from "../../../../../assets/webapp/pop-up/edit-popup.svg";
import { useToast } from "@chakra-ui/toast";
import { QrPopUp } from "../../../../components/ScanQrCodeModal/QrPopUp";
import { openQrModal } from "../../../dashboard/slice/dashboardSlice";
import { getMaterialLeadCheckThunk } from "../../../purchasedLeadsCheck/thunks/getMaterialLeadCheckThunk";
import {
  clearBasicDetails,
  clearStepperIndex,
} from "../../../addMaterial/slice/postMaterialsSlice";
import { AddToMarketplacePopUp } from "../../../../components/AddToMarketplacePopUp/AddToMarketplacePopUp";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { updateRemoveFromMarketPlace } from "../../../myMaterials/slice/myMaterialsSlice";
import { MarketplaceHoc } from "../../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { myMaterialsLoadMoreDataThunk } from "../../../myMaterials/thunk/myMaterialsLoadMoreDataThunk";
import { V5SidebarLayout } from "../../../../layout/V5SidebarLayout/V5SidebarLayout";

export const MyMaterialTypeStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const MaterialTypeValue = convertFromBase64(type);

  const { data: initialData } = useSelector((state) => state.myMaterials);

  const {
    requestReceivedData,
    requestReceivedLoading,
    connectsCountData,
    connectsCountLoading,
    connectsPendingData,
    connectsPendingLoading,
    likesCountData,
    likesCountLoading,
    endOfData,
    initialLoading,
    paginationLoading,
  } = useSelector((state) => state.myMaterials);
  //useType
  const { UserTypeValue } = useSelector((state) => state.user);
  //Free Leads Count
  const { freeLeadsData, freeLeadsLoading } = useSelector(
    (state) => state.freeLeads
  );

  //Subsciption Leads Count
  const { purchasedLeads, leadsRemaining, selectedSubscription } = useSelector(
    (state) => state.purchasedLeads.material
  );

  const [freeListingCount, setFreeListingCount] = useState(null);
  const [isDeletePopUpopen, setIsDeletePopupOpen] = useState(false);
  const [isEditPopUpopen, setIsEditPopupOpen] = useState(false);

  const [isRemovePopUpOpen, setIsRemovePopUpOpen] = useState(false);

  const [addToMarketplacePopupOpen, setAddToMarketplacePopupOpen] =
    useState(false);

  const [selectedMaterialId, setSelectedMaterialId] = useState(null);

  const [foundMaterialTypeId, setFoundmaterialTypeId] = useState(null);

  useEffect(() => {
    dispatch(clearStepperIndex());
    dispatch(clearBasicDetails());
    // getMaterialData();
  }, []);

  useEffect(() => {
    getMaterialData();
  }, []);

  const getMaterialData = async () => {
    const fetchData = async () => {
      const data = await getMaterialStore();
      const foundItem = data.find(
        (item) => item.MaterialTypeValue === MaterialTypeValue
      );
      if (foundItem) {
        setFoundmaterialTypeId(foundItem.MaterialTypeId);
        dispatch(myMaterialsInitialDataThunk(foundItem.MaterialTypeId));
        dispatch(
          getMaterialRequestReceivedCountByIdThunk(foundItem.MaterialTypeId)
        );
        dispatch(getMaterialConnectsCountByIdThunk(foundItem.MaterialTypeId));
        dispatch(
          getMaterialConnectsPendingCountByIdThunk(foundItem.MaterialTypeId)
        );
      } else {
        setFoundmaterialTypeId(null);
        navigate("/materials-type-store-list");
      }
    };
    fetchData();
  };

  useEffect(() => {
    if (!freeLeadsData || freeLeadsData.length <= 0) {
      if (UserTypeValue.toLowerCase() === "Company".toLowerCase()) {
        dispatch(getFreeLeadsForCompanyThunk());
      } else {
        dispatch(getFreeLeadsForIndividualThunk());
      }
    } else {
      freeSubscriptionCount();
    }
  }, [freeLeadsData, UserTypeValue]);

  useEffect(() => {
    dispatch(getMaterialLeadCheckThunk());
  }, []);

  const freeSubscriptionCount = () => {
    const materialListingData = freeLeadsData.find(
      (category) =>
        category.SubscriptionTypeValue === "Contact Based" &&
        category.ServiceTypeValue === "Material Listing"
    );
    setFreeListingCount({
      leadsRemaining: materialListingData.ContactRemaining,
      totalLeads: materialListingData.FreeLeadsProvided,
      serviceTypeId: materialListingData.ServiceTypeId,
    });
    return {
      leadsRemaining: materialListingData.ContactRemaining,
      totalLeads: materialListingData.FreeLeadsProvided,
    };
  };

  const onhandleNavigate = () => {
    dispatch(clearStepperIndex());
    navigate(`/add-material/${convertToBase64(MaterialTypeValue)}`, {});
  };

  const handleDeleteUserMaterial = async (id) => {
    try {
      const data = await deleteMaterialDetails(convertToBase64(id));
      if (data?.Status === 204) {
        toast({
          status: "success",
          description: "Material deleted successfully!",
          position: "top-right",
          isClosable: true,
        });
        dispatch(myMaterialsInitialDataThunk(foundMaterialTypeId));
      } else {
        toast({
          status: "error",
          description: "failed to delete!",
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (error) {
      return error;
    }
  };

  const handleAddToMarketplace = (selectedType) => {
    if (selectedType) {
      const leadType = selectedType.toLowerCase();
      switch (leadType) {
        case "free":
          handleFreeTransaction();
          break;
        case "subscription":
          handleSubscriptionTransaction();
          break;
        default:
          break;
      }
    }
  };

  const handleFreeTransaction = async () => {
    const payload = {
      ServiceTypeId: freeListingCount?.serviceTypeId,
      FreeLeadConsumed: 1,
    };
    const data = convertToFormData(payload);
    try {
      const response = await materialFreeReedemTransaction(data);
      if (response?.Status === 201) {
        handleMaterialMarketplacePost();
      } else {
      }
    } catch (error) {
      throw error;
    }
  };
  const handleSubscriptionTransaction = async () => {
    const reedeemPayload = [
      {
        TransactionId: selectedSubscription?.E9TransactionId,
        UserSubsServicePurchasedId:
          selectedSubscription?.UserSubsServicePurchasedId,
        ServiceId: selectedSubscription?.ServiceTypeId,
        LeadPurchased: selectedSubscription?.PurchasedCount,
        LeadRedeemed: 1,
        LeadRemaining: selectedSubscription?.LeadRemaining - 1,
      },
    ];

    try {
      const data = await materialSubscriptionReedemTransaction(
        JSON.stringify(reedeemPayload)
      );
      if (data?.data?.Status === 201) {
        handleMaterialMarketplacePost();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleMaterialMarketplacePost = async () => {
    const data = {
      MaterialTypeId: foundMaterialTypeId,
      MaterialId: selectedMaterialId,
    };
    const payload = convertToFormData(data);
    try {
      const data = await postMaterialMarketplace(payload);
      if (data?.Status === 201) {
        toast({
          title: "Material added suceesfully",
          position: "top-right",
          status: "success",
        });
        window.location.reload();
      } else {
        toast({
          title: data?.Message?.FailMessage,
          position: "top-right",
          status: "error",
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const handleRemoveFromMarketplace = async (materialId) => {
    try {
      const data = await removeMaterialFromMarketplace(
        foundMaterialTypeId,
        materialId
      );
      if (data?.Status === 204) {
        toast({
          title: "",
          description: `Material removed from marketplace`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        dispatch(updateRemoveFromMarketPlace({ MaterialId: materialId }));
      } else {
        toast({
          title: "",
          description: `Failed`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {}
  };
  const renderMyMaterialCards = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((material) => {
          return (
            <MaterialStoreCard
              key={uuid()}
              imageUrl={
                material.ImageList ? JSON.parse(material.ImageList) : []
              }
              IsOriginal={material.IsOriginal}
              IsVerified={material.IsVerified}
              ProductName={material.ProductName}
              ProductNumber={material.ProductNumber}
              EquipmentTypeValue={material.EquipmentTypeValue}
              OEMName={material.OEMName}
              ModelName={material.ModelName}
              Location={material.Location}
              MaxPriceRange={material.MaxPriceRange}
              MinPriceRange={material.MinPriceRange}
              MaterialUniqueId={material.MaterialUniqueId}
              IsOnMarketplace={material.IsOnMarketplace}
              onCardClick={() => {
                navigate(
                  `/material-details-page/${convertToBase64(
                    material.MaterialId
                  )}`,
                  {
                    state: {
                      material: material,
                    },
                  }
                );
              }}
              onDelete={() => {
                setSelectedMaterialId(material.MaterialId);
                setIsDeletePopupOpen(true);
              }}
              onEdit={() => {
                setSelectedMaterialId(material.MaterialId);
                setIsEditPopupOpen(true);
              }}
              onAddMarketPlace={
                material.IsOnMarketplace
                  ? () => {
                      setSelectedMaterialId(material.MaterialId);
                      setIsRemovePopUpOpen(true);
                    }
                  : () => {
                      // dispatch(openQrModal());
                      setSelectedMaterialId(material.MaterialId);
                      setAddToMarketplacePopupOpen(true);
                    }
              }
            />
          );
        })
      ) : (
        <p className="my-material-type__card-container--empty-msg">
          No Material store added yet
        </p>
      );
    return renderJsx;
  };

  return (
    // <NewSideMenuLayout>
    //   <QrPopUp />
    //   <AddToMarketplacePopUp
    //     onCloseButtonClick={() => setAddToMarketplacePopupOpen(false)}
    //     freeListingCount={
    //       freeListingCount && freeListingCount.totalLeads
    //         ? freeListingCount.leadsRemaining
    //         : 0
    //     }
    //     subscriptionListingCount={leadsRemaining ? leadsRemaining : 0}
    //     isOpen={addToMarketplacePopupOpen}
    //     onSubmitButtonClick={(value) => {
    //       handleAddToMarketplace(value);
    //       setAddToMarketplacePopupOpen(false);
    //     }}
    //   />
    //   <SinglePurposePopup
    //     isOpen={isRemovePopUpOpen}
    //     iconUrl={editPopUpIcon}
    //     bodyText="Are you sure you want to remove material from marketplace?"
    //     continueAction={async () => {
    //       await handleRemoveFromMarketplace(selectedMaterialId);
    //       setSelectedMaterialId(null);
    //       setIsRemovePopUpOpen(false);
    //     }}
    //     cancelAction={() => {
    //       setSelectedMaterialId(null);
    //       setIsRemovePopUpOpen(false);
    //     }}
    //   />
    //   <SinglePurposePopup
    //     isOpen={isDeletePopUpopen}
    //     iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/my-business/materials/delete-popup.svg`}
    //     bodyText="Are you sure you want to delete the material?"
    //     continueAction={async () => {
    //       await handleDeleteUserMaterial(selectedMaterialId);
    //       setSelectedMaterialId(null);
    //       setIsDeletePopupOpen(false);
    //     }}
    //     cancelAction={() => {
    //       setSelectedMaterialId(null);
    //       setIsDeletePopupOpen(false);
    //     }}
    //   />
    //   <SinglePurposePopup
    //     isOpen={isEditPopUpopen}
    //     iconUrl={editPopUpIcon}
    //     bodyText="Are you sure you want to edit the equipment?"
    //     cancelAction={() => {
    //       setIsEditPopupOpen(false);
    //     }}
    //     continueAction={() => {
    //       navigate(
    //         `/add-material/${convertToBase64(
    //           MaterialTypeValue
    //         )}/?mode=edit&materialId=${convertToBase64(selectedMaterialId)}`
    //       );
    //     }}
    //   />
    //   <Box>
    //     <Flex margin={"22px 33px"} direction={"column"}>
    //       <Flex justifyContent={"space-between"}>
    //         <div>
    //           <p className="my-material-type__heading">
    //             {MaterialTypeValue} Store Details
    //           </p>
    //           <p className="my-material-type__sub-heading">
    //             Add your {MaterialTypeValue} and list them on Marketplace
    //           </p>
    //         </div>

    //         <button
    //           className="my-material-type__add-button"
    //           onClick={onhandleNavigate}
    //         >
    //           + Add
    //         </button>
    //       </Flex>
    //       <Box width={"1230px"} overflow={"scroll"}>
    //         <Flex>
    //           {/*analytics ProgressBarCard*/}

    //           <Flex gap="1em" width={"600px"}>
    //             <ProgressBarCard
    //               title="Free Listings Remaining"
    //               colorVariant="gradient"
    //               totalCount={
    //                 freeListingCount && freeListingCount.totalLeads
    //                   ? freeListingCount.totalLeads
    //                   : 0
    //               }
    //               progressCount={
    //                 freeListingCount && freeListingCount.leadsRemaining
    //                   ? freeListingCount.leadsRemaining
    //                   : 0
    //               }
    //             />

    //             <ProgressBarCard
    //               title="Subscription Listings Remaining"
    //               progressCount={leadsRemaining ? leadsRemaining : 0}
    //               totalCount={purchasedLeads ? purchasedLeads : 0}
    //               colorVariant="green"
    //             />
    //           </Flex>

    //           {/* analytics cards */}
    //           <Flex textAlign={"center"} gap={"1em"} marginLeft={"1em"}>
    //             <AnalyticsCard
    //               width="172px"
    //               height="100%"
    //               isLoading={requestReceivedLoading}
    //               analytics={requestReceivedData ? requestReceivedData : 0}
    //               description={"Requests Received on Marketplace"}
    //             />

    //             <AnalyticsCard
    //               width="172px"
    //               height="100%"
    //               isLoading={connectsCountLoading}
    //               analytics={connectsCountData ? connectsCountData : 0}
    //               description={"Connects  on Marketplace"}
    //             />

    //             <AnalyticsCard
    //               width="172px"
    //               height="100%"
    //               isLoading={connectsPendingLoading}
    //               analytics={connectsPendingData ? connectsPendingData : 0}
    //               description={"Connects Pending"}
    //             />

    //             <AnalyticsCard
    //               width="172px"
    //               height="100%"
    //               isLoading={likesCountLoading}
    //               analytics={likesCountData ? likesCountData : 0}
    //               description={"Likes on Rental Marketplace"}
    //             />
    //           </Flex>
    //         </Flex>
    //       </Box>
    //     </Flex>

    //     <Box maxH={"380px"} overflowY={"scroll"}>
    //       <MarketplaceHoc
    //         dataArray={initialData ? initialData : []}
    //         cardsRenderFunction={renderMyMaterialCards}
    //         isNewNavbar={true}
    //         endOfData={endOfData}
    //         initialLoading={initialLoading}
    //         paginationLoading={paginationLoading}
    //         loadMoreAction={() => {
    //           dispatch(
    //             myMaterialsLoadMoreDataThunk(
    //               convertToBase64(foundMaterialTypeId)
    //             )
    //           );
    //         }}
    //       />
    //     </Box>
    //   </Box>
    // </NewSideMenuLayout>
    <V5SidebarLayout>
      <QrPopUp />
      <AddToMarketplacePopUp
        onCloseButtonClick={() => setAddToMarketplacePopupOpen(false)}
        freeListingCount={
          freeListingCount && freeListingCount.totalLeads
            ? freeListingCount.leadsRemaining
            : 0
        }
        subscriptionListingCount={leadsRemaining ? leadsRemaining : 0}
        isOpen={addToMarketplacePopupOpen}
        onSubmitButtonClick={(value) => {
          handleAddToMarketplace(value);
          setAddToMarketplacePopupOpen(false);
        }}
      />
      <SinglePurposePopup
        isOpen={isRemovePopUpOpen}
        iconUrl={editPopUpIcon}
        bodyText="Are you sure you want to remove material from marketplace?"
        primaryActionText={"Remove"}
        secondaryActionText={"Keep It"}
        continueAction={async () => {
          await handleRemoveFromMarketplace(selectedMaterialId);
          setSelectedMaterialId(null);
          setIsRemovePopUpOpen(false);
        }}
        cancelAction={() => {
          setSelectedMaterialId(null);
          setIsRemovePopUpOpen(false);
        }}
      />
      <SinglePurposePopup
        isOpen={isDeletePopUpopen}
        iconUrl={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/my-business/materials/delete-popup.svg`}
        bodyText="Are you sure you want to delete this material?"
        primaryActionText={"Delete"}
        secondaryActionText={"Keep It"}
        continueAction={async () => {
          await handleDeleteUserMaterial(selectedMaterialId);
          setSelectedMaterialId(null);
          setIsDeletePopupOpen(false);
        }}
        cancelAction={() => {
          setSelectedMaterialId(null);
          setIsDeletePopupOpen(false);
        }}
      />
      <SinglePurposePopup
        isOpen={isEditPopUpopen}
        iconUrl={editPopUpIcon}
        bodyText="Are you sure you want to edit this material?"
        primaryActionText={"Edit"}
        secondaryActionText={"Go Back"}
        cancelAction={() => {
          setIsEditPopupOpen(false);
        }}
        continueAction={() => {
          navigate(
            `/add-material/${convertToBase64(
              MaterialTypeValue
            )}/?mode=edit&materialId=${convertToBase64(selectedMaterialId)}`
          );
        }}
      />
      <Box>
        <Flex margin={"22px 33px"} direction={"column"}>
          <Flex justifyContent={"space-between"}>
            <div>
              <p className="my-material-type__heading">
                {MaterialTypeValue} Store Details
              </p>
              <p className="my-material-type__sub-heading">
                Add your {MaterialTypeValue} and list them on Marketplace
              </p>
            </div>

            <button
              className="my-material-type__add-button"
              onClick={onhandleNavigate}
            >
              + Add
            </button>
          </Flex>
          <Box width="100%" overflow="scroll">
            <Flex>
              {/*analytics ProgressBarCard*/}

              <Flex gap="1em">
                <ProgressBarCard
                  title="Free Remaining"
                  colorVariant="gradient"
                  totalCount={
                    freeListingCount && freeListingCount.totalLeads
                      ? freeListingCount.totalLeads
                      : 0
                  }
                  progressCount={
                    freeListingCount && freeListingCount.leadsRemaining
                      ? freeListingCount.leadsRemaining
                      : 0
                  }
                />

                <ProgressBarCard
                  title="Subscription Remaining"
                  progressCount={leadsRemaining ? leadsRemaining : 0}
                  totalCount={purchasedLeads ? purchasedLeads : 0}
                  colorVariant="green"
                />
              </Flex>

              {/* analytics cards */}
              <Flex textAlign={"center"} gap={"1em"} marginLeft={"1em"}>
                <AnalyticsCard
                  // width="172px"
                  // height="100%"
                  isLoading={requestReceivedLoading}
                  analytics={requestReceivedData ? requestReceivedData : 0}
                  description={"Requests Received on Marketplace"}
                />

                <AnalyticsCard
                  // width="172px"
                  // height="100%"
                  isLoading={connectsCountLoading}
                  analytics={connectsCountData ? connectsCountData : 0}
                  description={"Connects  on Marketplace"}
                />

                <AnalyticsCard
                  // width="172px"
                  // height="100%"
                  isLoading={connectsPendingLoading}
                  analytics={connectsPendingData ? connectsPendingData : 0}
                  description={"Connects Pending"}
                />

                <AnalyticsCard
                  // width="172px"
                  // height="100%"
                  isLoading={likesCountLoading}
                  analytics={likesCountData ? likesCountData : 0}
                  description={"Likes on Rental Marketplace"}
                />
              </Flex>
            </Flex>
          </Box>
        </Flex>

        <MarketplaceHoc
          dataArray={initialData ? initialData : []}
          cardsRenderFunction={renderMyMaterialCards}
          isNewNavbar={true}
          endOfData={endOfData}
          initialLoading={initialLoading}
          paginationLoading={paginationLoading}
          loadMoreAction={() => {
            dispatch(
              myMaterialsLoadMoreDataThunk(convertToBase64(foundMaterialTypeId))
            );
          }}
        />
      </Box>
    </V5SidebarLayout>
  );
};
