export const linkData = [
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/jcb_aug_01_155.png`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/Bauma_conexpo_155X155.jpg`,
    link: "https://bcindia.com/en/trade-fair/exhibitors/application-prices/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/Bauma_conexpo_180x180.jpg`,
    link: "https://bcindia.com/en/trade-fair/exhibitors/application-prices/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/mahindra-earthmaster.jpg`,
    link: "https://bit.ly/3Idtar3",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/jcb-7years.jpg`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/my-crane%24%24in.my-crane.com.png`,
    link: "https://in.my-crane.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/24_02_M&M_MCE_12113590_PN_EQUIP-9-BANNER_180-X-180-PX.jpg`,
    link: "https://bit.ly/3Idtar3",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/JCB_Monsoon_Offer_180X180.png`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/Mkc.png`,
    link: "https://mkcinfrastructureltd.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/SSSEL.png`,
    link: "https://sssengineers.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/Steel_Carrier.png`,
    link: "https://www.steelcarriers.in",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/T&T.png`,
    link: "http://tandtinfra.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/United-Core-1.png`,
    link: "http://www.unitedinfracore.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/United-Core-2.png`,
    link: "http://www.unitedinfracore.com/",
  },
];

export const caroslData2 = [
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel2/jcb_aug_02_155.png`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/Bauma_conexpo_155X155.jpg`,
    link: "https://bcindia.com/en/trade-fair/exhibitors/application-prices/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/Bauma_conexpo_180x180.jpg`,
    link: "https://bcindia.com/en/trade-fair/exhibitors/application-prices/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/24_02_M&M_MCE_12113590_PN_EQUIP-9-BANNER_180-X-180-PX.jpg`,
    link: "https://bit.ly/3Idtar3",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel2/jcb-electric-scissors.jpg`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel2/my-crane%24%24in.my-crane.com.png`,
    link: "https://in.my-crane.com/",
  },

  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/T&T.png`,
    link: "http://tandtinfra.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel2/mahindra-earthmaster.jpg`,
    link: "https://bit.ly/3Idtar3",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/United-Core-1.png`,
    link: "http://www.unitedinfracore.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/United-Core-2.png`,
    link: "http://www.unitedinfracore.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/JCB_Monsoon_Offer_180X180.png`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/Mkc.png`,
    link: "https://mkcinfrastructureltd.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/SSSEL.png`,
    link: "https://sssengineers.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/Steel_Carrier.png`,
    link: "https://www.steelcarriers.in",
  },
];

export const caroslData3 = [
    {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel3/jcb_aug_03_155.png`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/Bauma_conexpo_155X155.jpg`,
    link: "https://bcindia.com/en/trade-fair/exhibitors/application-prices/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/Bauma_conexpo_180x180.jpg`,
    link: "https://bcindia.com/en/trade-fair/exhibitors/application-prices/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel3/jcb-wls-excon.jpg`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel3/mahindra-earthmaster.jpg`,
    link: "https://bit.ly/3Idtar3",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel3/my-crane%24%24in.my-crane.com.png`,
    link: "https://in.my-crane.com/",
  },

  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/SSSEL.png`,
    link: "https://sssengineers.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/Steel_Carrier.png`,
    link: "https://www.steelcarriers.in",
  },

  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/Mkc.png`,
    link: "https://mkcinfrastructureltd.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/SmallAdvertisments/Carousel1/24_02_M&M_MCE_12113590_PN_EQUIP-9-BANNER_180-X-180-PX.jpg`,
    link: "https://bit.ly/3Idtar3",
  },

  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/United-Core-2.png`,
    link: "http://www.unitedinfracore.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/United-Core-1.png`,
    link: "http://www.unitedinfracore.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/JCB_Monsoon_Offer_180X180.png`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },

  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/T&T.png`,
    link: "http://tandtinfra.com/",
  },
];
