import { Box, Button, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import { V5Sidebar } from "../../components/V5Sidebar/V5Sidebar";
import { V5Navbar } from "../../components/V5Navbar/V5Navbar";
import { NewDashboardNavBar } from "../NewSideMenuLayout/NewDashboardNavbar";
import { RouteStepper } from "../../components/Stepper/Stepper";
import { ArrowBackIcon } from "@chakra-ui/icons";

export const V5FormFooterLayout = ({
  title = "Form",
  subtitle = "",
  stepperIndex = 0,
  stepperArray = [],
  children,
  primaryButtonText = "Primary Button",
  primaryButtonClickAction = () => {},
  formId = "",
  secondaryButtonText = "",
  isLoading=false,
  secondaryButtonClickAction = () => {},
}) => {
  return (
    <Grid
      maxH="100vh"
      height="100%"
      minH="100vh"
      maxW="1536px"
      margin="0 auto"
      gridTemplateRows={
        subtitle
          ? [
              "80px 80px 80px 1fr 80px",
              "80px 80px 80px 1fr 80px",
              "80px 80px 80px 1fr 80px",
              "80px 80px 80px 1fr 80px",
              "80px 80px 80px 1fr 80px",
              "80px 80px 80px 1fr 80px",
            ]
          : [
              "80px 50px 80px 1fr 80px",
              "80px 50px 80px 1fr 80px",
              "80px 50px 80px 1fr 80px",
              "80px 50px 80px 1fr 80px",
              "80px 50px 80px 1fr 80px",
              "80px 50px 80px 1fr 80px",
            ]
      }
      gridTemplateColumns={[
        "repeat(4, 1fr)",
        "repeat(4, 1fr)",
        "repeat(4, 1fr)",
        "repeat(8, 1fr)",
        "repeat(10, 1fr)",
        "repeat(12, 1fr)",
      ]}
      gap="0.5rem"
    >
      <GridItem gridColumn="span 10" boxShadow="md">
        <NewDashboardNavBar />
      </GridItem>
      <GridItem
        gridRow="1/6"
        gridColumn={[0, 0, 0, 0, "span 2"]}
        display={["none", "none", "none", "none", "block"]}
      >
        <V5Sidebar />
      </GridItem>
      <GridItem gridRow="2/3" gridColumn="span 10" padding="0 52px">
        <Heading as="h1" fontSize="32px" fontWeight="700">
          {title}
        </Heading>
        {subtitle && (
          <Text as="h2" fontSize="20px" fontWeight="400" color="#767676">
            {subtitle}
          </Text>
        )}
      </GridItem>
      <GridItem
        gridRow="3/4"
        gridColumn="span 10"
        dropShadow="0px 10px 30px #2C2C2C14"
        bgColor="#FFFFFF"
        padding="0 52px"
      >
        {/* Stepper */}
        <RouteStepper array={stepperArray} activeIndex={stepperIndex} />
      </GridItem>
      <GridItem
        gridRow="4/5"
        gridColumn="span 10"
        overflowY="scroll"
        height="100%"
        padding="0 52px"
        paddingBottom="1rem"
      >
        {children}
      </GridItem>
      <GridItem
        gridColumn="span 10"
        boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1),0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
      >
        <Grid
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          gridTemplateColumns={secondaryButtonText ? "150px 1fr" : "1fr"}
        >
          {secondaryButtonText && (
            <GridItem>
              <Button
                boxShadow="none"
                dropShadow="none"
                flexBasis="80px"
                onClick={secondaryButtonClickAction}
                background="none"
                _hover={{ background: "none" }}
              >
                <ArrowBackIcon /> {secondaryButtonText}
              </Button>
            </GridItem>
          )}
          <GridItem justifySelf="center">
            <Button
              width="347px"
              variant="gradient"
              form={formId}
              type={formId ? "submit" : "button"}
              onClick={primaryButtonClickAction}
              isLoading={isLoading}
            >
              {primaryButtonText}
            </Button>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};
