import { Text } from "@chakra-ui/react";
import { CustomGeneralModal } from "../CustomGeneralModal/CustomGeneralModal";

export const SinglePurposePopup = ({
  iconUrl = "",
  isOpen = false,
  bodyText = "This is modal description",
  header,
  primaryActionText="Continue",
  secondaryActionText="No Thanks",
  cancelAction = () => {},
  continueAction = () => {},
}) => {
  return (
  
    <CustomGeneralModal
    isOpen={isOpen}
    hasCloseButton={true}
    iconUrl={iconUrl}
    onCloseButtonClick={cancelAction}
    primaryAction={continueAction}
    primaryActionText={primaryActionText}
    secondaryActionText={secondaryActionText}
    secondaryAction={cancelAction}
    heading={bodyText}
    textAlign="center" maxWidth={"300px"} margin={"0 50px"}
    />
  
  );
};
