import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Select,
  useToast,
  Text,
} from "@chakra-ui/react";
import { MultiAdDivider } from "../../../../layout/multiAdLayout/MultiAdLayout";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { convertToFormData } from "../../../../helpers/dataConversion/convertToFormData";
import { increasePageIndex } from "../../slice/docFlowSlice";
import { FormikInput } from "../../../../components/Input/FormikInput";
import { ImageUpload } from "../../../../components/ImageUpload/ImageUpload";

import "./UploadEquipmentSkill.css";
import { SelectCardWrapper } from "../../../../components/SelectCardWrapper/SelectCardWrapper";
import {
  MAINTENANCE_CERTIFIED_VALIDATIONS,
  MAINTENANCE_NOT_CERTIFIED_VALIDATIONS,
  OPERATOR_CERTIFIED_VALIDATIONS,
  OPERATOR_NOT_CERTIFIED_VALIDATIONS,
} from "./formValues";
import { dateConverstion } from "../../../../helpers/dataConversion/substring";
import {
  getEquipmentMakesById,
  getEquipmentTypes,
} from "../../../../services/equipments/equipments";
import {
  createUserEquipmentDetails,
  getAllIndustry,
  getMaintenanceExpertiseTypeList,
} from "../../../../services/documentFlow/documentFlow";
import EquipmentExpertiseModal from "./EquipmentExpertiseModal";
import { setSessionStorageItems } from "../../../../helpers/sessionStorage/sessionStorage";
import { V5MultiAdLayout } from "../../../../layout/multiAdLayout/V5MultiAdLayout";
import { V5Scroll } from "../../../../components/V5Scroll/V5Scroll";
import { useSearchParams } from "react-router-dom";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/naigationContants";

export const UploadEquipmentSkill = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const toast = useToast();
  const [allEquipmentTypes, setAllEquipmentTypes] = useState(null);
  const [industryTypeList, setIndustryTypeList] = useState(null);
  const [industryMakeList, setIndustryMakeList] = useState(null);
  const [expertiseAreaList, setExpertiseAreaList] = useState(null);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedOption, setSelectedOption] = useState("No");
  const [certificateValidDate, setCertificateValidDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // const [certificateImageFile, setCertificateImageFile] = useState(null);
  const [certificateIssuer, setCertificateIssuer] = useState(null);
  const [expertiseArea, setExpertiseArea] = useState(null);
  const [loading, setLoading] = useState(false)
  const [urlParam, setUrlParam] = useSearchParams()

  useEffect(() => {
    const id = sessionStorage.getItem("DEFERRED_MARKETPLACE");
    const urlAssetId = urlParam.get(NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID)
    if(id && !urlAssetId){
     setUrlParam(`${NAVIGATION_CONSTANTS.ANONYMOUS_ASSET_ID}=${id}`)
    }
   }, [])

  const getAllEquipmentTypes = async () => {
    const data = await getEquipmentTypes();
    setAllEquipmentTypes(data);
  };

  const getIndustryMake = async () => {
    const data = await getEquipmentMakesById(selectedEquipmentType);
    setIndustryMakeList(data);
  };

  const getIndustryType = async () => {
    const data = await getAllIndustry(selectedEquipmentType);

    if (data.Status) {
      setIndustryTypeList(data.Data.IndustriesList);
    }
  };
  const getAllExpertiseArea = async () => {
    const data = await getMaintenanceExpertiseTypeList();

    if (data.Status === 200) {
      setExpertiseAreaList(data.Data.MaintenanceExpertiseTypes);
    }
  };

  const postEquipmentSkills = async (body) => {
    const data = await createUserEquipmentDetails(body);

    if (data.Status === 201) {
      toast({
        status: "success",
        title: data.Message.SuccessMessage,
        isClosable: true,
        position: "top-right",
      });
      dispatch(increasePageIndex());
    } else {
      toast({
        status: "error",
        title: data.Message.FailMessage,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getAllEquipmentTypes();
    })();
  }, []);

  useEffect(() => {
    if (selectedEquipmentType) {
      (async () => {
        await getIndustryMake();
        await getIndustryType();
        await getAllExpertiseArea();
      })();
    } else {
      setIndustryMakeList(null);
      setIndustryTypeList(null);
    }
  }, [selectedEquipmentType]);

  const handleExpertise = (values, setFieldValue) => {
    setSessionStorageItems("MP_EXPERTISE_VALUES", values);
    setFieldValue("expertise", values);
    setExpertiseArea(values);
    setIsOpen(false);
  };
  return (
    <V5MultiAdLayout
      heading="Add Your Equipment Skill"
      subHeading="Helps us identify the right jobs for you."
      stepper={true}
    >
      <V5Scroll maxHeight="350px" overflowX="unset" height="">
        <Formik
          initialValues={
            userData.BusinessTypeValue.toLowerCase() ===
              "Maintenance Partner".toLowerCase() && selectedOption === "No"
              ? {
                  equipmentType: null,
                  industryType: null,
                  equipmentMake: null,
                  expertise: null,
                }
              : userData.BusinessTypeValue.toLowerCase() ===
                  "Maintenance Partner".toLowerCase() &&
                selectedOption === "Yes"
              ? {
                  equipmentType: null,
                  industryType: null,
                  equipmentMake: null,
                  expertise: null,
                  certificateNumber: null,
                  certificateIssuer: null,
                  certificateIssueDate: null,
                  certificateImage: null,
                }
              : "Operator Partner".toLowerCase() && selectedOption === "No"
              ? {
                  equipmentType: null,
                  industryType: null,
                  equipmentMake: null,
                }
              : {
                  equipmentType: null,
                  industryType: null,
                  equipmentMake: null,
                  certificateNumber: null,
                  certificateIssuer: null,
                  certificateIssueDate: null,
                  certificateImage: null,
                }
          }
          validationSchema={
            userData.BusinessTypeValue.toLowerCase() ===
              "Maintenance Partner".toLowerCase() && selectedOption === "No"
              ? MAINTENANCE_NOT_CERTIFIED_VALIDATIONS
              : userData.BusinessTypeValue.toLowerCase() ===
                  "Maintenance Partner".toLowerCase() &&
                selectedOption === "Yes"
              ? MAINTENANCE_CERTIFIED_VALIDATIONS
              : userData.BusinessTypeValue.toLowerCase() ===
                  "Operator Partner".toLowerCase() && selectedOption === "No"
              ? OPERATOR_NOT_CERTIFIED_VALIDATIONS
              : OPERATOR_CERTIFIED_VALIDATIONS
          }
          onSubmit={async (values) => {
             const body = {
              IndustryId : values.industryType,
              EquipmentTypeId: values.equipmentType,
              EquipmentMakeId: values.equipmentMake,
              IsNeverExpire: 1
             }
             if(selectedOption === "No"){
              body.AreYouCertified = 0
             }
             else{
              body.AreYouCertified = 1;
              body.CertificateNumber = values.certificateNumber;
              body.IssuerName = values.certificateIssuer;
              body.ValidDate = dateConverstion(values.certificateIssueDate);
              body.UserEquipmentCertificates = values.certificateImage;
             }
             if(userData.BusinessTypeValue.toLowerCase() === "Maintenance Partner".toLowerCase()){
              const expertiseArea = JSON.parse(sessionStorage.getItem("MP_EXPERTISE_VALUES"))
              const expertiseAreaIds = expertiseArea.map(item => item.MaintenanceExpertiseTypeId);
              body.ExpertiseArea = expertiseAreaIds.toString();
             }
             setLoading(true)
             await postEquipmentSkills(convertToFormData(body))
             setLoading(false)
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form id="equipment-skill-form" onSubmit={handleSubmit}>
              <Flex justifyContent={"center"} alignItems={"center"}>
                <Flex
                  wrap="wrap"
                  width="80%"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  gap="2em"
                >
                  <FormControl isRequired={true} maxW={"374"} width="100%">
                    <FormLabel>Equipment Type</FormLabel>
                    <Select
                      name="equipmentType"
                      h="52px"
                      border="1px solid #2c2c2c14"
                      focusBorderColor="#2c2c2c"
                      backgroundColor={
                        values.equipmentType ? "#ffff" : "#2c2c2c05"
                      }
                      maxW="374px"
                      onChange={(e) => {
                        setSelectedEquipmentType(e.target.value);
                        setFieldValue("equipmentType", e.target.value);
                      }}
                    >
                      <option value="" isDisabled>
                        Select
                      </option>
                      {allEquipmentTypes && allEquipmentTypes.length > 0
                        ? allEquipmentTypes.map((equipmentType) => (
                            <option
                              key={equipmentType.id}
                              value={equipmentType.id}
                            >
                              {equipmentType.value}
                            </option>
                          ))
                        : null}
                    </Select>
                  </FormControl>

                  <FormControl
                    isDisabled={!industryTypeList}
                    isRequired={true}
                    maxW={"374"}
                    width="100%"
                  >
                    <FormLabel>Industry Type</FormLabel>
                    <Select
                      name="industryType"
                      h="52px"
                      border="1px solid #2c2c2c14"
                      focusBorderColor="#2c2c2c"
                      backgroundColor={
                        values.industryType ? "#ffff" : "#2c2c2c05"
                      }
                      maxW="374px"
                      value={values.industryType}
                      onChange={(e) =>
                        setFieldValue("industryType", e.target.value)
                      }
                    >
                      <option value="" isDisabled>
                        Select
                      </option>
                      {industryTypeList && industryTypeList.length > 0
                        ? industryTypeList.map((industryType) => (
                            <option
                              key={industryType.IndustryId}
                              value={industryType.IndustryId}
                            >
                              {industryType.IndustryValue}
                            </option>
                          ))
                        : null}
                    </Select>
                  </FormControl>

                  <FormControl
                    isDisabled={!industryMakeList}
                    isRequired={true}
                    maxW="374px"
                    width="100%"
                  >
                    <FormLabel>Equipment Make</FormLabel>
                    <Select
                      h="52px"
                      border="1px solid #2c2c2c14"
                      focusBorderColor="#2c2c2c"
                      backgroundColor={
                        values.equipmentMake ? "#ffff" : "#2c2c2c05"
                      }
                      maxW="374px"
                      value={values.equipmentMake}
                      onChange={(e) =>
                        setFieldValue("equipmentMake", e.target.value)
                      }
                    >
                      <option value="" isDisabled>
                        Select
                      </option>
                      {industryMakeList && industryMakeList.length > 0
                        ? industryMakeList.map((make) => (
                            <option key={make.id} value={make.id}>
                              {make.value}
                            </option>
                          ))
                        : null}
                    </Select>
                  </FormControl>

                  {userData.BusinessTypeValue.toLowerCase() ===
                    "Maintenance Partner".toLowerCase() && (

                      <FormControl
                        isRequired={expertiseArea ? false : true}
                        maxW="374px"
                        width="100%"
                      >
                        <Flex direction="column" gap="0.50em">
                        <FormLabel>Expertise Area <Text as="span" color="#e53e3e" display={expertiseArea ? "inline" : "none"}>*</Text></FormLabel>
                        <Box
                          onClick={() => setIsOpen(true)}
                          position="relative"
                          cursor="pointer"
                        >
                          <Select
                            name="expertise"
                            isDisabled={!expertiseAreaList}
                            h="52px"
                            border="1px solid #2c2c2c14"
                            focusBorderColor="#2c2c2c"
                            backgroundColor={
                              values.expertise ? "#ffff" : "#2c2c2c05"
                            }
                            maxW="374px"
                            value={expertiseArea}
                            onChange={(e) =>
                              setFieldValue("expertise", e.target.value)
                            }
                            pointerEvents="none"
                            cursor="not-allowed"
                            opacity="1"
                          >
                            <option value="" isDisabled>
                              {expertiseArea
                                ? `${expertiseArea.length} Expertise Selected`
                                : "Select Expertise"}
                            </option>
                          </Select>
                        </Box>

                        <Flex gap="1em">
                          {expertiseArea && expertiseArea.length > 0 ? (
                        <Box
                        width="150.979px"
                        borderRadius="2.89px"
                        border="0.722px solid #E3E5E8"
                        background="#FEFEFE"
                        boxShadow="0px 2.89px 4.334px -0.722px rgba(0, 0, 0, 0.10), 0px 1.445px 2.89px -0.722px rgba(0, 0, 0, 0.06)"
                        textAlign="center"
                      >
                        <Text
                        color="#1A1E21"
                        fontSize="14px"
                        fontWeight="600"
                        >{ expertiseArea[0]?.MaintenanceExpertiseTypeValue}</Text>
                      </Box>
                          ) : null}
                          {expertiseArea && expertiseArea.length > 2 ? (
                        <Box
                        width="150.979px"
                        borderRadius="2.89px"
                        border="0.722px solid #E3E5E8"
                        background="#FEFEFE"
                        boxShadow="0px 2.89px 4.334px -0.722px rgba(0, 0, 0, 0.10), 0px 1.445px 2.89px -0.722px rgba(0, 0, 0, 0.06)"
                        textAlign="center"
                      >
                        <Text
                        color="#1A1E21"
                        fontSize="14px"
                        fontWeight="600"
                        >{ expertiseArea[1]?.MaintenanceExpertiseTypeValue}</Text>
                      </Box>
                          ) : null}
                          {expertiseArea && expertiseArea.length > 2 ? (
                        <Box
                        width="150.979px"
                        borderRadius="2.89px"
                        border="0.722px solid #E3E5E8"
                        background="#FEFEFE"
                        boxShadow="0px 2.89px 4.334px -0.722px rgba(0, 0, 0, 0.10), 0px 1.445px 2.89px -0.722px rgba(0, 0, 0, 0.06)"
                        textAlign="center"
                      >
                        <Text
                        color="#1A1E21"
                        fontSize="14px"
                        fontWeight="600"
                        >{ `+${expertiseArea.length - 2}`}</Text>
                      </Box>
                          ) : null}

                      </Flex>
                    </Flex>
                      </FormControl>

                  )}

                  {isOpen && (
                    <EquipmentExpertiseModal
                      isOpen={isOpen}
                      onButtonClick={() => setIsOpen(false)}
                      onSubmitButtonClick={(values) =>
                        handleExpertise(values, setFieldValue)
                      }
                      data={expertiseAreaList}
                    />
                  )}

                  <Flex direction={"column"} width={"100%"}>
                    <FormLabel>
                      Are you certified for this equipment&#63;
                    </FormLabel>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "12px",
                      }}
                    >
                      <SelectCardWrapper
                        width="178px"
                        height="52px"
                        padding="0"
                        fontWeight="600"
                        isSelected={selectedOption === "No" ? true : false}
                        onClick={(e) => {
                          setSelectedOption(
                            e.target.innerText.split(" ")[
                              e.target.innerText.split(" ").length - 1
                            ]
                          );
                        }}
                      >
                        &#10005; No
                      </SelectCardWrapper>
                      <SelectCardWrapper
                        width="178px"
                        height="52px"
                        padding="0"
                        fontWeight="600"
                        isSelected={selectedOption === "Yes" ? true : false}
                        onClick={(e) => {
                          setSelectedOption(
                            e.target.innerText.split(" ")[
                              e.target.innerText.split(" ").length - 1
                            ]
                          );
                        }}
                      >
                        &#10004; Yes
                      </SelectCardWrapper>
                    </div>

                    {selectedOption === "Yes" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "2em",
                            gap: "2em",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormikInput
                            label="Certificate Number"
                            placeholder="Example : CET00001"
                            name="certificateNumber"
                            isRequired={true}
                            maxLength={25}
                          />
                          <FormikInput
                            label="Name of Certificate Issuer"
                            isRequired={true}
                            placeholder="Enter issuer's name here ..."
                            name="certificateIssuer"
                            value={certificateIssuer}
                            onChange={(e) =>
                              setCertificateIssuer(e.target.value)
                            }
                            maxLength={30}
                          />
                          <FormikInput
                            label="Certificate Valid Date"
                            isRequired={true}
                            placeholder="Select Date"
                            type="date"
                            name="certificateIssueDate"
                            value={certificateValidDate}
                            onChange={(e) =>
                              setCertificateValidDate(e.target.value)
                            }
                          />

                          <ImageUpload
                            label="Certificate Image"
                            name="certificateImage"
                            helperText=""
                            formikProps={{
                              setFieldValue,
                              errors,
                              touched,
                            }}
                          />
                        </div>
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </form>
          )}
        </Formik>
      </V5Scroll>
      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          bgColor="#fff"
          h="78px"
          style={{ boxShadow: "0px -10px 15px #2c2c2c0f" }}
        >
          <Button
            type="submit"
            variant="gradient"
            fontWeight="semibold"
            width="400px"
            margin="0 auto"
            maxW="400px"
            form="equipment-skill-form"
            isLoading={loading}
          >
            Next
          </Button>
        </Flex>
      </Box>
    </V5MultiAdLayout>
  );
};
