import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginPopUp } from "../../../../../components/PopUps/LoginPopUp";
import { MarketplaceHeader } from "../../../../../../webapp/components/MarketplacHeader/MarketplaceHeader";
import { MarketplaceHoc } from "../../../../../../webapp/features/marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import logo from "../../../../../../assets/logo/equip9_logo_dark.png";
import { useNavigate, useParams } from "react-router-dom";
import { primaryKeyEncryption } from "../../../../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import { QrPopUp } from "../../../../../../webapp/components/ScanQrCodeModal/QrPopUp";
import fetchHandler from "../../../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../../../constants/apiConstants";
import { sparePartsInitialDeferredThunk } from "../thunk/sparePartsInitialDeferredThunk";
import { V5DashboardAssetCard } from "../../../../../../webapp/components/V5DashboardAssetCard/V5DashboardAssetCard";
import { EquipmentMarketplaceCard } from "../../../../../../webapp/components/EquipmentMarketplaceCard/EquipmentMarketplaceCard";
import { Box, Tooltip } from "@chakra-ui/react";
import { subString } from "../../../../../../webapp/helpers/dataConversion/substring";
import { sparePartsLoadMoreDeferredThunk } from "../thunk/sparePartsLoadMoreDeferredThunk";
import { setMaterialMarketplaceId } from "../slice/sparePartsDeferredSlice";
import BrandHeader from "../../../screens/BrandHeader";

const SparePartsDeferred = () => {
  const { materialTypeValue ,materialTypeId } = useParams();
  const { data, paginationCount } = useSelector(
    (state) => state.sparePartsDeferred
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setMaterialMarketplaceId(materialTypeId));
    dispatch(sparePartsInitialDeferredThunk(materialTypeId));
  }, []);

  const encryptAssetDetails = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        "/encryptAssetId",
        {},
        "",
        { MarketplaceType: "material", AssetId: assetId },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.Token);
    } catch (error) {
      console.log(error);
    }
  };

  const renderCards = (dataArray = []) => {
    const renderJsx =
      dataArray && Array.isArray(dataArray) && dataArray.length > 0 ? (
        dataArray.map((item) => {
          return (
            <Box as="button" onClick={() => {
              navigate(`/deferred/marketplace/materials-marketplace/material-detail/${materialTypeValue}/${primaryKeyEncryption(item?.MaterialId)}/${primaryKeyEncryption(item?.OwnerUserCredentialId)}`)
            }}>
              <V5DashboardAssetCard
                key={item?.AssetUniqueId}
                minW={["350px", "350px", "344px", "295px", "262px", "272px"]}
                maxW="350px"
                width="100%"
                height="272px"
                display="flex"
                flexDirection="column"
                boxShadow="0px 6px 15px -3px #00000029"
                cursor="pointer"
              >
                <V5DashboardAssetCard.Header width="100%" height="150px">
                  <V5DashboardAssetCard.Image
                    src={
                      item.ImageList &&
                      item.ImageList.length > 0 &&
                      `${process.env.REACT_APP_CDN_PVT_URL}${
                        JSON.parse(item?.ImageList)?.[0].ImageFileRef
                      }`
                    }
                  />
                </V5DashboardAssetCard.Header>
                <V5DashboardAssetCard.Body
                  display="flex"
                  height="122px"
                  flexDirection="column"
                  padding="1em 1.5em"
                  alignItems = "baseline"
                >
                  <V5DashboardAssetCard.Heading tooltip={item?.ProductName}>
                    {subString(18, item?.ProductName)}
                  </V5DashboardAssetCard.Heading>
                  <V5DashboardAssetCard.HighlightedText tooltip={`${item?.MinPriceRangeitem}/- To ${item?.MaxPriceRange}/-`}>
                    {`${item?.MinPriceRange}/- To ${item?.MaxPriceRange}/-`}
                  </V5DashboardAssetCard.HighlightedText>
                  <Tooltip label={item?.Location}>
                    <V5DashboardAssetCard.Location>
                      {subString(20, item?.Location)}
                    </V5DashboardAssetCard.Location>
                  </Tooltip>
                  <V5DashboardAssetCard.Text>
                    {item?.IsOriginal ? "Original" : "Not Original"}
                  </V5DashboardAssetCard.Text>
                </V5DashboardAssetCard.Body>
              </V5DashboardAssetCard>
            </Box>
          );
        })
      ) : (
        <></>
      );
    return renderJsx;
  };

  return (
    <>
      <QrPopUp />
      <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />
      <BrandHeader />
      <MarketplaceHeader
        heading={"Spare Parts Marketplace"}
        filterButtonRequired={false}
      />
      <MarketplaceHoc
        marketplaceName="Deferred Marketplace"
        marketplaceType={"Spare Parts Marketplace"}
        dataArray={data}
        // userImageBaseReference={
        //   data?. ? uemData.userImageReference : ""
        // }
        assetImageBaseReference={data ? data?.AssetImageBaseReference : ""}
        endOfData={data?.endOfData}
        loadMoreAction={() => {
          console.log("Load More Called", paginationCount);
          dispatch(sparePartsLoadMoreDeferredThunk(paginationCount));
        }}
        initialLoading={data?.initialLoading}
        paginationLoading={data?.paginationLoading}
        cardsRenderFunction={renderCards}
      />
    </>
  );
};

export default SparePartsDeferred;
