import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { openQrModal } from "../../../../../webapp/features/dashboard/slice/dashboardSlice";
import { ReactSVG } from "react-svg";
import { useNavigate, useParams } from "react-router-dom";
import fetchHandler from "../../../../../webapp/services/fetchHandler";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { primaryKeyDecryption } from "../../../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { DetailsCard } from "../../../../../webapp/components/DetailsCard/DetailsCard";
import { LoginPopUp } from "../../../../components/PopUps/LoginPopUp";

const OpenEquipmentMarketplace = () => {
  const { assetId ,category ,subcategory} = useParams();
  const dispatch = useDispatch();
  const [assetData, setAssetData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const decryptedId = primaryKeyDecryption(assetId);
  const navigate = useNavigate();

  const encryptAssetDetails = async (assetId) => {
    try {
      const { data } = await fetchHandler(
        "/encryptAssetId",
        {},
        "",
        { MarketplaceType: category, SubCategory: subcategory, AssetId: decryptedId },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.Token);
    } catch (error) {
      console.log(error);
    }
  };

  const getMarketplaceDetails = async () => {
    const category = "equipment";
    const id = primaryKeyDecryption(assetId);
    const { data } = await fetchHandler(
      "/deferredLogin/marketplace",
      { category, id },
      "",
      "",
      API_CONSTANTS.HTTP_GET,
      { isAuthRequired: false }
    );
    setAssetData(data.Data);
  };

  const getCurrentYear = () => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  };

  const handleDeferred = async() =>{
    await encryptAssetDetails(decryptedId);
    setModalOpen(true);

  }
  useEffect(() => {
    getMarketplaceDetails();
  }, []);

  return (
    <>
          <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE",encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE",encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />

      <div style={{ margin: "0 52px", marginTop: "110px" }}>
        <Box maxH="510px" h="510px" overflowY="scroll" padding="1em 1.5em">
          <div className="assets-header-card-container">
            <div
              style={{
                width: "430px",
                border: "1px solid #EEEEEE",
              }}
            >
               <Swiper
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  style={{ height: "226px", width: "430px" }}
                >
                 
                  {assetData?.AssetVideoDetails &&
                  Object.keys(assetData?.AssetVideoDetails).length > 0 ? (
                    Object.entries(assetData?.AssetVideoDetails).map(
                      ([key, value], index) => (
                        <SwiperSlide key={index}>
                          <video controls preload="none" width="100%">
                            <source
                              src={`${process.env.REACT_APP_CDN_PVT_URL}${assetData?.AssetVideoBaseReference}${value}`}
                            />
                          </video>
                        </SwiperSlide>
                        
                      )
                    )
                  ) : (
                    <></>
                  )}

                
                  {assetData &&
                  Object.keys(assetData?.AssetImageDetails).length > 0 ? (
                    Object.entries(assetData?.AssetImageDetails).map(
                      ([key, value], index) => (
                        <SwiperSlide key={index}>
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "fill",
                            }}
                            alt="image"
                            src={`${process.env.REACT_APP_CDN_PVT_URL}${assetData?.AssetImageBaseReference}${value}`}
                            className="dealership-header-card-image"
                          />
                        </SwiperSlide>
                       
                      )
                    )
                  ) : (
                    <></>
                  )}
                </Swiper>
            </div>

            <div className="assets-body-card-container">
              <div className="assets-body-card-container-left">
                <div>
                  <h1 className="assets-card-text">
                    {assetData && assetData.AssetDetails
                      ? assetData.AssetDetails.EquipmentTypeValue
                      : "Equipment Type"}
                  </h1>
                </div>
              </div>

              {/* <div class="assets-body-card-container-right">
              <div>
                {assetData ? (
                  <Like
                    isLiked={
                      assetData?.E9MarketPlaceDetails?.LikeStatus
                        ? true
                        : false
                    }
                    height="20px"
                    width=""
                    onClick={(val) => handleLike(val)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div> */}
            </div>
          </div>

          <div>
            <div>
              <Box margin="0 auto">
                <Flex alignItems="center">
                  <Flex w="100%" alignItems="center" gap="0.5em">
                    <Box minWidth="max-content">
                      <Text
                        color="#011f6b"
                        fontWeight="semibold"
                        fontSize="2xl"
                      >
                        Equipment Details
                      </Text>
                    </Box>
                    <Flex alignItems="center" w="100%">
                      <Divider borderColor="#011f6b" borderWidth="0.1em" />
                    </Flex>
                  </Flex>
                </Flex>
                <Box>
                  <Flex
                    gap="2em"
                    flexDirection="row"
                    overflowX="scroll"
                    padding="1em 0 1em 1em"
                  >
                    <DetailsCard
                      heading={
                        assetData && assetData.AssetDetail
                          ? assetData?.AssetDetails?.EquipmentTypeValue
                          : ""
                      }
                      description="Equipment Type"
                    />
                    <DetailsCard
                      heading={
                        assetData ? assetData?.AssetDetails?.OEMName : ""
                      }
                      description="Equipment Make"
                    />
                    <DetailsCard
                      heading={
                        assetData ? assetData?.AssetDetails?.ModelName : ""
                      }
                      description="Equipment Model"
                    />
                    <DetailsCard
                      heading={`${
                        getCurrentYear() -
                        assetData?.AssetDetails?.AssetManufacturingYear
                      } Years`}
                      description="Equipment Age"
                    />
                    <DetailsCard
                      heading={
                        assetData
                          ? assetData?.AssetDetails?.AssetManufacturingYear
                          : ""
                      }
                      description="Manufacturing Year"
                    />
                    <DetailsCard
                      heading={
                        assetData
                          ? assetData?.AssetDetails?.AssetCurrentMeterReading
                          : ""
                      }
                      description="Meter Reading / Hours of Working"
                    />
                  </Flex>
                </Box>
              </Box>

              <Box margin="0 auto">
                <Flex alignItems="center">
                  <Flex w="100%" alignItems="center" gap="0.5em">
                    <Box minWidth="max-content">
                      <Text
                        color="#011f6b"
                        fontWeight="semibold"
                        fontSize="2xl"
                      >
                        Equipment Location
                      </Text>
                    </Box>
                    <Flex alignItems="center" w="100%">
                      <Divider borderColor="#011f6b" borderWidth="0.1em" />
                    </Flex>
                  </Flex>
                </Flex>
                <Box>
                  <Flex
                    gap="2em"
                    flexDirection="row"
                    overflowX="scroll"
                    padding="1em 0 1em 1em"
                  >
                    <DetailsCard
                      heading={`${
                        assetData?.AssetDetails?.CityName
                          ? assetData?.AssetDetails?.CityName + ","
                          : ""
                      } ${
                        assetData?.AssetDetails?.StateName
                          ? assetData?.AssetDetails?.StateName + ","
                          : ""
                      } ${
                        assetData?.AssetDetails?.CountryName
                          ? assetData?.AssetDetails?.CountryName
                          : ""
                      } ${
                        assetData?.AssetDetails?.AssetLocationZipCode
                          ? "," + assetData?.AssetDetails?.AssetLocationZipCode
                          : ""
                      }`}
                      description="Equipment Location"
                    />
                  </Flex>
                </Box>
              </Box>

              {/* Insurance */}

              <Box margin="0 auto">
                <Flex alignItems="center">
                  <Flex w="100%" alignItems="center" gap="0.5em">
                    <Box minWidth="max-content">
                      <Text
                        color="#011f6b"
                        fontWeight="semibold"
                        fontSize="2xl"
                      >
                        Insurance Details
                      </Text>
                    </Box>
                    <Flex alignItems="center" w="100%">
                      <Divider borderColor="#011f6b" borderWidth="0.1em" />
                    </Flex>
                  </Flex>
                </Flex>
                <Box>
                  <Flex
                    gap="2em"
                    flexDirection="row"
                    overflowX="scroll"
                    padding="1em 0 1em 1em"
                  >
                    <DetailsCard
                      heading={
                        assetData?.AssetDetails?.InsuranceTypeValue &&
                        assetData?.AssetDetails?.InsuranceTypeValue.toLowerCase() ===
                          "Yes".toLowerCase()
                          ? "Yes"
                          : "No"
                      }
                      description="Active Status"
                    />
                    <DetailsCard
                      heading={
                        assetData?.AssetDetails?.AssetInsuranceValidityDate
                      }
                      description="Valid Upto"
                    />
                  </Flex>
                </Box>
              </Box>

              {/* PURCHASE DETAILS */}

              <Box margin="0 auto">
                <Flex alignItems="center">
                  <Flex w="100%" alignItems="center" gap="0.5em">
                    <Box minWidth="max-content">
                      <Text
                        color="#011f6b"
                        fontWeight="semibold"
                        fontSize="2xl"
                      >
                        Purchasing Details
                      </Text>
                    </Box>
                    <Flex alignItems="center" w="100%">
                      <Divider borderColor="#011f6b" borderWidth="0.1em" />
                    </Flex>
                  </Flex>
                </Flex>
                <Box>
                  <Flex gap="2em" overflowX="scroll"  padding="1em 0 1em 1em">
                    <DetailsCard
                      heading={
                        assetData?.AssetPurchaseDetails?.IsUnderLoan
                          ? "Yes"
                          : "No"
                      }
                      description="Is Currently Under Loan ?"
                    />
                    <DetailsCard
                      heading={assetData?.AssetPurchaseDetails?.PurchasedYear}
                      description="Year"
                    />
                    <DetailsCard
                      heading={assetData?.AssetPurchaseDetails?.CountryName}
                      description="Registerd Country"
                    />
                    <DetailsCard
                      heading={assetData?.AssetPurchaseDetails?.StateName}
                      description="Registered State"
                    />
                    <DetailsCard
                      heading={
                        assetData?.AssetPurchaseDetails?.AssetOwnershipTypeValue
                      }
                      description="Ownership"
                    />
                    <DetailsCard
                      heading={
                        assetData?.AssetPurchaseDetails?.RentalShiftValue
                      }
                      description="Rental Shift"
                    />
                  </Flex>
                </Box>
              </Box>

              {/* ENGINE DETAILS PAGE */}

              <Box margin="0 auto">
                <Flex alignItems="center">
                  <Flex w="100%" alignItems="center" gap="0.5em">
                    <Box minWidth="max-content">
                      <Text
                        color="#011f6b"
                        fontWeight="semibold"
                        fontSize="2xl"
                      >
                        Engine Details
                      </Text>
                    </Box>
                    <Flex alignItems="center" w="100%">
                      <Divider borderColor="#011f6b" borderWidth="0.1em" />
                    </Flex>
                  </Flex>
                </Flex>
                <Box>
                  <Flex gap="2em" overflowX="scroll" padding="1em 0 1em 1em">
                    <DetailsCard
                      heading={
                        assetData?.AssetDetails?.EquipmentEngineMakeValue
                          ? assetData?.AssetDetails?.EquipmentEngineMakeValue
                          : "NA"
                      }
                      description="Engine Make"
                    />
                    <DetailsCard
                      heading={
                        assetData?.AssetDetails?.AssetChassisNumber
                          ? assetData?.AssetDetails?.AssetChassisNumber
                          : "NA"
                      }
                      description="Chassis Number"
                    />
                    <DetailsCard
                      heading={
                        assetData?.AssetDetails?.FuelTypeValue
                          ? assetData?.AssetDetails?.FuelTypeValue
                          : "NA"
                      }
                      description="Fuel Type"
                    />
                    <DetailsCard
                      heading={
                        assetData?.AssetDetails?.AssetAutoEngineShutdownId
                          ? "Yes"
                          : "No"
                      }
                      description="Auto Engine Shutdown"
                    />
                  </Flex>
                </Box>
              </Box>
            </div>
          </div>
        </Box>
      </div>

      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          maxHeight: "78px",
          boxShadow: "22px -3px 10px 0px #00000040",
          padding: "26px 73px 26px 73px",
        }}
      >
        <Button
          bgColor="transparent"
          outline="none"
          color={"#F79C00"}
          boxShadow="none"
          border="1px solid #F79C00"
          _hover={{ background: "transparent" }}
          onClick={handleDeferred}
        >
          Contact Owner
        </Button>
      </div>
    </>
  );
};

export default OpenEquipmentMarketplace;
