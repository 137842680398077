import { configureStore, compose} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react"

import { mtDataSlice } from "../features/global/mtData/slice/mtDataSlice";
import { AppSlice } from "../features/global/appConfig/slice/appConfigSlice";
import { registrationSlice } from "../features/registration/slice/registrationSlice";
import { otpSlice } from "../features/otp/slice/otpSlice";
import { loginSlice } from "../features/login/slice/loginSlice";
import { UserSlice } from "../features/global/user/slice/userSlice";
import { documentFlowSlice } from "../features/documentFlow/slice/docFlowSlice";
import { forgotPasswordSlice } from "../features/forgetPassword/slice/forgotPasswordSlice";
import { dashboardSlice } from "../features/dashboard/slice/dashboardSlice";
import { rentalEquipmentMarketplaceSlice } from "../features/rentalEquipmentMarketplace/slice/rentalEquipmentMartketplace";
import { operatorJobsNearMeSlice } from "../features/operatorJobs/slice/operatorJobsNearMeSlice";
import { maintenancePartnerJobsSlice } from "../features/maintenanceJobs/slice/maintenancePartnerJobsSlice";
import { operatorNearMeSlice } from "../features/operatorNearMe/slice/operatorNearMeSlice";
import { maintenancePartnerSlice } from "../features/maintenancePartnerNearMe/slice/maintenancePartnerSlice";
import {usedEquipmentMarketplaceSlice} from "../features/usedEquipmentMarketplace/slice/usedEquipmentMarketplaceSlice";
import { subscriptionSlice } from "../features/subscription/slice/subscriptionSlice";
import { e9ServiceSlice } from "../features/e9Services/slice/e9ServiceSlice";
import operatorSearchSlice from "../features/operatorSearch/slice/operatorSearchSlice";
import { dealershipMarketplaceSlice } from "../features/dealershipMarketplace/slice/dealershipMarketplaceSlice";
import { materialMarketplaceSlice } from "../features/MaterialMarketplace/slice/materialsMarketplaceSlice";
import { concreteMaterialMarketplaceSlice } from "../features/concreteMaterialMarketplace/slice/concreteMaterialMarketplaceSlice";
import { generatorsMaterialMarketplaceSlice } from "../features/generatorsMaterialMarketplace/slice/generatorsMaterialMarketplaceSlice";
import { oilMaterialMarketplaceSlice } from "../features/oilMaterialMarketplace/slice/oilMaterialMarketplaceSlice";
import { sparePartsMaterialMarketplaceSlice } from "../features/sparePartsMaterialMarketplace/slice/sparePartsMaterialMarketplace";
import { safetyEquipmentMaterialMarketplaceSlice } from "../features/safetyEquipmentMaterialMarketplace/slice/safetyEquipmentMaterialMarketplaceSlice" ;
import { myBusinessDealershipSlice } from "../features/MyBusinessDealershipDetail/slice/myBusinessDealershipSlice";
// import {equipmentListingSlice }from "../features/equipmentListing/slice/equipmentListingSlice";
import maintenanceJobPostSlice from "../features/maintenanceJobPost/slice/maintenanceJobPostSlice";
import locationSlice from "../features/location/slice/slice"
import { postMaterialSlice } from "../components/MaterailStoreList/MyBusinessStore/AddMarketPlace/MarketPlaceSteps/slice/postMaterialSlice";
import { editPostMaterialSlice } from "../components/MaterailStoreList/MyBusinessStore/AddMarketPlace/MarketPlaceSteps/EditMarketPlace/EditMaterialSlice/EditMaterialSlice";
import jobsPostedSlice from "../features/postedJobs/slice/jobsPostedSlice";
import { myBusinessFinanceSlice } from "../features/MyBusinessFinanceDetail/Slice/MyBusinessFinanceSlice";
import { myStoreSlice } from "../features/myStore/slice/myStoreSlice";
import equipmentStoreSlice from "../features/equipmentStore/slice/equipmentStoreSlice";
import usedEquipmentStoreSlice from "../features/equipmentStore/slice/usedEquipmentStoreSlice";
import rentalEquipmentStoreSlice  from "../features/equipmentStore/slice/rentalEquipmentStoreSlice";
import { freeLeadsSlice } from "../features/freeLeads/slice/slice";
import  equipmentListingSlice  from "../features/equipmentListing/slice/equipmentListingSlice";
import { purchasedLeadsSlice } from "../features/purchasedLeadsCheck/slice/slice";
import { myLeadsSlice } from "../features/myLeads/Slice/myLeadsSlice";
import advertisementSlice from "../features/advertisement/slice/advertisementSlice";
import { materialsSlice } from "../features/materials/slice/materialsSlice";
import { postMaterialsSlice } from "../features/addMaterial/slice/postMaterialsSlice";
import { purchasedLeadSlice } from "../features/purchasedLeadsCheck/slice";
import { myMaterialsSlice } from "../features/myMaterials/slice/myMaterialsSlice";
import { uemDeferedSlice } from "../../website/Pages/Product/slice/uemDeferedSlice";
import { RemDeferedSlice } from "../../website/Pages/Product/slice/RemDeferedSlice";
import { opDeferredSlice } from "../../website/Pages/Product/slice/opDeferredSlice";
import { mpDeferredSlice } from "../../website/Pages/Product/slice/mpDeferredSlice";
import { dealershipDeferedSlice } from "../../website/Pages/Product/slice/dealershipDeferedSlice";
import { opJobDeferredSlice } from "../../website/Pages/Product/slice/opJobDeferredSlice";
import { mpJobDeferredSlice } from "../../website/Pages/Product/slice/mpJobDeferredSlice";
import { sparePartsDeferredSlice } from "../../website/Pages/openMarketplace/materials/sparePartsMaterial/slice/sparePartsDeferredSlice";
import { oilDeferredSlice } from "../../website/Pages/openMarketplace/materials/oilMaterial/slice/oilDeferredSlice";
import { concreteDeferredSlice } from "../../website/Pages/openMarketplace/materials/concreteMaterial/slice/concreteDeferredSlice";
import { generatorsDeferredSlice } from "../../website/Pages/openMarketplace/materials/generatorsMaterial/slice/generatorsDeferredSlice";
import { safetyDeferredSlice } from "../../website/Pages/openMarketplace/materials/safetyMaterial/slice/safetyDeferredSlice";
import { myBuisinessDealershipAssetSlice } from "../features/MyBusinessDealershipDetail/slice/myBuisinessDealershipAssetSlice";


export const store = configureStore({
    reducer : {
    masterData : mtDataSlice.reducer,
    appConfig : AppSlice.reducer,
    registration: registrationSlice.reducer,
    otp: otpSlice.reducer,
    login: loginSlice.reducer,
    user: UserSlice.reducer,
    documentFlow : documentFlowSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    dashboard : dashboardSlice.reducer,
    usedEquipmentMarketplace: usedEquipmentMarketplaceSlice.reducer,
    rentalEquipmentMarketplace: rentalEquipmentMarketplaceSlice.reducer,
    operatorJobsNearMe: operatorJobsNearMeSlice.reducer,
    operatorPartnerNearMe: operatorNearMeSlice.reducer,
    maintenanceJobsNearMe: maintenancePartnerJobsSlice.reducer,
    maintenancePartnerNearMe: maintenancePartnerSlice.reducer,
    subscription: subscriptionSlice.reducer,
    e9Services: e9ServiceSlice.reducer,
    operatorSearch: operatorSearchSlice,
    dealershipMarketplace: dealershipMarketplaceSlice.reducer,
    concreteMaterialMarketplace : concreteMaterialMarketplaceSlice.reducer,
    generatorsMaterialMarketplace : generatorsMaterialMarketplaceSlice.reducer,
    oilMaterialMarketplace : oilMaterialMarketplaceSlice.reducer,
    safetyEquipmentMaterialMarketplace : safetyEquipmentMaterialMarketplaceSlice.reducer,
    sparePartsMaterialMarketplace : sparePartsMaterialMarketplaceSlice.reducer,
    materialMarketplace : materialMarketplaceSlice.reducer,
    myBusinessDealership : myBusinessDealershipSlice.reducer,
    maintenanceSearch: maintenanceJobPostSlice,
    addEquipment: equipmentListingSlice,
    location: locationSlice,
    postMaterial : postMaterialSlice.reducer,
    postMaterials : postMaterialsSlice.reducer,
    EditpostMaterial:editPostMaterialSlice.reducer,
    jobsPosted: jobsPostedSlice,
    myFinance : myBusinessFinanceSlice.reducer,
    myStore : myStoreSlice.reducer,
    equipmentStore : equipmentStoreSlice,
    rentalEquipmentStore: rentalEquipmentStoreSlice,
    usedEquipmentStore: usedEquipmentStoreSlice,
    freeLeads: freeLeadsSlice.reducer,
    subscriptionLeadsCheck: purchasedLeadsSlice.reducer,
    myLeads : myLeadsSlice.reducer,
    advertisement:advertisementSlice,
    materials:materialsSlice.reducer,
    purchasedLeads : purchasedLeadSlice,
    myMaterials : myMaterialsSlice.reducer,

    // Deferred Login Start
    uemDeferred : uemDeferedSlice.reducer,
    remDeferred : RemDeferedSlice.reducer,
    opDeferred : opDeferredSlice.reducer,
    mpDeferred : mpDeferredSlice.reducer,
    dealershipDeferred : dealershipDeferedSlice.reducer,
    opJobDeferred : opJobDeferredSlice.reducer,
    mpJobDeferred : mpJobDeferredSlice.reducer,
    sparePartsDeferred : sparePartsDeferredSlice.reducer,
    oilDeferred : oilDeferredSlice.reducer,
    concreteDeferred : concreteDeferredSlice.reducer,
    generatorsDeferred : generatorsDeferredSlice.reducer,
    safetyDeferred : safetyDeferredSlice.reducer,
    // Deferred Login End

    dealershipAssests : myBuisinessDealershipAssetSlice.reducer,

    } 
})