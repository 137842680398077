import { Card } from "../Card/Card";
import { subString } from "../../helpers/dataConversion/substring";
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import defaultIcon from "../../../assets/webapp/marketplace/defaultAssetImage.png";
import { ReactSVG } from "react-svg";
import "./MaterialCard.css";
import { useSelector } from "react-redux";
import { Like } from "../Like/Like";
import { getReponseTypeByResponseId } from "../../helpers/buttonTextGeneration/buttonTextGeneration";
import { Tooltip } from "@chakra-ui/react";

export const MaterialCard = ({
  imageUrl,
  isVerified,
  isOriginal,
  productNumber,
  productName,
  equipmentTypeValue,
  oemName,
  modelName,
  minPriceRange,
  maxPriceRange,
  city,
  state,
  onClick,
  likeStatus,
  onLikeClick = () => {},
  ResponseTypeId,
  onContactSeller=()=>{},
  responseTypeDetailsByResponseIds,
  isMyAsset
}) => {
  const { currencyDetails } = useSelector((state) => state.user);

  const isAccepted = responseTypeDetailsByResponseIds[ResponseTypeId] === "Request Accepted";
  const isRejected = responseTypeDetailsByResponseIds[ResponseTypeId] === "Request Rejected";
  const isDisabled = ["Request Accepted", "Request Rejected"].includes(responseTypeDetailsByResponseIds[ResponseTypeId?.toString()])

  let contactBtnClassName = "material-card__contact-button"
  if(isMyAsset) contactBtnClassName += " my__asset";
  if(isRejected) contactBtnClassName += " rejected";
  if(isAccepted) contactBtnClassName += " accepted";
  if(ResponseTypeId === null || ResponseTypeId === 4) contactBtnClassName += " contact-seller";

  return (
    <>
      <Card type="materialCard">
        <div
          className="material-marketplace-card"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="material-marketplace-card__section">
            <div className="material-marketplace-card__image-overlay">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "98%",
                  margin: "0 auto",
                  marginTop: "0.15em",
                }}
              >
                <div
                  className="material-card__verification-badge"
                  style={{ background: isVerified ? "#319F0060" : "#D6000060" }}
                >
                  <p className="material-card__verification-badge__text">
                    {isVerified ? "Verified" : "Not Verified"}
                  </p>

                  {isOriginal && (
                    <p className="material-card__original">
                      {isOriginal ? "Original" : ""}
                    </p>
                  )}
                </div>
              </div>

              <div className="material-card__details-section">
                <p className="material-card__details-section__text">
                  {subString(
                    32,
                    ` Id:${[
                      productNumber,
                      productName,
                      equipmentTypeValue,
                      oemName,
                      modelName,
                    ].join(" • ")}`
                  )}
                </p>
              </div>
            </div>

            <div className="material-card__section--image">
              <img
                className="material-card__section--image__image"
                src={
                  imageUrl
                    ? imageUrl
                    : `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/DelarshipDefaultImage.png`
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = defaultIcon;
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 0.5em",
              paddingTop: "0.25em",
              height: "30%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                cursor: "pointer",
                gap: "0.25em",
              }}
              onClick={onClick}
            >
              <Tooltip label={productName?.length > 25 ? productName : null}>
              <p
                style={{
                  color: "#1E3264",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {productName
                  ? productName.length > 0
                    ? subString(25,productName)
                    : "NA"
                  : "NA"}
              </p>
              </Tooltip>
              <p
                style={{
                  fontSize: "16px",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "rgb(30, 50, 100)",
                }}
              >
                {currencyDetails?.CurrencyValue}
                {minPriceRange}{"/-"} &nbsp; to &nbsp;{" "}
                {currencyDetails?.CurrencyValue}
                {maxPriceRange}{"/-"}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", gap: "0.5em", marginTop: "0.35em" }}
              >
                <button>
                  <ReactSVG
                    src={shareIcon}
                    beforeInjection={(svg) => {
                      svg.setAttribute("style", "height: 20px");
                    }}
                  />
                </button>
                <button>
                  <Like isLiked={likeStatus} onClick={onLikeClick} />
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              margin: "0.5em",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p style={{ fontWeight: "700" }}>
                {city && state
                  ? `${city.substring(0, 10)}, ${state.substring(0, 11)}...`
                  : "NA"}
              </p>
            </div>

            <button
                className={contactBtnClassName}
                onClick={() => isDisabled ? null : onContactSeller()} 
                disabled={isDisabled}
              >
                {isMyAsset ? "Remove From Marketplace" : getReponseTypeByResponseId(responseTypeDetailsByResponseIds, ResponseTypeId)}
              </button>
          </div>
        </div>
      </Card>
    </>
  );
};
