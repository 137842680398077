import { store } from "../webapp/app/store";

let defaultLanguage = store.getState().masterData.defaultLanguage
let appLanguage = store.getState().appConfig?.language?.LanguageOptionTypeId

export let sidebarData = ""
export let businessOwnerIndividualData = ""
export let maintenancePartnerIndividualData = ""
export let maintenancePartnerCompanyData = ""
export let businessOwnerCompanyData = ""
export let equipmentOwnerIndividualData = ""
export let equipmentOwnerCompanyData = ""
export let guestData = ""
export let dashboardRedirection = ""
export let MyProfilesection = ""
export let myprofilesectionIndividual = ""
export let myProfilesectionEquipmentOwnerIndivdual = ""
export let myProfilesectionEquipmentOwnerCompany = ""
export let operatorPatner = ""
export let maintenancePatnerIndividual = ""
export let maintenancePatnerCompany = ""
export let OperatorPatner = ""
export let settingsSelection = ""
export let settingsSelectionIndividual = ""
export let tableHeading = ""
export let settingsData = ""
export let DrawerOptions = ""
export let materialList = ""
export let EquipmentList = ""
export let otherList = ""
export let workforce = ""


const updateStaticData = (defaultLanguage) => {

  // sidebarData
  sidebarData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.My_Leads}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Advertisement.svg`,
      profileName: `${defaultLanguage?.E_Services}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        "My Free Contract",
        "Purchased Subscription",
        `${defaultLanguage?.Buy_New_Subscription}`,
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [{
        addName: `${defaultLanguage?.pay_per_use}`,
        route: "",
      },
      {
        addName: `${defaultLanguage?.subscriptions}`,
        route: "/transactions/subscription",
      },
      {
        addName: `${defaultLanguage?.refund_payment}`,
        route: "",
      },],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Support.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  //  businessOwnerIndividualData
  businessOwnerIndividualData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.My_Leads}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.pay_per_use}`,
          route: "",
        },
        {
          addName: `${defaultLanguage?.subscriptions}`,
          route: "/transactions/subscription",
        },
        {
          addName: `${defaultLanguage?.refund_payment}`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  // maintenancePartnerIndividualData

  maintenancePartnerIndividualData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.Job_Status}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/job-status",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [{
        addName: `${defaultLanguage?.pay_per_use}`,
        route: "",
      },
      {
        addName: `${defaultLanguage?.subscriptions}`,
        route: "/transactions/subscription",
      },
      {
        addName: `${defaultLanguage?.refund_payment}`,
        route: "",
      },],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Quiz}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  // maintenancePartnerCompanyData

  maintenancePartnerCompanyData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.Job_Status}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/job-status",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.pay_per_use}`,
          route: "",
        },
        {
          addName: `${defaultLanguage?.subscriptions}`,
          route: "/transactions/subscription",
        },
        {
          addName: `${defaultLanguage?.refund_payment}`,
          route: "",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Quiz}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: `${defaultLanguage?.EQUIP9_Support}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];


  //  businessOwnerCompanyData

  businessOwnerCompanyData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.My_Leads}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.pay_per_use}`,
          route: "",
        },
        {
          addName: `${defaultLanguage?.subscriptions}`,
          route: "/transactions/subscription",
        },
        {
          addName: `${defaultLanguage?.refund_payment}`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  //  equipmentOwnerIndividualData

  equipmentOwnerIndividualData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.My_Leads}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [{
        addName: `${defaultLanguage?.pay_per_use}`,
        route: "",
      },
      {
        addName: `${defaultLanguage?.subscriptions}`,
        route: "/transactions/subscription",
      },
      {
        addName: `${defaultLanguage?.refund_payment}`,
        route: "",
      },],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  //  equipmentOwnerCompanyData

  equipmentOwnerCompanyData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.My_Leads}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.pay_per_use}`,
          route: "",
        },
        {
          addName: `${defaultLanguage?.subscriptions}`,
          route: "/transactions/subscription",
        },
        {
          addName: `${defaultLanguage?.refund_payment}`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  //  guestData

  guestData = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.My_Leads}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.Job_Status}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/job-status",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.pay_per_use}`,
          route: "",
        },
        {
          addName: `${defaultLanguage?.subscriptions}`,
          route: "/transactions/subscription",
        },
        {
          addName: `${defaultLanguage?.refund_payment}`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  dashboardRedirection = [
    {
      name: `${defaultLanguage?.Dashboard}`,
    },
    {
      name: "Equip9",
    },
  ];

  MyProfilesection = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/BusinessExpertise.svg`,
      name: `${defaultLanguage?.Business_Expertise}`,
      route: "/business-expertise",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/PersonalDetail.svg`,
      name: `${defaultLanguage?.Personal_Details}`,
      route: "/my-profile-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/ResidenceBuilding.svg`,
      name: "Company Details",
      route: "/company-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/VerificationDetils.svg`,
      name: `${defaultLanguage?.Verification_Details}`,
      route: "/verification-detail",
    },
  ];

  myprofilesectionIndividual = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/BusinessExpertise.svg`,
      name: `${defaultLanguage?.Business_Expertise}`,
      route: "/business-expertise",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/PersonalDetail.svg`,
      name: `${defaultLanguage?.Personal_Details}`,
      route: "/my-profile-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/VerificationDetils.svg`,
      name: `${defaultLanguage?.Verification_Details}`,
      route: "/verification-detail",
    },
  ];

  myProfilesectionEquipmentOwnerIndivdual = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/BusinessExpertise.svg`,
      name: `${defaultLanguage?.Business_Expertise}`,
      route: "/business-expertise",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/PersonalDetail.svg`,
      name: `${defaultLanguage?.Personal_Details}`,
      route: "/my-profile-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/VerificationDetils.svg`,
      name: `${defaultLanguage?.Verification_Details}`,
      route: "/verification-detail",
    },
  ];

  myProfilesectionEquipmentOwnerCompany = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/BusinessExpertise.svg`,
      name: `${defaultLanguage?.Business_Expertise}`,
      route: "/business-expertise",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/PersonalDetail.svg`,
      name: `${defaultLanguage?.Personal_Details}`,
      route: "/my-profile-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/ResidenceBuilding.svg`,
      name: "Company Details",
      route: "/company-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/VerificationDetils.svg`,
      name: `${defaultLanguage?.Verification_Details}`,
      route: "/verification-detail",
    },
  ];

  operatorPatner = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/BusinessExpertise.svg`,
      name: `${defaultLanguage?.Your_Role}`,
      route: "/business-expertise",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/PersonalDetail.svg`,
      name: `${defaultLanguage?.Personal_Details}`,
      route: "/my-profile-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/WorkExpirence.svg`,
      name: "Work Experience",
      route: "/work-expirence",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/EquipmentDetils.svg`,
      name: `${defaultLanguage?.Equipment_Details}`,
      route: "/equipment-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/LiceseDetils.svg`,
      name: `${defaultLanguage?.License_Details}`,
      route: "/license-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Education.svg`,
      name: `${defaultLanguage?.Education_Details}`,
      route: "/operatorpatner-education-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/VerificationDetils.svg`,
      name: `${defaultLanguage?.Verification_Details}`,
      route: "/verification-detail",
    },
  ];

  maintenancePatnerIndividual = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/BusinessExpertise.svg`,
      name: `${defaultLanguage?.Business_Expertise}`,
      route: "/business-expertise",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/PersonalDetail.svg`,
      name: `${defaultLanguage?.Personal_Details}`,
      route: "/my-profile-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/WorkExpirence.svg`,
      name: "Work Experience",
      route: "/work-expirence",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/EquipmentDetils.svg`,
      name: `${defaultLanguage?.Equipment_Details}`,
      route: "/equipment-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/LiceseDetils.svg`,
      name: `${defaultLanguage?.License_Details}`,
      route: "/license-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Education.svg`,
      name: `${defaultLanguage?.Education_Details}`,
      route: "/operatorpatner-education-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/VerificationDetils.svg`,
      name: `${defaultLanguage?.Verification_Details}`,
      route: "/verification-detail",
    },
  ];

  // mP
  maintenancePatnerCompany = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/BusinessExpertise.svg`,
      name: `${defaultLanguage?.Business_Expertise}`,
      route: "/business-expertise",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/PersonalDetail.svg`,
      name: `${defaultLanguage?.Personal_Details}`,
      route: "/my-profile-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/ResidenceBuilding.svg`,
      name: "Company Details",
      route: "/company-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/EquipmentDetils.svg`,
      name: `${defaultLanguage?.Equipment_Details}`,
      route: "/equipment-details",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/VerificationDetils.svg`,
      name: `${defaultLanguage?.Verification_Details}`,
      route: "/verification-detail",
    },


  ]

  OperatorPatner = [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      profileName: `${defaultLanguage?.Dashboard}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      profileName: `${defaultLanguage?.Profile}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      profileName: `${defaultLanguage?.Job_Status}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/job-status",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Advertisement.svg`,
      profileName: `${defaultLanguage?.E_Services}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      profileName: `${defaultLanguage?.My_Subscription}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.My_free_contacts}`,
          route: "/subscription/my-free-contacts"
        },
        {
          addName: `${defaultLanguage?.purchased_subscriptions}`,
          route: "/subscription/purchased-subscriptions"
        },
        {
          addName: `${defaultLanguage?.Buy_New_Subscription}`,
          route: "/subscription/subscription-type"
        }
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      profileName: `${defaultLanguage?.My_Transactions}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      additionalOptions: [
        {
          addName: `${defaultLanguage?.pay_per_use}`,
          route: "",
        },
        {
          addName: `${defaultLanguage?.subscriptions}`,
          route: "/transactions/subscription",
        },
        {
          addName: `${defaultLanguage?.refund_payment}`,
          route: "",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Quiz}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      profileName: `${defaultLanguage?.Refer_a_friend}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      profileName: `${defaultLanguage?.Settings}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      profileName: `${defaultLanguage?.EQUIP9_Support}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      profileName: `${defaultLanguage?.Legal_Policy}`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      additionalOptions: [
        {
          addName: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          addName: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          addName: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          addName: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      profileName: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ];

  // Settings Redirection
  settingsSelection = [
    {
      name: `${defaultLanguage?.PRIVACY}`,
      route: "/privacy-settings",
    },
    {
      name: `${defaultLanguage?.APP_SECURITY}`,
      route: "/change-password",
    },
  ];

  settingsSelectionIndividual = [
    {
      name: `${defaultLanguage?.PRIVACY}`,
      route: "/privacy-settings",
    },
    {
      name: `${defaultLanguage?.APP_SECURITY}`,
      route: "/change-password",
    },
  ];

  tableHeading = [
    {
      name: `${defaultLanguage?.E_Services}`,
    },
    {
      name: `${defaultLanguage?.used}`,
    },
    {
      name: "Free Available",
    },
    {
      name: "New Purchased",
    },
  ];

  // ###################### Setting  #############################

  settingsData = [
    {
      id: 1,
      settingHeroIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/account.png`,
      settingHeading: `${defaultLanguage?.PRIVACY}`,
      arrowIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/rightArrow.png`,
      settingRoute: "/privacy-settings"
    },

    {
      id: 2,
      settingHeroIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/appSecurity.png`,
      settingHeading: `${defaultLanguage?.APP_SECURITY}`,
      arrowIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/rightArrow.png`,
      settingRoute: "/change-password"
    },
    {
      id: 3,
      settingHeroIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/account.png`,
      settingHeading: `${defaultLanguage?.ACCOUNT}`,
      arrowIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/rightArrow.png`,
      settingRoute: "/delete-account"
    },
    {
      id: 4,
      settingHeroIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/language.png`,
      settingHeading: "Language",
      arrowIcon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/icons/e9-security/rightArrow.png`,
      settingRoute: "/change-language"
    }
  ];

  DrawerOptions = [
    {
      id: 1,
      OptionName: `${defaultLanguage?.Home}`,
      Route: "/",
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/Home_Drawer.svg`
    },
    {
      id: 2,
      OptionName: "About Us",
      Route: "/about",
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/Drawer.svg`
    },
    {
      id: 3,
      OptionName: "Product",
      Route: "/our-product",
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/OurProduct.svg`
    },
    {
      id: 4,
      OptionName: "Contact",
      Route: "/contact",
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/ContactUs.svg`
    },
    {
      id: 4,
      OptionName: "Blog",
      Route: "/our-blog",
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/Blog.svg`
    },
    {
      id:5,
      OptionName:"Login",
      Route:"/login/login-form",
      icon:`${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/Login.svg`
    },
    {
      id:6,
      OptionName:"Register",
      Route:"/welcome/select-language",
      icon:`${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/DrawerIcon/Register.svg`
    }

  ];

  materialList = [
    {
      id: 1,
      name: `${defaultLanguage?.spare_parts}`,
      icon: "",
      route: ""
    },
    {
      id: 2,
      name: `${defaultLanguage?.Oil}`,
      icon: "",
      route: ""
    },
    {
      id: 3,
      name: `${defaultLanguage?.Concrete}`,
      icon: "",
      route: ""
    },
    {
      id: 4,
      name: `${defaultLanguage?.Generators}`,
      icon: "",
      route: ""
    },
    {
      id: 5,
      name: "Safety Equipment",
      icon: "",
      route: ""
    },
  ];

  EquipmentList = [
    {
      id: 1,
      name: `${defaultLanguage?.Used_Equipment}`,
      icon: "",
      route: "used-equipment"
    },
    {
      id: 2,
      name: `${defaultLanguage?.Rental_Equipment}`,
      icon: "",
      route: "rental-equipment"
    },
    {
      id: 3,
      name: `${defaultLanguage?.dealerships}`,
      icon: "",
      route: "dealership"
    }
  ];

  otherList = [
    {
      id: 1,
      name: "Industry Requirements",
      icon: "",
      route: ""
    },
  ];

  workforce = [
    {
      id: 1,
      name: `${defaultLanguage?.jobs_posted}`,
      icon: "",
      route: ""
    },
    {
      id: 2,
      name: "Owners",
      icon: "",
      route: ""

    }
  ]

}

export const clientList = [
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/16.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/1.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/14.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/10.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/8.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/11.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/3.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/21.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/5.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/20.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/7.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/19.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/4.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/2.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/6.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/12.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/15.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/18.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/9.png`,
  },
  {
    clientName: `${process.env.REACT_APP_CDN_STATIC_URL}Website/About/Clients/17.png`,
  },
];


export const expirenceMonths = [
  {
    MonthrOfExperienceId: 0,
    MonthsOfExperienceValue: 0,
  },
  {
    MonthrOfExperienceId: 1,
    MonthsOfExperienceValue: 1,
  },
  {
    MonthrOfExperienceId: 2,
    MonthsOfExperienceValue: 2,
  },
  {
    MonthrOfExperienceId: 3,
    MonthsOfExperienceValue: 3,
  },
  {
    MonthrOfExperienceId: 4,
    MonthsOfExperienceValue: 4,
  },
  {
    MonthrOfExperienceId: 5,
    MonthsOfExperienceValue: 5,
  },
  {
    MonthrOfExperienceId: 6,
    MonthsOfExperienceValue: 6,
  },
  {
    MonthrOfExperienceId: 7,
    MonthsOfExperienceValue: 7,
  },
  {
    MonthrOfExperienceId: 8,
    MonthsOfExperienceValue: 8,
  },
  {
    MonthrOfExperienceId: 9,
    MonthsOfExperienceValue: 9,
  },
  {
    MonthrOfExperienceId: 10,
    MonthsOfExperienceValue: 10,
  },
  {
    MonthrOfExperienceId: 11,
    MonthsOfExperienceValue: 11,
  },
  {
    MonthrOfExperienceId: 12,
    MonthsOfExperienceValue: 12,
  },
];

export const companyDetails = [
  {
    name: "Subscription Details",
    route: "/subcription-details",
  },
  {
    name: "Company Member Details",
    route: "/company-member-details",
  },
];

export const tableMemberSubscription = [
  {
    name: "Member Name",
  },
  {
    name: "Designation",
  },
  {
    name: "verify"
  }, {
    name: "Active/Inactive"
  }
]

// ###################### Our-Product #############################
export const ourProduct = [
  {
    id: 1,
    type: "Operator Marketplace",
    headingText: "Operator Partner Marketplace",
    highlightedText: "IESC & OEM Certified Operator",
    description: "Search for a skilled heavy equipment operator from a pool of over 20,000+ operators proficient in operating machinery such as backhoes, dumpers, Tunnel Boring Machines, Cranes, Skid Steer Loaders, Wheel Loaders, Forklifts, Tower Cranes and Excavators. Our operator pool holds IESC and OEM certifications, ensuring top-notch expertise in the field.",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/operator.png`,
    marketplaceLink : "/deferred/marketplace/partners/operator"

  },
  {
    id: 2,
    type: "Mechanic Marketplace",
    headingText: "Mechanic Partner Marketplace",
    highlightedText: "ITI & Diploma Holder Mechanic",
    description: "Start your next search of heavy equipment mechanic from a pool of 5,000+ mechanic partners on our web app or mobile app. Partners have proficiency in repairing backhoes, dumpers, and excavators, along with expertise in welding, electrical work, and painting. Partners hold an ITI diploma, ensuring comprehensive skills for effective maintenance and repairs.",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/mechanic.png`,
    marketplaceLink : "/deferred/marketplace/partners/mechanic"

  },
  {
    id: 3,
    type: "Rental Marketplace",
    headingText: "Rental Marketplace",
    highlightedText: "Easy Heavy Equipment Rental",
    description: "Discover hassle-free heavy equipment rentals for JCB, Volvo, Cranes, Forklifts, and Dumpers all across India. Join the EQUIP9 network today to access a wide range of machinery and enjoy the convenience of secure document storage. Simplify your equipment rental needs with us!",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/rental.png`,
    marketplaceLink : "/deferred/marketplace/rental-marketplace"

   
  },
  {
    id: 4,
    type: "Used Marketplace",
    headingText: "Used Equipment Marketplace",
    highlightedText: "Connect With Verified Buyers & Sellers",
    description: "Eager to resell JCB, Volvo, Komatsu, L&T, Cranes, Forklifts, or Dumpers? Look no further! EQUIP9 offers lightning-fast resale of premium, refurbished equipment with zero brokerage and zero commission. Your documents are securely handled, providing a hassle-free experience. Join the EQUIP9 network today and capitalize on a seamless platform that prioritizes efficiency, transparency, and savings in the heavy equipment resale market. Unlock the full potential of your assets with EQUIP9!",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/used-equipment.png`,
    marketplaceLink : "/deferred/marketplace/used-marketplace"
  },
  {
    id: 5,
    type: "Dealership Marketplace",
    headingText: "Dealers Marketplace",
    highlightedText: "Create Virtual Dealership",
    description: "Locate dealers offering JCB, Volvo, Komatsu, L&T, Caterpillar, Cranes, Forklifts, and Dumpers, specializing in refurbished equipment. Elevate your business by establishing a virtual dealership, fostering growth in the heavy equipment industry. Connect with these dealers to access quality machinery and expand your reach and capabilities. Start building lucrative partnerships today!",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/delarship.png`,
    marketplaceLink : "/deferred/marketplace/dealership"
  },
  {
    id: 6,
    type: "Material Marketplace",
    headingText: "Material Marketplace",
    highlightedText: "Connect with Genuine Material Supplier",
    description: "Acquire authentic heavy equipment materials: attachments, spare parts, hydraulic oil, engine oil, ropes, generators, and more. Connect with top-quality product providers to ensure the optimal performance, safety, and durability of your machinery. Join our network today to access the genuine materials you need for your heavy equipment maintenance and operations.",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/material.png`,
    marketplaceLink : "/deferred/marketplace/materials"
  },
  {
    id: 7,
    type: "OperatorJob Marketplace",
    headingText: "JOBS FOR OPERATORS PARTNERS ",
    highlightedText: "Elevate Your Career as a Skilled Equipment Operator",
    description: "JOIN Equip9 today to access diverse job listings, certified roles, career growth opportunities, and enjoy a seamless application process.Equip9 connects skilled workers and mechanics to over 25,000+ job openings in the construction industry. Whether you're an IESC or OEM certified operator or an ITI certified mechanic, we have the right opportunity for Partners.",
    marketplaceLink : "/deferred/marketplace/jobs/operator"
    
  },
  {
    id: 8,
    type: "MaintenanceJob Marketplace",
    headingText: "JOBS FOR MAINTENANCE PARTNERS ",
    highlightedText: "Boost Your Career as an Expert Mechanic",
    description: "Equip9 offers numerous job opportunities for mechanics with ITI diplomas and specialized skills in maintaining and repairing construction equipment. From welding and electrical work to mechanical repairs, find positions that recognize and reward your expertise. Access diverse job listings, certified roles, opportunities for career growth, and enjoy a simple application process. Join Equip9 today.",
    marketplaceLink : "/deferred/marketplace/jobs/maintenance"
   
  },
  {
    id: 9,
    headingText: "E-Learning",
    highlightedText: "Join Equip9 Community Today",
    description: "Empower your operators, mechanics, and customers through our EQUIP9 community feature. Share informative videos and foster knowledge exchange. Enhance skills, stay updated, and connect with industry experts. Join us in creating a dynamic platform that promotes learning and growth in the heavy equipment sector.",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Product/eLearning.png`
  }

]
// ###################### Our-Blog #############################
export const ourBlog = [
  {
    id: 1,
    headingText: "Driving Growth: India's Construction Equipment",
    highlightedText: "Sector Set to Surge by 10% in FY24",
    description:
      "In a promising development for the Indian economy, the construction equipment sector is gearing up for substantial growth, with projections indicating a robust 10% expansion in the fiscal year 2023-24. This positive trajectory is underlined by various factors, reflecting not only the sector's resilience but also its pivotal role in propelling infrastructure development across the nation.",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Blog/bolgOne.png`,
  },
  {
    id: 2,
    headingText: "The Indian Heavy Equipment Market:",
    highlightedText: "5 Key Players and Future Trends",
    description: "We are almost entering the year 2024. Over the years, India has witnessed significant growth in its construction and infrastructure sectors, driving the demand for heavy machinery. We'll explore the key players shaping this dynamic market and the trends that define their success.",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Blog/blogTwo.png`,

  },
  {
    id: 3,
    headingText: "Forecasting the Workforce Landscape in the Heavy Equipment ",
    highlightedText: "Industry for 2024",
    description: "The heavy equipment industry's workforce dynamics are poised for transformation in the upcoming year of 2024. A confluence of technological advancements, shifting skill requirements, and industry demands is set to shape the workforce landscape, presenting both opportunities and challenges for stakeholders within this sector.",
    bannerImage: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Blog/blogThree.png`,
  },

]


export const searchOperatorBlock = [
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/EquipmentType.svg`,
    stats: "20000+",
    category: "Operators Registered",

  },
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/OperatorMarketPlace.svg`,
    stats: "27+",
    category: "Equipment Type",

  },
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/ZeroCast.svg`,
    stats: "10",
    category: "Free Search",
  },
];


export const ConnectDealerBlock = [
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/ListedEquipment.svg`,
    stats: "20000+",
    category: "Listed Equipments",

  },
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/Location.svg`,
    stats: "197",
    category: "Cities",

  },
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/handshake.svg`,
    stats: "5000+",
    category: "Business Owners",
  },
]


export const SerachSkilled = [
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/Maintenancetools.svg`,
    stats: "3780+",
    category: "Mechanics Registered",

  },
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/building.svg`,
    stats: "50+",
    category: "Maintenance Agencies",

  },
  {
    image: `${process.env.REACT_APP_CDN_STATIC_URL}Website/Home/MobileIcon/FreeSearch.svg`,
    stats: "10",
    category: "Free Search",
  },
]

export const managerDetails = [
  {
    id: 1,
    name: "Sakshi"
  },
  {
    id: 2,
    name: "Manager"
  }
]

export const localimageDetails = [
  {
    id: 1,
    name: "Front Image",
    src: `${localStorage.getItem(`photoType_1`)}`,
    alt: "Front Image"
  },
  {
    id: 2,
    name: "Back Image",
    src: `${localStorage.getItem(`photoType_2`)}`,
    alt: "Back Image"
  },
  {
    id: 3,
    name: "Other Image",
    src: `${localStorage.getItem(`photoType_3`)}`,
    alt: "Other Image"
  }
  , {
    id:4,
    name:"Left Image",
    src:`${localStorage.getItem(`photoType_4`)}`,
    alt:"Left Image"
  },
  {
    id:5,
    name:"Right Image",
    src:`${localStorage.getItem(`photoType_5`)}`,
    alt:"Right Image"
  }
];

export const weightDimension = [
  { id: 1, name: "KG" },
  { id: 2, name: "Tonnes" }
]

export const measureDimension = [
  { id: 1, name: "CM" },
  { id: 2, name: "M" }

]

export const jobDurationTypeList = [
  { id: 1, value: "Years" },
  { id: 2, value: "Months" }
]

/****check String */

const convertData = (dateTimeString) => {
  dateTimeString = dateTimeString.replace(/'/g, '');
  const parts = dateTimeString.split(', ');
  const datePart = parts[0];
  const timePart = parts[1].split('.')[0];
  const formattedDateTime = `${datePart}, ${timePart}`;
  return formattedDateTime;
}


export const jsonLog = (userId, LogType, PayloadValues, HttpMethods, routeName, apiEndPoint, message, stausCode, functionAction, data) => ({
  UserIp: sessionStorage.getItem('userIP'),
  timeStamp: convertData(new Date().toISOString()),
  userId: userId,
  LogType: LogType,
  logger: 'Kibana',
  PayloadValues: PayloadValues,
  HttpMethods: HttpMethods,
  route: routeName,
  apiEndPoint: apiEndPoint,
  message: message,
  stausCode: stausCode,
  context: {
    functionAction: functionAction,
    data: data
  }
});

/****Setting Ip into Session  */
export const storeUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    sessionStorage.setItem('userIP', data.ip);
  } catch (error) {
    console.error('Error fetching IP:', error);
  }
}


export const defaultEnglishJson = 
  {
    CountryId: 99,
    IsDefault: 1,
    IsDisabled: 0,
    JsonFileName: "english.json",
    LanguageOptionTypeId: 2,
    LanguageOptionValue: "ENGLISH",
    LanguageSysName: "English"
  }

  store.subscribe(() => {
    let getDefaultLanguage = store.getState().appConfig?.language?.LanguageOptionTypeId;
    const languageState = store.getState().masterData.defaultLanguage;
  
    if (getDefaultLanguage == null) {
      getDefaultLanguage = defaultEnglishJson?.LanguageOptionTypeId
      return; 
    }
  
    if (getDefaultLanguage !== appLanguage) {
      defaultLanguage = languageState;
      updateStaticData(defaultLanguage);
    } else {
      updateStaticData(languageState);
    }
  });
  







