import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { v4 as uuid } from "uuid";

import { DashboardNavBar } from "../../../components/DashboardNavBar/DashboardNavBar";
import { SideMenuLayout } from "../../../layout/sideMenuLayout/SideMenuLayout";
import {
  subString,
  maskMobileNumber,
} from "../../../helpers/dataConversion/substring";
import { NearMePartnerMarketplaceCard } from "../../../components/NearMePartnerMarketplaceCard/NearMePartnerMarketplaceCard";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { getInitialDataThunk } from "../thunks/getInitialDataThunk";
import { getLoadMoreDataThunk } from "../thunks/GetLoadMoreDataThunk";
import { FilterPopUp } from "../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { resetFilters, setFilterByCountry, setFilterByEquipmentType, setFilterByState, setFilterByVerificationStatus, setFilterModalOpen, setFilteredDataRequired } from "../slice/maintenancePartnerSlice";
import { getInitialFilteredDataThunk } from "../thunks/getInitialFilteredDataThunk";
import { getLoadMoreFilteredDataThunk } from "../thunks/getLoadMoreFilteredDataThunk";
import { MARKETPLACE_TYPES } from "../../../constants/e9Constants";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";


export const MaintenancePartnerNeaMeMarketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data,
    imageBaseUrl,
    initialLoading,
    paginationLoading,
    endOfData,
    filteredDataRequired,
    filterModalOpen,
    filterByEquipmentType,
    filterByVerificationStatus,
    filterByCountry,
    filterByState,
  } = useSelector((state) => state.maintenancePartnerNearMe);

  useEffect(() => {
    if(filteredDataRequired){
      dispatch(getInitialFilteredDataThunk())
    }
    else{
      dispatch(getInitialDataThunk());
    }
  }, [filteredDataRequired]);

  

  const filterFunction = (values) => {
    if (values.equipmentType) {
               dispatch(setFilterByEquipmentType(values.equipmentType));
             }
             if (values.country) {
               dispatch(setFilterByCountry(values.country));
             }
             if (values.state) {
               dispatch(setFilterByState(values.state));
             }
             if (values.verificationStatus) {
               dispatch(
                setFilterByVerificationStatus(values.verificationStatus)
               );
             }
             if (
               values.equipmentType ||
               values.country ||
               values.state ||
               values.verificationStatus
             ) {
              
               dispatch(setFilteredDataRequired(true));
             }
             else{
               dispatch(setFilteredDataRequired(false))
             }
             dispatch(getInitialFilteredDataThunk());
             dispatch(setFilterModalOpen(false));
 }

  const renderFunction = (array) => {
    const renderJsx =
      array && array.length > 0 ? (
        array.map((partner) => (
          <NearMePartnerMarketplaceCard
            key={uuid()}
            type="maintenance"
            certificateIssuerName={
              partner.CertificateIssuerName
                ? subString(25, partner.CertificateIssuerName.toString())
                : "N.A."
            }
            equipmentTypeValue={
              partner.EquipmentTypeValue
                ? subString(25, partner.EquipmentTypeValue)
                : "N.A."
            }
            experienceMonth={partner.ExperienceMonth}
            experienceYear={partner.ExperienceYear}
            imageBaseUrl={imageBaseUrl}
            isdCode={partner.IsdCode}
            mobileNumber={maskMobileNumber(partner.MobileNumber)}
            name={partner.PartnerName}
            profilePictureUrl={partner.ProfilePictureFileReference}
            rating={partner.OverAllRating}
            salary={partner.PerVisitCost}
            verificationStatus={partner.PartnerVerificationStatus}
            onClick={() => {
              navigate(
                `/near-me/MarketPlaceMP/Card/${partner.UserCredentialId}`
              );
            }}
            onContact={() => (dispatch(openQrModal()))}
          />
        ))
      ) : (
        <p>No data to display</p>
      );
    return renderJsx;
  };

  return (
    <SideMenuLayout>
      <DashboardNavBar />
      <QrPopUp />
      <FilterPopUp 
      marketplaceType={MARKETPLACE_TYPES.NEAR_ME_PARTNER_MAINTENANCE}
      isModalOpen={filterModalOpen}
      initialEquipmentValue={filterByEquipmentType}
      initialCountryValue={filterByCountry}
      initialStateValue={filterByState}
      initialVerificationValue={filterByVerificationStatus}
      onResetFilterClick={() => {
        dispatch(resetFilters())
      }}
      onSubmitButtonClick={filterFunction}
      onCloseButtonClick={() => {dispatch(setFilterModalOpen(false))}}
      />
      <div
        style={{
          position: "sticky",
          top: "69.5px",
          paddingRight: "52px",
          paddingLeft: "52px",
          paddingTop: "1em",
          zIndex: "1",
          background: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ fontSize: "26px", fontWeight: "700" }}>
            Maintenance Partner Near Me
          </h1>
          <button
            style={{
              borderRadius: "4px",
              color: "#1e3264",
              fontWeight: "600",
              fontSize: "18px",
              padding: "0.25em 0.5em",
              backgroundColor: filteredDataRequired ? "#FFEBCB" : "#fff",
              border: filteredDataRequired ? "1px solid #F79C00" : "",
            }}
            onClick={() => {
             dispatch(setFilterModalOpen(true))
            }}
          >
            {filteredDataRequired ? "Clear Filter(s)" : "Filter"}
          </button>
        </div>
      </div>
      <MarketplaceHoc
        marketplaceType="partner"
        dataArray={data}
        cardsRenderFunction={renderFunction}
        initialLoading={initialLoading}
        paginationLoading={paginationLoading}
        endOfData={endOfData}
        loadMoreAction={() => {
          filteredDataRequired ? dispatch(getLoadMoreFilteredDataThunk()) : dispatch(getLoadMoreDataThunk())
        }}
      />
    </SideMenuLayout>
  );
};
