import { ReactSVG } from "react-svg";
import { Card } from "../Card/Card";
import { VerificationBadge } from "../CardVerificationBadge/CardVerificationBadge";
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import "./JobMarketplaceCard.css";

export const JobMarketplaceCard = ({
  verificationText = "",
  verificationStatus = "",
  jobId = "",
  postedOn = "",
  name = "",
  location = "",
  money = "NA",
  cycle = "NA",
  onClick = () => {},
  onShareButtonClick = () => {},
}) => {
  return (
    <Card
      type="jobCard"
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
    >
      <div className="job-marketplace-card">
        <div className="job-marketplace-card__verification-badge-container">
          <VerificationBadge
            text={verificationText}
            type={verificationStatus}
          />
        </div>
        <div className="job-marketplace__container">
          <div>
            <p className="job-marketplace__text--dark">Job Id: {jobId}</p>
            <p className="job-marketplace__text--light">
              Posted on: {postedOn}
            </p>
            <p className="job-marketplace__text--dark">Job Name: {name}</p>
            <p className="job-marketplace__text--light">
              Job Location: {location}
            </p>
            <p className="job-marketplace-card__text--price">
              {money} / {cycle}
            </p>
          </div>
          <div>
            <ReactSVG
              src={shareIcon}
              beforeInjection={(svg) => {
                svg.setAttribute("style", "height: 27px");
              }}
              onClick={(e) => {
                onShareButtonClick();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
