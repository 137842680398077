import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Field, Formik } from "formik";
import {
  FormControl,
  FormLabel,
  Select,
  Flex,
  RadioGroup,
  Radio,
  Box,
} from "@chakra-ui/react";
import { MARKETPLACE_TYPES } from "../../../../constants/e9Constants";
import { getEquipmentMakesById, getEquipmentTypes } from "../../../../services/equipments/equipments";
import { getStatesById } from "../../../../services/states/states";
import { getAllE9DealershipMarketPlaceList, getDealershipEquipmentMakeList } from "../../../../services/dealershipMarketplace/dealershipMarketplace";
import { getLicenseTypeList } from "../../../../services/documentFlow/documentFlow";
import { getCitiesById } from "../../../../services/cities/cities";
import { CustomInputModal } from "../../../../components/CustomInputModal/CustomInputModal";
import { RadioParent } from "../../../../components/RadioParent/RadioParent";

export const FilterPopUp = ({
  onSubmitButtonClick = () => { },
  onResetFilterClick = () => { },
  onCloseButtonClick = () => { },
  isModalOpen = false,
  marketplaceType = "",
  initialEquipmentValue = "",
  initialEquipmentMakeValue = "",
  initialEquipmentTypeId = "",
  initialEquipmentMakeId = "",
  isOriginal = "",
  initialCountryValue = "",
  initialStateValue = "",
  initialCityValue = "",
  initialVerificationValue = "",
  initialLicenseValue = "",
}) => {
  const masterData = useSelector((state) => state.masterData);
  const userData = useSelector((state) => state.user);
  const [equipmentList, setEquipmentList] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [licenseTypesList, setLicenseTypesList] = useState(null);
  const [equipmentMakeList, setEquipmentMakeList] = useState(null);
  const [equipmentTypeIdList, setEquipmentTypeIdList] = useState(null);
  const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] = useState(null)
  const [equipmentMakeIdList, setEquipmentMakeIdList] = useState(null);
  const [selectedEquipmentMakeId, setSelectedEquipmentMakeId] = useState(null);
  const [isOriginalValue, setIsOriginalValue] = useState(null);

  const jobsInitialValues = (
    equipmentType = "",
    country = "",
    state = "",
    city = ""
  ) => ({ equipmentType, country, state, city });

  const operatorPartnerInitialValues = (
    equipmentType = "",
    verificationType = "",
    licenseType = ""
  ) => ({ equipmentType, verificationType, licenseType });

  const dealershipInitialValues = (
    equipmentMake = "",
    country = "",
    state = ""
  ) => ({ equipmentMake, country, state })

  const materialsFilterValues = (
    initialEquipmentTypeId = "",
    initialEquipmentMakeId = "",
    equipmentTypeId = "",
    equipmentMakeId = "",
    isOriginal = "",
    country = "",
    state = "",
  ) => ({ equipmentTypeId, equipmentMakeId, isOriginal, initialEquipmentTypeId, initialEquipmentMakeId , country,state })

  useEffect(() => {
    if (marketplaceType === MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR) {
      (async () => {
      
        const data = await getLicenseTypeList();

        if (data.Status) {
          setLicenseTypesList(data.Data.LicenseTypes);
        } else {
          setLicenseTypesList([]);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      (async () => {
     
        const data= await getStatesById(selectedCountry);
        setStateList(data);
        
      })();
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      (async () => {
    
        const data = await getCitiesById(selectedState)
        setCityList(data);
      })();
    }
  }, [selectedState]);

  useEffect(() => {
    (async () => {
      try {
      const data = await getAllE9DealershipMarketPlaceList(12,0);
        
      } catch (error) {
        
      }
      // setEquipmentMakeList("setEquipmentMakeList" ,data);
    })();
  }, []);


  // GET_EQUIPMENT_TYPE_ID
  useEffect(() => {
    getEquipmentTypes().then((data) => {
      setEquipmentTypeIdList(data);
    })
  }, []);

  // GET_EQUIPMENT_Make_ID_BY_Selected_EquipmentTypeID
  useEffect(() => {
    (async () => {
      try {
        const data = await getEquipmentMakesById(selectedEquipmentTypeId)
        setEquipmentMakeIdList(data)
      } catch (error) {

      }
    })()
  }, [selectedEquipmentTypeId, selectedEquipmentMakeId])

  useEffect(() => {
    (async () => {
      try {
        const data = await getDealershipEquipmentMakeList()
        if (data?.Status === 200) {
          setEquipmentMakeList(data?.Data?.EquipmentMakeList)
          return data
        }
      } catch (error) {
        // console.log("Error", error)       
      }
    })()
  }, [])


  return (
    <CustomInputModal
      isOpen={isModalOpen}
      isArrow={true}
      hasCloseButton={true}
      onCloseButtonClick={onCloseButtonClick}
      primaryActionText={"Apply Filters"}
      heading={<p style={{ color: "#002961" }}>Select Filter</p>}
      formId="filter-form"
      secondaryAction={onResetFilterClick}
      secondaryActionText={" Reset Filters"}
      icon={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/filter.svg`}
    >
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Formik
          initialValues={
            marketplaceType === MARKETPLACE_TYPES.NEAR_ME_JOBS
              ? {
                  equipmentType: initialEquipmentValue,
                  country: initialCountryValue,
                  state: initialStateValue,
                  verificationStatus: initialVerificationValue,
                }
              : marketplaceType === MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR
              ? operatorPartnerInitialValues(
                  initialEquipmentValue,
                  initialLicenseValue,
                  initialVerificationValue
                )
              : marketplaceType === MARKETPLACE_TYPES.DEARLERSHIPS_MARKETPLACE
              ? dealershipInitialValues(
                  initialEquipmentMakeValue,
                  initialCountryValue,
                  initialStateValue
                )
              : marketplaceType === MARKETPLACE_TYPES.MATERIALS_MARKETPLACE
              ? materialsFilterValues(
                  initialEquipmentTypeId,
                  initialEquipmentMakeId,
                  isOriginal,
                  initialCountryValue,
                  initialStateValue
                )
              : jobsInitialValues(
                  initialEquipmentValue,
                  initialCountryValue,
                  initialStateValue,
                  initialCityValue
                )
          }
          onSubmit={(values) => {
            onSubmitButtonClick(values);
          }}
          onReset={(values) => {
            values.equipmentType = "";
            values.equipmentMake = "";
            values.country = "";
            values.state = "";
            values.verificationStatus = "";
            values.license = "";
          }}
        >
          {({ values, handleSubmit, handleReset, setFieldValue }) => (
            <form
              id="filter-form"
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              <Flex direction={"column"} gap={"8px"} maxW={"350px"} w={"350px"}>
                {marketplaceType === MARKETPLACE_TYPES.MATERIALS_MARKETPLACE ? (
                  <FormControl>
                    <Flex direction={"column"} gap={"8px"}>
                      <Box>
                        <FormLabel fontSize={"14px"} fontWeight={"600"}>
                          Original Part
                        </FormLabel>
                        {/* <div
                          // className="filterRadio"
                          style={{
                            width: "100%",
                            height: "44px",
                            maxHeight: "44px",
                            fontSize: "20px",
                            display: "flex",
                            borderRadius: "4px",
                            outline: "1px solid #e2e8f0",
                            // outlineOffset: "2px",
                            alignItems: "center",
                            paddingLeft: "12px",
                          }}
                        >
                          <FormLabel
                            fontSize={"14px"}
                            fontWeight={"600"}
                            display="flex"
                            alignItems={"center"}
                            gap={"2px"}
                          >
                            <Field type="radio" name="isOriginal" value="1" />
                            Yes
                          </FormLabel>
                          <FormLabel
                            fontSize={"14px"}
                            fontWeight={"600"}
                            display="flex"
                            alignItems={"center"}
                            gap={"2px"}
                          >
                            <Field type="radio" name="isOriginal" value="0" />
                            No
                          </FormLabel>
                        </div> */}
                        <Field name="isOriginal">
                          {({ field, form }) => (
                            <RadioParent
                              name={field.name}
                              options={[
                                {
                                  id: 0,
                                  value: "NO",
                                },
                                {
                                  id: 1,
                                  value: "Yes",
                                },
                              ]}
                              maxWidth="350px"
                              minWidth="350px"
                              height="44px"
                              isFormik={true}
                              onChange={(value) => {
                                setFieldValue("isOriginal", value);
                              }}
                            />
                          )}
                        </Field>
                      </Box>

                      <Box>
                        <FormLabel fontSize={"14px"} fontWeight={"600"}>
                          Equipment Type
                        </FormLabel>
                        <Field
                          as={Select}
                          name="equipmentTypeId"
                          onChange={(e) => {
                            setFieldValue("equipmentTypeId", e.target.value);
                            setSelectedEquipmentTypeId(e.target.value);
                            // console.log("equipmentTypeId" ,e.target.value);
                          }}
                          fontWeight={"600"}
                          fontSize={"14px"}
                          h={"44px"}
                        >
                          <option
                            value=""
                            w={"350px"}
                            maxH="44px"
                            maxW={"350px"}
                          >
                            Select Equipment Type
                          </option>
                          {equipmentTypeIdList
                            ? equipmentTypeIdList.map((equipment) => (
                                <option
                                  value={equipment.id}
                                  key={equipment.value}
                                  w={"350px"}
                                  h={"44px"}
                                  maxW={"350px"}
                                >
                                  {equipment.value}
                                </option>
                              ))
                            : null}
                        </Field>
                      </Box>

                      <Box>
                        <FormLabel fontSize={"14px"} fontWeight={"600"}>
                          Equipment Make
                        </FormLabel>
                        <Field
                          as={Select}
                          name="equipmentMakeId"
                          onChange={(e) => {
                            setFieldValue("equipmentMakeId", e.target.value);
                            setSelectedEquipmentMakeId(e.target.value);
                            // console.log("equipmentMakeId" ,e.target.value);
                          }}
                          fontWeight={"600"}
                          fontSize={"14px"}
                          h={"44px"}
                        >
                          <option
                            value=""
                            w={"350px"}
                            h={"44px"}
                            maxW={"350px"}
                          >
                            Select Equipment Make
                          </option>
                          {equipmentMakeIdList
                            ? equipmentMakeIdList.map((equipment) => (
                                <option
                                  value={equipment.id}
                                  key={equipment.value}
                                  w={"350px"}
                                  h={"44px"}
                                  maxW={"350px"}
                                >
                                  {equipment.value}
                                </option>
                              ))
                            : null}
                        </Field>
                      </Box>
                    </Flex>
                  </FormControl>
                ) : null}
                {marketplaceType ===
                MARKETPLACE_TYPES.DEARLERSHIPS_MARKETPLACE ? (
                  <>
                    <FormControl>
                      <FormLabel fontSize={"14px"} fontWeight={"600"}>
                        Equipment Make
                      </FormLabel>
                      <Field
                        as={Select}
                        name="equipmentMake"
                        onChange={(e) => {
                          setFieldValue("equipmentMake", e.target.value);
                        }}
                        fontWeight={"600"}
                        fontSize={"14px"}
                        h="44px"
                      >
                        <option value="" w={"350px"} h={"44px"} maxW={"350px"}>
                          Select Equipment Make
                        </option>
                        {equipmentMakeList ? (
                          equipmentMakeList.map((equipment) => (
                            <option
                              value={equipment.EquipmentMakeId}
                              key={equipment.EquipmentMakeId}
                              w={"350px"}
                              h={"44px"}
                              maxW={"350px"}
                            >
                              {equipment.EquipmentMakeValue}
                            </option>
                          ))
                        ) : (
                          <FormControl>
                            <FormLabel fontSize={"14px"} fontWeight={"600"}>
                              EquipmentType
                            </FormLabel>
                            <Field
                              as={Select}
                              name="equipmentType"
                              onChange={(e) => {
                                setFieldValue("equipmentType", e.target.value);
                              }}
                              fontWeight={"600"}
                              fontSize={"14px"}
                            >
                              <option
                                value=""
                                w={"350px"}
                                h={"44px"}
                                maxW={"350px"}
                              >
                                Select Equipment Type
                              </option>
                              {equipmentList
                                ? equipmentList.map((equipment) => (
                                    <option
                                      value={equipment.EquipmentTypeValue}
                                      key={equipment.EquipmentTypeId}
                                      w={"350px"}
                                      h={"44px"}
                                      maxW={"350px"}
                                    >
                                      {equipment.EquipmentTypeValue}
                                    </option>
                                  ))
                                : null}
                            </Field>
                          </FormControl>
                        )}
                      </Field>
                    </FormControl>
                  </>
                ) : (
                  <></>
                )}

                {/* Equipment */}
                {marketplaceType === MARKETPLACE_TYPES.EQUIPMENT_MARKETPLACE ||
                marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR ||
                marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_PARTNER_MAINTENANCE ? (
                  <Box>
                    <FormLabel fontSize={"14px"} fontWeight={"600"}>
                      Equipment Type
                    </FormLabel>
                    <Field
                      as={Select}
                      name="equipmentType"
                      onChange={(e) => {
                        setFieldValue("equipmentType", e.target.value);
                        setSelectedEquipmentTypeId(e.target.value);
                      }}
                      fontWeight={"600"}
                      fontSize={"14px"}
                      h={"44px"}
                    >
                      <option value="" w={"350px"} h={"44px"} maxW={"350px"}>
                        Select Equipment Type
                      </option>
                      {equipmentTypeIdList
                        ? equipmentTypeIdList.map((equipment) => (
                            <option
                              value={equipment.value}
                              key={equipment.value}
                              w={"350px"}
                              h={"44px"}
                              maxW={"350px"}
                            >
                              {equipment.value}
                            </option>
                          ))
                        : null}
                    </Field>
                  </Box>
                ) : (
                  <></>
                )}

                {/* Country */}
                {marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR ||
                marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_PARTNER_MAINTENANCE ? (
                  <></>
                ) : (
                  <Box>
                    <FormLabel
                      fontSize={"14px"}
                      fontWeight={"700"}
                      color={"#002961"}
                    >
                      Location
                    </FormLabel>

                    <Flex gap="0.5em">
                      <FormControl>
                        <FormLabel fontSize={"14px"} fontWeight={"600"}>
                          Country
                        </FormLabel>
                        <Field
                          as={Select}
                          name="country"
                          onChange={(e) => {
                            if (e.target.value) {
                              const country = masterData.Countries.find(
                                (country) =>
                                  country.CountryName === e.target.value
                              );
                              setSelectedCountry(country.CountryId);
                              setCityList(null);
                              setFieldValue("country", e.target.value);
                              setFieldValue("state", "");
                              setFieldValue("city", "");
                            } else {
                              setCityList(null);
                              setFieldValue("country", "");
                              setFieldValue("state", "");
                              setFieldValue("city", "");
                              setCityList(null);
                            }
                          }}
                          fontWeight={"600"}
                          fontSize={"14px"}
                          h={"44px"}
                        >
                          <option
                            value=""
                            w={"350px"}
                            h={"44px"}
                            maxW={"350px"}
                          >
                            Select Country
                          </option>
                          {masterData && masterData.Countries
                            ? masterData.Countries.map((country) => (
                                <option
                                  value={country.CountryName}
                                  key={country.CountryId}
                                  w={"350px"}
                                  h={"44px"}
                                  maxW={"350px"}
                                >
                                  {country.CountryName}
                                </option>
                              ))
                            : null}
                        </Field>
                      </FormControl>
                      <FormControl isDisabled={!stateList}>
                        <FormLabel fontSize={"14px"} fontWeight={"600"}>
                          State
                        </FormLabel>
                        <Field
                          as={Select}
                          name="state"
                          onChange={(e) => {
                            if (e.target.value) {
                              const state = stateList.find(
                                (state) => state.value === e.target.value
                              );
                              setSelectedState(state.id);
                              setFieldValue("state", state.value);
                              setFieldValue("city", "");
                            } else {
                              setSelectedState(null);
                              setFieldValue("state", "");
                              setFieldValue("city", "");
                            }
                          }}
                          fontWeight={"600"}
                          fontSize={"14px"}
                          h={"44px"}
                        >
                          <option
                            value=""
                            w={"350px"}
                            h={"44px"}
                            maxW={"350px"}
                          >
                            {stateList ? "Select State" : "No States"}
                          </option>
                          {stateList
                            ? stateList.map((state) => (
                                <option
                                  value={state.value}
                                  key={state.id}
                                  w={"350px"}
                                  h={"44px"}
                                  maxW={"350px"}
                                >
                                  {state.value}
                                </option>
                              ))
                            : null}
                        </Field>
                      </FormControl>
                    </Flex>
                  </Box>
                )}

                {/* city */}
                {marketplaceType === MARKETPLACE_TYPES.NEAR_ME_JOBS ||
                marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_JOBS_MAINTENANCE ||
                marketplaceType === MARKETPLACE_TYPES.NEAR_ME_JOBS_OPEARTOR ? (
                  <FormControl isDisabled={!cityList}>
                    <FormLabel>City</FormLabel>
                    <Field
                      as={Select}
                      name="city"
                      onChange={(e) => {
                        setFieldValue("city", e.target.value);
                      }}
                    >
                      <option value="">
                        {cityList ? "Select City" : "No City List Found"}
                      </option>
                      {cityList
                        ? cityList.map((city) => (
                            <option value={city.value} key={city.id}>
                              {city.value}
                            </option>
                          ))
                        : null}
                    </Field>
                  </FormControl>
                ) : (
                  <></>
                )}

                {/* Licence Type */}
                {marketplaceType ===
                MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR ? (
                  <Box>
                    <FormControl isDisabled={!licenseTypesList}>
                      <FormLabel fontSize={"14px"} fontWeight={"600"}>
                        License Type
                      </FormLabel>
                      <Field
                        as={Select}
                        name="license"
                        onChange={(e) => {
                          setFieldValue("license", e.target.value);
                        }}
                        fontWeight={"600"}
                        fontSize={"14px"}
                        h="44px"
                      >
                        <option value="" w={"350px"} h={"44px"} maxW={"350px"}>
                          {licenseTypesList
                            ? "Select License Type"
                            : "No License List Found"}
                        </option>
                        {licenseTypesList
                          ? licenseTypesList.map((license) => (
                              <option
                                value={license.LicenseTypeValue}
                                key={license.LicenseTypeValue}
                                w={"350px"}
                                h={"44px"}
                                maxW={"350px"}
                              >
                                {license.LicenseTypeValue}
                              </option>
                            ))
                          : null}
                      </Field>
                    </FormControl>
                  </Box>
                ) : (
                  <></>
                )}

                {/* Certification Status and Verification Status */}
                {marketplaceType === MARKETPLACE_TYPES.EQUIPMENT_MARKETPLACE ||
                marketplaceType ===
                  MARKETPLACE_TYPES.EQUIPMENT_MARKETPLACE_RENTAL ||
                marketplaceType ===
                  MARKETPLACE_TYPES.EQUIPMENT_MARKETPLACE_USED ||
                marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR ||
                marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_PARTNER_MAINTENANCE ||
                marketplaceType ===
                  MARKETPLACE_TYPES.NEAR_ME_PARTNER_MAINTENANCE ? (
                  <FormControl>
                    <FormLabel fontSize={"14px"} fontWeight={"600"}>
                      {marketplaceType ===
                        MARKETPLACE_TYPES.NEAR_ME_PARTNER_OPERATOR ||
                      marketplaceType ===
                        MARKETPLACE_TYPES.NEAR_ME_PARTNER_MAINTENANCE
                        ? "Certification Status"
                        : "Verification Status"}
                    </FormLabel>
                    {/* <RadioGroup
                      name="verificationStatus"
                      value={values.verificationStatus}
                    >
                      <Field 
                      name="verificationStatus"
                      >
                        {({ field }) => (
                          <Flex justifyContent="space-around">
                            <Radio {...field} value="1">
                              Yes
                            </Radio>
                            <Radio {...field} value="0">
                              No
                            </Radio>
                          </Flex>
                        )}
                      </Field>
                    </RadioGroup> */}
                    <Field name="verificationStatus">
                      {({ field, form }) => (
                        <RadioParent
                          name={field.name}
                          options={[
                            {
                              id: 0,
                              value: "NO",
                            },
                            {
                              id: 1,
                              value: "Yes",
                            },
                          ]}
                          maxWidth="350px"
                          minWidth="350px"
                          height="44px"
                          isFormik={true}
                          onChange={(value) => {
                            setFieldValue("verificationStatus", value);
                          }}
                        />
                      )}
                    </Field>
                  </FormControl>
                ) : (
                  <></>
                )}
              </Flex>
            </form>
          )}
        </Formik>
      </Flex>
    </CustomInputModal>
  );
};
