import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PartnerDetailsCard } from "../../../../../webapp/components/PartnerDetailsPage/PartnerDetailsCard";
import { subString } from "../../../../../webapp/helpers/dataConversion/substring";
import { viewOperatorMantenancePartnerProfile } from "../../../../../webapp/services/jobsPosted/jobsPosted";
import { primaryKeyDecryption } from "../../../../../webapp/helpers/dataConversion/primaryKeyEncryption";
import { fetchHandler } from "../../../../..";
import { API_CONSTANTS } from "../../../../../constants/apiConstants";
import { Button, Flex, Grid } from "@chakra-ui/react";
import { LoginPopUp } from "../../../../components/PopUps/LoginPopUp";
import BrandHeader from "../../screens/BrandHeader";

const OpenPartnersDetails = () => {
  const { assetId, category, subCategory } = useParams();
  const userData = useSelector((state) => state.user);
  const [certificateReference, setCertificateReference] = useState(null);
  const [licenseBaseReference, setLicenseReference] = useState(null);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [equipmentDetails, setEquipmentDetails] = useState(null);
  const [experienceDetails, setExperienceDetails] = useState(null);
  const [licenseDetails, setLicenseDetails] = useState(null);
  const [profileDetails, setProfileDetails] = useState(null);
  const [profilePictureBaseUrl, setProfilePictureBaseUrl] = useState(null);
  const [salarySlipImageBaseUrl, setSalarySlipImageBaseUrl] = useState(null);
  const [verificationDocumentBaseUrl, setVerificationDocumentBaseUrl] =
    useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageReference, setSelectedImageReference] = useState(null);
  const [selectedDocumentName, setSelectedDocumentName] = useState(null);
  const [encryptedAssetId, setEncryptedAssetId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const id = primaryKeyDecryption(assetId);

  const getMarketplaceDetails = async () => {
    try {
      const category = "partner";
      const { data } = await fetchHandler(
        "/deferredLogin/marketplace",
        { category, id },
        "",
        "",
        API_CONSTANTS.HTTP_GET,
        { isAuthRequired: false }
      );
      return data;
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      const data = await getMarketplaceDetails();
      if (data?.Status === 200) {
        setDocumentDetails(data.Data.PartnerDocumentDetails);
        setEquipmentDetails(data.Data.PartnerEquipmentDetails);
        setExperienceDetails(data.Data.PartnerExperienceDetails);
        setLicenseDetails(data.Data.PartnerLicenseDetails);
        setProfileDetails(data?.Data?.PartnerProfileDetails);
        setCertificateReference(data.Data.CertificateImageBaseUrl);
        setLicenseReference(data.Data.LicenseImageBaseUrl);
        setProfilePictureBaseUrl(data.Data.ProfilePicImageBaseUrl);
        setVerificationDocumentBaseUrl(
          data.Data.VerificationDocumentImageBaseUrl
        );
        setSalarySlipImageBaseUrl(data.Data.SalarySlipImageBaseUrl);
      }
    })();
  }, []);

  const encryptAssetDetails = async (id) => {
    try {
      const { data } = await fetchHandler(
        "/encryptAssetId",
        {},
        "",
        { MarketplaceType: "partner", SubCategory: category, AssetId: id },
        API_CONSTANTS.HTT_POST,
        { isAuthRequired: false }
      );
      setEncryptedAssetId(data.Data.Token);
    } catch (error) {}
  };

  return (
    <>
      <LoginPopUp
        isModalOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
          setEncryptedAssetId("");
        }}
        onLoginClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/login/login-form?id=${encryptedAssetId}`);
        }}
        onRegisterClick={() => {
          sessionStorage.setItem("DEFERRED_MARKETPLACE", encryptedAssetId);
          navigate(`/welcome/select-language?id=${encryptedAssetId}`);
        }}
      />
      <Grid
      templateRows="auto 1fr auto"
      height="100vh"
      >
      {/* Header */}
      <BrandHeader />

      {/* Body */}
      <Flex direction="column" overflowY="scroll">
      <Flex
        background={"#fffbf5"}
        minHeight={"200px"}
        padding={"1em 2.25em"}
        gap={"1em"}
        alignItems={"center"}
      >
        <Flex
          height={"100px"}
          width={"100px"}
          borderRadius={"50%"}
          overflow={"hidden"}
        >
          <img
            style={{ height: "100%", width: "100%" }}
            src={
              !profileDetails?.ProfilePictureFileReference

                ? `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Avatar.png`
                : `${process.env.REACT_APP_CDN_PVT_URL}${profilePictureBaseUrl}${profileDetails?.ProfilePictureFileReference}`
            }
          />

        </Flex>
        <Flex direction={"column"} gap={"0.25em"} padding={"0 52px"}>
          <h1 style={{ fontSize: "32px", fontWeight: "700" }}>
            {profileDetails
              ? `${profileDetails.FirstName} ${profileDetails.LastName}`
              : "NA"}
          </h1>

          <p style={{ fontSize: "18px" }}>
            Work Experience:{" "}
            {profileDetails &&
            (profileDetails.ExperienceYears || profileDetails.ExperienceMonths)
              ? `${profileDetails.ExperienceYears}.${profileDetails.ExperienceMonths}`
              : 0}{" "}
            Years
          </p>

          <p style={{ fontSize: "18px" }}>
            Expected Salary:{" "}
            {`₹ ${
              profileDetails && profileDetails.MonthlySalary
                ? profileDetails.MonthlySalary
                : "NA"
            }`}
          </p>
        </Flex>
      </Flex>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          padding: "1em 52px",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          Equipment Profile
        </p>
        <div
          style={{
            display: "flex",
            gap: "0.75em",
            overflowX:
              equipmentDetails && equipmentDetails.length > 0 ? "scroll" : "",
          }}
        >
          {equipmentDetails && equipmentDetails.length > 0 ? (
            equipmentDetails.map((equipment) => (
              <PartnerDetailsCard
                headingKey="Equipment Type"
                descriptionKey="Equipment Make"
                headingValue={
                  equipment.EquipmentTypeValue
                    ? subString(12, equipment.EquipmentTypeValue)
                    : "NA"
                }
                descriptionValue={
                  equipment.OEMName ? subString(12, equipment.OEMName) : "NA"
                }
                isDeferred={true}
              />
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Nothing to show.</p>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          padding: "1em 52px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          License
        </p>
        <div
          style={{
            display: "flex",
            gap: "0.75em",
            overflowX:
              licenseDetails && licenseDetails.length > 0 ? "scroll" : "",
          }}
        >
          {licenseDetails && licenseDetails.length > 0 ? (
            licenseDetails.map((license) => (
              <PartnerDetailsCard
                headingKey="License Type"
                headingValue={
                  license.LicenseTypeValue
                    ? subString(12, license.LicenseTypeValue)
                    : "NA"
                }
                isDiscription={true}
                isDeferred={true}

              />
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Nothing to show.</p>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          padding: "1em 52px",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          Verification Document
        </p>
        <div
          style={{
            display: "flex",
            gap: "0.75em",
            overflowX:
              documentDetails && documentDetails.length > 0 ? "scroll" : "",
          }}
        >
          {documentDetails && documentDetails.length > 0 ? (
            documentDetails.map((document) => (
              <PartnerDetailsCard
                headingKey="License Type Type"
                descriptionKey="License Number"
                headingValue={
                  document.VerificationDocumentName
                    ? subString(12, document.VerificationDocumentName)
                    : "NA"
                }
                isDeferred={true}
                isDiscription={true}

              />
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Nothing to show.</p>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          padding: "1em 52px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          Experience
        </p>
        <div
          style={{
            display: "flex",
            gap: "0.75em",
            overflowX:
              experienceDetails && experienceDetails.length > 0 ? "scroll" : "",
          }}
        >
          {experienceDetails && experienceDetails.length > 0 ? (
            experienceDetails.map((experience) => (
              <div style={{ minWidth: "300px", border: "4px" }}>
                <PartnerDetailsCard
                  headingKey="Company Name"
                  descriptionKey="Location Name"
                  subDescriptionKey="Experience"
                  headingValue={
                    experience.CompanyName ? experience.CompanyName : "NA"
                  }
                  descriptionValue={
                    experience.CompanyCity && experience.CompanyState
                      ? subString(12,`${experience.CompanyCity}, ${experience.CompanyState}`)
                      : "NA"
                  }
                  subDescriptionValue={
                    experience.CompanyExperienceYear >= 1 ||
                    experience.CompanyExperienceMonth >= 1
                      ? `${experience.CompanyExperienceYear}.${experience.CompanyExperienceMonth} Years`
                      : "NA"
                  }
                  isDeferred={true}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Nothing to show.</p>
            </div>
          )}
        </div>
      </div>
      </Flex>

      {/* Footer */}
      <Flex
        alignItems="center"
        justifyContent="center"
        backgroundColor="rgb(255, 255, 255)"
        boxShadow="rgba(44, 44, 44, 0.06) 0px -10px 15px"
        height="70px"
      >
        <Button
          variant="gradient"
          fontWeight="semibold"
          width="80%"
          margin="0 auto"
          maxW="347px"
          onClick={() => {
            encryptAssetDetails(id);
            setModalOpen(true);
          }}
        >
          Contact Partner
        </Button>
      </Flex>
      </Grid>
    </>
  );
};

export default OpenPartnersDetails;
