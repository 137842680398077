const setSessionStorageItems = (key, value) => {
    if(!value){
      return sessionStorage.setItem(key, "")
    }
      if(typeof(value) === "object"){
         return sessionStorage.setItem(key, JSON.stringify(value))
      }
      return sessionStorage.setItem(key, value);
  }
  
  const getSessionStorageItems = (key) => {
    if(sessionStorage.getItem(key)){
         if(sessionStorage.getItem(key).indexOf("[") > -1 || sessionStorage.getItem(key).indexOf("{") > -1 ){
          return JSON.parse(sessionStorage.getItem(key));
         }
         else{
          return sessionStorage.getItem(key);
         }
    }
    else{
      return null;
    }
  }
  
  
  export {setSessionStorageItems, getSessionStorageItems};