import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilteredPartners } from "../../../services/jobsPosted/jobsPosted";

export const getInitialFilteredOperatorJobsThunk = createAsyncThunk(
  "opJobs/initialFilteredData",
  async (_, { getState }) => {
    const {
      cardCount,
      filterByEquipmentType,
      filterByCountry,
      filterByState,
      filterByCity,
    } = getState().operatorJobsNearMe;
    let query = "?FilterType=operator job";
    if (filterByEquipmentType) {
      query += `&FilterByEquipmentType=${filterByEquipmentType}`;
    }
    if (filterByCountry) {
      query += `&FilterByLocationCountry=${filterByCountry}`;
    }
    if (filterByState) {
      query += `&FilterByLocationState=${filterByState}`;
    }
    if (filterByCity) {
      query += `&FilterByLocationCity=${filterByCity}`;
    }

    try {
      const data = getFilteredPartners(cardCount, 0, query);

      if (data.Status === 200 && Object.keys(data.Data).length > 0) {
        if (data.Data.TotalRecords > 0) {
          return data.Data.GetFilteredOperatorJobMarketplace;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {}
  }
);
