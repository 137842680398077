import { useEffect, useState } from "react";
import { Flex, Box, Divider, Text } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import shareIcon from "../../../assets/webapp/dashboard/share.svg";
import { EquipmentRentalLayout } from "../../layout/equipmentRentalLayout/EquipmentRentalLayout";
import { DetailsCard } from "../DetailsCard/DetailsCard";
import { useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Popupconformation } from "../../../sharedComponent/PopUp/ConfomationPopUp/ConformationUp";
import "./JobDetailsPage.css";
import {
  acceptRejectMpJobRequest,
  acceptRejectOpJobRequest,
  viewJobRequestOfMaintenance,
  viewNewJobRequestOfOperator,
} from "../../services/jobsPosted/jobsPosted";

export const JobDetailsPage = ({ jobType = "", onShareClick }) => {
  const userData = useSelector((state) => state.user);
  const { cardId } = useParams();
  const [data, setData] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showConfirmOp, setShowConfirmOp] = useState(false);
  const [showRejectOP, setShowRejectOP] = useState(false);
  const [jobImageBaseReference, setJobImageBaseReference] = useState(null);
  const [assignedStaffList, setAssignedStaffList] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  // const UserJobResponseTypeValue = location?.state?.UserJobResponse;
  // const JobResponseTypeValue = location?.state?.OperatorJobResponse;

  const [UserJobResponseTypeValue, setUserJobResponseTypeValue] = useState();
  const [JobResponseTypeValue, setJobResponseTypeValue] = useState();
  /*********created at 12/6/2023 @shubham************/
  /****Accept */
  const handleAcceptConform = () => {
    setShowConfirm(true);
  };
  const handleAcceptCancel = () => {
    setShowConfirm(false);
  };

  const acceptJob = async (payload) => {
    const data = await acceptRejectMpJobRequest(payload);
    try {
      if (data.Status === 200) {
        toast({
          title: "",
          description: `Job Accepted Successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "",
        description: `${error}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleConformStatus = () => {
    const payload = {
      JobId: cardId,
      UserResponseStatusId: 2,
    };
    acceptJob(payload);
    navigate(-1);
    setShowConfirm(false);
  };
  /*********Reject MP*********** */
  const handleRejectConform = () => {
    setShowReject(true);
  };
  const handleRejectCancel = () => {
    setShowReject(false);
  };
  const rejectJob = async (payload) => {
    const data = await acceptRejectMpJobRequest(payload);
    try {
      if (data.Status === 200) {
        toast({
          title: "",
          description: `Job Rejected  Successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "",
        description: `${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleRejectStatus = () => {
    const payload = {
      JobId: cardId,
      UserResponseStatusId: 3,
    };
    rejectJob(payload);
    navigate(-1);
    setShowReject(false);
  };
  /*************OP*************************************/
  /****Accept OP */
  const handleAcceptConformOP = () => {
    setShowConfirmOp(true);
  };
  const handleAcceptOPCancel = () => {
    setShowConfirmOp(false);
  };
  const acceptJobOP = async (payload) => {
    const data = await acceptRejectOpJobRequest(payload);
    try {
      if (data.Status === 200) {
        toast({
          title: "",
          description: `Job Accepted Successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "",
        description: `${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleConformStatusOP = () => {
    const payload = {
      JobId: cardId,
      UserResponseStatusId: 2,
    };
    acceptJobOP(payload);
    navigate(-1);
    setShowConfirmOp(false);
  };
  /********Reject OP */
  const handleRejectConformOP = () => {
    setShowRejectOP(true);
  };
  const handleRejectCancelOP = () => {
    setShowRejectOP(false);
  };
  const rejectJobOP = async (payload) => {
    const data = await acceptRejectOpJobRequest(payload);
    try {
      if (data.Status === 200) {
        toast({
          title: "",
          description: `Job Rejected Successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "",
        description: `${error}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleRejectStatusOP = () => {
    const payload = {
      JobId: cardId,
      UserResponseStatusId: 3,
    };
    rejectJobOP(payload);
    navigate(-1);
    setShowRejectOP(false);
  };
  /********************************************/

  useEffect(() => {
    (async () => {
      try {
        let response = "";
        if (jobType === "maintenance".toLowerCase()) {
          response = await viewJobRequestOfMaintenance(cardId);
          setUserJobResponseTypeValue(response?.Data?.MaintenanceQuickJobDetails?.UserJobResponseTypeValue)

        } else {
          response = await viewNewJobRequestOfOperator(cardId);
          setJobResponseTypeValue(response?.Data?.ViewNewOperatorJob?.UserJobResponseTypeValue)
        }
        if (response.Status === 200 && Object.keys(response.Data).length > 0) {
          if (jobType === "maintenance") {
            setData(response.Data.MaintenanceQuickJobDetails);
          } else {
            setData(response.Data.ViewNewOperatorJob);

          }
          setJobImageBaseReference(response.Data.JobImageBaseUrl);
          setAssignedStaffList(response.Data.AssignedStaffList);
        }
      } catch (error) {}
    })();
  }, []);
  return (
    <EquipmentRentalLayout>
      {data ? (
        <div>
          {userData?.BusinessTypeValue === "Maintenance Partner" &&
          UserJobResponseTypeValue === "Waiting For Your Response" ? (
            <div>
              <div
                style={{
                  position: "fixed",
                  // right: "1%",
                  display: "flex",
                  flexDirection: "row",
                  top: "91%",
                  gap: "43px",
                  borderRadius: "8x",
                  backgroundColor: "#fff",
                  alignItems: "center",
                  justifyContent: "center",

                  boxShadow: "0px 0px 9.3px 0px rgba(0, 0, 0, 0.54)",
                  height: "68px",
                  width: "85%",
                  zIndex:"1"
                  // bottom:"12% "
                }}
              >
                <button className="reject-button" onClick={handleRejectConform}>
                  <p>Reject </p>
                </button>
                <button className="accept-button" onClick={handleAcceptConform}>
                  <p>Apply</p>
                </button>
              </div>
              <Popupconformation
                isOpen={showConfirm}
                onClose={handleAcceptCancel}
                onConfirm={handleConformStatus}
                message={"Are you sure want to apply this job ?"}
              />
              <Popupconformation
                isOpen={showReject}
                onClose={handleRejectCancel}
                onConfirm={handleRejectStatus}
                message={"Are you sure want to reject this job ?"}
              />
            </div>
          ) : (
            <div>
              <div
                style={{
                  position: "fixed",
                  right: "3%",
                  display: "flex",
                  flexDirection: "row",
                  top: "87%",
                  gap: "5px",
                }}
              >
                {userData?.BusinessTypeValue === "Maintenance Partner" && (
                  <>
                    {UserJobResponseTypeValue === "Rejected" ? (
                      <>
                        {" "}
                        <p className="reject-button">Rejected</p>
                      </>
                    ) : (
                      <>
                        {" "}
                        <p className="accept-button">Accepted</p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {userData?.BusinessTypeValue === "Operator Partner" &&
          JobResponseTypeValue === "Waiting For Your Response" ? (
            <div>

              <div
                style={{
                  position: "fixed",
                  // right: "1%",
                  display: "flex",
                  flexDirection: "row",
                  top: "91%",
                  gap: "43px",
                  borderRadius: "8x",
                  backgroundColor: "#fff",
                  alignItems: "center",
                  justifyContent: "center",

                  boxShadow: "0px 0px 9.3px 0px rgba(0, 0, 0, 0.54)",
                  height: "68px",
                  width: "85%",
                  zIndex:"1"
                }}
              >
                <button
                  className="reject-button"
                  onClick={handleRejectConformOP}
                >
                  <p>Reject </p>
                </button>
                <button
                  className="accept-button"
                  onClick={handleAcceptConformOP}
                >
                  <p>Apply</p>
                </button>
              </div>
              <Popupconformation
                isOpen={showConfirmOp}
                onClose={handleAcceptOPCancel}
                onConfirm={handleConformStatusOP}
                message={"Are you sure want to apply this job ?"}
              />
              <Popupconformation
                isOpen={showRejectOP}
                onClose={handleRejectCancelOP}
                onConfirm={handleRejectStatusOP}
                message={"Are you sure want to Reject  this job ?"}
              />
            </div>
          ) : (
            <div>
              <div
                style={{
                  position: "fixed",
                  right: "4%",
                  display: "flex",
                  flexDirection: "row",
                  top: "87%",
                  gap: "5px",
                }}
              >
                {userData?.BusinessTypeValue === "Operator Partner" && (
                  <>
                    {JobResponseTypeValue === "Rejected" ? (
                      <>
                        <p className="reject-button">Rejected</p>
                      </>
                    ) : (
                      <>
                        <p className="accept-button">Accepted</p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <div style={{ padding: "0 52px", marginTop: "80px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontSize: "32px", fontWeight: "700" }}>
                {jobType === "maintenance"
                  ? "Maintenance Partner"
                  : "Operator Partner"}
              </h1>
              <ReactSVG
                src={shareIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "height: 27px");
                }}
                style={{ cursor: "pointer" }}
                onClick={onShareClick}
              />
            </div>
            <Box margin="0 auto">
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      Equipment Details
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#011f6b" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box>
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0"
                >
                  <DetailsCard
                    heading={data.EquipmentTypeName}
                    description="Equipment Type"
                  />
                  <DetailsCard
                    heading={data.EquipmentMakeName}
                    description="Equipment Make"
                  />
                  <DetailsCard
                    heading={data.EquipmentModelName}
                    description="Equipment Model"
                  />
                  <DetailsCard
                    heading={data.EquipmentAge}
                    description="Equipment Age"
                  />
                </Flex>
              </Box>
            </Box>

            {/* Job Details */}
            <Box margin="0 auto">
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      Job Details
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#011f6b" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box>
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0"
                >
                  <DetailsCard
                    heading={data.WorkLocation}
                    description="Job Location"
                  />
                </Flex>
              </Box>
            </Box>

            {/* IN HAND SALARY */}
            <Box margin="0 auto">
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      In Hand Salary
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#011f6b" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box>
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0"
                >
                  <DetailsCard
                    heading={`${data.Salary ? data.Salary : "NA"} / ${
                      data.SalaryTypeValue ? data.SalaryTypeValue : "NA"
                    }`}
                    description="In Hand Salary"
                  />
                  <DetailsCard
                    heading={data.PFValue}
                    description="PF Provided"
                  />
                  <DetailsCard
                    heading={
                      data.PayCycleValue
                        ? `${data.PayCycleValue} week`
                        : "NA week"
                    }
                    description="Pay Cycle"
                  />
                </Flex>
              </Box>
            </Box>
            {/* Food and accomodation */}
            <Box margin="0 auto" marginBottom="20">
              <Flex alignItems="center">
                <Flex w="100%" alignItems="center" gap="0.5em">
                  <Box minWidth="max-content">
                    <Text color="#011f6b" fontWeight="semibold" fontSize="2xl">
                      Food & Accomodation
                    </Text>
                  </Box>
                  <Flex alignItems="center" w="100%">
                    <Divider borderColor="#011f6b" borderWidth="0.1em" />
                  </Flex>
                </Flex>
              </Flex>
              <Box>
                <Flex
                  gap="2em"
                  flexDirection="row"
                  overflowX="scroll"
                  padding="1em 0"
                >
                  <DetailsCard
                    heading={
                      data.FoodBenefitValue ? data.FoodBenefitValue : "NA"
                    }
                    description="Food"
                  />
                  <DetailsCard
                    heading={
                      data.AccomodationValue ? data.AccomodationValue : "NA"
                    }
                    description="Accomodtion"
                  />
                </Flex>
              </Box>
            </Box>

            {/* Working Shift */}

            {jobType !== "maintenance" ? (
              <>
                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Working Shift
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      <DetailsCard
                        heading={
                          data.WorkShiftValue ? data.WorkShiftValue : "NA"
                        }
                        description="Shift Type"
                      />
                      <DetailsCard
                        heading={
                          data.LunchBreakValue
                            ? `${data.WorkingHourValue} + ${data.LunchBreakValue}`
                            : data.WorkingHourValue
                            ? data.WorkingHourValue
                            : "NA"
                        }
                        description="Working Shift"
                      />
                      <DetailsCard
                        heading={
                          data.JobDurationValue ? data.JobDurationValue : "NA"
                        }
                        description="Job Duration"
                      />
                    </Flex>
                  </Box>
                </Box>

                <Box margin="0 auto">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Nature Of Work
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0"
                    >
                      <DetailsCard
                        heading={data.WorkTypeValue ? data.WorkTypeValue : "NA"}
                        description="Work Type"
                      />
                      <DetailsCard
                        heading={data.Insurance ? data.Insurance : "NA"}
                        description="Insurance"
                      />
                      <DetailsCard
                        heading={
                          data.TravelAllowance ? data.TravelAllowance : "NA"
                        }
                        description="Travel Allowance"
                      />
                    </Flex>
                  </Box>
                </Box>
                <br />
                <br />
                <br />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: "0 52px",
            marginTop: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <p style={{ fontSize: "22px", fontWeight: 600 }}>Nothing to show</p>
        </div>
      )}
    </EquipmentRentalLayout>
  );
};
