import React, { useEffect, useState } from "react";
import "./ProfileDetailsForm.css";
import { useSelector } from "react-redux";
import { DashboardNavBar } from "../../../../../../../../components/DashboardNavBar/DashboardNavBar";
import { SideMenuLayout } from "../../../../../../../../layout/sideMenuLayout/SideMenuLayout";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  PersonalDetails,
  StateCountryId,
  countrylist,
  city,
  getReferredType,
  savePersonalDetail
} from "../../../../../../../../../ApiEquip9/CommonApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import { CompanyIndiProfile } from "../../../../../../../../../sharedComponent/Loading/CompanyIndiProfile";
import { MidSection } from "../../../../../../../../../sharedComponent/midSection/MidSection";
import { useToast } from "@chakra-ui/react";
import { ProfileVerification } from "../../../../../../../../components/ProfileVerication/ProfileVerification";
import { getUserDetails } from "../../../../../../../../services/login/login";

export const ProfileDetailsForm = () => {
  const userData = useSelector((state) => state.user);
  const loginData = useSelector((state) => state.login);
  const {defaultLanguage} = useSelector(state => state.masterData);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [currentAddress, setCurrentAddress] = useState(null);
  /*****************Changing State***********************/
  const [countryListData, setCountryListData] = useState(null);
  const [stateListData, setStateListData] = useState(null);
  const [cityListData, setCityListData] = useState(null);

  const [address,setAddress] = useState(null);

  const [residentAddress, setResidentAddress] = useState(null);
  const [previousRestidentAddress, setPreviousRestidentAddress] =
    useState(null);
  const [residentAdd, setresidentAdd] = useState(null);

  const [currentPincode, setCurrentPincode] = useState(null);
  const [previousCurrentPincode, setPreviousCurrentPincode] = useState(null);
  const [pincode, setPincode] = useState(null);

  const [residentCountry, setResidentCountry] = useState("");
  const [residentState, setResidentState] = useState("");
  const [residentCity, setResidentCity] = useState("");


  const [CompanyresidentCountry, setCompanyresidentCountry] = useState("");
  const [CompanyresidentState, setCompanyresidentState] = useState("");
  const [CompnayresidentCity, setCompnayresidentCity] = useState("")


  const [residentPincode, setResidentPincode] = useState(null);
  const [previousResidentPincode, setPreviousResidentPincode] = useState(null);
  const [residentZipCode, setResidentZipCode] = useState(null);

  const [isSameAsCurrentAddress, setIsSameAsCurrentAddress] = useState(false);

  const [cityList, setCityList] = useState([]);
  const [residentCityList, setresidentCityList] = useState([]);

  const [personalDetail, setPersonalDetail] = useState(null);
  const [previousPersonalDetails, setPreviousPersonalDetails] = useState(null);
  const [firstName, setFirstName] = useState(null);

  const [credentialDetails, setCredentialDetails] = useState(null);

  const [companyName, setCompanyName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [fetchMobile, setFetchMobile] = useState(null);
  const [gstin, setGstin] = useState(null);
  const [Email,setEmail]=useState(null);
  const [listresidentCountry,setlistresidentCountry]=useState(null);
  const [listresidentState,setlistresidentState]=useState(null);
  const [listresidentCity,setlistresidentCity]=useState(null);
  //
  const [oplistindivCountry,setoplistindivCountry]=useState(null);
  const [oplistindivState,setoplistindivState]=useState(null);
  const [oplistindivCity,setoplistindivCity]=useState(null);
  const [indivCurrentAddress,setindivCurrentAddress]=useState(null);
  const [indivResidenceAddress,setindivResidenceAddress]=useState(null);
  const [addressData,setAddressData]=useState(null);
  const [indivCurrentPincode,setindivCurrentPincode]=useState(null);
  const [indvResidencePincode,setindvResidencePincode]=useState(null);
  const [indivResidentCountry,setindivResidentCounty]=useState(null);
  const [indivResidentState,setndivResidentState]=useState(null);
  const [indivResidentCity,setindivResidentCity]=useState(null);

  const [getInitialValues, setGetInitialValues] = useState({
    EnterpriseName: "",
    gstin: "",
    firstName: "",
    lastName: "",
    currentAddress: "",
    residentAddress: "",
    currentPincode: "",
    residentPincode: "",
    isSameAsCurrentAddress: false,
    country: "",
    state: "",
    city: "",
    residentCountry: "",
    residentState: "",
    residentCity: "",
  });

  // Refer by

  const { data: getReferedBy } = useQuery("getResidentCitylist", () =>
    getReferredType()
  );

  const businessTypeValue = userData.BusinessTypeValue.toLowerCase();
  const createUserTypeValue = userData.UserTypeValue.toLowerCase();

  const isObjectEqual = (ob1, ob2) => {
    return JSON.stringify(ob1) == JSON.stringify(ob2);
  };


 


  

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await getUserDetails();
        setAddressData(response?.data?.Data?.CurrentAddress?.Address)
        // setAddress(response?.data?.Data?.ResidenceAddress);
        setPersonalDetail(response?.data?.Data?.PersonalDetails);
        setPreviousPersonalDetails(response?.data?.Data?.PersonalDetails);
        setFirstName(response?.data?.Data?.PersonalDetails?.FirstName)
        setLastName(response?.data?.Data?.PersonalDetails?.LastName);
        setFetchMobile(response?.data?.Data?.CredentialDetails?.MobileNumber);
        setEmail(response?.data?.Data?.CredentialDetails?.Email);
        setGstin(response?.data?.CompanyDetails?.TaxNumber);
        setCurrentAddress(response?.data?.Data?.CurrentAddress?.Address);
        setSelectedCountry(response?.data?.Data?.CurrentAddress?.CountryId);
        setSelectedState(response?.data?.Data?.CurrentAddress?.StateId);
        setSelectedCity(response?.data?.Data?.CurrentAddress?.CityId);
        setindivResidentCounty(response?.data?.Data?.ResidenceAddress?.CountryId);
        setndivResidentState(response?.data?.Data?.ResidenceAddress?.StateId);
        setindivResidentCity(response?.data?.Data?.ResidenceAddress?.CityId);
        
       
        setindivCurrentAddress(response?.data?.Data?.CurrentAddress?.Address);
        setindivResidenceAddress(response?.data?.Data?.ResidenceAddress?.Address);
        setCurrentPincode(response?.data?.Data?.CurrentAddress?.ZipCode);
        setindivCurrentPincode(response?.data?.Data?.CurrentAddress?.ZipCode);
        setindvResidencePincode(response?.data?.Data?.ResidenceAddress?.ZipCode)

  
        if (
          response?.data?.Data?.CurrentAddress &&
          response?.data?.Data?.ResidenceAddress &&
          JSON.stringify(response?.data?.Data?.CurrentAddress) === JSON.stringify(response?.data?.Data?.ResidenceAddress)
        ) {
          setIsSameAsCurrentAddress(true);
        } else {
          setIsSameAsCurrentAddress(false);
        }

     

        const isSameAddress = isSameAsCurrentAddress || // additional condition if needed
        (response?.data?.Data?.CurrentAddress && response?.data?.Data?.ResidenceAddress &&
          JSON.stringify(response?.data?.Data?.CurrentAddress) === JSON.stringify(response?.data?.Data?.ResidenceAddress));
     
 
        setGetInitialValues({
          EnterpriseName: response?.data?.Data?.PersonalDetails?.EnterpriseName || "",
    firstName: response?.data?.Data?.PersonalDetails?.FirstName || "",
    lastName: response?.data?.Data?.PersonalDetails?.LastName || "",
    currentAddress: response?.data?.Data?.CurrentAddress?.Address || "",
    currentPincode: response?.data?.Data?.CurrentAddress?.ZipCode || "",
    residentPincode: isSameAddress ? response?.data?.Data?.CurrentAddress?.ZipCode : response?.data?.Data?.ResidenceAddress?.ZipCode || "",
    residentAddress: isSameAddress ? response?.data?.Data?.CurrentAddress?.Address : response?.data?.Data?.ResidenceAddress?.Address || "",
    email: response?.data?.Data?.CredentialDetails?.Email || "",
    country: response?.data?.Data?.CurrentAddress?.CountryId || "",
    state: response?.data?.Data?.CurrentAddress?.StateId || "",
    city: response?.data?.Data?.CurrentAddress?.CityId || "",
    gstin: response?.data?.CompanyDetails?.TaxNumber || "",
    residentCountry: isSameAddress ? response?.data?.Data?.CurrentAddress?.CountryId : response?.data?.Data?.ResidenceAddress?.CountryId || "",
    residentState: isSameAddress ? response?.data?.Data?.CurrentAddress?.StateId : response?.data?.Data?.ResidenceAddress?.StateId || "",
    residentCity: isSameAddress ? response?.data?.Data?.CurrentAddress?.CityId : response?.data?.Data?.ResidenceAddress?.CityId || "",
        });


if(Array.isArray(response?.data?.Data?.ResidenceAddress)){
     
  setGetInitialValues({
     EnterpriseName: response?.data?.Data?.PersonalDetails?.EnterpriseName||"",
     gstin:response?.data?.CompanyDetails?.TaxNumber||"",
     firstName: response?.data?.Data?.PersonalDetails?.FirstName||"",
    lastName: response?.data?.Data?.PersonalDetails?.LastName||"",
    // currentAddress: response?.data?.Data?.CurrentAddress?.Address,
    // currentPincode: response?.data?.Data?.CurrentAddress?.ZipCode,
    email:response?.data?.Data?.CredentialDetails?.Email||"",
    // country: response?.data?.Data?.CurrentAddress?.CountryId,
    // state: response?.data?.Data?.CurrentAddress?.StateId,
    // city: response?.data?.Data?.CurrentAddress?.CityId,
    residentPincode: response?.data?.Data?.ResidenceAddress[0]?.ZipCode||"",
    residentAddress: response?.data?.Data?.ResidenceAddress[0]?.Address||"",
    residentCountry: response?.data?.Data?.ResidenceAddress[0]?.CountryId||"",
    residentState: response?.data?.Data?.ResidenceAddress[0]?.StateId||"",
    residentCity: response?.data?.Data?.ResidenceAddress[0]?.CityId||"",
  })
          
}
        setResidentPincode(response?.data?.Data?.ResidenceAddress[0]?.ZipCode);
   
        setPreviousResidentPincode(
          response?.data?.Data?.ResidenceAddress?.map((item)=>item?.ZipCode)
        );

        setCompanyresidentCountry(response?.data?.Data?.ResidenceAddress[0].CountryId);
        setCompanyresidentState(response?.data?.Data?.ResidenceAddress[0].StateId);
        setCompnayresidentCity(response?.data?.Data?.ResidenceAddress[0].CityId);
        setResidentCountry(response?.data?.Data?.ResidenceAddress[0].CountryId);
        setResidentState(response?.data?.Data?.ResidenceAddress[0].StateId);
        setResidentCity(response?.data?.Data?.ResidenceAddress[0].CityId);
        setResidentAddress(response?.data?.Data?.ResidenceAddress[0]?.Address);
        setPreviousRestidentAddress(response?.data?.Data?.ResidenceAddress[0].Address);

       
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  // // personalDetail
  // FirstName
  const initialValues = {
    EnterpriseName: "",
    gstin: "",
    firstName:"",
    lastName: "", 
    currentAddress: "",
    residentAddress: "",
    currentPincode: "",
    residentPincode: "",
    isSameAsCurrentAddress: false,
    city:"",
    state:"",
    country:""

  };


    const getBusinessTypeValue = userData?.BusinessTypeValue.toLowerCase();
  const operatorPartner = "operator partner";
    const getUserTypeValue = userData?.UserTypeValue;


  useEffect(() => {
    if (personalDetail) {
      setCompanyName(personalDetail?.EnterpriseName);
      setFirstName(personalDetail?.FirstName);
      setLastName(personalDetail?.LastName);
    }
  }, [personalDetail]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const verification =
    userData?.accountDetails?.UserDetails?.E9VerificationMessage;
  const enabledValidationSchema = Yup.object({
    EnterpriseName:Yup.string().max(50,"Max 100 characters allowed").required("Is Required"),
    firstName: Yup.string()
      .max(15, "Max 15 characters allowed")
      .required("Is Required"),
    lastName: Yup.string()
      .max(15, "Max 15 characters allowed")
      .required("Is Required"),
     residentAddress : Yup.string().required("Is Required"),
    //  currentAddress: Yup.string().required("Is Required"),
    residentPincode : Yup.string()
    .matches(/^\d{6}$/, "PIN code must be exactly 6 digits")
    .required("Is Required"),
    email: Yup.string().matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Invalid email format"
    ).required("Is Required"),
    residentCountry:Yup.string().required("Is Required"),
    residentState:Yup.string().required("Is Required"),
    residentCity:Yup.string().required("Is Required"),
    gstin:Yup.string().required("is Required").min(2,"minimum 2 characters required").max(20,"charcter are excedding limit").matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, "Invalid GST Format"),
  });
  const disabledCompanyValidationSchema = Yup.object().shape(
    {
    //  EnterpriseName:Yup.string().min(3,"Min 5 Charcter needed").max(50,"Max 50 characters allowed").required("Is Required"),
    firstName: Yup.string()
      .max(15, "Max 15 characters allowed")
      .required("Is Required"),
    lastName: Yup.string()
      .max(15, "Max 15 characters allowed")
      .required("Is Required"),
    currentAddress: Yup.string().required("Is Required"),
     residentAddress : Yup.string().required("Is Required"),
    currentPincode: Yup.string()
      .matches(/^\d{6}$/, "PIN code must be exactly 6 digits")
      .required("Is Required"),
    residentPincode : Yup.string()
    .matches(/^\d{6}$/, "PIN code must be exactly 6 digits")
    .required("Is Required"),
    email: Yup.string().matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Invalid email format"
    ).required("Is Required"),
    city:Yup.string().required("Is Required"),
    state:Yup.string().required("Is Required"),
    country:Yup.string().required("Is Required"),
    residentCountry:Yup.string().required("Is Required"),
    residentState:Yup.string().required("Is Required"),
    residentCity:Yup.string().required("Is Required"),
   
  });



  const validate = (values) => {
    //
    const errors = {};

    if (
      !isSameAsCurrentAddress &&
      businessTypeValue.includes(
        "Business Owner".toLowerCase() &&
          createUserTypeValue.includes("Company".toLowerCase())
      )
    ) {
      if (!values?.residentAddress) {
        errors.residentAddress = "Residence Address is required";
      }

      if (!values?.residentPincode) {
        errors.residentPincode = "Pincode is required";
      }

      if (!values?.residentCountry) {
        errors.residentCountry = "Country is required";
      }

      if (!values?.residentCity) {
        errors.residentCity = "city is required";
      }

      if (!values?.residentPincode) {
        errors.residentPincode = "picode is required";
      }

    }
    

    return errors;
  };
  /*****************Check country || state ||   city************************** */

  const { data: fetchedCountryList } = useQuery("countryList", () =>
    countrylist()
  );
  useEffect(() => {
    setCountryListData(fetchedCountryList?.data?.Data?.CountriesList);
  }, [fetchedCountryList]);

  const { data: fetchedStateList } = useQuery(
    ["getStateList", selectedCountry],
    () => StateCountryId( selectedCountry),
    {
      enabled: !!selectedCountry,
    }
  );
  useEffect(() => {
    setStateListData(fetchedStateList?.data?.Data?.StatesList);
    setCityListData(null);
  }, [fetchedStateList, selectedCountry]);

  const { data: fetchedCityList } = useQuery(
    ["getCitylist", selectedState],
    () => city(selectedState),
    () => city(selectedState),
    {
      enabled: !!selectedState,
    }
  );
  useEffect(() => {
    setCityListData(fetchedCityList?.data?.Data?.CitiesList);
  }, [fetchedCityList]);



  /*********************************end******************************************/

  /*********************************Resident*************************************/

  //
  const { data: getResidentCountryList } = useQuery(
    "getResidentCountryList",
    () => countrylist()
  );


  useEffect(() => {
    setlistresidentCountry(fetchedCountryList?.data?.Data?.CountriesList);
  }, [fetchedCountryList]);
  //
  const getResidentCountryListID =
    getResidentCountryList?.data.Data?.CountriesList;



  const { data: getResidentStateList } = useQuery(
    ["getResidentStateList", selectedCountry],
    () => StateCountryId(residentCountry),
    {
      enabled: !!residentCountry,
    }
  );
  

  // get Resident City
  const { data: getResidentCitylist } = useQuery(
    ["getResidentCitylist", residentState],
    () => city(residentState),
    {
      enabled:!!residentState
    }
  );
  //


  useEffect(() => {
    if (getResidentCitylist?.data?.Data?.CitiesList) {
      setresidentCityList(getResidentCitylist?.data?.Data?.CitiesList);
    }
  }, [getResidentCitylist]);

  const getResidentStateCountryID = getResidentStateList?.data.Data?.StatesList;


  /**************************************************************************/
  /********************************CHeck wiht improvise**************** */

  //
  const { data: getResidentCountryListt } = useQuery(
    "getResidentCountryListt",
    () => countrylist()
  );
  useEffect(() => {
    setlistresidentCountry(getResidentCountryListt?.data?.Data?.CountriesList);
  }, [getResidentCountryListt ]);



  // 
  const { data: getResidentStateListt } = useQuery(
    ["getResidentStateListt", CompanyresidentCountry],
    () => StateCountryId(CompanyresidentCountry),
    () => StateCountryId(CompanyresidentCountry),
    {
      enabled: !!CompanyresidentCountry,
    }
  );
  useEffect(() => {
    setlistresidentState(getResidentStateListt?.data?.Data?.StatesList);
    setlistresidentCity(null);
  }, [getResidentStateListt,CompanyresidentCountry]);




  const { data: getResidentCityListt } = useQuery(
    ["getResidentCitylisttt",CompanyresidentState],
    () => city(CompanyresidentState),{
      enabled:!!CompanyresidentState
    }
  );

  useEffect(() => {
    setindivResidentCity(getResidentCityListt?.data?.Data?.CitiesList);
  }, [getResidentCityListt]);



  /********************************************************************* */

/***********Indiv Resideice Patner********************** */

const { data: getResidentCountryListtt } = useQuery(
  "getResidentCountryListttt",
  () => countrylist()
);

if(getResidentCountryListtt){

}
useEffect(() => {
  //
  setoplistindivCountry(getResidentCountryListtt?.data?.Data?.CountriesList);
  // setoplistindivState(null)
  // setoplistindivCity(null);
},[getResidentCountryListtt]);

useEffect(()=>{ 
  //
},[oplistindivCountry])

//

const { data: getResidentStateListtt } = useQuery(
  ["getResidentStateListttt", indivResidentCountry],
  () => StateCountryId(indivResidentCountry),
  {
    enabled: !!indivResidentCountry,
  }
);
useEffect(() => {
  setoplistindivState(getResidentStateListtt?.data?.Data?.StatesList);
  setoplistindivCity(null);
}, [getResidentStateListtt, indivResidentCountry]);




const { data: getResidentCityListtt } = useQuery(
  ["getResidentCitylistttt",indivResidentState],
  () => city(indivResidentState),
  {
    enabled:!!indivResidentState
  }
);

useEffect(() => {
  setoplistindivCity(getResidentCityListtt?.data?.Data?.CitiesList);
}, [getResidentCityListtt]);



/******** */

useEffect(() => {
  if (indivResidentCountry) {
    setoplistindivCountry(getResidentCountryListtt?.data?.Data?.CountriesList);
  } 
}, [getResidentCountryListtt, indivResidentCountry]);


// useEffect(() => {
//   if (indivResidentCountry && getResidentStateListtt) {
//     setoplistindivState(getResidentStateListtt?.data?.Data?.StatesList);
//   } else {
//     setoplistindivState(null);
//   }
//   // We don't need to set city to null here because it will be handled in the city useEffect
// }, [getResidentStateListtt, indivResidentCountry]);

// useEffect(() => {
//   if (indivResidentState && getResidentCityListtt) {
//     setoplistindivCity(getResidentCityListtt?.data?.Data?.CitiesList);
//   } else {
//     setoplistindivCity(null);
//   }
// }, [getResidentCityListtt, indivResidentState]);



/********************************************************** */



  const getReferedByList = getReferedBy?.data?.Data.ReferredTypes;
  const getUserCredentialID = loginData?.selectedAccount?.UserCredentialId;

  const getCountryIsdCode =
    userData?.accountDetails?.UserDetails?.CredentialDetails?.IsdCode;

  // const getResidenceAddress =
  //   userData?.accountDetails?.UserDetails?.ResidenceAddress?.Address;



  /****************************error /********************* */
  const getErrorDescription = (err) => {
    let descriptionString = "";
    descriptionString += err?.data?.Message?.AddressLine1
      ? `AddressLine: ${err?.data?.Message?.AddressLine1}\n`
      : "";
    descriptionString += err?.data?.Message?.CityId
      ? `City ID: ${err?.data?.Message?.CityId}\n`
      : "";
    descriptionString += err?.data?.Message?.CountryId
      ? `Country ID: ${err?.data?.Message?.CountryId}\n`
      : "";
    descriptionString += err?.data?.Message?.CompanyName
      ? `Company Name: ${err?.data?.Message?.CompanyName}\n`
      : "";
    descriptionString += err?.data?.Message?.ExperienceInMonth
      ? `Experience (Months): ${err?.data?.Message?.ExperienceInMonth}\n`
      : "";
    descriptionString += err?.data?.Message?.ExperienceInYear
      ? `Experience (Years): ${err?.data?.Message?.ExperienceInYear}\n`
      : "";
    descriptionString += err?.data?.Message?.ReferenceEmail
      ? `Reference Email: ${err?.data?.Message?.ReferenceEmail}\n`
      : "";
    descriptionString += err?.data?.Message?.ReferenceMobileNumber
      ? `Reference Mobile Number: ${err?.data?.Message?.ReferenceMobileNumber}\n`
      : "";
    descriptionString += err?.data?.Message?.ReferenceName
      ? `Reference Name: ${err?.data?.Message?.ReferenceName}\n`
      : "";
    descriptionString += err?.data?.Message?.StateId
      ? `State ID: ${err?.data?.Message?.StateId}\n`
      : "";
    descriptionString += err?.data?.Message?.Zipcode
      ? `Zipcode: ${err?.data?.Message?.Zipcode}\n`
      : "";
    return descriptionString;
  };

  /********************************************************** */
  // const handleCheckboxChange = () => {
  //   //
  //   setIsSameAsCurrentAddress((prevIsSame) => !prevIsSame);
  //   if (!isSameAsCurrentAddress) {
  //     setindivResidentCounty(selectedCountry);
  //     setndivResidentState(selectedState);
  //     setindivResidentCity(selectedCity);
  //     setindivResidenceAddress(indivCurrentAddress);
  //     setindvResidencePincode(indivCurrentPincode);
  //   } else {
  //     setindivResidentCounty("");
  //     setndivResidentState("");
  //     setindivResidentCity("");
  //     setindivResidenceAddress("");
  //     setindvResidencePincode("");
  //   }
  // };


  const handleCheckboxChange = (setFieldValue) => {
    const newValue = !isSameAsCurrentAddress;
    setIsSameAsCurrentAddress(newValue);
  
    if (newValue) {
      // Copy current address fields to resident address fields in Formik
      setindivResidentCounty(selectedCountry);
      setndivResidentState(selectedState);
      setindivResidentCity(selectedCity);
      setindivResidenceAddress(indivCurrentAddress);
      setindvResidencePincode(indivCurrentPincode);
      setFieldValue("residentCountry", selectedCountry);
      setFieldValue("residentState", selectedState);
      setFieldValue("residentCity", selectedCity);
      setFieldValue("residentAddress", indivCurrentAddress);
      setFieldValue("residentPincode", indivCurrentPincode);
    } else {
      // Clear the resident address fields in Formik
      setFieldValue("residentCountry", "");
      setFieldValue("residentState", "");
      setFieldValue("residentCity", "");
      setFieldValue("residentAddress", "");
      setFieldValue("residentPincode", "");
      setindivResidentCounty("");
          setndivResidentState("");
          setindivResidentCity("");
          setindivResidenceAddress("");
          setindvResidencePincode("");
    }
  };

  const handleSubmitIndivdual=async(values)=>{
   
   const payLoad=JSON.stringify({
    UpdateData: {
      EnterpriseName: values?.EnterpriseName
        ? values.EnterpriseName
        : previousPersonalDetails?.EnterpriseName,
      TaxNumber: values.gstin || null,
      UserCredentialId: getUserCredentialID.toString(),
      FirstName: firstName?firstName: values?.firstName,
      LastName: lastName?lastName:values?.LastName,
      MobileNumber: parseInt(fetchMobile),
      IsdCode: parseInt(getCountryIsdCode),
      Email:Email?Email?.replace(/\s+/g, ''):values?.email?.replace(/\s+/g, ''),
      CurrentAddress: indivCurrentAddress?indivCurrentAddress:
        values?.currentAddress,
      CurrentCountryId: values?.country
        ? values?.country
        : parseInt(selectedCountry),
      CurrentStateId: parseInt(selectedState),
      CurrentCityId: parseInt(selectedCity),
      CurrentPinCode: values?.currentPincode
        ? values?.currentPincode
        : indivCurrentPincode    ,
      CurrentLatitude: 76.458965,
      CurrentLongitude: 78.562345,
      ResidenceAddress: !isSameAsCurrentAddress
        ? values?.residentAddress
        : indivResidenceAddress,
      ResidenceCountryId: !isSameAsCurrentAddress
        ? values?.residentCountry
        : parseInt(indivResidentCountry),
      ResidenceStateId: !isSameAsCurrentAddress
        ? values?.residentState
        : parseInt(indivResidentState),
      ResidenceCityId: !isSameAsCurrentAddress
        ? values?.residentCity
        : parseInt(indivResidentCity),
      ResidencePinCode: !isSameAsCurrentAddress
        ? values?.residentPincode
        :indvResidencePincode,
      ResidenceLatitude: 76.458965,
      ResidenceLongitude: "78.562345",
      ReferredBy: values?.refer
        ? values?.refer
        : parseInt(previousPersonalDetails?.ReferredBy),
      PartnerId: '',
      // CompanyResidenceAddress:values.address

    }
   });

 

   try{
    const response = await PersonalDetails(payLoad);
    if (response.data.APIResponseCode === 200) {
      toast({
        status: "success",
        title: `${"Personal Details Updated Successfully"}`,
        position: "top-right",
        isClosable: true,
      });
      // window.location.reload();
    } else {
      toast({
        status: "error",
        title: `error `,
        position: "top-right",
        isClosable: true,
      });

    }
    window.location.reload();
    return response;
  } catch (error) {
    const errorDescription = getErrorDescription(error);
    toast({
      status: "error",
      title: `${errorDescription}`,
      position: "top-right",
      isClosable: true,
    });
  }
}


const handleSubmitCompany=async(values)=>{

  const payLoad={
      EnterpriseName:personalDetail?.EnterpriseName?personalDetail?.EnterpriseName:values.EnterpriseName,
      TaxNumber: gstin,
      UserCredentialId: getUserCredentialID.toString(),
      FirstName: firstName?firstName:values?.firstName, 
      LastName:lastName?lastName:values?.LastName,
      MobileNumber: parseInt(fetchMobile),
      // IsdCode: parseInt(getCountryIsdCode),
      Email:Email?Email?.replace(/\s+/g, ''):values?.email.replace(/\s+/g, ''),
      // Email: values?.email ? values?.email : credentialDetails?.Email,
      CompanyAddress:residentAddress? residentAddress:values?.currentAddress,
      CompanyCountryId: parseInt(CompanyresidentCountry)?parseInt(CompanyresidentCountry): values?.residentCountry,
      CompanyStateId: parseInt(CompanyresidentState)?parseInt(CompanyresidentState):values?.residentState,
      CompanyCityId: parseInt(CompnayresidentCity)?parseInt(CompnayresidentCity):values?.residentCity,
      CompanyPinCode: residentPincode?residentPincode:values?.currentPincode,
      CompanyLatitude: 76.458965,
      CompanyLongitude: 78.562345,
   };
    //
   try{
     const companyPersonalDetails=await  savePersonalDetail(payLoad);
    //

    //   if(companyPersonalDetails?.data?.Status===204){
    //       toast({
    //         status: "success",
    //         title: `${companyPersonalDetails?.data?.Message?.SuccessMessage}`,
    //         position: "top-right",
    //         isClosable: true,
    //       }  
    //     )
    //   }
    //  return companyPersonalDetails;
    
   }
   catch(error){
   
    const errorDescription = getErrorDescription(error);
    toast({
      title: "Error while uploading",
      description: `${errorDescription}`,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
    return null;
   }
}
    const updatePersonalDetails = async (values) => {
    if(getUserTypeValue.toLowerCase()==="Individual".toLowerCase()){
      handleSubmitIndivdual(values);
    }
     if(getUserTypeValue.toLowerCase()==="Company".toLowerCase()){
     
      handleSubmitCompany(values);
     }
  }
  const mutation = useMutation(updatePersonalDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries("userData");
    },
  });

  const handleSubmit = (values, { resetForm }) => {
    
    mutation.mutate(values);
    resetForm();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1].focus();
      }
    }
  };


  useEffect(() => {
    if (isSameAsCurrentAddress) {
      setindivResidentCounty(selectedCountry);
      setndivResidentState(selectedState);
      setindivResidentCity(selectedCity);
    }
  }, [selectedCountry, selectedState, selectedCity, isSameAsCurrentAddress]);

  return (
    <SideMenuLayout>
      <DashboardNavBar />
      <MidSection>
        <ProfileVerification />
        <CompanyIndiProfile />

        <Formik
          initialValues={getInitialValues}
          
          validationSchema={
            createUserTypeValue.includes("Company".toLowerCase())
              ? enabledValidationSchema
              : disabledCompanyValidationSchema
          }
          validate={validate}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue, isSubmitting }) => (
            <Form className="formContainerMain">
              <div className="form-container">
                {getUserTypeValue.toLowerCase() == "Company".toLowerCase() && (
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="EnterpriseName">
                      Company Name<span className="asterisk">*</span>
                    </label>
                    <Field
                      className="profileField"
                      type="text"
                      id="EnterpriseName"
                      name="EnterpriseName"
                      value={
                        personalDetail
                          ? personalDetail?.EnterpriseName
                          : ""
                      }
                      onChange={(e) => {
                        setPersonalDetail((preDetails) => {
                          return {
                            ...preDetails,
                            EnterpriseName: e.target.value,
                          };
                        });
                        setFieldValue("EnterpriseName", e.target.value);
                      }}
                      placeholder="Please Enter Company Name"
                      onKeyPress={handleKeyPress}
                    />
                    <ErrorMessage
                      name="EnterpriseName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}

                {getBusinessTypeValue === operatorPartner
                  ? null
                  : getUserTypeValue.toLowerCase() ===
                      "Individual".toLowerCase() && (
                      <div className="profileForm">
                        <label
                          className="profileLabel"
                          htmlFor="EnterpriseName"
                        >
                          Enterprise Name (If Any)
                        </label>
                        <Field
                          className="profileField"
                          type="text"
                          id="EnterpriseName"
                          name="EnterpriseName"
                          value={
                            personalDetail
                              ? previousPersonalDetails?.EnterpriseName
                              : ""
                          }
                          onChange={(e) => {
                            setPreviousPersonalDetails((preDetails) => {
                              return {
                                ...preDetails,
                                EnterpriseName: e.target.value,
                              };
                            });
                            setFieldValue("EnterpriseName", e.target.value);
                          }}
                          placeholder="Equip9"
                          onKeyPress={handleKeyPress}
                        />
                        <ErrorMessage
                          name="EnterpriseName"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    )}

                {getUserTypeValue.toLowerCase() === "Company".toLowerCase() && (
                  <div className="profileForm">
                    <label
                      className="profileLabel"
                      htmlFor="gstin"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        GSTIN Number<span className="asterisk">*</span>
                      </div>
                      <div className="verified">Verified</div>
                    </label>
                    <Field
                      className="profileField"
                      type="text"
                      id="gstin"
                      name="gstin"
                      value={gstin}
                      onChange={(e)=>{
                        setGstin(e.target.value);
                        setFieldValue("gstin", e.target.value);
                      }}
                      placeholder="Enter the GST Number"
                      onKeyPress={handleKeyPress}
                    />
                    <ErrorMessage
                      name="gstin"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}

                <div className="profileForm">
                  <label className="profileLabel" htmlFor="firstName">
                    First Name<span className="asterisk">*</span>
                  </label>
                  <input
                    className="profileField"
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setFieldValue("firstName", e.target.value);
                    }}
                    placeholder="Enter First Name"
                    onKeyPress={handleKeyPress}
                  />

                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="profileForm">
                  <label className="profileLabel" htmlFor="lastName">
                    Last Name<span className="asterisk">*</span>
                  </label>
                  <Field
                    className="profileField"
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={
                      lastName ? lastName : ""
                    }
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setFieldValue("lastName", e.target.value);
                    }}
                    placeholder="Enter Last Name"
                    onKeyPress={handleKeyPress}
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="profileForm">
                  <label
                    className="profileLabel "
                    htmlFor="mobile"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      {defaultLanguage?.mobile_number}<span className="asterisk">*</span>
                    </div>
                    <div className="verified">Verified</div>
                  </label>
                  <input
                    className="profileField"
                    style={{ color: "#c2c2c2" }}
                    type="text"
                    id="mobile"
                    name="mobile"
                    readOnly
                    value={fetchMobile}
                    onChange={handleChange}
                    placeholder="Enter Mobile Number"
                    onKeyPress={handleKeyPress}
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="profileForm">
                  <label className="profileLabel" htmlFor="email">
                    {defaultLanguage?.email}
                  </label>
                  <Field
                    className="profileField"
                    type="text"
                    id="email"
                    name="email"
                    value={Email? Email.trim() : ""}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      handleChange(e);
                      setFieldValue("email", e.target.value.trim());
                    }}
                    placeholder="Enter Email id"
                    onKeyPress={handleKeyPress}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>

                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase()&&(
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="currentAddress">
                      Current Address<span className="asterisk">*</span>
                    </label>
                    <input
                      className="profileField"
                      type="text"
                      id="currentAddress"
                      name="currentAddress"
                      value={indivCurrentAddress ? indivCurrentAddress : ""}
                      onChange={(e) => {
                        setindivCurrentAddress(e.target.value);
                        setFieldValue("currentAddress", e.target.value);
                      }}
                      placeholder="Enter Current Address"
                      onKeyPress={handleKeyPress}
                    />
                    <ErrorMessage
                      name="currentAddress"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}

                {/* {getUserTypeValue.toLowerCase() === "Company".toLowerCase() && (
                  
                )} */}

               

                {getUserTypeValue.toLowerCase()==="Individual".toLowerCase()?(<>

                  <div className="profileForm">
                  <label className="profileLabel" htmlFor="country">
                   {defaultLanguage?.select_country}<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    className="profileField"
                    id="country"
                    name="country"
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      setFieldValue("country", e.target.value);
                      // if(isSameAsCurrentAddress)
                      // {
                      //   setindivResidentCounty(selectedCountry);
                      // }
                      handleChange(e);
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="">Select an option</option>
                    {countryListData?.map((item, index) => (
                      <option key={index} value={item.CountryId}>
                        {item.CountryName}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="country"
                    component="div"
                    className="error-message"
                  />
                </div>

                </>):(<>
                  <div className="profileForm">
                  <label className="profileLabel" htmlFor="country">
                    {defaultLanguage?.select_country}<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    className="profileField"
                    id="country"
                    name="residentCountry"
                    value={CompanyresidentCountry}
                    onChange={(e) => {
                      setCompanyresidentCountry(e.target.value);
                      setSelectedCountry(e.target.value);
                      setFieldValue("residentCountry", e.target.value);
                      handleChange(e);
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="">Select an option</option>
                    {listresidentCountry?.map((item, index) => (
                      <option key={index} value={item.CountryId}>
                        {item.CountryName}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="residentCountry"
                    component="div"
                    className="error-message"
                  />
                </div>
                
                </>)}


                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase()?(<>
                  <div className="profileForm">
                  <label className="profileLabel" htmlFor="state">
                    {defaultLanguage?.select_state}<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    className="profileField"
                    id="state"
                    name="state"
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setFieldValue("state", e.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="">Select an option</option>
                    {stateListData?.map((item, index) => (
                      <option key={index.id} value={item.StateId}>
                        {item.StateName}
                      </option>
                    ))}
                  </Field>
                  {!selectedState && (
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="error-message"
                    />
                  )}
                </div> 
                  
                  </>):(<>
                  
                    <div className="profileForm">
                  <label className="profileLabel" htmlFor="state">
                    {defaultLanguage?.select_state}<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    className="profileField"
                    id="state"
                    name="residentState"
                    value={CompanyresidentState}
                    onChange={(e) => {
                      setCompanyresidentState(e.target.value);
                      setFieldValue("residentState", e.target.value);
                      if(isSameAsCurrentAddress)
                      {
                        setndivResidentState(CompanyresidentState);
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="">Select an option</option>
                    {listresidentState?.map((item, index) => (
                      <option key={index.id} value={item.StateId}>
                        {item.StateName}
                      </option>
                    ))}
                  </Field>
                  {!selectedState && (
                    <ErrorMessage
                      name="residentState"
                      component="div"
                      className="error-message"
                    />
                  )}
                </div>
                  
                  </>)
                  
                }

                  {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase()?(<>
                    <div className="profileForm">
                  <label className="profileLabel" htmlFor="city">
                    {defaultLanguage?.select_city}<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    className="profileField"
                    id="city"
                    name="city"
                    value={selectedCity}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      setFieldValue("city", e.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="">Select an option</option>
                    {cityListData?.map((item, index) => (
                      <option key={index.id} value={item.CityId}>
                        {item.CityName}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="city"
                    component="div"
                    className="error-message"
                  />
                </div>
                  
                  </>):(<>
                  
                  
                    <div className="profileForm">
                  <label className="profileLabel" htmlFor="city">
                  {defaultLanguage?.select_city}<span className="asterisk">*</span>
                  </label>
                  <Field
                    as="select"
                    className="profileField"
                    id="city"
                    name="residentCity"
                    value={CompnayresidentCity}
                    onChange={(e) => {
                      setCompnayresidentCity(e.target.value);
                      setFieldValue("residentCity", e.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                  >
                    <option value="">Select an option</option>
                    {indivResidentCity?.map((item, index) => (
                      <option key={index.id} value={item.CityId}>
                        {item.CityName}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="residentCity"
                    component="div"
                    className="error-message"
                  />
                </div>
                  
                  </>)

                  }

                  {getUserTypeValue.toLowerCase() ===
                  "Individual".toLowerCase()?(<>
                  
                  <div className="profileForm">
                  <label className="profileLabel" htmlFor="currentPincode">
                    Pincode<span className="asterisk">*</span>
                  </label>
                  <Field
                    className="profileField"
                    type="text"
                    id="currentPincode"
                    name="currentPincode"
                    value={indivCurrentPincode ? indivCurrentPincode : ""}
                    onChange={(e) => {
                      setindivCurrentPincode  (e.target.value);
                      setFieldValue("currentPincode", e.target.value);
                    }}
                    placeholder="Enter Pincode"
                    onKeyPress={handleKeyPress}
                  />
                  <ErrorMessage
                    name="currentPincode"
                    component="div"
                    className="error-message"
                  />
                </div>
                  
                  </>):(<>
                  
                  
                    <div className="profileForm">
                  <label className="profileLabel" htmlFor="currentPincode">
                   Pincode<span className="asterisk">*</span>
                  </label>
                  <Field
                    className="profileField"
                    type="text"
                    id="currentPincode"
                    name="residentPincode"
                    value={residentPincode ? residentPincode : ""}
                    onChange={(e) => {
                      setResidentPincode(e.target.value);
                      setFieldValue("residentPincode", e.target.value);
                    }}
                    placeholder="Enter Pincode"
                    onKeyPress={handleKeyPress}
                  />
                  <ErrorMessage
                    name="residentPincode"
                    component="div"
                    className="error-message"
                  />
                </div> 
                  </>)}

                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase() && (
                  <div className="profileForm profileFormCheck">
                    <label className="profileLabel" htmlFor="current">
                      Same as current address
                    </label>
                    <Field
                      className="profileField profileFormCheckBox"
                      type="checkbox"
                      id="current"
                      name="current"
                      checked={isSameAsCurrentAddress}
                      onChange={() => handleCheckboxChange(setFieldValue, values)}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                )}

                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase() ? (
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="residentAddress">
                      Residence Address<span className="asterisk">*</span>
                    </label>
                    <Field
                      className="profileField"
                      type="text"
                      id="residentAddress"
                      name="residentAddress"
                      value={indivResidenceAddress ? indivResidenceAddress :"" }
                      onChange={(e) => {
                       setindivResidenceAddress(e.target.value);
                        setFieldValue("residentAddress", e.target.value);
                      }}
                      placeholder="Residence Address"
                      onKeyPress={handleKeyPress}
                      disabled={isSameAsCurrentAddress}
                      // validate={isSameAsCurrentAddress ? undefined : validate(residentAddress ? residentAddress?.Address : "")}
                    />
                    <ErrorMessage
                      name="residentAddress"
                      component="div"
                      className="error-message"
                    />
                  </div>
                ):(
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="currentAddress">
                      Company Address<span className="asterisk">*</span>
                    </label>
                    <Field
                      className="profileField"
                      type="text"
                      id="residentAddress"
                      name="residentAddress"
                      value={residentAddress ? residentAddress : ""}
                      onChange={(e) => {
                        setResidentAddress(e.target.value);
                        setFieldValue("residentAddress", e.target.value);
                      }}
                      placeholder="Company Address"
                      onKeyPress={handleKeyPress}
                    />
                    <ErrorMessage
                      name="residentAddress"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}
                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase() && (
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="residentCountry">
                       {defaultLanguage?.select_country}<span className="asterisk">*</span>
                    </label>
                    <Field
                      as="select"
                      className="profileField"
                      id="residentCountry"
                      name="residentCountry"
                      value={indivResidentCountry}
                      onChange={(e) => {
                        setindivResidentCounty(e.target.value);
                        setFieldValue("residentCountry", e.target.value);
                        handleChange(e);
                      }}
                      onKeyPress={handleKeyPress}
                      disabled={isSameAsCurrentAddress}
                    >
                      <option value="">Select an option</option>
                      {oplistindivCountry?.map((item, index) => (
                        <option key={index.id} value={item.CountryId}>
                          {item.CountryName}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="residentCountry"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}
                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase() && (
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="residentState">
                      {defaultLanguage?.select_state}<span className="asterisk">*</span>
                    </label>
                    <Field
                      as="select"
                      className="profileField"
                      id="residentState"
                      name="residentState"
                      value={indivResidentState}
                      onChange={(e) => {
                        setndivResidentState(e.target.value);
                        setFieldValue("residentState", e.target.value);
                 
                        handleChange(e);
                      }}
                      onKeyPress={handleKeyPress}
                      disabled={isSameAsCurrentAddress}
                      // validate={isSameAsCurrentAddress ? undefined : validate}
                    >
                      <option value="">Select an option</option>
                      {oplistindivState?.map((item, index) => (
                        <option key={index.id} value={item.StateId}>
                          {item.StateName}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="residentState"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}

           
                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase() && (
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="residentCity">
                      {defaultLanguage?.select_city}<span className="asterisk">*</span>
                    </label>
                    <Field
                      as="select"
                      className="profileField"
                      id="residentCity"
                      name="residentCity"
                      value={indivResidentCity}
                      onChange={(e) => {
                        setindivResidentCity(e.target.value);
                        setFieldValue("residentCity", e.target.value);
                        handleChange(e);
                      }}
                      onKeyPress={handleKeyPress}
                      disabled={isSameAsCurrentAddress}
                      // validate={isSameAsCurrentAddress ? undefined : validate}
                    >
                      <option value="">Select an option</option>
                      {oplistindivCity?.map((item, index) => (
                        <option key={index.id} value={item.CityId}>
                          {item.CityName}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="residentCity"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}


                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase() && (
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="residentPincode">
                      Pincode<span className="asterisk">*</span>
                    </label>
                    <Field
                      className="profileField"
                      type="text"
                      id="residentPincode"
                      name="residentPincode"
                      value={indvResidencePincode ? indvResidencePincode : ""}
                      onChange={(e) => {
                        
                        setindvResidencePincode (e.target.value);

                        setFieldValue("residentPincode", e.target.value);
                      }}
                      placeholder="Enter Pincode"
                      onKeyPress={handleKeyPress}
                      disabled={isSameAsCurrentAddress}
                      // validate={isSameAsCurrentAddress ? undefined : validate}
                    />
                    {!residentPincode && (
                      <ErrorMessage
                        name="residentPincode"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
                {getUserTypeValue.toLowerCase() ==
                  "Individual".toLowerCase() && (
                  <div className="profileForm">
                    <label className="profileLabel" htmlFor="refer">
                      Refered By
                    </label>
                    <span className="asterisk">*</span>
                    <Field
                      as="select"
                      className="profileField"
                      id="refer"
                      name="refer"
                      value={
                        personalDetail
                          ? personalDetail?.ReferredBy
                          : ""
                      }
                      onChange={(e) => {
                        setPreviousPersonalDetails((preDetails) => {
                          return { ...preDetails, ReferredBy: e.target.value };
                        });
                        setFieldValue("refer", e.target.value);
                      }}
                      onKeyPress={handleKeyPress}
                    >
                      <option value="">Select an option</option>
                      {getReferedByList?.map((item, index) => (
                        <option key={index} value={item.ReferredTypeId}>
                          {item.ReferredTypeName}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="refer"
                      component="div"
                      className="error-message"
                    />
                  </div>
                )}
                <div
                  className="profileForm"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    type="submit"
                    className="profileButton"
                    disabled={isSubmitting}
                    onKeyPress={handleKeyPress}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </MidSection>
    </SideMenuLayout>
  );
};
