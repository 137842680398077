
import React, { useState } from 'react'
import { FilterPopUp } from '../../marketplace/hoc/FilterPopUp/FilterPopUp'
import { QrPopUp } from '../../../components/ScanQrCodeModal/QrPopUp'
import { v4 as uuid } from 'uuid'

import { MarketPlaceLayout } from '../../../layout/dealershipMarketplaceLayout/MarketPlaceLayout'
import { MarketplaceHeader } from '../../../components/MarketplacHeader/MarketplaceHeader'
import { MarketplaceHoc } from '../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { concreteInitialDataThunk } from '../thunk/concreteInitialDataThunk'
import { MaterialCard } from '../../../components/MaterialsCard/MaterialCard'
import { concreteLoadMoreDataThunk } from '../thunk/concreteLoadMoreDataThunk'
import { MARKETPLACE_TYPES } from '../../../constants/e9Constants'
import { resetFilters, setFilterByCountry, setFilterByState, setFilterModalOpen, setFilteredDataRequired, setLikedData, setLikedListdata, setMaterialResponseTypeId, setMaterialsLocation, setVideoList, setfilterByEquipmentMakeId, setfilterByEquipmentTypeId, setisOriginal, updateRemoveFromMarketPlace, updateResponseTypeId } from '../slice/concreteMaterialMarketplaceSlice'
import { concreteInitialFilteredDataThunk } from '../thunk/concreteInitialFilteredDataThunk'
import { concreteLoadMoreFilteredDataThunk } from '../thunk/concreteLoadMoreFilteredDataThunk'
// import { useNavigate } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'
import { convertFromBase64, convertToBase64 } from '../../../../utils/formatConverter/convertToBase64'
import { contactSeller, getMaterialLikeList, getResponseTypeDetails, postMaterialLikes, removeMaterialFromMarketplace } from '../../../services/materials/materials'
import { useToast } from '@chakra-ui/react'

export const ConcreteMaterialMarketplace = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const materialTypeId = convertFromBase64(queryParams.get('materialTypeId'));

  const dispatch = useDispatch();
  const {data,likeListData} = useSelector((state)=>state.concreteMaterialMarketplace)
  const concreteMaterial = useSelector((state)=>state.concreteMaterialMarketplace)
  const { UserTypeValue, accountDetails } = useSelector(
    (state) => state.user
  );
  const UserCredentialId = accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId;
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(()=>{
    sessionStorage.clear()
  },[])
  
  useEffect(()=>{
    dispatch(concreteInitialDataThunk())
  },[])


  useEffect(()=>{
    mergeLike();
  },[likeListData,data])

  const [responseTypeDetailsByResponseIds,setResponseTypeDetailsByResponseIds]= useState({});

  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await getResponseTypeDetails();
            const obj={};
            data.ResponseTypes.forEach(({ResponseTypeId, ResponseTypeValue}) => {
              obj[ResponseTypeId] = ResponseTypeValue;            
            })
            setResponseTypeDetailsByResponseIds(obj);
        } catch (error) {
            console.error("Error fetching response type details:", error);
        }
    };
    fetchData();
}, []);

  const mergeLike=async ()=>{
      const likedData=(data || []).map((material)=>{
      const matchingLike=(likeListData || []).find(
        (likedItem)=>
          likedItem.MaterialId===material.MaterialId
      );
  
      if(matchingLike){
        return {
          ...material,
          likeStatus:true
        }
       }else{
        return {
          ...material,
          likeStatus:false
        }
       }
     })
     dispatch(setLikedData(likedData));
    }

  useEffect(()=>{
    (async()=>{
      await getLikeList();
    })()
  },[])

  const getLikeList=async ()=>{
    const data=await getMaterialLikeList(materialTypeId);
  
    if(data){
      dispatch(setLikedListdata(data?.Data?.LikeList));
    }else{
      dispatch(setLikedListdata([]));
    }
    }

    const handleLike=async (MaterialId)=>{
      const body={
        MaterialTypeId:materialTypeId,
        MaterialId:MaterialId
      }
      try {
        const data =await postMaterialLikes(body);
        if(data?.Status===201 || data?.Status===204 ){
          getLikeList();
        }else{
          console.error(data);
        }
      } catch (error) {
        throw error;
      }
    }

  const filterFunction = (values) => {
    // console.log("Filter CAlled",values)
    dispatch(setfilterByEquipmentMakeId(values.equipmentMake));
    dispatch(setfilterByEquipmentTypeId(values.equipmentTypeId));
    dispatch(setisOriginal(values.isOriginal))
    dispatch(setFilterByCountry(values.country));
    dispatch(setFilterByState(values.state));
    if (
      values.equipmentMake ||
      values.equipmentTypeId ||
      values.isOriginal ||
      values.country ||
      values.state 
    ) {

      // console.log("Thunk Values Filter Formik",values);
      dispatch(setFilteredDataRequired(true));
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(concreteInitialFilteredDataThunk());
    dispatch(setFilterModalOpen(false));
  };

  const removeFromMarketPlace = async ({MaterialId}) => {
    try {
      const data = await removeMaterialFromMarketplace(materialTypeId,MaterialId);
      if (data?.Status === 204) {
        dispatch(updateRemoveFromMarketPlace({MaterialId}));
      } else {
        console.error(data);
      }
    } catch (error) {
      throw error;
    }
  }

  const handleContactSeller = async (item) => {
    if(UserTypeValue?.toLowerCase() === "company" && !accountDetails?.CompanyDetails?.TaxNumber)
      toast({
        status: "error",
        description: "Please update GST Number to Contact Owner.",
        position: "top-right",
        isClosable: true,
      })  
      else{
        const {ResponseTypeId, MaterialId, OwnerUserCredentialId, MaterialUniqueId} = item;
        const isMyAsset = UserCredentialId === OwnerUserCredentialId;
        const connectData = {
          MaterialTypeId: materialTypeId,
          MaterialId: MaterialId,
          OwnerId: OwnerUserCredentialId,
          ResponseTypeId,
        };
    
        try {
          const {data, payload} = await contactSeller(connectData);
          if (data?.Status === 201 || data?.Status === 204) {
            dispatch(updateResponseTypeId({MaterialUniqueId, ResponseTypeId: payload.ResponseTypeId}));
          } else {
            console.error(data);
          }
        } catch (error) {
          throw error;
        }
      }
   
  }

  const renderConcreteCard = (array)=>{
    const renderJsx = 
    array && array.length > 0 ?(
      array.map((data)=>{
        const isMyAsset = UserCredentialId === data.OwnerUserCredentialId || accountDetails?.CompanyDetails?.TaxNumber===data?.TaxNumber;
       return <>
        <MaterialCard 
        key={uuid() }
        imageUrl={
          data.ImageList && data.ImageList.length > 0 && 
            `${process.env.REACT_APP_CDN_PVT_URL}${JSON.parse(data?.ImageList)[0].ImageFileRef}`
        }
        isVerified = {data.IsVerified}
        productNumber = {data.ProductNumber}
        productName ={data.ProductName}
        equipmentTypeValue = {data.EquipmentTypeValue ? data.EquipmentTypeValue : "N.A."}
        oemName = {data.OEMName ? data.OEMName : "N.A."}
        modelName = {data.ModelName ? data.ModelName : "N.A."}
        minPriceRange={data.MinPriceRange}
        maxPriceRange= {data.MaxPriceRange}
        city = {data.City}
        state = {data.State}
        onClick={() => {
          const OwnerUserCredentialId = convertToBase64(data?.OwnerUserCredentialId)
          const materialId = convertToBase64(data?.MaterialId);
          dispatch(setVideoList(data?.VideoList))
          dispatch(setMaterialsLocation(data?.Location))
          dispatch(setMaterialResponseTypeId(data?.ResponseTypeId))
          navigate(
            `/marketplace/MaterialMarketplace/material-details/${materialId}/${OwnerUserCredentialId}`,{
              state:{
                materialTypeId:materialTypeId,
                likeStatus:data.likeStatus,
                isMyAsset:isMyAsset,
                materialData:data
              }
            }
          );
        }}
        likeStatus={data.likeStatus}
        onLikeClick={async ()=>{
          await handleLike(data.MaterialId)
        }}
        ResponseTypeId={data.ResponseTypeId}
        onContactSeller={() => isMyAsset ? removeFromMarketPlace(data) : handleContactSeller(data)}
        responseTypeDetailsByResponseIds={responseTypeDetailsByResponseIds}
        isMyAsset={isMyAsset}
        />
        </>

      })
    ) : (
      <p>No data available</p>
    )
      return renderJsx;
  }

  return (
    <>
          <FilterPopUp 
          marketplaceType={MARKETPLACE_TYPES.MATERIALS_MARKETPLACE}
          isModalOpen={concreteMaterial.filterModalOpen}
          initialEquipmentMakeValue={concreteMaterial.filterByEquipmentMake}
          initialCountryValue={concreteMaterial.filterByCountry}
          initialStateValue={concreteMaterial.filterByState}
          onResetFilterClick={() => {
            dispatch(resetFilters());
          }}
          onSubmitButtonClick={filterFunction}
          onCloseButtonClick={() => {dispatch(setFilterModalOpen(false))}}
          />
    <QrPopUp />
    <MarketPlaceLayout>
        <MarketplaceHeader 
          heading="Concrete Store"
          filterButtonSelected={concreteMaterial.filteredDataRequired}
          filterButtonClickAction={()=>dispatch(setFilterModalOpen(true))}
        />
          <MarketplaceHoc
            marketplaceType="Material contact"
            dataArray={concreteMaterial.likedData ? concreteMaterial.likedData : []}
            endOfData={concreteMaterial?.endOfData}
            initialLoading={concreteMaterial?.initialLoading}
            paginationLoading={concreteMaterial?.paginationLoading}
            cardsRenderFunction={renderConcreteCard}
            loadMoreAction={() => {
              concreteMaterial.filteredDataRequired
                ? dispatch(concreteLoadMoreFilteredDataThunk())
                : dispatch(concreteLoadMoreDataThunk(concreteMaterial.paginitionCount))
            }}
          />
    </MarketPlaceLayout>
    </>
  )
}
