import { createSlice, current } from "@reduxjs/toolkit";

import { initialState } from "../constants/initialState";
import { getUemInitialDataThunk } from "../thunks/getUemInitialDataThunk";
import { getUemLoadMoreDataThunk } from "../thunks/getUemLoadMoreDataThunk";
import {getUemInititalFilteredDataThunk } from "../thunks/getUemInitialFilteredDataThunk";
import { getUemLoadMoreFilteredDataThunk } from "../thunks/getUemLoadMoreFilteredDataThunk";
import { getUemFilterImagesDataThunk } from "../thunks/getUemFilterImagesDataThunk";

export const usedEquipmentMarketplaceSlice = createSlice({
  name: "usedEquipmentMarketplace",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setUserImageReference: (state, action) => {
      state.userImageReference = action.payload;
    },
    setAssetImageReference: (state, action) => {
      state.assetImageReference = action.payload;
    },
    
    setFilteredDataRequired: (state, action) => {
      state.filteredDataRequired = action.payload;
    },
    setFilterModalOpen: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setFilterByCountry: (state, action) => {
      state.filterByCountry = action.payload;
    },
    setFilterByState: (state, action) => {
      state.filterByState = action.payload;
    },
    setFilterByVerificationStatus: (state, action) => {
      state.filterByVerificationStatus = action.payload;
    },
    setFilterByEquipmentType: (state, action) => {
      state.filterByEquipmentType = action.payload;
    },
    sendConnectRequest: (state, {payload}) => {
      // console.log(payload)
      const marketplaceDetails = []
    const index = state.data.findIndex((card) => (parseInt(card.E9MarketPlaceId) === parseInt(payload)))
    // console.log(index)


    const selectedObject = current(state.data)[index]

    state.data[index] = selectedObject
    
    // console.log(selectedObject)
      state.data[index] = {...state.data[index], ResponseTypeValue: "Request Sent"}
    },
    sendRevokeRequest : (state, {payload}) => {
      const marketplaceDetails = []
      const index = state.data.findIndex((card) => (parseInt(card.E9MarketPlaceId) === parseInt(payload)))
      // console.log(index)
  
  
      const selectedObject = current(state.data)[index]
  
      state.data[index] = selectedObject
      
      // console.log(selectedObject)
        state.data[index] = {...state.data[index], ResponseTypeValue: ""}
    },
    resetFilters : (state) => {
      state.filterByEquipmentType = null;
      state.filterByCountry = null;
      state.filterByState = null;
      state.filterByVerificationStatus = null;
      state.filteredDataRequired = false;
      state.filterModalOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUemFilterImagesDataThunk.pending, (state) => {
        state.equipmentTypeFilterList=null;
        state.filterIconImageReference=null;
      })
      .addCase(getUemFilterImagesDataThunk.fulfilled, (state,action) => {
        state.equipmentTypeFilterList=action.payload.GetEquipmentTypeFilterList;
        state.filterIconImageReference=action.payload.EquipmentTypeIconBaseReference;
      })
      .addCase(getUemInitialDataThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 1;
        state.endOfData = false
      })
      .addCase(getUemInitialDataThunk.fulfilled, (state, action) => {
        state.data = action.payload.UsedEquipmentList;
        state.assetImageReference = action.payload.AssetImageBaseReference;
        state.userImageReference = action.payload.UserProfilePicBaseReference;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        if(action.payload.UsedEquipmentList.length < state.cardCount){
          state.endOfData = true
        }
      })
      .addCase(getUemLoadMoreDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(getUemLoadMoreDataThunk.fulfilled, (state, action) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        state.data = [...state.data, ...action.payload];
        if (action.payload.length >= state.cardCount) {
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })
      .addCase(getUemInititalFilteredDataThunk.pending, (state) => {
        state.data = null;
        state.initialLoading = true;
        state.paginationLoading = false;
        state.error = false;
        state.errorMessage = false;
        state.paginationCount = 1;
        state.endOfData = false;
      })
      .addCase(getUemInititalFilteredDataThunk.fulfilled, (state, action) => {
        state.data = action.payload.GetFilteredAllUsedEquipments;
        state.assetImageReference = action.payload.AssetImageBaseReference;
        state.userImageReference = action.payload.UserProfilePicBaseReference;
        state.paginationCount = 1;
        state.initialLoading = false;
        state.error = false;
        state.errorMessage = null;
        if(action.payload.GetFilteredAllUsedEquipments?.length < state.cardCount){
          state.endOfData = true
        }
      })
      .addCase(getUemLoadMoreFilteredDataThunk.pending, (state) => {
        state.paginationLoading = true;
        state.error = false;
        state.errorMessage = null;
        state.error = false;
        state.errorMessage = null;
      })
      .addCase(getUemLoadMoreFilteredDataThunk.fulfilled, (state, action) => {
        state.error = false;
        state.errorMessage = null;
        state.paginationLoading = false;
        state.data = [...state.data, ...action.payload];
        if (action.payload.length >= state.cardCount) {
          state.paginationCount += 1;
        } else {
          state.endOfData = true;
        }
      })
  },
});

export const {
  setData,
  setAssetImageReference,
  setUserImageReference,
  setFilterModalOpen,
  setFilteredDataRequired,
  setFilterByCountry,
  setFilterByEquipmentType,
  setFilterByState,
  setFilterByVerificationStatus,
  sendConnectRequest,
  sendRevokeRequest,
  resetFilters
} = usedEquipmentMarketplaceSlice.actions;
