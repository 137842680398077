import React from "react";
import { SideMenuLayout } from "../../layout/sideMenuLayout/SideMenuLayout";
import { DashboardNavBar } from "../../components/DashboardNavBar/DashboardNavBar";
import { MidSection } from "../../../sharedComponent/midSection/MidSection";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { UpcomingJobs } from "./JobStatusScrren/Ongoing/Ongoing";
import { Cancelled } from "./JobStatusScrren/Cancelled/Cancelled";
import { OnGoing } from "./JobStatusScrren/Upcoming/Upcoming";
import { Completedjob } from "./Completed/Completed";

import './JobStatus.css';
export const JobStatus = () => {
  return (
    <div>
      <SideMenuLayout>
        <DashboardNavBar />
        <MidSection>
          <div className="tabs-section">
            <Tabs colorScheme='orange'>
              <TabList>
                <Tab>Upcoming</Tab>
                <Tab>Ongoing</Tab>
                <Tab>Completed</Tab>
                <Tab>Cancelled</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <UpcomingJobs />
                </TabPanel>
                <TabPanel>
                  <OnGoing />
                </TabPanel>
                <TabPanel>
                   <Completedjob />
                </TabPanel>
                <TabPanel>
                  <Cancelled />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </MidSection>
      </SideMenuLayout>
    </div>
  );
};
