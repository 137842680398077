import { API_CONSTANTS } from "../../constants/apiConstants";

const sidebarData = {
  businessOwnerIndividual: [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      name: `Dashboard`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      name: `Profile`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      name: `My_Leads`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      name: `My_Subscription`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      subMenu: [
        {
          name: `My_free_contacts`,
          route: "/subscription/my-free-contacts",
        },
        {
          name: `purchased_subscriptions`,
          route: "/subscription/purchased-subscriptions",
        },
        {
          name: `Buy_New_Subscription`,
          route: "/subscription/subscription-type",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      name: `My_Transactions`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      subMenu: [
        {
          name: `pay_per_use`,
          route: "",
        },
        {
          name: `subscriptions`,
          route: "/transactions/subscription",
        },
        {
          name: `refund_payment`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Refer_a_friend`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      name: `Settings`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      name: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      name: `Legal_Policy`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      subMenu: [
        {
          name: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          name: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          name: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          name: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      name: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ],
  businessOwnerCompany: [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      name: `Dashboard`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      name: `Profile`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      name: `My_Leads`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      name: `My_Subscription`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      subMenu: [
        {
          name: `My_free_contacts`,
          route: "/subscription/my-free-contacts",
        },
        {
          name: `purchased_subscriptions`,
          route: "/subscription/purchased-subscriptions",
        },
        {
          name: `Buy_New_Subscription`,
          route: "/subscription/subscription-type",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      name: `My_Transactions`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      subMenu: [
        {
          name: `pay_per_use`,
          route: "",
        },
        {
          name: `subscriptions`,
          route: "/transactions/subscription",
        },
        {
          name: `refund_payment`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Refer_a_friend`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      name: `Settings`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      name: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      name: `Legal_Policy`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      subMenu: [
        {
          name: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          name: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          name: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          name: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      name: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ],
  equipmentOwnerIndividual: [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      name: `Dashboard`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      name: `Profile`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      name: `My_Leads`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      name: `My_Subscription`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      subMenu: [
        {
          name: `My_free_contacts`,
          route: "/subscription/my-free-contacts",
        },
        {
          name: `purchased_subscriptions`,
          route: "/subscription/purchased-subscriptions",
        },
        {
          name: `Buy_New_Subscription`,
          route: "/subscription/subscription-type",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      name: `My_Transactions`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      subMenu: [
        {
          name: `pay_per_use`,
          route: "",
        },
        {
          name: `subscriptions`,
          route: "/transactions/subscription",
        },
        {
          name: `refund_payment`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Refer_a_friend`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      name: `Settings`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      name: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      name: `Legal_Policy`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      subMenu: [
        {
          name: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          name: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          name: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          name: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      name: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ],
  equipmentOwnerCompany: [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      name: `Dashboard`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      name: `Profile`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      name: `My_Leads`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      name: `My_Subscription`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      subMenu: [
        {
          name: `My_free_contacts`,
          route: "/subscription/my-free-contacts",
        },
        {
          name: `purchased_subscriptions`,
          route: "/subscription/purchased-subscriptions",
        },
        {
          name: `Buy_New_Subscription`,
          route: "/subscription/subscription-type",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      name: `My_Transactions`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      subMenu: [
        {
          name: `pay_per_use`,
          route: "",
        },
        {
          name: `subscriptions`,
          route: "/transactions/subscription",
        },
        {
          name: `refund_payment`,
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Refer_a_friend`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      name: `Settings`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      name: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      name: `Legal_Policy`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      subMenu: [
        {
          name: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          name: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          name: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          name: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      name: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ],
  maintenancePartnerCompany: [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      name: `Dashboard`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      name: `Profile`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      name: `Job_Status`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/job-status",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      name: `My_Subscription`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      subMenu: [
        {
          name: `My_free_contacts`,
          route: "/subscription/my-free-contacts",
        },
        {
          name: `purchased_subscriptions`,
          route: "/subscription/purchased-subscriptions",
        },
        {
          name: `Buy_New_Subscription`,
          route: "/subscription/subscription-type",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      name: `My_Transactions`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      subMenu: [
        {
          name: `pay_per_use`,
          route: "",
        },
        {
          name: `subscriptions`,
          route: "/transactions/subscription",
        },
        {
          name: `refund_payment`,
          route: "",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Quiz`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Refer_a_friend`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      name: `Settings`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      name: `EQUIP9_Support`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      name: `Legal_Policy`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      subMenu: [
        {
          name: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          name: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          name: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          name: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      name: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ],
  maintenancePartnerIndividual: [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      name: `Dashboard`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/dashboard",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      name: `Profile`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      name: `Job_Status`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/job-status",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      name: `My_Subscription`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      subMenu: [
        {
          name: `My_free_contacts`,
          route: "/subscription/my-free-contacts",
        },
        {
          name: `purchased_subscriptions`,
          route: "/subscription/purchased-subscriptions",
        },
        {
          name: `Buy_New_Subscription`,
          route: "/subscription/subscription-type",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      name: `My_Transactions`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      subMenu: [
        {
          name: `pay_per_use`,
          route: "",
        },
        {
          name: `subscriptions`,
          route: "/transactions/subscription",
        },
        {
          name: `refund_payment`,
          route: "",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Quiz`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: `Refer_a_friend`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      name: `Settings`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      name: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      name: `Legal_Policy`,
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      subMenu: [
        {
          name: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          name: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          name: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          name: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      name: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
    },
  ],
  operatorPartner: [
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Dashboard.svg`,
      name: "Dashboard",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      route: "/dashboard",
      subMenu: [],
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Profile.svg`,
      name: "Profile",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myprofile.svg`,
      route: "/my-profile",
      subMenu: [],
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Leads.svg`,
      name: "My_Leads",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/myleads.svg`,
      route: "/my-leads",
      subMenu: [],
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Subscription.svg`,
      name: "My_Subscription",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mysubcription.svg`,
      subMenu: [
        {
          name: "My_free_contacts",
          route: "/subscription/my-free-contacts",
        },
        {
          name: "purchased_subscriptions",
          route: "/subscription/purchased-subscriptions",
        },
        {
          name: "Buy_New_Subscription",
          route: "/subscription/subscription-type",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Purchase.svg`,
      name: "My_Transactions",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/mytransction.svg`,
      subMenu: [
        {
          name: "pay_per_use",
          route: "",
        },
        {
          name: "subscriptions",
          route: "/transactions/subscription",
        },
        {
          name: "refund_payment",
          route: "",
        },
      ],
      route: "",
    },

    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Refer&Earn.svg`,
      name: "Refer_a_friend",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/Refer-a-friend.svg`,
      route: "",
      subMenu: [],
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/settings.svg`,
      name: "Settings",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/settings.svg`,
      route: "/settings",
      subMenu: [],
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Support.svg`,
      name: "EQUIP9™ Support",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/support.svg`,
      route: "/raisesupport",
      subMenu: [],
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/dashboard.svg`,
      name: "Legal_Policy",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/dashboard.svg`,
      subMenu: [
        {
          name: "About Us",
          route: "/legal-policy/about-us",
        },
        {
          name: "Privacy Policy",
          route: "/legal-policy/privacy-policy",
        },
        {
          name: "Contact Us",
          route: "/legal-policy/contact-us",
        },
        {
          name: "Terms and Condition",
          route: "/legal-policy/terms-and-condition",
        },
      ],
      route: "",
    },
    {
      icon: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/logout.svg`,
      name: "Logout",
      iconhover: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/iconshover/log-out.svg`,
      route: "",
      subMenu: [],
    },
  ],
};

export const sideBarOptionsByRole = (businessType = "", userType = "") => {
  const lowerCaseBusinessType = businessType.toString().toLowerCase();
  const lowerCaseUserType = userType.toString().toLowerCase();
  switch (lowerCaseBusinessType && lowerCaseUserType) {
    case lowerCaseBusinessType === API_CONSTANTS.BUSINESS_OWNER &&
      lowerCaseUserType === API_CONSTANTS.COMPANY:
      return sidebarData.businessOwnerCompany;
    case lowerCaseBusinessType === API_CONSTANTS.BUSINESS_OWNER &&
      lowerCaseUserType === API_CONSTANTS.INDIVIDUAL:
      return sidebarData.businessOwnerIndividual;
    case lowerCaseBusinessType === API_CONSTANTS.EQUIPMENT_OWNER &&
      lowerCaseUserType === API_CONSTANTS.COMPANY:
      return sidebarData.equipmentOwnerCompany;
    case lowerCaseBusinessType === API_CONSTANTS.EQUIPMENT_OWNER &&
      lowerCaseUserType === API_CONSTANTS.INDIVIDUAL:
      return sidebarData.equipmentOwnerIndividual;
    case lowerCaseBusinessType === API_CONSTANTS.MAINTENCE_PATNER &&
      lowerCaseUserType === API_CONSTANTS.COMPANY:
      return sidebarData.maintenancePartnerCompany;
    case lowerCaseBusinessType === API_CONSTANTS.MAINTENCE_PATNER &&
      lowerCaseUserType === API_CONSTANTS.INDIVIDUAL:
      return sidebarData.maintenancePartnerIndividual;
    default:
      return sidebarData.operatorPartner;
  }
};
