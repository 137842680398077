import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllMaintenanceJobsWaitingForYou } from "../../../services/jobsPosted/jobsPosted";

export const getInitialMaintenancePartnerJobs = createAsyncThunk("/mpJobs/initialData", async(_,{getState}) => {

const cardCount = getState().maintenanceJobsNearMe.cardCount;

try{
    
    const data = await getAllMaintenanceJobsWaitingForYou(cardCount,0);

    if(data.Status === 200 && Object.keys(data.Data).length > 0){
        if(data.Data.TotalRecords && data.Data.TotalRecords > 0){
            return data.Data.JobsDetails
        }
        else{
            return [];
        }
    }
    else{
       return [];
    }
}
catch(error){
}
})