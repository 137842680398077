import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { MarketPlaceLayout } from "../../layout/dealershipMarketplaceLayout/MarketPlaceLayout";
import { v4 as uuid } from "uuid";
import { Flex, Box, Divider, Text, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { convertFromBase64 } from "../../../utils/formatConverter/convertToBase64";
import { API_CONSTANTS } from "../../../constants/apiConstants";
import MaterialHeaderCard from "../MaterialDetailsCard/MaterialHeaderCard";
import { MaterialDetailsCard } from "../MaterialDetailsCard/MaterialDetailsCard";
import { getSessionStorageItems } from "../../helpers/sessionStorage/sessionStorage";
import { QrPopUp } from "../ScanQrCodeModal/QrPopUp";
import {
  contactSeller,
  getMaterialDetails,
  getResponseTypeDetails,
  postMaterialLikes,
  removeMaterialFromMarketplace,
} from "../../services/materials/materials";
import "./MaterialDetails.css";
import { getReponseTypeByResponseId } from "../../helpers/buttonTextGeneration/buttonTextGeneration";
import { V5FixedFooterSidebarLayout } from "../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";

export const MaterialDetail = () => {
  const { state } = useLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const materialTypeId = state?.materialTypeId;
  const likeStatus = state?.likeStatus;
  const isMyAsset = state?.isMyAsset;
  const material_data = state?.materialData;

  const { MaterialId, OwnerUserCredentialId } = useParams();
  const userData = useSelector((state) => state.user);
  const UserCredentialId = useSelector(
    (state) =>
      state.user?.accountDetails?.UserDetails?.CredentialDetails
        ?.UserCredentialId
  );
  const [
    responseTypeDetailsByResponseIds,
    setResponseTypeDetailsByResponseIds,
  ] = useState({});

  const {UserTypeValue, accountDetails } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getResponseTypeDetails();
        const obj = {};
        data.ResponseTypes.forEach(({ ResponseTypeId, ResponseTypeValue }) => {
          obj[ResponseTypeId] = ResponseTypeValue;
        });
        setResponseTypeDetailsByResponseIds(obj);
      } catch (error) {
        console.error("Error fetching response type details:", error);
      }
    };
    fetchData();
  }, []);

  const [materialData, setMaterialData] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const [imageList, setImageList] = useState();
  const [imageListArray, setImageListArray] = useState();
  const [videoListArray, setVideoListArray] = useState();

  const decodedMaterialId = convertFromBase64(MaterialId);
  const decodedOwnerUserCredentialId = convertFromBase64(OwnerUserCredentialId);
  const videoList = getSessionStorageItems("MATERIALS_VIDEO_LIST");
  const location = getSessionStorageItems("MATERIALS_LOCATION");
  const [ResponseTypeId, setResponseTypeId] = useState(null);

  const isAccepted =
    responseTypeDetailsByResponseIds[ResponseTypeId] === "Request Accepted";
  const isRejected =
    responseTypeDetailsByResponseIds[ResponseTypeId] === "Request Rejected";
  const isRequestSent =
    responseTypeDetailsByResponseIds[ResponseTypeId] === "Request Sent";

  const contactButtonText = getReponseTypeByResponseId(
    responseTypeDetailsByResponseIds,
    ResponseTypeId
  );

  const getResponseTypeIdFromStorage = useCallback(() => {
    const materialResponseTypeID = Number(
      sessionStorage.getItem("MATERIALS_RESPONSE")
    );
    setResponseTypeId(
      materialResponseTypeID > 0 ? materialResponseTypeID : null
    );
  }, []);

  useEffect(() => {
    getResponseTypeIdFromStorage();
  }, [getResponseTypeIdFromStorage]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getMaterialDetails(decodedMaterialId);
        setMaterialData(data.Data?.MaterialDetails);
        setAdditionalDetails(data.Data?.MaterialDetails[0].AdditionalDetails);
        setImageList(data?.Data?.MaterialDetails[0]?.ImageList);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (imageList) {
      setImageListArray(JSON.parse(imageList));
    }
    if (videoList) {
      setVideoListArray(videoList);
    }
  }, [imageList]);

  const handleContactOwner = async () => {
    if(UserTypeValue?.toLowerCase() === "company" && !accountDetails?.CompanyDetails?.TaxNumber)
      toast({
        status: "error",
        description: "Please update GST Number to Contact Owner.",
        position: "top-right",
        isClosable: true,
      })  
    else{
      if (isAccepted || isRejected) return;
      const connectData = {
        MaterialTypeId: materialData[0]?.MaterialTypeId,
        MaterialId: materialData[0]?.MaterialId,
        OwnerId: decodedOwnerUserCredentialId,
        ResponseTypeId,
      };
      const { data, payload } = await contactSeller(connectData);
      if (data?.Status === 201 || data?.Status === 204) {
        sessionStorage.setItem("MATERIALS_RESPONSE", payload?.ResponseTypeId);
        setResponseTypeId(payload?.ResponseTypeId);
        toast({
          title: "",
          description: `${data?.Message?.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "",
          description: `${data?.Message?.SuccessMessage}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  const handleRemoveFromMarketplace = async () => {
    const data = await removeMaterialFromMarketplace(
      materialData[0]?.MaterialTypeId,
      materialData[0]?.MaterialId
    );

    if (data?.Status === 204) {
      toast({
        title: "",
        description: `Material removed from marketplace`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      navigate("/marketplace/MaterialMarketplace");
    } else {
      toast({
        title: "",
        description: `Failed`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleLike = async () => {
    const body = {
      MaterialTypeId: materialTypeId,
      MaterialId: decodedMaterialId,
    };
    try {
      const data = await postMaterialLikes(body);
      if (data?.Status === 201 || data?.Status === 204) {
      } else {
        console.error(data);
      }
    } catch (error) {
      throw error;
    }
  };

  const renderDealershipDetail = (data) => {
    if (data) {
      return (
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "5em" }}>
            {data.map((material) => (
              <MaterialHeaderCard
                key={uuid()}
                imageUrl={imageListArray}
                productName={material?.ProductName}
                maxPriceRange={material?.MaxPriceRange}
                minPriceRange={material?.MinPriceRange}
                materialUniqueId={material?.MaterialUniqueId}
                location={location}
                isOriginal={material?.isOriginal}
                likeStatus={likeStatus}
                onLikeClick={async () => {
                  await handleLike();
                }}
                materialData={material_data}
              />
            ))}

            <div>
              <div
                className="material-region"
                style={{ display: "flex", gap: "2em" }}
              >
                {/* Parts */}
                {materialData.length === 0 ? (
                  <Text color="#011f6b" fontSize="1.2em" textAlign="center">
                    No Parts Available
                  </Text>
                ) : (
                  <Box className="material-main-container" overflowY={"scroll"}>
                    <Flex alignItems="center">
                      <Flex w="100%" alignItems="center" gap="0.5em">
                        <Box minWidth="max-content">
                          <Text
                            color="#011f6b"
                            fontWeight="semibold"
                            fontSize="2xl"
                          >
                            Part Details
                          </Text>
                        </Box>
                        <Flex alignItems="center" w="100%">
                          <Divider borderColor="#011f6b" borderWidth="0.1em" />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box>
                      <Flex
                        gap="2em"
                        flexDirection="row"
                        overflowX="scroll"
                        padding="1em 0 1em 0.5em"
                      >
                        <MaterialDetailsCard
                          formatType={true}
                          minPrice={materialData[0]?.MinPriceRange}
                          maxPrice={materialData[0]?.MaxPriceRange}
                          description="Price Range"
                        />
                        <MaterialDetailsCard
                          heading={
                            materialData[0]?.Height
                              ? materialData[0]?.Height
                              : "NA"
                          }
                          description="Height"
                        />

                        <MaterialDetailsCard
                          heading={
                            materialData[0]?.SLength
                              ? materialData[0]?.SLength
                              : "NA"
                          }
                          description="Length"
                        />
                        <MaterialDetailsCard
                          heading={
                            materialData[0]?.Radius
                              ? materialData[0]?.Radius
                              : "NA"
                          }
                          description="Radius"
                        />
                        <MaterialDetailsCard
                          heading={
                            materialData[0]?.Weight
                              ? materialData[0]?.Weight
                              : "NA"
                          }
                          description="Weight"
                        />
                      </Flex>
                    </Box>
                  </Box>
                )}
              </div>

              <div
                className="material-region"
                style={{ display: "flex", gap: "2em" }}
              >
                {/* Additional Details */}
                <Box width="100%" className="material-main-container">
                  <Flex alignItems="center">
                    <Flex w="100%" alignItems="center" gap="0.5em">
                      <Box minWidth="max-content">
                        <Text
                          color="#011f6b"
                          fontWeight="semibold"
                          fontSize="2xl"
                        >
                          Additional Details
                        </Text>
                      </Box>
                      <Flex alignItems="center" w="100%">
                        <Divider borderColor="#011f6b" borderWidth="0.1em" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box>
                    <Flex
                      gap="2em"
                      flexDirection="row"
                      overflowX="scroll"
                      padding="1em 0 1em 0.5em"
                    >
                      {additionalDetails === undefined ||
                      additionalDetails === null ? (
                        <Text
                          color="#011f6b"
                          fontSize="1.2em"
                          textAlign="center"
                        >
                          No Additional Details Available
                        </Text>
                      ) : (
                        <MaterialDetailsCard description={additionalDetails} />
                      )}
                    </Flex>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <p>No Material Listed</p>;
    }
  };

  let requestBtnClassName = "request__button ";
  if (isMyAsset) requestBtnClassName += "my__asset";
  if (isAccepted) requestBtnClassName += "request__accepted";
  if (isRejected) requestBtnClassName += "request__rejected";
  if (isRequestSent) requestBtnClassName += "request__sent";

  return (
    <>
      <QrPopUp />
      <V5FixedFooterSidebarLayout
        primaryButtonText={
          isMyAsset ? "Remove From Marketplace" : contactButtonText
        }
        primaryButtonClickAction={
          isMyAsset ? handleRemoveFromMarketplace : handleContactOwner
        }
        primaryButtonDisabled={isAccepted || isRejected}
        primaryButtonBorderColor={
          isAccepted || isRequestSent
            ? "#319f00"
            : isMyAsset
            ? ""
            : isRejected
            ? "#D60000"
            : "#F79C00"
        }
        primaryButtonTextColor={
          isAccepted || isRequestSent
            ? "#319f00"
            : isMyAsset
            ? ""
            : isRejected
            ? "#D60000"
            : "#F79C00"
        }
      >
        {/* <div style={{ margin: "0 52px", marginTop: "110px" }}> */}
        <Box h="100%" overflowY="scroll" padding="0em 1.5em">
          {renderDealershipDetail(materialData)}
        </Box>
        {/* </div> */}

        {/* <div className="request__button--wrapper">
          <button
            type="submit"
            className={requestBtnClassName}
            onClick={
              isMyAsset ? handleRemoveFromMarketplace : handleContactOwner
            }
            disabled={isAccepted || isRejected}
          >
            {isMyAsset ? "Remove From Marketplace" : contactButtonText}
          </button>
        </div> */}
      </V5FixedFooterSidebarLayout>
    </>
  );
};
