import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EquipmentMarketplaceCard } from "../../../components/EquipmentMarketplaceCard/EquipmentMarketplaceCard";
import { EquipmentRentalLayout } from "../../../layout/equipmentRentalLayout/EquipmentRentalLayout";
import { openQrModal } from "../../dashboard/slice/dashboardSlice";
import { QrPopUp } from "../../../components/ScanQrCodeModal/QrPopUp";
import AvatarIcon from "../../../../assets/user.jpg";
import { MarketplaceHoc } from "../../marketplace/hoc/MarketplaceHoc/MarketplaceHoc";
import { FilterPopUp } from "../../marketplace/hoc/FilterPopUp/FilterPopUp";
import { primaryKeyEncryption } from "../../../helpers/dataConversion/primaryKeyEncryption";
import {
  resetFilters,
  sendConnectRequest,
  sendRevokeRequest,
  setFilterByCountry,
  setFilterByEquipmentType,
  setFilterByState,
  setFilterByVerificationStatus,
  setFilterModalOpen,
  setFilteredDataRequired,
} from "../slice/usedEquipmentMarketplaceSlice";
import { getUemInitialDataThunk } from "../thunks/getUemInitialDataThunk";
import { getUemLoadMoreDataThunk } from "../thunks/getUemLoadMoreDataThunk";
import { getUemInititalFilteredDataThunk } from "../thunks/getUemInitialFilteredDataThunk";
import { getUemLoadMoreFilteredDataThunk } from "../thunks/getUemLoadMoreFilteredDataThunk";
import { MARKETPLACE_TYPES } from "../../../constants/e9Constants";
import { MarketplaceHeader } from "../../../components/MarketplacHeader/MarketplaceHeader";
import { getUemFilterImagesDataThunk } from "../thunks/getUemFilterImagesDataThunk";
import { NativeCarousel } from "../../../components/Carousel/NativeCarousel";
import { getAllResponseTypes } from "../../../services/responseTypes/responseTypes";
import { getFreeLeadsForCompanyThunk } from "../../freeLeads/thunks/getFreeLeadsForCompanyThunk";
import { getUemConnectLeadCheckThunk } from "../../purchasedLeadsCheck/thunks/getUemConnectLeadsCheckThunk";
import { NoLeadsPopUp } from "../../../components/NoLeadsPopUp/NoLeadsPopUp";
import axios from "axios";
import { getFreeLeadsForIndividualThunk } from "../../freeLeads/thunks/getFreeLeadsForUserThunk";
import { sendLikeRequestToEquipment } from "../../../services/equipmentMarketplace/equipmentMarketplace";
import { debounce } from "lodash";
import { fetchHandler } from "../../../../index";
import { API_CONSTANTS } from "../../../../constants/apiConstants";
import { updateAssetContactRequest } from "../../../services/myLeads/myLeads";
import { getInitialUserAssetsThunk } from "../../equipmentStore/thunks/getInitialUserAssetsThunk";

export const UsedEquipmentMarketplace = () => {
  const dispatch = useDispatch();
  const uemData = useSelector((state) => state.usedEquipmentMarketplace);
  const { accountDetails, UserTypeValue, token } = useSelector(
    (state) => state.user
  );
  const userCredentialId =
    accountDetails?.UserDetails?.CredentialDetails?.UserCredentialId;
  const advertisements = useSelector((state) => state.advertisement);
  const navigate = useNavigate();
  const [selectedImageFilterButton, setSelectedImageFilterButton] =
    useState(null);
  const [allResponseTypes, setAllResponseTypes] = useState(null);
  const { freeLeadsData } = useSelector((state) => state.freeLeads);
  const { leadsRemaining } = useSelector(
    (state) => state.purchasedLeads.usedEquipment
  );
  const [freeLeadsCount, setFreeLeadsCount] = useState(0);
  const [isNoLeadModalOpen, setIsNoLeadsModalOpen] = useState(false);

  // const usedEquipmentCount = uemData.data?.length;
  const { data: companyEquipments } = useSelector(
    (state) => state.equipmentStore
  );
  const [companyEquipmentIds, setCompanyEquipmentIds] = useState(null);

  const getResponseTypesList = async () => {
    try {
      const data = await getAllResponseTypes();
      setAllResponseTypes(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsedEquipmentSubscriptionLeads = (freeSubscriptionData) => {
    const selectedDetails = freeLeadsData.find(
      ({ ServiceTypeValue, SubscriptionTypeValue }) =>
        ServiceTypeValue === "Used Equipment" &&
        SubscriptionTypeValue === "Contact Based"
    );
    if (selectedDetails) {
      setFreeLeadsCount(selectedDetails.ContactRemaining);
      return selectedDetails.ServiceTypeId;
    } else {
      return null;
    }
  };

  const handleContactSend = async (
    equipmentMarketplaceId,
    recieverCredentials
  ) => {
    const body = {
      E9MarketPlaceId: equipmentMarketplaceId,
      ReceiverUserCredentialId: recieverCredentials,
      ContactStatus: 1,
      RequestTypeId: 1,
    };
    let payload = [];
    for (const key in body) {
      const requiredKey = encodeURIComponent(key);
      const requiredValue = encodeURIComponent(body[key]);
      payload.push(`${requiredKey}=${requiredValue}`);
    }

    const finalPayload = payload.join("&");

    try {
      const data = await updateAssetContactRequest(finalPayload);
      dispatch(sendConnectRequest(equipmentMarketplaceId));
      // console.log(data);
    } catch (error) {
      // Handle errors here
    }
  };

  const handleRevoke = async (equipmentMarketplaceId, recieverCredentials) => {
    const body = {
      E9MarketPlaceId: equipmentMarketplaceId,
      ReceiverUserCredentialId: recieverCredentials,
      ContactStatus: 0,
      RequestTypeId: 4,
    };
    let payload = [];
    for (const key in body) {
      const requiredKey = encodeURIComponent(key);
      const requiredValue = encodeURIComponent(body[key]);
      payload.push(`${requiredKey}=${requiredValue}`);
    }

    const finalPayload = payload.join("&");

    try {
      const data = await updateAssetContactRequest(finalPayload);

      dispatch(sendRevokeRequest(equipmentMarketplaceId));
    } catch (error) {
      // Handle errors here
    }
  };

  const handleLike = async (body) => {
    try {
      const data = await sendLikeRequestToEquipment(body);
    } catch (error) {}
  };

  const filterFunction = (values) => {
    dispatch(setFilterByEquipmentType(values.equipmentType));
    dispatch(setFilterByCountry(values.country));
    dispatch(setFilterByState(values.state));
    dispatch(setFilterByVerificationStatus(values.verificationStatus));
    if (
      values.equipmentType ||
      values.country ||
      values.state ||
      values.verificationStatus
    ) {
      dispatch(setFilteredDataRequired(true));
    } else {
      dispatch(setFilteredDataRequired(false));
    }
    dispatch(getUemInititalFilteredDataThunk());
    dispatch(setFilterModalOpen(false));
  };

  const renderUsedEquipmentCards = (array, interval = 6) => {
    const renderJSX =
      array && array.length > 0 ? (
        array.map((equipment, index) => (
          <>
            <EquipmentMarketplaceCard
              key={uuid()}
              imageUrl={
                uemData.assetImageReference
                  ? `${process.env.REACT_APP_CDN_PVT_URL}${uemData.assetImageReference}${equipment.AssetImages.Image_Front_FileRef}`
                  : ""
              }
              equipmentTypeValue={equipment.EquipmentTypeValue}
              oemName={equipment.OEMName}
              modelName={equipment.ModelName}
              price={`${equipment.ExpectedPrice}`}
              status={equipment.IsCurrentlyAvailable ? "Available" : "Sold"}
              ownerName={equipment.OwnerName}
              listedTime={equipment.Listed}
              equipmentId={equipment.E9MarketPlaceUniqueId}
              city={equipment.CityName}
              state={equipment.StateName}
              country={equipment.CountryName}
              userImageUrl={
                equipment.ProfilePictureFileReference &&
                uemData.userImageReference
                  ? `${process.env.REACT_APP_CDN_PVT_URL}${uemData.userImageReference}${equipment.ProfilePictureFileReference}`
                  : AvatarIcon
              }
              isPromoted={equipment.IsPromoted}
              isVerified={equipment.IsVerified}
              isLiked={equipment.LikeStatus}
              onClick={() => {
                navigate(
                  `/marketplace/MarketPlaceUEM/Cards/${primaryKeyEncryption(
                    equipment.E9MarketPlaceId
                  )}`
                );
              }}
              isCtaButton={
                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                equipment.ResponseTypeValue === "Request Revoked" ||
                equipment.ResponseTypeValue === "Request Sent" ||
                !equipment.ResponseTypeValue
              }
              buttonText={
                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                (companyEquipmentIds &&
                  companyEquipmentIds.includes(parseInt(equipment.AssetId)))
                  ? "My Asset"
                  : equipment.ResponseTypeValue &&
                    equipment.ResponseTypeValue !== "Request Revoked"
                  ? equipment.ResponseTypeValue
                  : "Contact Owner"
              }
              buttonColor={
                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                (companyEquipmentIds &&
                  companyEquipmentIds.includes(parseInt(equipment.AssetId)))
                  ? "#1E3264"
                  : equipment.ResponseTypeValue === "Request Rejected"
                  ? "#D60000"
                  : equipment.ResponseTypeValue === "Request Sent" ||
                    equipment.ResponseTypeValue === "Request Accepted"
                  ? "#319F00"
                  : "#F67C00"
              }
              onShareClick={() => {
                dispatch(openQrModal());
              }}
              onContactClick={() => {
                (userCredentialId &&
                  parseInt(equipment.AssetOwnerUserCredentialId) ===
                    parseInt(userCredentialId)) ||
                (companyEquipmentIds &&
                  companyEquipmentIds.includes(parseInt(equipment.AssetId)))
                  ? navigate("/equipment-store")
                  : !freeLeadsCount && !leadsRemaining
                  ? setIsNoLeadsModalOpen(true)
                  : equipment.ResponseTypeValue &&
                    equipment.ResponseTypeValue === "Request Sent"
                  ? handleRevoke(
                      equipment.E9MarketPlaceId,
                      equipment.AssetOwnerUserCredentialId
                    )
                  : !equipment.ResponseTypeValue ||
                    (equipment.ResponseTypeValue &&
                      equipment.ResponseTypeValue === "Request Revoked")
                  ? handleContactSend(
                      equipment.E9MarketPlaceId,
                      equipment.AssetOwnerUserCredentialId
                    )
                  : (() => {
                      return null;
                    })();
              }}
              onLikeClick={async (value) => {
                await handleLike({
                  E9MarketPlaceId: equipment.E9MarketPlaceId,
                  LikeStatus: value ? 1 : 0,
                });
              }}
            />

            {(index + 1) % interval === 0 && (
              <div
                key={`${index}`}
                style={{
                  width: "100%",
                  gridColumn: "span 3",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 8px 0px #00000040",
                  cursor: "pointer",
                }}
              >
                <NativeCarousel
                  images={
                    advertisements ? advertisements.data?.requiredImgUrl : []
                  }
                  redirectionUrl={
                    advertisements
                      ? advertisements.data?.requiredRedirectUrl
                      : []
                  }
                  speed="3000"
                  height="350px"
                  width="100%"
                />
              </div>
            )}
          </>
        ))
      ) : (
        <p>No data available</p>
      );

    return renderJSX;
  };

  useEffect(() => {
    dispatch(getUemFilterImagesDataThunk());
    getResponseTypesList();
    if (uemData.filteredDataRequired) {
      dispatch(getUemInititalFilteredDataThunk());
    } else {
      dispatch(getUemInitialDataThunk());
    }
  }, []);

  useEffect(() => {
    if (accountDetails && UserTypeValue === "Company") {
      dispatch(getFreeLeadsForCompanyThunk());
    } else {
      dispatch(getFreeLeadsForIndividualThunk());
    }
  }, []);

  useEffect(() => {
    if (freeLeadsData) {
      const serviceId = getUsedEquipmentSubscriptionLeads();
      if (serviceId) {
        dispatch(getUemConnectLeadCheckThunk(serviceId));
      }
    }
  }, [freeLeadsData]);

  useEffect(() => {
    if (
      UserTypeValue &&
      UserTypeValue.toLowerCase() === "company".toLowerCase()
    ) {
      dispatch(getInitialUserAssetsThunk());
    }
  }, []);

  useEffect(() => {
    if (companyEquipments) {
      const assetIds = companyEquipments.map(({ AssetId }) => AssetId);
      setCompanyEquipmentIds(assetIds);
    }
  }, [companyEquipments]);

  return (
    <>
      <FilterPopUp
        marketplaceType={MARKETPLACE_TYPES.EQUIPMENT_MARKETPLACE}
        isModalOpen={uemData.filterModalOpen}
        initialEquipmentValue={uemData.filterByEquipmentType}
        initialVerificationValue={uemData.filterByVerificationStatus}
        initialCountryValue={uemData.filterByCountry}
        initialStateValue={uemData.filterByState}
        onResetFilterClick={() => {
          dispatch(resetFilters());
        }}
        onSubmitButtonClick={filterFunction}
        onCloseButtonClick={() => {
          dispatch(setFilterModalOpen(false));
        }}
      />
      <QrPopUp />
      <NoLeadsPopUp
        isOpen={isNoLeadModalOpen}
        onCloseClick={() => {
          setIsNoLeadsModalOpen(false);
        }}
        onButtonClick={() => navigate("/subscription/subscription-type")}
      />
      <EquipmentRentalLayout>
        <MarketplaceHeader
          heading="Used Equipment Marketplace"
          filterButtonSelected={uemData.filteredDataRequired}
          filterButtonClickAction={() => {
            dispatch(setFilterModalOpen(true));
          }}
          filterImagesArray={
            uemData.equipmentTypeFilterList
              ? uemData.equipmentTypeFilterList
              : []
          }
          filterImagesReference={
            uemData.filterIconImageReference
              ? uemData.filterIconImageReference
              : ""
          }
          filterImageButtonClickAction={(EquipmentType) => {
            dispatch(setFilterByEquipmentType(EquipmentType));
            dispatch(getUemInititalFilteredDataThunk());

            if (selectedImageFilterButton === EquipmentType) {
              setSelectedImageFilterButton(null);
              dispatch(setFilteredDataRequired(false));
              dispatch(getUemInitialDataThunk());
            } else {
              setSelectedImageFilterButton(EquipmentType);
              dispatch(setFilteredDataRequired(true));
            }
          }}
          selectedImageFilterButton={selectedImageFilterButton}
        />
        <MarketplaceHoc
          marketplaceName="Used Marketplace"
          dataArray={uemData.data ? uemData.data : []}
          userImageBaseReference={
            uemData.userImageReference ? uemData.userImageReference : ""
          }
          assetImageBaseReference={
            uemData.assetImageReference ? uemData.assetImageReference : ""
          }
          endOfData={uemData.endOfData}
          loadMoreAction={() =>
            uemData.filteredDataRequired
              ? dispatch(getUemLoadMoreFilteredDataThunk())
              : dispatch(getUemLoadMoreDataThunk(uemData.paginitionCount))
          }
          initialLoading={uemData.initialLoading}
          paginationLoading={uemData.paginationLoading}
          cardsRenderFunction={renderUsedEquipmentCards}
          filterButtonArray={uemData.equipmentTypeFilterList}
          filterButtonImageReference={uemData.filterIconImageReference}
        />
      </EquipmentRentalLayout>
    </>
  );
};
