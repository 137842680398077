import EquipmentPartnerLogo from "../../../assets/webapp/registartion/Equipment-Owner.png";
import OperatorPartnerLogo from "../../../assets/webapp/registartion/Operator-Partner.png";
import BusinessOwnerLogo from "../../../assets/webapp/registartion/Business-Owner.png";
import GuestLogo from "../../../assets/webapp/registartion/Guest.png";
import MaintenancePartnerLogo from "../../../assets/webapp/registartion/Maintenance-Partner.png";
import CompanyLogo from "../../../assets/webapp/registartion/select-user-type/company-logo.png";
import IndividualLogo from "../../../assets/webapp/registartion/select-user-type/individual-logo.png";
import ceoLogo from "../../../assets/webapp/doc_flow/ceo.svg";
import managerLogo from "../../../assets/webapp/doc_flow/manager.svg";
import staffLogo from "../../../assets/webapp/doc_flow/staff.svg";
import { ReactSVG } from "react-svg";

export const imageSelector = (
  input,
  onClick = () => {},
  size = { height: "90%", width: "90%" }
) => {
  switch (input.toLowerCase()) {
    case "business owner":
      return (
        <img
          style={{ height: size.height, width: size.width }}
          src={BusinessOwnerLogo}
          onClick={onClick}
        />
      );
    case "maintenance partner":
      return (
        <img
          style={{ height: size.height, width: size.width }}
          src={MaintenancePartnerLogo}
          onClick={onClick}
        />
      );
    case "operator partner":
      return (
        <img
          style={{ height: size.height, width: size.width }}
          src={OperatorPartnerLogo}
          onClick={onClick}
        />
      );
    case "equipment owner":
      return (
        <img
          style={{ height: size.height, width: size.width }}
          src={EquipmentPartnerLogo}
          onClick={onClick}
        />
      );
    case "guest":
      return (
        <img
          style={{ height: size.height, width: size.width }}
          src={GuestLogo}
          onClick={onClick}
        />
      );

    case "company":
      return (
        <img
          style={{ height: size.height, width: size.width }}
          src={CompanyLogo}
        />
      );
    case "individual":
      return (
        <img
          style={{ height: size.height, width: size.width }}
          src={IndividualLogo}
        />
      );
    case "ceo" : 
    return(
      <img
      style={{ height: size.height, width: size.width }}
      src={ceoLogo}
      alt="CEO"
    />
    ) 
    case "manager" : 
    return(
      <img
      style={{ height: size.height, width: size.width }}
      src={managerLogo}
      alt="Manager"
    />
    ) 
    case "staff" : 
    return(
      <img
      style={{ height: size.height, width: size.width}}
      src={staffLogo}
      alt="staff"
    />
    ) 
    default:
      return "icon";
  }
};
